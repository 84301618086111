import React, { useEffect, useState, useRef } from "react";
import InnerHeader from "../etc/innerheader";
import { useLocation } from "react-router-dom";
import { getFetch } from "../../fetch/fetch";
import { IonLoading } from "@ionic/react";
import styles from "./studytimeranking.module.css";
import AspectRatio from "@mui/joy/AspectRatio";
import { CssVarsProvider } from '@mui/joy/styles';
import CircularProgress from '@mui/joy/CircularProgress';
import { LinearProgress } from "@mui/joy";
import {ReactComponent as Crown} from "../../../src/svg/mobiletotal/crown-solid.svg";

const StudyTimeRanking: React.FC<any> = (props) => {

    const [loading, setLoading] = useState(false);
    const [menuChangeLoading, setMenuChangeLoading] = useState(false);

    const headerRef = useRef<any>();
    const location: any = useLocation();

    const [dateInfoState, setDateInfoState] = useState<any>();
    const [weekInfoState, setWeekInfoState] = useState<any>();
    const [monthInfoState, setMonthInfoState] = useState<any>();

    const whiteRef = useRef<any>();
    const wrapperRef = useRef<any>();
    const [translateValue, setTranslateValue] = useState("translate(3%, -115%)");


    const [graphMenu, setGraphMenu] = useState("daily");

    const [height, setHeight] = useState(0);




    useEffect(() => {


        if (!headerRef || !headerRef.current) {
            console.log("noRef");
            return;
        }

        const calcBodyHeight = () => {

            if (!headerRef || !headerRef.current) {
                console.log("noRef");
                return;
            }

            console.log("gogogo");

            const totalHeight = window.innerHeight;
            const safeAreaTop = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const headerHeight = headerRef.current.offsetHeight;
            const bodyHeight = totalHeight - headerHeight - safeAreaTop;

            console.log(bodyHeight);
            console.log("headerHeight");
            console.log(headerHeight);
            setHeight(bodyHeight);
        }

        calcBodyHeight();

        window.addEventListener("resize", () => calcBodyHeight);

        return () => window.removeEventListener("resize", () => calcBodyHeight);

    }, [headerRef]);




    const setCharAt = (string: string, index: number, chr: string) => {
        const str = string.replaceAll(" ", "");
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }

    const insertUserID = async () => {

        try {

            setMenuChangeLoading(true);

            
            const result: any = await getFetch("/fingerprint/getUserIdFromFingerprintId");
            
            const userData = result.data;

            userData.reverse();

            userData.forEach((each: any, index: number) => {
                console.log(1);
                if (each.name) {
                    each.name = setCharAt(each.name, 1, "*");
                }
            })

            const dateInfo = location.state.dateInfo;
            const weekInfo = location.state.weekInfo;
            const monthInfo = location.state.monthInfo;


            const newDateInfo: any = [];
            const newWeekInfo: any = [];
            const newMonthInfo: any = [];

            dateInfo.forEach((eachDateInfo: any, index: number) => {
                eachDateInfo.totalTime = +eachDateInfo.totalTime;
                eachDateInfo.studytimeString = `${Math.floor(+eachDateInfo.totalTime / 3600000)}H ${Math.floor(+eachDateInfo.totalTime / 60000) % 60}M`
                userData.forEach((eachUser: any) => {
                    if (eachDateInfo.fingerprintId === eachUser.fingerprintId) {
                        eachDateInfo.userId = eachUser.id;
                        eachDateInfo.value = eachUser.value;
                        eachDateInfo.name = eachUser.name;
                        eachDateInfo.avatar = eachUser.avatar;
                    }
                });
                if (eachDateInfo.value === "student") {
                    newDateInfo.push(eachDateInfo);
                }

            })

            console.log("date");
            console.log(newDateInfo);

            newDateInfo.sort((a : any, b : any) => {

                if(!a.totalTime || !b.totalTime){
                    console.log("noOption");
                    return 0;
                }

                if(+a.totalTime > +b.totalTime){
                    console.log("sort");
                    return -1;
                }

                if(+a.totalTime < +b.totalTime){
                    console.log("nosort");
                    return -1;
                }
            });

            console.log(newDateInfo);

            const newDateInfo1 = [...newDateInfo];
            const newDateInfo2 = [...newDateInfo];
            const newnewDateInfo : any = [];

            for(var i =0; i < newDateInfo.length; i++){
                newDateInfo1.forEach((each1 : any, index1 : number) => {
                    var count = 0;
                    newDateInfo2.forEach((each2 : any, index2 : number) => {
                        if(each1.totalTime < each2.totalTime){
                            count++;
                        }
                    })
                    if(count === 0){
                        newnewDateInfo.push(each1);
                        newDateInfo1.splice(index1, 1);
                        newDateInfo2.splice(index1, 1);
                    }
                })
            }

            console.log("newenw");
            console.log(newnewDateInfo);



            newnewDateInfo.forEach((each: any) => {
                each.ratio = (Math.floor((+each.totalTime / +newDateInfo[0].totalTime * 1000))) / 1000;
            })

            weekInfo.forEach((eachDateInfo: any, index: number) => {
                eachDateInfo.studytimeString = `${Math.floor(+eachDateInfo.totalStudyTime / 3600000)}H ${Math.floor(+eachDateInfo.totalStudyTime / 60000) % 60}M`
                userData.forEach((eachUser: any) => {
                    if (eachDateInfo.fingerprintId === eachUser.fingerprintId) {
                        eachDateInfo.userId = eachUser.id;
                        eachDateInfo.value = eachUser.value;
                        eachDateInfo.name = eachUser.name;
                        eachDateInfo.avatar = eachUser.avatar;
                    }
                });
                if (eachDateInfo.value === "student") {
                    newWeekInfo.push(eachDateInfo);
                }
            })

            console.log(+"0");
            console.log("week");
            // console.log(newWeekInfo);
            newWeekInfo.sort((after: any, before: any) => {
                if (+after.totalStudyTime > +before.totalStudyTime) {
                    return false;
                } else {
                    return true;
                }
            });
            // console.log(newWeekInfo);

            newWeekInfo.forEach((each: any) => {
                each.ratio = (Math.floor((+each.totalStudyTime / +newWeekInfo[0].totalStudyTime * 1000))) / 1000;
            })

            monthInfo.forEach((eachDateInfo: any, index: number) => {
                eachDateInfo.studytimeString = `${Math.floor(+eachDateInfo.totalStudyTime / 3600000)}H ${Math.floor(+eachDateInfo.totalStudyTime / 60000) % 60}M`
                userData.forEach((eachUser: any) => {
                    if (eachDateInfo.fingerprintId === eachUser.fingerprintId) {
                        eachDateInfo.userId = eachUser.id;
                        eachDateInfo.value = eachUser.value;
                        eachDateInfo.name = eachUser.name;
                        eachDateInfo.avatar = eachUser.avatar;
                    }
                });
                if (eachDateInfo.value === "student") {
                    newMonthInfo.push(eachDateInfo);
                }
            })

            newMonthInfo.sort((after: any, before: any) => {
                if (+after.totalStudyTime > +before.totalStudyTime) {
                    return false;
                } else {
                    return true;
                }
            });

            newMonthInfo.forEach((each: any) => {
                each.ratio = (Math.floor((+each.totalStudyTime / +newMonthInfo[0].totalStudyTime * 1000))) / 1000;
            })


            setDateInfoState([...newnewDateInfo]);
            setWeekInfoState([...newWeekInfo]);
            setMonthInfoState([...newMonthInfo]);

            setTimeout(() => {
                setMenuChangeLoading(false);
            }, 500);

            

        } catch (e) {
            console.log(e);
            console.log("error");
            setTimeout(() => {
                setMenuChangeLoading(false);
            }, 1000);
        }


    }

    useEffect(() => {

        // console.log(location.state);

        if(!location.state){
            return;
        }

        if (!location.state.dateInfo || !location.state.weekInfo || !location.state.monthInfo) {
            return;
        }

        insertUserID();

    }, [location.state ? location.state.dateInfo : "", location.state ? location.state.weekInfo : '', location.state ? location.state.monthInfo : "" ]);


    const menuClickHandle = (index: number) => {

        setMenuChangeLoading(true);

        setTimeout(() => {
            setMenuChangeLoading(false);
        }, 1000);

        console.log(22);

        if (!wrapperRef.current || !whiteRef.current) {
            console.log("noRef");
            return;
        }

        const wrapperWidth = wrapperRef.current.clientWidth;
        const whiteWidth = whiteRef.current.clientWidth;

        const value0 = whiteWidth * 0.03;
        const value1 = wrapperWidth / 2 - whiteWidth / 2;
        const value2 = wrapperWidth - whiteWidth * 1.03;

        var newTranslateValue: any;
        switch (index) {
            case 0:
                newTranslateValue = value0;
                setGraphMenu("daily");
                break;
            case 1:
                newTranslateValue = value1;
                setGraphMenu("weekly");
                break;
            case 2:
                newTranslateValue = value2;
                setGraphMenu("monthly");
                break;
        }

        if (!newTranslateValue) {
            return;
        }

        const newTranslateValueText = `translate(${newTranslateValue}px, -115%)`;

        setTranslateValue(newTranslateValueText);
    }


    return (
        <div>
            <InnerHeader headerRef={headerRef} title="공부 시간 랭킹" />

            <div className={styles.totalBody} style={{ height: `${height}px`, overflow: "scroll" }}>
                <div className={styles.menuSelectorDiv}>
                    <div className={styles.menuSelector}>
                        <div className={styles.studyTimeSelectMenuWrapper} ref={wrapperRef}>
                            <div className={`${styles.studyTimeSelectMenuText} ${graphMenu === "daily" ? styles.active : ""}`} onClick={() => { menuClickHandle(0) }}>
                                일별
                            </div>
                            <div className={`${styles.studyTimeSelectMenuText} ${graphMenu === "weekly" ? styles.active : ""}`} onClick={() => { menuClickHandle(1) }}>
                                주별
                            </div>
                            <div className={`${styles.studyTimeSelectMenuText} ${graphMenu === "monthly" ? styles.active : ""}`} onClick={() => { menuClickHandle(2) }}>
                                월별
                            </div>
                        </div>
                        <div style={{ position: "relative" }}>
                            <div ref={whiteRef} className={styles.whiteWrapper} style={{ transform: translateValue }}>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.border}>

                </div>


                {
                    menuChangeLoading &&
                    <div className={styles.menuChangeLoading}>
                        <CssVarsProvider>
                            <LinearProgress color="primary" variant="plain" />
                        </CssVarsProvider>
                        {/* <LinearProgress /> */}
                    </div>
                }
                {
                    !menuChangeLoading &&
                    <div className={styles.body}>
                        {
                            graphMenu === "daily" &&
                            dateInfoState && dateInfoState.map((eachDateInfo: any, index: number) => {


                                return (
                                    <div className={styles.eachStudent} key={eachDateInfo.id}>
                                        <div className={styles.rankingNumberDiv}>

                                            <CssVarsProvider>
                                                <AspectRatio ratio="1/1" variant="plain">
                                                    <div className={`${styles.rankingNumberCircle} ${props.user.id === eachDateInfo.userId ? styles.mine : ""}`}>
                                                        {index + 1}
                                                    </div>
                                                </AspectRatio>
                                            </CssVarsProvider>

                                            <Crown className={`${styles.crown} ${index < 3 ? styles.active : ""}`} fill="#3d50b0" />
                                        </div>
                                        <div className={styles.rankingDescriptionDiv}>
                                            <div className={styles.rankingDescriptionNameDiv}>
                                                <div className={styles.studentName}>
                                                    {eachDateInfo.name}
                                                </div>
                                                <div className={styles.studyTime}>
                                                    {eachDateInfo.studytimeString}
                                                </div>
                                            </div>
                                            <div className={styles.studyTimeGraphDiv}>
                                                <div className={styles.studyTimeGraphBar}>
                                                    <div className={styles.studyTimeGraphBarContent} style={{ width: `${eachDateInfo.ratio * 100}%` }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }

                        {
                            (graphMenu === "daily" && dateInfoState && dateInfoState.length === 0) ?
                            <div className={styles.blankText}>
                                공부시간 데이터 수집 중입니다 🧐
                            </div>
                            :
                            ""
                        }

                        {
                            graphMenu === "weekly" &&
                            weekInfoState && weekInfoState.map((eachDateInfo: any, index: number) => {


                                return (
                                    <div className={styles.eachStudent} key={eachDateInfo.id}>
                                        <div className={styles.rankingNumberDiv}>
                                            <CssVarsProvider>
                                                <AspectRatio ratio="1/1" variant="plain">
                                                    <div className={`${styles.rankingNumberCircle} ${props.user.id === eachDateInfo.userId ? styles.mine : ""}`}>
                                                        {index + 1}
                                                    </div>
                                                </AspectRatio>
                                            </CssVarsProvider>
                                            <Crown className={`${styles.crown} ${index < 3 ? styles.active : ""}`} fill="#3d50b0" />


                                        </div>
                                        <div className={styles.rankingDescriptionDiv}>
                                            <div className={styles.rankingDescriptionNameDiv}>
                                                <div className={styles.studentName}>
                                                    {eachDateInfo.name}
                                                </div>
                                                <div className={styles.studyTime}>
                                                    {eachDateInfo.studytimeString}
                                                </div>
                                            </div>
                                            <div className={styles.studyTimeGraphDiv}>
                                                <div className={styles.studyTimeGraphBar}>
                                                    <div className={styles.studyTimeGraphBarContent} style={{ width: `${eachDateInfo.ratio * 100}%` }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }

                        {
                            (graphMenu === "weekly" && weekInfoState && weekInfoState.length === 0) ?
                            <div className={styles.blankText}>
                                공부시간 데이터 수집 중입니다 🧐
                            </div>
                            :
                            ""
                        }


                        {
                            graphMenu === "monthly" &&
                            monthInfoState && monthInfoState.map((eachDateInfo: any, index: number) => {


                                return (
                                    <div className={styles.eachStudent} key={eachDateInfo.id}>
                                        <div className={styles.rankingNumberDiv}>
                                            <CssVarsProvider>
                                                <AspectRatio ratio="1/1" variant="plain">
                                                    <div className={`${styles.rankingNumberCircle} ${props.user.id === eachDateInfo.userId ? styles.mine : ""}`}>
                                                        {index + 1}
                                                    </div>
                                                </AspectRatio>
                                            </CssVarsProvider>
                                            <Crown className={`${styles.crown} ${index < 3 ? styles.active : ""}`} fill="#3d50b0" />
                                        </div>
                                        <div className={styles.rankingDescriptionDiv}>
                                            <div className={styles.rankingDescriptionNameDiv}>
                                                <div className={styles.studentName}>
                                                    {eachDateInfo.name}
                                                </div>
                                                <div className={styles.studyTime}>
                                                    {eachDateInfo.studytimeString}
                                                </div>
                                            </div>
                                            <div className={styles.studyTimeGraphDiv}>
                                                <div className={styles.studyTimeGraphBar}>
                                                    <div className={styles.studyTimeGraphBarContent} style={{ width: `${eachDateInfo.ratio * 100}%` }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }

                        {
                            (graphMenu === "monthly" && monthInfoState && monthInfoState.length === 0) ?
                            <div className={styles.blankText}>
                                공부시간 데이터 수집 중입니다 🧐
                            </div>
                            :
                            ""
                        }
                    </div>
                }


            </div>


            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}
        </div>
    );

}

export default StudyTimeRanking;