import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PatrolManager from "./page/patrolmanager";
import TotalPatrol from "./page/totalpatrol";


const PatrolMain: React.FC<any> = (props) => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const notify1 = () => toast.success("저장 성공!", {
        position : "bottom-right",
        autoClose : 3000,
        hideProgressBar : true,
        theme : "colored"
    });

    const notify2 = () => toast.success("문자메세지를 전송했습니다!", {
        position : "bottom-right",
        autoClose : 3000,
        hideProgressBar : true,
        theme : "colored"
    });

    const notify3 = () => toast.success("전화 요청을 보냈습니다!", {
        position : "bottom-right",
        autoClose : 3000,
        hideProgressBar : true,
        theme : "colored"
    });

    const notify4 = () => toast.success("폰 미제출 알림톡을 보냈습니다!", {
        position : "bottom-right",
        autoClose : 3000,
        hideProgressBar : true,
        theme : "colored"
    });


    return (
        <div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label={<span style={{ fontFamily: "Apple_B", fontSize: "18px" }}>순찰하기</span>} value="1" />
                            <Tab label={<span style={{ fontFamily: "Apple_B", fontSize: "18px" }}>전체결과</span>} value="2" />
                            <Tab label={<span style={{ fontFamily: "Apple_B", fontSize: "18px" }}>학생별결과</span>} value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <PatrolManager socket={props.socket} user={props.user} />
                    </TabPanel>
                    <TabPanel value="2">
                        <TotalPatrol user={props.user} />
                    </TabPanel>
                </TabContext>
            </Box>

            <ToastContainer />
        </div>
    )

}

export default PatrolMain;
