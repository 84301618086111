import React, { useEffect, useState, useRef } from "react";
import styles from "./innerheader.module.css";
import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router-dom";


const InnerHeader: React.FC<any> = (props) => {

    const [platform, setPlatform] = useState("");
    const history = useHistory();

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

    }, []);

    const handleClick = (e: any) => {
        if (props.home) {
            history.push("/mobiledashboard");
        } else {
            history.goBack();
        }
    }

    return (
        <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
            <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`} ref={props.headerRef}>
                    <div className={styles.backBtnDiv}
                        onClick={(e: any) => { handleClick(e) }}
                    >
                        <ArrowLeftLight
                            className={styles.arrowLeft}
                        />
                    </div>

                    <div className={styles.titleText}>
                        {props.title}
                        {/* &nbsp;<span className={styles.imageNumber}>{data && data.length}</span> */}
                    </div>


                    <div className={`${styles.submit}`} onClick={props.handleClickSubmitBtn}>
                        {props.submitBtn}
                    </div>
            </div>
        </div>
    );
}

export default InnerHeader;