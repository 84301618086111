import {initializeApp} from "firebase/app";
import { getAnalytics } from "firebase/analytics";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "peetsunbaenotification.firebaseapp.com",
    projectId: "peetsunbaenotification",
    storageBucket: "peetsunbaenotification.appspot.com",
    messagingSenderId: "1038520676927",
    appId: "1:1038520676927:web:fdc2e15f4278551d84fe8a",
    measurementId: "G-2SJ9LP0DJF"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;




