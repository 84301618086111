import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import koLocale from 'date-fns/locale/ko'
import styles from "../../../componentsStyle/totaledit.module.css"
import { GridRenderCellParams, DataGridPro, GridRowsProp, GridColDef, GridToolbar, LicenseInfo, useGridApiRef, GridFilterModel, DataGridPremium, GridRowModel } from '@mui/x-data-grid-premium';
import { eachDayOfInterval } from 'date-fns';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { Autocomplete, Backdrop, CircularProgress, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { IonItemSliding } from '@ionic/react';
import { Button, Radio, RadioGroup } from "@mui/joy";
import { ArrowBackIos, ArrowForwardIos, Autorenew, HowToReg } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import totalLocationData from "../../../../data/locationdata";
import { getFetch, postFetch } from "../../../../fetch/fetch";

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

interface GridCellExpandProps {
    value: string;
    width: number;
}


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    }),
);


const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .completed': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                },
                '& .notCompleted': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .notApplied': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                },
                '& .notPermit': {
                    color: "rgb(205, 205, 198)",
                },
            },
        };
    },
    { defaultTheme },
);


function isOverflown(element: Element): boolean {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}


const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps,
) {
    const { width, value } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);


    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current!);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <div ref={cellValue} className="cellValue">
                {value}
            </div>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );
});

function renderCellExpand(params: GridRenderCellParams<any>) {
    return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
}

const TotalCheckList: React.FC<any> = (props) => {

    const classes = useStyles2();
    const [targetDate, setTargetDate] = useState<Date>(new Date());
    const [submitBool, setSubmitBool] = useState(false);
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [loading, setLoading] = useState(false);
    const [alignment, setAlignment] = useState("total");
    const [alignment2, setAlignment2] = useState("전체");
    const [textfieldValue, setTextfieldValue] = useState("");
    const [clickedData, setClickedData] = useState<any>(null);
    const [disable, setDisable] = useState(true);
    const [loading2, setLoading2] = useState(false);

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            {
                id: 1, field: "location", operator: "contains", value: "",
            },
            {
                id: 2, field: "name", operator: "contains", value: ""
            },
            {
                id: 3, field: "status", operator: "isAnyOf", value: []
            }
        ],
    });

    const columns: GridColDef[] = [
        { field: "date", headerName: "날짜", width: 100 },
        { field: "time", headerName: "시간", width: 100 },
        { field: "name", headerName: "이름", width: 100 },
        { field : "grade", headerName : "학년", width : 100},
        { field: "location", headerName: "장소", width: 150 },
        { field: "seat", headerName: "좌석", width: 150 },
        { field: "purestudytime", headerName: "순공부시간", width: 150 },
        { field: "status", headerName: "완료여부", width: 100 },
        { field : "completeTime", headerName : "완료시간", width : 150},
        { field: "studentMessage", headerName: "학생전달사항", width: 150, renderCell: renderCellExpand },
        { field: "teacherMessage", headerName: "직원메모", width: 150, renderCell: renderCellExpand, editable: true },
    ];

    useEffect(() => {
        start();
    }, [targetDate]);

    useEffect(() => {

        var newDisable = true

        if(clickedData){

            if(clickedData.enrolledId && clickedData.status === "미완료"){
                newDisable = false;
            }

        }

        setDisable(newDisable);


    }, [clickedData]);

    const start = async () => {

        setLoading(true);
        await getData();
        setLoading(false);
    }

    const getData = async () => {

        try {

            const targetDateTime = targetDate.getTime();

            const result: any = await getFetch("/dashboard/checklist/totalchecklist?time=" + targetDateTime);

            console.log(result);

            const pureTimeData = calcPureStudyTime(result.data);

            console.log("pureTimeData");
            console.log(pureTimeData);

            const checkData: any = result.checkData;

            console.log("checkData");
            console.log(checkData);

            pureTimeData.forEach((item: any, index : number) => {

                checkData.forEach((item2: any) => {

                    if (item.id === item2.fingerprintId) {
                        item.name = item2.name;
                        item.userId = item2.userId;
                        
                        if(item2.completeTime){
                            console.log("completeTime");
                            const completeTime = new Date(item2.completeTime);
                            const completeTimeString = `${completeTime.getHours()}시 ${completeTime.getMinutes()}분`;
                            item.completeTime = completeTimeString;
                        }else{
                            console.log("no completeTime");
                        }

                        const targetDate = new Date(item2.targetDate);
                        const targetDateString = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일`;

                        item.date = targetDateString;
                        item.enrolledId = +item2.enrolledId;

                        const startDate = new Date(+item2.enrolledId);
                        const startHours = startDate.getHours();

                        var time = `${startHours}시~`;

                        const intervalTime = item2.intervalTime;

                        startDate.setMinutes(startDate.getMinutes() + intervalTime);
                        const endHours = startDate.getHours();
                        time += `${endHours}시`;

                        item.time = time;

                        if (item2.status === "notCompleted") {
                            item.status = "미완료";
                            item.statusNumber = 2;
                        }

                        if (item2.status === "completed") {
                            item.status = "완료";
                            item.statusNumber = 3;
                        }

                        item.studentMessage = item2.studentMessage;
                        item.teacherMessage = item2.teacherMessage;

                    }

                });

                if (!item.status) {
                    item.status = "미신청";
                    item.statusNumber = 1;
                }

                item.fingerprintId = item.id;
                item.id = index + 1;

            })

            //enrolledId가 있을경우 enrolledId 기준으로 오름차순 정렬
            pureTimeData.sort((a: any, b: any) => {

                if (!a.enrolledId) {
                    return 0;
                }

                if (!b.enrolledId) {
                    return 0;
                }

                return a.enrolledId - b.enrolledId;
            })

            //status 기준으로 내림차순 정렬
            pureTimeData.sort((a: any, b: any) => {
                return b.statusNumber - a.statusNumber;
            })

            console.log("pureTimeData");
            console.log(pureTimeData);

            setRows([...pureTimeData]);

        } catch (e) {
            console.log(e);
        }

    }

    const calcPureStudyTime = (totalAccessInformation: any) => {
        const userId: number[] = [];
        const userNameArray: string[] = [];

        totalAccessInformation.forEach((item: any) => {
            if (!userId.includes(item.userId)
                && item.value === "student"
            ) {
                userId.push(item.userId);
                userNameArray.push(item.name);
            }
        })

        const totalData: { userId: number, usersAccessInformation: [], userName: string, location?: string, seat?: string, grade? : string }[] = [];

        userId.forEach((item: any, index: number) => {
            totalData.push({ userId: item, userName: userNameArray[index], usersAccessInformation: [] });
        }
        )

        totalAccessInformation.forEach((item: any) => {
            totalData.forEach((item2: any) => {
                if (item.userId === item2.userId) {
                    item2.usersAccessInformation.push(item);
                    item2.location = item.location;
                    item2.seat = item.seat;
                    item2.grade = item.grade
                }
            })
        }
        )

        console.log(totalData);

        totalData.forEach((item: any) => {
            item.pureStudyTime = 0;
            var previousAccessInformation: any;
            item.usersAccessInformation.forEach((item2: any) => {
                if (previousAccessInformation) {
                    if (previousAccessInformation.direction !== item2.direction && previousAccessInformation.direction === "outside") {
                        item.pureStudyTime += item2.time - previousAccessInformation.time;
                    }
                }
                previousAccessInformation = item2;
            })

        })

        const newRows: any = [];

        totalData.forEach((item: any) => {

            const oneRow: any = {};
            oneRow.id = item.userId;
            oneRow.name = item.userName;
            //item.purestudytime은 millisecond단위인데 이를 ~시간 ~분 꼴로 바꿔줘야함
            const hour = Math.floor(item.pureStudyTime / 3600000);
            const minute = Math.floor((item.pureStudyTime - hour * 3600000) / 60000);
            oneRow.purestudytime = hour + "시간 " + minute + "분";
            oneRow.pureTime = item.pureStudyTime;
            oneRow.location = item.location;
            oneRow.seat = item.seat
            const grade = item.grade;
            var gradeString = "";

            switch(grade){
                case "middle3" :
                    gradeString = "중3";
                    break;
                case "high1" :
                    gradeString = "고1";
                    break;
                case "high2" :
                    gradeString = "고2";
                    break;
                case "high3" :
                    gradeString = "고3";
                    break;
                case "n" :
                    gradeString = "N수생";
                    break;
            }

            oneRow.grade = gradeString


            newRows.push(oneRow);
        })

        //pureTime을 기준으로 내림차순 정렬

        newRows.sort((a: any, b: any) => {
            return b.pureTime - a.pureTime;
        })

        return newRows;
    }

    const onClick = (direction: string) => {

        if (direction === "left") {
            const newDate = new Date(targetDate.getTime() - 24 * 60 * 60 * 1000);
            setTargetDate(newDate);
        } else {
            const newDate = new Date(targetDate.getTime() + 24 * 60 * 60 * 1000);
            setTargetDate(newDate);
        }

    }

    const handleChange = (event: React.MouseEvent, newAlignment: string) => {

        if (!newAlignment) {
            return;
        }

        console.log(newAlignment);

        setAlignment(newAlignment);

        const newFilterModel = filterModel;

        totalLocationData.forEach((each) => {

            if (each.english === newAlignment) {
                newFilterModel.items.forEach((each2: any) => {
                    if (each2.id === 1) {
                        each2.value = each.filterValue_contains;
                    }
                })
            }

        });

        setFilterModel({ ...newFilterModel });

    }

    const handleChange2 = (event: React.MouseEvent, newAlignment: string) => {

        if (!newAlignment) {
            return;
        }

        console.log(newAlignment);

        setAlignment2(newAlignment);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 3) {
                if (newAlignment === "전체") {
                    each.value = [];
                } else {
                    each.value = [newAlignment];
                }
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    const textFieldChange = (e: any) => {

        setTextfieldValue(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 2) {
                each.value = e.target.value;
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    const letsComplete = async () => {

        if (!clickedData) {
            return;
        }

        if (!clickedData.enrolledId) {
            return;
        }

        if(!clickedData.userId){
            return;
        }

        const enrolledId = clickedData.enrolledId;
        const userId = clickedData.userId;

        try {

            setLoading2(true);

            const result: any = await postFetch("/dashboard/checklist/completechecklist", { enrolledId, userId });

            console.log(result);

            setLoading2(false);

            if (result.message === "success") {
                toast.success("완료처리되었습니다.");
                start();
            } else {
                toast.error("완료처리에 실패했습니다.");
            }

        } catch (e) {
            console.log(e);
            toast.error("완료처리에 실패했습니다.");
        }

    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";


            console.log("new");

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const enrolledId = newRow.enrolledId;
            const userId = newRow.userId;

            if (!field) {
                console.log("noChanged");
                return newRow;
            }

            if(!enrolledId){
                console.log("noEnrolledId");
                return newRow;
            }

            if(!userId){
                console.log("noUserId");
                return newRow;
            }

            const data = {
                value,
                field,
                enrolledId,
                userId
            }



            try{

                const result : any = fetch("https://peetsunbae.com/dashboard/checklist/editchecklist", {
                    method : "POST",
                    headers : {
                        "Content-Type" : "application/json",
                    },
                    credentials : "include",
                    body : JSON.stringify(data)
                }).then((response) => response.json().then((result) => {
                    console.log(result);
                })).catch((e) => {
                    console.log(e);
                    return old;
                })

                return newRow;

            }catch(e){
                console.log(e);
            }

            console.log(data);




        }, []
    );


    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log("error");
        console.log(error.message);
    }, []);


    return (
        <div>

            <div style={{
                width: "1500px",
                display: "flex",
                justifyContent: "space-between",
            }}>
                <div>

                </div>
                <div>
                    <div style={{ marginTop: "12px" }}>
                        <IconButton
                            onClick={() => onClick("left")}
                        >
                            <ArrowBackIos />
                        </IconButton>
                        <IconButton
                            onClick={() => onClick("right")}
                        >
                            <ArrowForwardIos />
                        </IconButton>
                    </div>
                    <div style={{ marginTop: "12px", fontFamily: "Apple_SB", marginBottom: "24px" }}>
                        {
                            targetDate &&
                            `${targetDate.getFullYear()}/${targetDate.getMonth() + 1}/${targetDate.getDate()}`
                        }
                    </div>
                </div>
            </div>

            <div>
                <div style={{
                    marginBottom: "16px"
                }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                    >
                        {
                            props.user && totalLocationData.filter((each) => each.academy.includes(props.user.academy)).map((each, index) => {
                                return (
                                    <ToggleButton key={index} value={each.english}>{each.korean}</ToggleButton>
                                );
                            })
                        }
                    </ToggleButtonGroup>
                </div>
                <div style={{
                    marginBottom: "24px"
                }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment2}
                        exclusive
                        onChange={handleChange2}
                    >
                        <ToggleButton value="전체">전체</ToggleButton>
                        <ToggleButton value="완료">완료</ToggleButton>
                        <ToggleButton value="미완료">미완료</ToggleButton>
                        <ToggleButton value="미신청">미신청</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            <div style={{
                width: "1500px",
                display: "flex",
                justifyContent: "space-between",
                marginBottom : "24px"
            }}>
                <div>
                    <TextField value={textfieldValue} variant="standard" placeholder='이름을 검색하세요' onChange={textFieldChange} />
                </div>
                <div>
                    <span style={{
                        marginRight: "12px",
                    }}>
                        {(clickedData && !disable) && clickedData.name}
                    </span>
                    <Button color="primary" onClick={letsComplete}
                    disabled={disable}
                    >완료시키기</Button>
                </div>
            </div>
            <div style={{ height: 650, width: '1500px' }}>
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }} className={classes.root}>
                        <DataGridPremium
                            processRowUpdate={handleEditCommit}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                            rows={rows}
                            columns={columns}
                            loading={loading}
                            density="compact"
                            filterModel={filterModel}
                            onFilterModelChange={(model) => {
                                setFilterModel(model);
                            }}
                            isCellEditable={(params) => {

                                if(params.field === "teacherMessage"){
                                    if(params.row.enrolledId){
                                        return true;
                                    }
                                }

                                return false;  
                            }}
                            getCellClassName={(params) => {

                                if (params.value === "완료") {
                                    return "completed";
                                }

                                if (params.value === "미완료") {
                                    return "notCompleted";
                                }

                                if (params.value === "미신청") {
                                    return "notApplied";
                                }

                                return ""
                            }}
                            onRowClick={(params) => {
                                console.log(params);

                                const data = {
                                    enrolledId : params.row.enrolledId,
                                    status : params.row.status,
                                    fingerprintId : params.row.fingerprintId,
                                    name : params.row.name,
                                    userId : params.row.userId
                                }

                                setClickedData(data);

                            }}
                        />
                    </div>
                </div>
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading2}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ToastContainer
            position="bottom-right"
            autoClose={1500}
            hideProgressBar={true}
            />
        </div>
    )
}

export default TotalCheckList;