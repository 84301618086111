import { Button, Divider } from "@mui/joy";
import React, { useEffect, useState } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    LicenseInfo,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { fil, th } from "date-fns/locale";
import { set } from "lodash";

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .suspicious_in': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .suspicious': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .rejectCell': {
                    color : "red"
                },
                '& .acceptCell': {
                    color : "blue",
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .phone_problem': {
                    color: "red"
                },
                '& .phone_noproblem': {
                    color: "black"
                },
                '& .phone_memo': {
                    color: "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);



const DemeritSuddenData: React.FC<any> = (props) => {

    const [rows, setRows] = useState<GridRowsProp>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field : "kind", headerName : "종류", width : 80 },
        { field : "accept", headerName : "승인", width : 80 },
        { field : "start", headerName : "출발시간", width : 80 },
        { field : "end", headerName : "도착시간", width : 80 },
        { field : "description", headerName : "사유", width : 300 },
        { field : "createdAt", headerName : "제출시간", width : 150 },
    ]);
    const classes = useStyles2();
    const apiRef = useGridApiRef();

    useEffect(() => {

        letsStart();

    }, [props.suddenNoticeData]);


    const letsStart = async () => {

        try {

            if(!props.suddenNoticeData){
                setRows([]);
                return;
            }

            makeRows()

        } catch (e) {
            console.log(e);
        }

    }

    const makeRows = async () => {

        if(!props.suddenNoticeData){
            setRows([]);
            return;
        }

        const newRows : any = [];

        props.suddenNoticeData.forEach((eachData : any) => {

            const oneRow : any = {};

            oneRow.id = eachData.id;

            const kind = eachData.type;

            var kindString = "";

            switch(kind) {
                case "long" :
                    kindString = "지각";
                    break;
                case "early" :
                    kindString = "조퇴";
                    break;
                case "among" :
                    kindString = "외출";
                    break;
                case "absent" :
                    kindString = "결석";
                    break;
            }

            oneRow.kind = kindString;

            if(eachData.parentpermit === 1){
                oneRow.accept = "O";
            }else{
                oneRow.accept = "X";
            }

            var arriveTime = "";
            var leaveTime = "";

            switch(kind){
                case "long" :
                    arriveTime = `${eachData.startHours < 10 ? `0${eachData.startHours}` : eachData.startHours}:${eachData.startMinutes < 10 ? `0${eachData.startMinutes}` : eachData.startMinutes}`;
                    break;
                case "early" :
                    leaveTime = `${eachData.endHours < 10 ? `0${eachData.endHours}` : eachData.endHours}:${eachData.endMinutes < 10 ? `0${eachData.endMinutes}` : eachData.endMinutes}`;
                    break;
                case "among" :
                    //arriveTime은 endHours와 endMinutes로 구성
                    //leaveTime은 startHours와 startMinutes로 구성
                    arriveTime = `${eachData.endHours < 10 ? `0${eachData.endHours}` : eachData.endHours}:${eachData.endMinutes < 10 ? `0${eachData.endMinutes}` : eachData.endMinutes}`;
                    leaveTime = `${eachData.startHours < 10 ? `0${eachData.startHours}` : eachData.startHours}:${eachData.startMinutes < 10 ? `0${eachData.startMinutes}` : eachData.startMinutes}`;
            }

            oneRow.start = leaveTime;
            oneRow.end = arriveTime;

            oneRow.description = eachData.reason;

            const createdAtDate = new Date(eachData.DateInserted);
            const createdAtDateString = `${createdAtDate.getFullYear()}-${createdAtDate.getMonth() + 1}-${createdAtDate.getDate()} ${createdAtDate.getHours() < 10 ? "0" + createdAtDate.getHours() : createdAtDate.getHours()}:${createdAtDate.getMinutes() < 10 ? "0" + createdAtDate.getMinutes() : createdAtDate.getMinutes()}:${createdAtDate.getSeconds() < 10 ? "0" + createdAtDate.getSeconds() : createdAtDate.getSeconds()}`;

            oneRow.createdAt = createdAtDateString;

            newRows.push(oneRow);

        })

        setRows([...newRows]);

    }





    return (
        <div>
            <div>
                <div className={classes.root} style={{
                    width: "1000px",
                    height: "160px",
                }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "10px",
                            fontFamily: "Apple_SB",
                            paddingTop: "106px",
                        }}
                    >
                        사유 제출
                    </div>
                    <DataGridPremium
                        sx={{
                            backgroundColor: "#ffffff",
                        }}
                        rows={rows}
                        columns={columns}
                        density="compact"
                        apiRef={apiRef}
                        hideFooter={true}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            const data: any = apiRef.current.getSelectedRows();
                            if (!data) {
                                return;
                            }
                        }}
                        getCellClassName={(params : any) => {

                            //accept가 "O"이면 blue, "X"이면 red
                            if(params.field === "accept"){
                                if(params.value === "O"){
                                    return "acceptCell";
                                }else{
                                    return "rejectCell";
                                }
                            }
            
                            return ""
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default DemeritSuddenData;