import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import MenuTable from '../components/menu/menutable';
import OptionTable from '../components/menu/optiontable';

const MenuManagement: React.FC<any> = (props) => {

    const [menu, setMenu] = useState("menu");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setMenu(newValue);
    }

    return (
        <div>
            <div style={{ width: "180px", marginTop: "2.98rem" }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={menu}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                                <Tab label={<span style={{ fontFamily: "Apple_SB" }}>메뉴관리</span>} value="menu" />
                                <Tab label={<span style={{ fontFamily: "Apple_SB" }}>옵션관리</span>} value="option" />
                            </TabList>
                        </Box>
                    </TabContext>
                </Box>
            </div>

            <div style={{
                marginTop : "2.19rem"
            }}>
                {
                    menu === "menu" ?
                        <MenuTable /> : ""
                }
                {
                    menu === "option" ?
                        <OptionTable /> : ""
                }
            </div>

        </div>
    )
}

export default MenuManagement;