import React, { useState, useEffect } from 'react';
import styles from "../../money.module.css";
import { Button, IconButton, Option, Radio, RadioGroup, Select } from '@mui/joy';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import useStyles2 from '../../etc/useStyles';
import { Backdrop, Box, CircularProgress, FormControl, MenuItem, Modal, OutlinedInput, Switch, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ModalHeaderForMoney from '../../etc/modalheader';
import { ControlPoint, ControlPointSharp, DeleteOutline, Filter, PhotoOutlined } from '@mui/icons-material';
import ModalBottomForMoney from '../../etc/modalbottom';
import { getFetch, postFetch } from '../../../../../../fetch/fetch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { id } from 'date-fns/locale';
import { set } from 'lodash';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
};

const MenuTable: React.FC<any> = (props) => {

    const classes = useStyles2();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState([
        { field: 'category', headerName: '옵션 이름', width: 90 },
        { field: 'menuName', headerName: '필수 선택 여부', width: 130 },
        { field: 'price', headerName: '선택 가능한 최대 항목 수', width: 180 },
        { field: 'due', headerName: '옵션 항목 수', width: 130 },
        { field: 'addedOption', headerName: '옵션 항목 내용', width: 200 },
        { field: 'payCompleted', headerName: "결제완료", width: 130 },
        { field: "menuImg", headerName: "첨부사진", width: 150 },
        { field: "finalEditor", headerName: "최종 수정자", width: 150 }
    ]);
    const [modalType, setModalType] = useState("");
    const [menuKind, setMenuKind] = useState("register");
    const [menuName, setMenuName] = useState("");
    const [optionItems, setOptionItems] = useState([""]);
    const [isMandantory, setIsMandantory] = useState(false);
    const [maxNumber, setMaxNumber] = useState(1);
    const [disabled, setDisabled] = useState(true);
    const [editDisabled, setEditDisabled] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [update, setUpdate] = useState(0);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [alignment, setAlignment] = useState('total');
    const [price, setPrice] = useState<any>();

    const [year, setYear] = useState<any>();
    const [month, setMonth] = useState<any>();
    const [subDescription1, setSubDescription1] = useState<any>("");
    const [subDescription2, setSubDescription2] = useState<any>("");

    const [uploadFile, setUploadFile] = useState<any>();
    const [uploadFileName, setUploadFileName] = useState<any>();

    const [optionList, setOptionList] = useState<any>([]);
    const [currentOptions, setCurrentOptions] = useState<any>([]);

    const [startDateTime, setStartDateTime] = useState<any>(new Date());
    const [endDateTime, setEndDateTime] = useState<any>();

    const notify1 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify2 = () => toast.error("실패했습니다.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });



    useEffect(() => {

        setPrice("");
        setSubDescription1("");
        setSubDescription2("");
        setUploadFile(null);
        setUploadFileName("");
        setOptionList([]);

        if (menuKind === "contents") {
            setMenuName("");
            setYear("");
            setMonth("");
            return;
        }

    }, [menuKind]);

    useEffect(() => {

        if (menuKind === "contents") {
            setMenuName("");
            return;
        }

        var menuName = `${year}년 ${month}월 등록비`;
        setMenuName(menuName);
        console.log(menuName);


    }, [year, month, menuKind]);

    useEffect(() => {

        var newDisabled = true;

        if (menuName && optionItems.length > 0) {

            var isEmpty = false;

            optionItems.forEach((item) => {

                if (!item) {
                    isEmpty = true;
                }

            });

            if (!isEmpty) {
                newDisabled = false;
            }

        }

        setDisabled(newDisabled);

    }, [menuName, optionItems, open]);

    useEffect(() => {

        if (selectedRow) {
            setEditDisabled(false);
        } else {
            setEditDisabled(true);
        }

    }, [selectedRow]);

    useEffect(() => {

        getOptions();

    }, []);


    const getOptions = async () => {

        try {

            const result: any = await getFetch("/dashboard/payments/option/get");

            console.log(result);

            const data = result.data;

            const newCurrentOptions: any = [];

            data.forEach((item: any) => {

                const oneRow: any = {
                    id: item.id,
                    name: item.optionName,
                }

                newCurrentOptions.push(oneRow);

            });

            setCurrentOptions([...newCurrentOptions]);

        } catch (e) {
            console.log(e);
        }

    }

    const handleClose = () => {
        setOpen(false);
        setModalType("");
        setMonth("");
        setYear("");
        setMenuName("");
        setEditDisabled(true);
        setMaxNumber(1);
        setMenuKind("");
        setPrice("");
        setSubDescription1("");
        setSubDescription2("");
        setUploadFile(null);
        setUploadFileName("");
        setOptionList([]);
    }

    const handleOpen = (type: string) => {

        if (type === "edit") {

            if (!selectedRow) {
                return;
            }

            console.log(selectedRow);

            const value = selectedRow.row.value;

            console.log(value);

            setMenuName(value.MenuName);
            setOptionItems(value.optionItems);
            setIsMandantory(value.isMandantory);
            setMaxNumber(value.maxNumber);

        }

        setMenuKind("register");
        setModalType(type);
        setOpen(true);

    }

    const handleCreateOption = () => {
        const newOptionItems = optionItems;
        newOptionItems.push("");
        setOptionItems([...newOptionItems]);
    }

    const handleDeleteOption = (index: number) => {
        const newOptionItems = optionItems;

        if (newOptionItems.length === 1) {
            return;
        }

        newOptionItems.splice(index, 1);
        setOptionItems([...newOptionItems]);
    }



    const submitNewOption = async () => {

        try {

            const data = {
                MenuName: menuName,
                optionItems: optionItems,
                isMandantory: isMandantory,
                maxNumber: maxNumber
            }

            handleClose();

            if (!menuName || optionItems.length === 0) {
                console.log("필수 입력값이 없습니다.");
                return;
            }

            console.log(data);

            setLoading(true);

            const result: any = await postFetch("/dashboard/payments/option/create", data);

            setLoading(false);

            setUpdate(Math.random());

            console.log(result);

            if (result.message === "success") {
                notify1();
            } else {
                notify2();
            }

        } catch (e) {
            console.log(e);
        }


    }

    const submitEditOption = async () => {

        try {

            if (!selectedRow) {
                return;
            }

            const id = selectedRow.id;

            const data = {
                MenuName: menuName,
                optionItems: optionItems,
                isMandantory: isMandantory,
                maxNumber: maxNumber,
                id
            }

            handleClose();

            if (!menuName || optionItems.length === 0) {
                console.log("필수 입력값이 없습니다.");
                return;
            }

            console.log(data);

            setLoading(true);

            const result: any = await postFetch("/dashboard/payments/option/update", data);

            setLoading(false);

            setUpdate(Math.random());

            console.log(result);

            if (result.message === "success") {
                notify1();
            } else {
                notify2();
            }

        } catch (e) {
            console.log(e);
        }

    }

    const submitDeleteOption = async () => {

        try {

            if (!selectedRow) {
                return;
            }

            const id = selectedRow.id;

            if (!id) {
                return;
            }

            setLoading(true);

            const result: any = await postFetch("/dashboard/payments/option/delete", { id });

            setLoading(false);

            console.log(result);

            if (result.message === "success") {
                setUpdate(Math.random());
                notify1();
            } else {
                notify2();
            }

        } catch (e) {
            console.log(e);
        }

    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {

        console.log(newValue);
        setAlignment(newValue);
    };

    //답변에 사진 올리기 기능-----------------------------------------------------------
    const fileOnChange = (event: any) => {
        if (event.target.files.length > 0) {
            if (event.target) {
                setUploadFile(event.target.files[0]);
                setUploadFileName(event.target.files[0].name);
            }
        }
    }

    const handlePlusOption = () => {
    }

    return (
        <div>
            <div className={styles.main}>

                <div style={{
                    marginBottom: "1.2rem"
                }}>
                    <ToggleButtonGroup value={alignment} onChange={handleChange} exclusive
                        color='primary'
                    >
                        <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="register">등록비</ToggleButton>
                        <ToggleButton value="contents">컨텐츠</ToggleButton>
                    </ToggleButtonGroup>
                </div>

                <div className={styles.btnLine}>
                    <div>
                        <TextField id="standard-basic" variant="standard" placeholder='메뉴이름 검색' />
                    </div>
                    <div className={styles.btns}>
                        <Button variant='soft' color='neutral' size='sm' sx={{ marginRight: "1rem" }} onClick={() => { handleOpen("create") }}>메뉴 생성</Button>
                        <Button variant='soft' color='neutral' size='sm' sx={{ marginRight: "1rem" }}
                            disabled={editDisabled} onClick={() => { handleOpen("edit") }}
                        >수정하기</Button>
                        <Button variant='soft' color='danger' size='sm'
                            disabled={editDisabled} onClick={submitDeleteOption}
                        >삭제하기</Button>
                    </div>
                </div>

                <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white", marginTop: "24px" }}>
                    <DataGridPremium loading={tableLoading} rows={rows} columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        density='compact'
                        hideFooter
                        onRowClick={(row) => {
                            setSelectedRow(row);
                        }}
                    />
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ModalHeaderForMoney title={modalType === "create" ? "신규 메뉴 생성" : modalType === "edit" ? "메뉴 수정" : ""} />
                    <div className={styles.modalBody}>
                        <div style={{ height: "1rem" }}></div>
                        <div>
                            <RadioGroup value={menuKind} onChange={(e) => { setMenuKind(e.target.value) }} orientation='horizontal'>
                                <Radio value="register" label={<span className={styles.radioText}>등록비</span>} />
                                <Radio value="contents" label={<span className={styles.radioText}>컨텐츠</span>} />
                            </RadioGroup>
                        </div>
                        <div>
                            <div className={styles.title}>
                                메뉴 이름
                            </div>
                            {
                                menuKind === "register" ?
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <div>등록비</div>
                                        <div>
                                            <FormControl variant='outlined'>
                                                <OutlinedInput
                                                    value={year} onChange={(e) => {

                                                        var value = e.target.value;

                                                        if (value.length > 4) {
                                                            return;
                                                        }
                                                        //0-9만 입력 가능
                                                        //value에 0-9가 아닌 문자가 포함되어 있으면 제거
                                                        value = value.replace(/[^0-9]/g, '');
                                                        setYear(Number(value));
                                                    }}
                                                    size='small'
                                                    endAdornment={<div>년</div>}
                                                    sx={{ width: "100px", marginLeft: "1rem", marginRight: "1rem" }}
                                                    placeholder='2024'
                                                />
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl variant='outlined'>
                                                <OutlinedInput
                                                    value={month} onChange={(e) => {
                                                        var value = e.target.value;

                                                        if (value.length > 2) {
                                                            return;
                                                        }

                                                        if (+value > 12) {
                                                            return;
                                                        }
                                                        //0-9만 입력 가능
                                                        //value에 0-9가 아닌 문자가 포함되어 있으면 제거
                                                        value = value.replace(/[^0-9]/g, '');
                                                        setMonth(Number(value));
                                                    }}
                                                    size='small'
                                                    endAdornment={<div>월</div>}
                                                    sx={{ width: "100px" }}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    :
                                    <TextField variant="outlined" fullWidth
                                        value={menuName} onChange={(e) => { setMenuName(e.target.value) }}
                                        size='small'
                                    />
                            }
                        </div>
                        <div>
                            <div className={styles.title}>
                                가격
                            </div>
                            <div>
                                <FormControl variant='outlined'
                                    fullWidth
                                >
                                    <OutlinedInput
                                        value={price} onChange={(e) => {

                                            console.log(menuKind);

                                            if (menuKind === "register") {
                                                console.log("register");
                                                e.target.value = "";
                                            }
                                            console.log("gogo");
                                            var value = e.target.value;
                                            value = value.replace(/[^0-9]/g, '');
                                            //value를 3자리마다 콤마를 찍어줌
                                            value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                            setPrice(value);
                                        }}
                                        size='small'
                                        endAdornment={<div>원</div>}
                                        fullWidth
                                        placeholder={
                                            menuKind === "register" ? "학생 개별 가격" : ""
                                        }
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>
                                메뉴설명1
                            </div>
                            <div>
                                <TextField variant="outlined" fullWidth size="small" value={subDescription1} onChange={(e) => { setSubDescription1(e.target.value) }}
                                    placeholder='월단위 등록비용 + 전월 프린트 이용료 + 기타금액'
                                />
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>
                                메뉴설명2
                            </div>
                            <div>
                                <TextField variant="outlined" fullWidth size="small" value={subDescription2} onChange={(e) => { setSubDescription2(e.target.value) }}
                                    placeholder='분할결제, 현금결제, 서울페이 등은 데스크 문의'
                                />
                            </div>
                        </div>
                        {
                            menuKind === "contents" ?
                                <div>
                                    <div className={styles.title}>
                                        옵션 선택
                                    </div>
                                    <div style={{ height: "8px" }}></div>
                                    {
                                        optionList.map((item: any, index: any) => {
                                            return (
                                                <div key={index} className={styles.optionItem}>
                                                    <div>
                                                        옵션 {index + 1}
                                                    </div>
                                                    <div className={styles.optionSelect}>
                                                        <div>
                                                            <Select
                                                                sx={{ width: "120px" }}
                                                                value={item} onChange={(e, newValue: any) => {
                                                                    const newOptionList = optionList;
                                                                    newOptionList[index] = newValue;
                                                                    setOptionList([...newOptionList]);
                                                                }}>
                                                                <Option value={""}>-</Option>
                                                                {
                                                                    (currentOptions && currentOptions.length > 0) && currentOptions.map((option: any, index: any) => {
                                                                        return (
                                                                            <Option key={index} value={option.id}>{option.name}</Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </div>
                                                        <div className={styles.deleteDiv}>
                                                            <DeleteOutline onClick={() => {
                                                                const newOptionList = optionList;
                                                                newOptionList.splice(index, 1);
                                                                setOptionList([...newOptionList]);
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className={styles.clickPlus} onClick={(e) => {
                                        const newOptionList = optionList;
                                        newOptionList.push("");
                                        setOptionList([...newOptionList]);
                                    }}>
                                        <div className={styles.plusIcon}>
                                            <ControlPointSharp className={styles.plusIcon} onClick={handlePlusOption} />
                                        </div>
                                        <div className={styles.plusIconText}>
                                            옵션 추가하기
                                        </div>
                                    </div>
                                </div>
                                :
                                ""

                        }

                        <div>
                            <div className={styles.title}>
                                사진 등록
                            </div>
                            <label htmlFor='file'>
                                <div className={styles.imageUploadDiv} >
                                    <div style={{
                                        marginRight: "0.8rem"
                                    }}>
                                        <PhotoOutlined />
                                    </div>
                                    <div>
                                        {
                                            uploadFileName ? <span>{uploadFileName}</span> : "가로 세로 같은 사이즈 사진"
                                        }
                                    </div>
                                </div>
                            </label>
                            <input onChange={(e) => fileOnChange(e)} type="file" name="file" id="file" accept="image/*" hidden />
                        </div>
                        <div>
                            <div className={styles.title}>
                                결제 기한
                            </div>
                            <div style={{ height : "0.6rem"}}></div>
                            <div>
                                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        value={startDateTime}
                                        onChange={(e) => { setStartDateTime(e) }}
                                        renderInput={(params) => <TextField {...params} />}
                                        label="결제 시작"
                                    />
                                </LocalizationProvider>
                            </div>
                            <div style={{marginTop : "1rem"}}>
                                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        value={endDateTime}
                                        onChange={(e) => { setEndDateTime(e) }}
                                        renderInput={(params) => <TextField {...params} />}
                                        label="결제 종료"
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className={styles.justHeight2}>

                        </div>
                    </div>
                    <ModalBottomForMoney disabled={disabled} buttonName={modalType === "create" ? "생성하기" : modalType === "edit" ? "수정하기" : ""} handleClick={modalType === "create" ? submitNewOption : modalType === "edit" ? submitEditOption : () => { }} />
                </Box>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ToastContainer />
        </div>
    )

}

export default MenuTable;