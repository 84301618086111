import React, { useState, useEffect } from 'react';
import styles from "./money.module.css";
import Payment from './page/payment';
import MenuManagement from './page/menu';

const Money: React.FC<any> = (props) => {

    const [selectMenu, setSelectMenu] = useState("payment");

    useEffect(() => {
        props.activateMenuList("money");
    }, []);

    const changeSelectedMenu = (e: any, menu: string) => {
        setSelectMenu(menu);
    }

    return (
        <div className="main22">
            <div className="title" style={{
                display: "flex", alignItems: "center",
            }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "6px",
                        marginRight: "10px"
                    }}
                >
                    <img src="img/off/money.svg" alt="playlist" style={{ width: "18px" }} />
                </div>
                <div>결제 관리</div>
            </div>

            <div className={styles.selectMenu}>
                <div onClick={(e) => { changeSelectedMenu(e, "payment") }} className={`selectedMenu ${selectMenu === "payment" ? "active" : ""}`}>
                    결제 현황
                </div>
                <div onClick={(e) => { changeSelectedMenu(e, "menu") }} className={`selectedMenu ${selectMenu === "menu" ? "active" : ""}`}>
                    메뉴 관리
                </div>
                <div onClick={(e) => { changeSelectedMenu(e, "withdrawal") }} className={`selectedMenu ${selectMenu === "withdrawal" ? "active" : ""}`}>
                    계좌이체 현황
                </div>
                <div onClick={(e) => { changeSelectedMenu(e, "card") }} className={`selectedMenu ${selectMenu === "card" ? "active" : ""}`}>
                    카드결제 현황
                </div>
            </div>

            {
                selectMenu === "payment" ? 
                <Payment /> : ""
            }
            {
                selectMenu === "menu" ?
                <MenuManagement /> : ""
            }
        </div>
    )
}

export default Money;