import React, {useEffect, useState, useRef} from "react";
import Footer from "../etc/footer";
import styles from "./mobiletotal.module.css";
import { IonAvatar } from "@ionic/react";
import { getAvatarSrc } from "../../etc/functions";
import { getFetch } from "../../fetch/fetch";

const MobileTotal : React.FC<any> = (props) => {

    const footerRef = useRef<any>(null);
    const [howMuch, setHowMuch] = useState<number | null>();
    const [avatarSrc, setAvatarSrc] = useState("");

    const getAvatar = async () => {
        try {
            const avatarSrc: any = await getAvatarSrc();
            console.log("avatar");
            console.log(avatarSrc);
            setAvatarSrc(avatarSrc);

        } catch (e) {
            console.log(e);
        }

    }

    const getDate = async () => {
        try{
            const result : any = await getFetch("/dashboard/profile/howmuch");
            if(result.message === "success"){
                console.log(result.createdAt);
                const today = new Date().getTime();

                const gap = (+today) - (+result.createdAt);
                const howMuchDay = Math.floor(gap/86400000);
                setHowMuch(howMuchDay);
            }
        }catch(e){ 
            console.log(e);
        }
    }

    //아바타 그림 소스랑 가입한 날짜 가져오기
    useEffect(() => {
        if(props.user){
            getAvatar();
            getDate();
        }
    }, [props.user]);



    return (
        <div style={{paddingTop : "env(safe-area-inset-top)"}} >
            <div className={styles.header}>
                <div className={styles.avatarDiv}>
                    <IonAvatar className={styles.avatar}>
                        <img alt="Silhouette of a person's head" src={avatarSrc ? avatarSrc : "https://ionicframework.com/docs/img/demos/avatar.svg"} className={styles.avatarImg} />
                    </IonAvatar>
                </div>
                <div className={styles.nameDiv}>
                    <div className={styles.name}>
                        {props.user && props.user.name}
                    </div>
                    <div className={styles.howMuchDay}>
                        가입한지 {howMuch && howMuch}일
                    </div>
                </div>

            </div>

            <Footer unreadMessage={props.unreadMessage} footerRef={footerRef} location="total" />
        </div>
    );
}

export default MobileTotal;