import React, {useEffect, useState} from "react";

const Blank : React.FC<any> = (props) => {
    return (
        <div>

        </div>
    );
}

export default Blank;