import React, { useState, useEffect, useRef } from "react";
import styles from "./mobileloginstyle.module.css";
import { ReactComponent as LogoSvg } from '../../svg/newlogo.svg';
import { StoreMallDirectorySharp } from "@mui/icons-material";
import { style } from "@mui/system";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { useLocation } from 'react-router';
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"
import AspectRatio from '@mui/joy/AspectRatio';

import {
    IonApp,
    IonButtons,
    IonButton,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    useIonAlert,
    IonLoading
} from '@ionic/react';
import { CssVarsProvider } from "@mui/joy/styles";
import { set } from "lodash";


const MobileLogin: React.FC = (props: any) => {

    const [focus, setFocus] = useState([false, false]);
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [certNumber, setCertNumber] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [verifiedNumberError, setVerifiedNumberError] = useState(false);
    const [rePasswordError, setRePasswordError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const [isCertSended, setIsCertNumberSended] = useState(false);
    const [isPasswordChanged, SetIsPasswordChanged] = useState(false);

    const [platform, setPlatform] = useState("");

    // const classes = props.classes;

    const location = useLocation<any>();

    const [presentAlert] = useIonAlert();


    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

    }, [])


    //로그인 창 처음 들어왔을때 토큰 있는지 확인해서 있으면 로그인창 스킵하고 바로 대시보드로 보내는 기능-----------
    useEffect(() => {

        setShowLoading(true);

        var bool = true;

        if (location.state) {
            if (location.state.from === "dashboard") {
                bool = false;
            }
        }

        async function send() {
            //일렉트론 이라면 토큰 가져오는 기능------------------------------------------------------
            var token: any = ""
            console.log("22222");
            if (window.electron) {
                console.log("111111");
                token = await window.electron.sendMessageApi.getToken();
            }
            //-------------------------------------------------------------------------------------

            //네이티브 라면 토큰 가져오는 기능
            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                console.log(value);
                token = value;
            }


            // token = ipcRenderer.sendSync("getToken");

            fetch("https://peetsunbae.com/login/start", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include"
            }).then((response) => {
                response.json()
                    .then((result) => {
                        setShowLoading(false);
                        if (result.message === "LOGIN") {
                            props.history.push("/mobiledashboard");
                        }
                    })
            })
        }

        if (bool) {
            send();
        }
    }, [])
    //------------------------------------------------------------------------------------------------------


    //로그인 정보 제출----------------------------------------------------------------------------------
    const submit = (e: any) => {

        setShowLoading(true);

        e.preventDefault();

        setEmailError(false);
        setPasswordError(false);

        fetch("https://peetsunbae.com/login/submit", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email.split(" ").join(""),
                password: password,
            })
        }).then((response) => {
            response.json()
                .then(async (result) => {
                    console.log(result);
                    setShowLoading(false);
                    //로그인 성공 시--------------------------------------------------------------------------
                    if (result.message === "success") {
                        if (window.electron) {
                            console.log("electron");
                            const returnValue = window.electron.sendMessageApi.setToken(result.token);
                            // const returnValue2 = ipcRenderer.sendSync("setToken", result.token);
                            console.log(returnValue);
                            // console.log(returnValue2);
                        }
                        if (Capacitor.isNativePlatform()) {

                            console.log("native");
                            const token = result.token;

                            await Preferences.set({
                                key: "token",
                                value: token
                            })

                        }
                        props.history.push("/mobiledashboard");
                    }
                    //-----------------------------------------------------------------------------------
                    if (result.message === "fail") {
                        setPasswordError(true);
                    }
                    if (result.message === "emailfail") {
                        setEmailError(true);
                    }
                    if (result.message === "notNow"){
                        alert("재원생 승인 후 가능합니다.");
                    }


                })
        }).catch((error) => {
            console.log(error);
        })

    }
    //--------------------------------------------------------------------------------------------------

    //모달 창 열때 일어나는 기능--------------------------------------------------------------------------
    const modal = () => {
        console.log("modal");
        setOpen(true);
    }
    //----------------------------------------------------------------------------------------------------

    //modal 창 닫을 때 일어나는 기능------------------------------------------------------------------------
    const handleClose = () => {
        setOpen(false);
        setIsCertNumberSended(false);
        setVerifiedNumberError(false);
        setRePasswordError(false);
        SetIsPasswordChanged(false);
    }
    //----------------------------------------------------------------------------------------------------


    //재설정 비밀번호 전송하는 기능---------------------------------------------------------------------------
    const sendRePassword = (e: any) => {
        e.preventDefault();
        setVerifiedNumberError(false);
        setRePasswordError(false);

        fetch("https://peetsunbae.com/signup/repassword", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                phoneNumber: phoneNumber,
                certNumber: certNumber,
                rePassword: rePassword
            })
        }).then((result) => {
            result.json()
                .then((body) => {
                    console.log(body);

                    if (body.message === "NOT_VALID_PASSWORD") {
                        setRePasswordError(true);
                    }
                    if (body.message === "NOT_COINCIDE_CERT") {
                        setVerifiedNumberError(true);
                    }
                    if (body.message === "CERT_IS_EXPIRED") {
                        setVerifiedNumberError(true);
                    }
                    if (body.message === "success") {
                        SetIsPasswordChanged(true);
                        setIsOpen(false);
                        presentAlert({
                            header: "변경 성공",
                            message: "새로운 비밀번호로 로그인해주세요"
                        })
                        setPhoneNumber("");
                        setCertNumber("");
                        setRePassword("");
                        setVerifiedNumberError(false);
                        setRePasswordError(false);
                        setIsCertNumberSended(false);
                    }
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    //-------------------------------------------------------------------------------------------

    //비밀번호 재설정 인증번호 요구하는 기능--------------------------------------------------------
    const sendCert = (e: any) => {
        e.preventDefault();
        setIsCertNumberSended(true);

        fetch("https://peetsunbae.com/signup/cert2", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                phoneNumber: phoneNumber
            })
        }).then((result) => {
            result.json()
                .then((body) => {
                    console.log(body);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    //-------------------------------------------------------------------------------------------


    const handleFocus = (e: any, number: number) => {
        const newFocus = focus;
        newFocus[number] = true;
        setFocus([...newFocus]);
    }

    const handleBlur = (e: any, number: number) => {
        const newFocus = focus;
        newFocus[number] = false;
        setFocus([...newFocus]);
    }


    return (
        <IonApp>
            <CssVarsProvider>
                <Helmet>
                    <meta name="viewport" content="initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width, viewport-fit=cover" />
                </Helmet>
                <div className={`${styles.main} ${platform === "ios" ? styles.iosMain : ""}`}>
                    <div className={styles.headerDiv}>
                        <div className={styles.header}>
                            <div className={styles.logoDiv}>
                                <LogoSvg className={styles.logo} />
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>

                    <div className={styles.bodyDiv}>
                        <div className={styles.body}>

                            <div className={styles.height1}>

                            </div>

                            <div className={styles.titleTextDiv}>
                                이제 쉽고 간편하게<br></br>로그인하세요!
                            </div>
                            <AspectRatio ratio="100/13.881" variant="plain">
                                <div className={`${styles.emailDiv} ${emailError ? styles.emailError : ""} ${focus[0] ? styles.active : ""}`}>
                                    <input onFocus={(e: any) => { handleFocus(e, 0) }} onBlur={(e: any) => { handleBlur(e, 0) }} className={styles.input} value={email} onChange={(e: any) => { setEmail(e.target.value); }} type="text" placeholder="이메일 주소" />
                                </div>
                            </AspectRatio>

                            <div style={{ height: "0.656rem" }}>

                            </div>

                            <AspectRatio ratio="100/13.881" variant="plain">
                                <div className={`${styles.passwordDiv} ${passwordError ? styles.passwordError : ""} ${focus[1] ? styles.active : ""}`}>
                                    <input onFocus={(e: any) => { handleFocus(e, 1) }} onBlur={(e: any) => { handleBlur(e, 1) }} className={styles.input} value={password} onChange={(e: any) => { setPassword(e.target.value) }} type="password" placeholder="비밀번호" />
                                </div>
                            </AspectRatio>

                            <div style={{ height: "1.094rem" }}>

                            </div>

                            <AspectRatio ratio="100/17.014" variant="plain">
                                <div onClick={submit} className={styles.submitBtn}>
                                    로그인
                                </div>
                            </AspectRatio>


                            <div className={styles.findEmailDiv} onClick={() => { setIsOpen(true); }}>
                                비밀번호 찾기 <span className={styles.chevronSpan}> &gt;</span>
                            </div>

                            <div className={styles.signUpDiv}>
                                수능선배가 처음이신가요? <Link to="/signup"><span className={styles.signUpBtn}>회원가입</span></Link>
                            </div>




                        </div>

                    </div>

                    <div className={styles.underLine}>

                    </div>

                    <IonModal isOpen={isOpen}>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>
                                    <div className={`${styles.ionTitle} ${platform === "android" ? styles.androidTitle : ""}`}>
                                        비밀번호 재설정
                                    </div>
                                </IonTitle>
                                <IonButtons slot="end">
                                    <IonButton onClick={() => { setIsOpen(false); setPhoneNumber(""); setCertNumber(""); setRePassword(""); setVerifiedNumberError(false); setRePasswordError(false); setIsCertNumberSended(false); }} ><span style={{ fontFamily: "Apple_SB" }}>닫기</span></IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            <div className={styles.ionContentDiv}>
                                <div className={styles.eachDiv}>
                                    <div className={styles.findTitle}>
                                        전화번호
                                    </div>
                                    <AspectRatio ratio="100/13.881" variant="plain">
                                        <div className={`${styles.emailDiv} ${phoneNumberError ? styles.phoneNumberError : ""}`}>
                                            <input value={phoneNumber} onChange={(e: any) => { setPhoneNumber(e.target.value); }} type="tel" style={{ marginLeft: "1.188rem" }} className={styles.input} placeholder="핸드폰번호(-없이)" />
                                        </div>

                                    </AspectRatio>
                                    <div style={{ height: "0.656rem" }}>

                                    </div>
                                </div>

                                {
                                    isCertSended &&
                                    <div className={styles.certSendedFirst}>
                                        <div className={styles.eachDiv}>
                                            <div className={styles.findTitle}>
                                                인증번호
                                            </div>
                                            <AspectRatio ratio="100/13.881" variant="plain">

                                                <div className={`${styles.emailDiv} ${verifiedNumberError ? styles.verifyNumberError : ""}`}>
                                                    <input type="number" value={certNumber} onChange={(e: any) => { setCertNumber(e.target.value) }} className={styles.input} placeholder="인증번호를 입력학세요" />
                                                </div>
                                            </AspectRatio>
                                            <div style={{ height: "0.656rem" }}>

                                            </div>
                                        </div>
                                        <div className={styles.eachDiv}>
                                            <div className={styles.findTitle}>
                                                변경할 비밀번호
                                            </div>
                                            <AspectRatio ratio="100/13.881" variant="plain">

                                                <div className={`${styles.emailDiv} ${rePasswordError ? styles.rePasswordError : ""}`}>
                                                    <input type="password" className={styles.input} value={rePassword} onChange={(e: any) => { setRePassword(e.target.value); }} placeholder="변경할 비밀번호(8-20자리)" />
                                                </div>
                                            </AspectRatio>
                                            <div style={{ height: "0.656rem" }}>

                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={styles.btnDiv}>
                                    {
                                        !isCertSended &&
                                        <IonButton onClick={sendCert} color="dark">
                                            확인
                                        </IonButton>
                                    }
                                    {
                                        isCertSended &&
                                        <IonButton onClick={sendRePassword} color="dark">
                                            변경
                                        </IonButton>
                                    }
                                </div>
                            </div>
                        </IonContent>
                    </IonModal>

                </div>

                <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'잠시만 기다려주세요'}
            />

            </CssVarsProvider>
        </IonApp>
    );
}

export default MobileLogin;