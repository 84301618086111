import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styles from "../componentsStyle/attendance.module.css";
import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';



// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import TimePicker from '@mui/lab/TimePicker'
// import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';



import TextField from "@mui/material/TextField";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, Stack } from '@mui/material';
import WorkLoadToday from './controls/workloadtoday';
import TotalWorkLoad from './controls/totalworkload';
import koLocale from 'date-fns/locale/ko'
import TotalAttendance from './controls/totalattendance';
import PhotoManager from "./controls/photomanager";
import Mobile from './controls/mobile';
import TotalFingerprint from './controls/totalfingerprint';
import CurrentSeat from './controls/currentseat';
import SecondAttendance from './controls/secondattendance';
import DaechiAttendance from './controls/daechiattendance/secondattendance';
import { CssVarsProvider } from '@mui/joy';
import NewAttendance from './controls/newattendance/newattendance';
import { Socket } from 'socket.io-client';
import PatrolMain from './patrol/patrolmain';
import CatchBreak from './controls/catchbreak';
import CatchBioStarErrorMain from './controls/catchbiostarerrormain';
import ManageConsult from './controls/manageconsult/manageconsultmain';
import totalLocationData from '../../data/locationdata';


type currentSideBarMenuList = "home" | "notification" | "alarm" | "edit" | "book" | "question" | "restaurant" | "envelope" | "search" | "chart" | "attendance" | "출석 관리 보고";

interface attendanceProps extends RouteComponentProps {
    activateMenuList: (curret: currentSideBarMenuList) => void;
    user: any;
    socket: Socket;
    messageUpdate : any;
    unreadMessage : any;
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 5,
    borderRadius : 4
};


const Attendance: React.FC<attendanceProps> = (props : any) => {

    const mainRef = React.useRef<HTMLDivElement>(null);

    const [disabled, setDisabled] = useState(true);

    const [loading, setLoading] = useState(false);
    const [uploadBool, setUploadBool] = useState(false);

    const [searchMenu, setSearchMenu] = useState("write");
    const [open, setOpen] = React.useState(false);
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();

    const [location, setLocation] = useState([false, false, false, false, false, false, false, false]);
    const [day, setDay] = useState([false, false, false, false, false, false, false]);
    const [description, setDescription] = useState("");
    const [show, setShow] = useState("no");

    const [update, setUpdate] = useState(0);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const change = (e: any, type: string) => {
        const parsedType = type.split("-")[0];
        const index = +(type.split("-")[1] ? type.split("-")[1] : 99);
        console.log(parsedType);

        switch (parsedType) {
            case "location":
                const newLocation = location;
                newLocation[index] = e.target.checked;
                setLocation([...newLocation]);
                break;
            case "day":
                const newDay = day;
                newDay[index] = e.target.checked;
                setDay([...newDay]);
                break;
            case "description":
                setDescription(e.target.value);
                break;
            case "show":
                setShow(e.target.value);
                break;
        }
    }


    useEffect(() => {

        var locationCount = 0;

        location.forEach((each) => {
            if(each){
                locationCount++;
            }
        });

        var dayCount = 0;

        day.forEach((each) => {
            if(each){
                dayCount++;
            }
        })

        var timeCount = 0;

        if(new Date(startTime).getTime() <= new Date(endTime).getTime()){
            timeCount++;
        }

        if(locationCount && dayCount && timeCount && description){
            setDisabled(false);
        }else{
            setDisabled(true);
        }


    }, [location, day, startTime, endTime, description, show]);

    const submit = async (e: any) => {
        setLoading(true);
        const message = {
            location, day, startTime, endTime, description, show
        }

        console.log(message);

        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/report/work", {
            method: "POST",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(message)
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    setLoading(false);
                    if(result.message === "success"){
                        setDescription("");
                        setUploadBool(true);
                        setUpdate(Math.random());
                        setTimeout(()=>{
                            setUploadBool(false);
                        }, 1000);
                    }
                })
        })
    }


    useEffect(() => {
        props.activateMenuList("attendance");

        const date = new Date();

        setStartTime(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9));
        setEndTime(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9));

    }, [])

    const scrollToTop = () => {


        if (mainRef.current) {
            mainRef.current.scrollTop = 0;
        }
    }

    return (
        <div className={styles.main} ref={mainRef}>

            <div className={`${styles.mainBoard} ${searchMenu === "photo" ? styles.photo : ""}`}>
                <div className={styles.title}>
                    <img src="img/off/chart.svg" alt="chart" /> 업무 관리
                </div>

                <div className={styles.searchMenu}>
                    <div onClick={(e) => { setSearchMenu("write") }} className={`${styles.searchMenuDiv} ${searchMenu === "write" ? styles.active : ""}`}>
                        오늘 업무보고
                    </div>
                    <div onClick={(e) => { setSearchMenu("watch") }} className={`${styles.searchMenuDiv} ${searchMenu === "watch" ? styles.active : ""}`}>
                        전체 업무보고
                    </div>
                    {/* <div onClick={(e) => { setSearchMenu("photo") }} className={`${styles.searchMenuDiv} ${searchMenu === "photo" ? styles.active : ""}`}>
                        인증샷
                    </div> */}

                    {/* <div onClick={(e) => { setSearchMenu("attendance") }} className={`${styles.searchMenuDiv} ${searchMenu === "attendance" ? styles.active : ""}`}>
                        출결버스
                    </div> */}
                    {/* <div onClick={(e) => { setSearchMenu("message") }} className={`${styles.searchMenuDiv} ${searchMenu === "message" ? styles.active : ""}`}>
                        휴대폰 관리
                    </div>
                    <div onClick={(e) => { setSearchMenu("second") }} className={`${styles.searchMenuDiv} ${searchMenu === "second" ? styles.active : ""}`}>
                        강남점 출석
                    </div>
                    <div onClick={(e) => { setSearchMenu("daechi") }} className={`${styles.searchMenuDiv} ${searchMenu === "daechi" ? styles.active : ""}`}>
                        대치점 출석
                    </div> */}

                    {/* <div onClick={(e) => { setSearchMenu("gangnampractice") }} className={`${styles.searchMenuDiv} ${searchMenu === "gangnampractice" ? styles.active : ""}`}>
                        강남점 출석
                    </div>
                    <div onClick={(e) => { setSearchMenu("daechipractice") }} className={`${styles.searchMenuDiv} ${searchMenu === "daechipractice" ? styles.active : ""}`}>
                        대치점 출석
                    </div>
                    <div onClick={(e) => { setSearchMenu("daechi2practice") }} className={`${styles.searchMenuDiv} ${searchMenu === "daechi2practice" ? styles.active : ""}`}>
                        대치3층 출석
                    </div>
                    <div onClick={(e) => { setSearchMenu("daechi3practice") }} className={`${styles.searchMenuDiv} ${searchMenu === "daechi3practice" ? styles.active : ""}`}>
                        대치6층 출석
                    </div> */}
                    {
                        props.user && totalLocationData.map((eachLocation, index) => {

                            if(eachLocation.english === "total"){
                                return;
                            }

                            if(!eachLocation.academy.includes(props.user.academy)){
                                return;
                            }

                            return (
                                <div onClick={(e) => { setSearchMenu(`${eachLocation.english}practice`) }} className={`${styles.searchMenuDiv} ${searchMenu === `${eachLocation.english}practice` ? styles.active : ""}`}>
                                    {eachLocation.korean} 출석
                                </div>
                            )
                        })
                    }
                    <div onClick={(e) => { setSearchMenu("patrolmanager") }} className={`${styles.searchMenuDiv} ${searchMenu === "patrolmanager" ? styles.active : ""}`}>
                        순찰 일지
                    </div>
                    <div onClick={(e) => { setSearchMenu("manageconsult") }} className={`${styles.searchMenuDiv} ${searchMenu === "manageconsult" ? styles.active : ""}`}>
                        멘토상담 관리
                    </div>
                    <div onClick={(e) => { setSearchMenu("catcherror") }} className={`${styles.searchMenuDiv} ${searchMenu === "catcherror" ? styles.active : ""}`}>
                        얼굴 인식 분석
                    </div>
                    <div onClick={(e) => { setSearchMenu("catchbreak") }} className={`${styles.searchMenuDiv} ${searchMenu === "catchbreak" ? styles.active : ""}`}>
                        대치점 의심 리스트
                    </div>
                </div>

                {
                    searchMenu === "write" &&
                    <WorkLoadToday user={props.user} />
                }
                {
                    searchMenu === "photo" &&
                    <PhotoManager />
                }
                {
                    searchMenu === "watch" &&
                    <TotalWorkLoad user={props.user} update={update} />
                }
                {
                    searchMenu === "attendance" &&
                    <TotalAttendance user={props.user} />
                }
                {
                    searchMenu === "message" &&
                    <Mobile />
                }
                {
                    searchMenu === "second" &&
                    <>
                        <SecondAttendance />
                    </>
                }
                {
                    searchMenu === "daechi" &&
                    <>
                        <DaechiAttendance />
                    </>
                }
                {/* {
                    searchMenu === "gangnampractice" &&
                    <>
                        <NewAttendance socket={props.socket} location="gangnam" messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage} user={props.user}  />
                    </>
                }
                {
                    searchMenu === "daechipractice" &&
                    <>
                        <NewAttendance socket={props.socket} location="daechi" messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage} user={props.user}  />
                    </>
                }
                {
                    searchMenu === "daechi2practice" &&
                    <>
                        <NewAttendance socket={props.socket} location="daechi2" messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage} user={props.user}  />
                    </>
                }
                {
                    searchMenu === "daechi3practice" &&
                    <>
                        <NewAttendance socket={props.socket} location="daechi3" messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage} user={props.user}  />
                    </>
                } */}
                {
                    props.user && totalLocationData.map((eachLocation, index) => {

                        if(searchMenu !== `${eachLocation.english}practice`){
                            return;
                        }

                        if(eachLocation.english === "total"){
                            return;
                        }

                        if(!eachLocation.academy.includes(props.user.academy)){
                            return;
                        }

                        return (
                            <>
                                <NewAttendance socket={props.socket} location={eachLocation.english} messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage} user={props.user}  />
                            </>
                        )

                    })
                }
                {
                    searchMenu === "patrolmanager" &&
                    <>
                        <PatrolMain socket={props.socket} user={props.user} />
                    </>
                }
                {
                    searchMenu === "catcherror" &&
                    <>
                        <CatchBioStarErrorMain scrollTop={scrollToTop} />
                    </>
                }
                {
                    searchMenu === "catchbreak" &&
                    <>
                        <CatchBreak />
                    </>
                }
                {
                    searchMenu === "manageconsult" &&
                    <>
                        <ManageConsult user={props.user} />
                    </>
                }


            </div>
            
            {
                searchMenu === "watch" &&
                <div onClick={handleOpen} className="qnaWrite">
                    <img src="./img/pencil.svg" alt="pencil" />
                    업무 추가하기
                </div>
            }
            

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.blockDiv}>
                        <div>위치</div>
                        <FormGroup row>
                            {/* <FormControlLabel control={<Checkbox checked={location[0]} onChange={(e) => { change(e, "location-0") }} />} label={<span className={styles.text}>강남 데스크</span>} />
                            <FormControlLabel control={<Checkbox checked={location[1]} onChange={(e) => { change(e, "location-1") }} />} label={<span className={styles.text}>강남 사감</span>} />
                            <FormControlLabel control={<Checkbox checked={location[2]} onChange={(e) => { change(e, "location-2") }} />} label={<span className={styles.text}>대치 데스크</span>} />
                            <FormControlLabel control={<Checkbox checked={location[3]} onChange={(e) => { change(e, "location-3") }} />} label={<span className={styles.text}>대치 사감</span>} />
                            <FormControlLabel control={<Checkbox checked={location[4]} onChange={(e) => { change(e, "location-4") }} />} label={<span className={styles.text}>대치 3층 데스크</span>} />
                            <FormControlLabel control={<Checkbox checked={location[5]} onChange={(e) => { change(e, "location-5") }} />} label={<span className={styles.text}>대치 3층 사감</span>} />
                            <FormControlLabel control={<Checkbox checked={location[6]} onChange={(e) => { change(e, "location-6") }} />} label={<span className={styles.text}>대치 6층 데스크</span>} />
                            <FormControlLabel control={<Checkbox checked={location[7]} onChange={(e) => { change(e, "location-7") }} />} label={<span className={styles.text}>대치 6층 사감</span>} /> */}
                            {
                            props.user && totalLocationData.map((eachLocation, index) => {

                                if(eachLocation.english === "total"){
                                    return;
                                }

                                if(!eachLocation.jobs){
                                    return;
                                }

                                if(!eachLocation.academy.includes(props.user.academy)){
                                    return;
                                }


                                return eachLocation.jobs.map((eachJob,index2) => {


                                    // var locationIndex = (2*index) + index2 - 2;
                                    // console.log(locationIndex, eachJob);
                                    const locationIndex = eachJob.index;

                                    return (
                                        <FormControlLabel control={<Checkbox checked={location[locationIndex]} onChange={(e) => { change(e, `location-${locationIndex}`) }} />} label={<span className={styles.text}>{eachJob.korean}</span>} />
                                    )
                                })

                            })
                        }
                        </FormGroup>
                    </div>
                    <div className={styles.blockDiv}>
                        <div>요일</div>
                        <FormGroup row>
                            <FormControlLabel control={<Checkbox checked={day[1]} onChange={(e) => { change(e, "day-1") }} />} label={<span className={styles.text}>월</span>} />
                            <FormControlLabel control={<Checkbox checked={day[2]} onChange={(e) => { change(e, "day-2") }} />} label={<span className={styles.text}>화</span>} />
                            <FormControlLabel control={<Checkbox checked={day[3]} onChange={(e) => { change(e, "day-3") }} />} label={<span className={styles.text}>수</span>} />
                            <FormControlLabel control={<Checkbox checked={day[4]} onChange={(e) => { change(e, "day-4") }} />} label={<span className={styles.text}>목</span>} />
                            <FormControlLabel control={<Checkbox checked={day[5]} onChange={(e) => { change(e, "day-5") }} />} label={<span className={styles.text}>금</span>} />
                            <FormControlLabel control={<Checkbox checked={day[6]} onChange={(e) => { change(e, "day-6") }} />} label={<span className={styles.text}>토</span>} />
                            <FormControlLabel control={<Checkbox checked={day[0]} onChange={(e) => { change(e, "day-0") }} />} label={<span className={styles.text}>일</span>} />
                        </FormGroup>
                    </div>
                    <div className={styles.blockDiv}>
                        <div className={styles.timeDiv}>수행 시간</div>
                        <div className={styles.timeSelect}>
                            <div>
                                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        value={startTime}
                                        onChange={(newValue: any) => {
                                            console.log(newValue);
                                            setStartTime(newValue);
                                        }}
                                        renderInput={(params : any) => <TextField sx={{ width: "160px" }} {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className={styles.dash}>
                                ~
                            </div>
                            <div>
                                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        value={endTime}
                                        onChange={(newValue: any) => {
                                            console.log(newValue);
                                            setEndTime(newValue);
                                        }}
                                        renderInput={(params : any) => <TextField sx={{ width: "160px" }} {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                    <div className={styles.blockDiv}>
                        <div className={styles.workDescription}>
                            업무내용
                        </div>
                        <div>
                            <TextField value={description} onChange={(e) => { change(e, "description") }} fullWidth id="outlined-basic" variant="outlined" />
                        </div>
                    </div>
                    <div className={styles.blockDiv}>
                        <div>
                            학생 노출
                        </div>
                        <div>
                            <RadioGroup value={show} onChange={(e) => { change(e, "show") }} row aria-label="gender" name="row-radio-buttons-group">
                                <FormControlLabel value="yes" control={<Radio />} label={<span className={styles.text}>YES</span>} />
                                <FormControlLabel value="no" control={<Radio />} label={<span className={styles.text}>NO</span>} />
                            </RadioGroup>
                        </div>
                    </div>
                    <div className={styles.submit}>
                        <CssVarsProvider>
                            <Button disabled={disabled} size="lg" fullWidth variant="solid" onClick={submit}>제출</Button>
                        </CssVarsProvider>
                    </div>

                    {loading &&
                        <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
                            <LinearProgress />
                        </Box>
                    }



                    {uploadBool &&
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2 }}><span className={styles.spanStyles2}>업로드 성공 !</span></Alert>
                        </Stack>
                    }
                </Box>
            </Modal>


        </div>
    )
}

export default Attendance;