import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from '@mui/styles';
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    LicenseInfo,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridToolbar,
    GridRowClassNameParams,
    GridTreeNode
} from '@mui/x-data-grid-premium';
import { createTheme, darken, lighten } from '@mui/material/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Button, Checkbox, FormControl, FormLabel, Input, Modal, ModalDialog, Radio, RadioGroup, Textarea } from "@mui/joy";
import ImageIcon from '@mui/icons-material/Image';
import { sub } from "date-fns";
import { set } from "lodash";
import BlockIcon from '@mui/icons-material/Block';
import UploadIcon from '@mui/icons-material/Upload';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageUploadModal from "./components/imageuploadmodal";
import ImageModal from "../use/imagemodal";
import { Backdrop, CircularProgress, DialogTitle, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { GridCellParams } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import Add from "@mui/icons-material/Add";
import Check from "@mui/icons-material/Check";
import totalLocationData from "../../../data/locationdata";
import { englishLocationToKorean } from "../../../data/locationFuntion";


LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");


const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .completed': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .duplicate': {
                    color: "#ff7f00"
                },
                '& .completed2': {
                    color: "#1b49af"
                },
                '& .letsselect': {
                    color: "rgba(0, 0, 0, 0.26)"
                },
                '& .autoinput': {
                    color: "rgba(0, 0, 0, 0.26)"
                },

            },
        };
    },
    { defaultTheme },
);

// [{value : 1, label : 1}, {value : 2, label : 2} --- 이렇게 해서 75번까지 만들기]
const seatExample: any = [];

for (let i = 1; i <= 80; i++) {
    seatExample.push({ value: `${i}`, label: `${i}` });
}

const positionList = ["오전 데스크", "주간 사감", "오후 데스크", "야간 사감", "저녁 데스크"];



const Todos: React.FC<any> = (props) => {
    
    const classes = useStyles2();
    const apiRef = useGridApiRef();

    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(0);


    const [selectedRow, setSelectedRow] = useState<any>();

    const [disabled, setDisabled] = useState(false);
    const [disabled6, setDisabled6] = useState(false);

    const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [rows, setRows] = useState<any>([]);

    const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);

    const [forImageUploadSelectedId, setForImageUploadSelectedId] = useState();

    const handleImageUploadModalClose = () => {
        setImageUploadModalOpen(false);
    }

    const [open3, setOpen3] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const [searchText, setSearchText] = useState("");

    const [alignment, setAlignment] = useState("incomplete");
    const [alignment2, setAlignment2] = useState("total");
    const [alignment3, setAlignment3] = useState("total");

    const [location, setLocation] = useState("gangnam");
    const [position, setPosition] = useState([false, false, false, false, false]);
    const [description, setDescription] = useState("");

    const [registerLoading, setRegisterLoading] = useState(false);

    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 1,
                field: "location",
                operator: "contains",
                value: ""
            },
            {
                id: 2,
                field: "justForFilter",
                operator: "contains",
                value: "미완료"
            },
            {
                id: 3,
                field: "position",
                operator: "contains",
                value: ""
            }
        ]
    });

    const columns: GridColDef[] =
        [
            { field: 'createdAt', headerName: "날짜", width: 100 },
            { field: 'description', headerName: "전달사항", width: 800, editable: true },
            {
                field: 'photo', headerName: '참고사진', width: 100, filterable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        return (
                            <div
                                onClick={(e: any) => {

                                    setImageSrc(params.value);
                                    setOpen3(true);

                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "100%",
                                    cursor: "pointer"
                                }}>
                                <ImageIcon color="success" />
                            </div>
                        )

                    } else {
                        return (
                            <div>
                            </div>
                        )
                    }

                }
            },
            {
                field: 'finalCheck', headerName: "담당자 확인", width: 150, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    const userName = props.user ? props.user.name : "";

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    }  else {
                        return (
                            <Button variant="soft" size="sm" color="success"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                                startDecorator={<Check />}
                                loading={btnLoading}
                            >
                                확인했습니다
                            </Button>
                        )
                    }
                }
            },
            {
                field: "location", headerName: "지점", width: 100, filterable: true
            },
            {
                field: "position", headerName: "담당자", width: 100, filterable: true
            },
            {
                field: "justForFilter", headerName: "필터", width: 2, filterable: true
            }
        ]



    const [loading, setLoading] = useState(false);


    const notify1 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify2 = () => toast.success("업로드 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify3 = () => toast.success("수정 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify4 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    useEffect(() => {

        var isLocation = false;
        var isPosition = false;
        var isDescription = false;

        if(location){
            isLocation = true;
        }

        position.forEach((each: any) => {
            if(each){
                isPosition = true;
            }
        });

        if(description){
            isDescription = true;
        }

        if(isLocation && isPosition && isDescription){
            setDisabled6(false);
        }else{
            setDisabled6(true);
        }

    }, [location, position, description]);

    useEffect(() => {

        if (selectedRow) {
            setDisabled(false);

        } else {
            setDisabled(true);
        }

    }, [selectedRow]);


    useEffect(() => {

        getNewTodoList();

    }, [update]);

    const submitCancel = async (id: number, column: string) => {

        if (!id || !column) {
            return;
        }

        try {

            setBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/report/staffTodos/submitcancel", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id, column
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                getNewTodoList();
                setBtnLoading(false);
            }

        } catch (e) {
            console.log(e);
        }

    }

    const submitCheck = async (id: number, column: string) => {

        if (!id || !column) {
            return;
        }

        try {

            setBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/report/staffTodos/submitcheck", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id, column
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                getNewTodoList();
                setBtnLoading(false);
            }

        } catch (e) {
            console.log(e);
        }

    }

    const getNewTodoList = async () => {

        try {

            const response = await fetch("https://peetsunbae.com/dashboard/report/staffTodos", {
                method: "GET",
                credentials: "include",
            });

            const result = await response.json();

            if (result.message === "success") {
                console.log(result);

                const data = result.data;

                const newRows: any = [];

                data.forEach((each: any) => {

                    const oneRow: any = {};

                    oneRow.id = each.id;

                    
                    oneRow.userId = each.userId;

                    const createdAtDate = new Date(each.createdAt);
                    //datestring으로 ~/~ ~:~ 꼴로 만들기
                    const createdAtDateString = `${createdAtDate.getMonth() + 1}/${createdAtDate.getDate()} ${createdAtDate.getHours() < 10 ? "0" + createdAtDate.getHours() : createdAtDate.getHours()}:${createdAtDate.getMinutes() < 10 ? "0" + createdAtDate.getMinutes() : createdAtDate.getMinutes()}`;
                    oneRow.createdAt = createdAtDateString;
                    oneRow.description = each.description;

                    // switch(each.location){
                    //     case "gangnam" :
                    //         oneRow.location = "강남점";
                    //         break;
                    //     case "daechi" :
                    //         oneRow.location = "대치점";
                    //         break;
                    //     case "daechi2" :
                    //         oneRow.location = "대치3층";
                    //         break;
                    // }

                    oneRow.location = englishLocationToKorean(each.location);

                    oneRow.photo = each.photo;
                    oneRow.finalCheck = each.finalCheck;
                    oneRow.position = each.position;
                    oneRow.etc = each.etc;

                    if (each.finalCheck) {
                        oneRow.justForFilter = "완료됨";
                    } else {
                        oneRow.justForFilter = "미완료";
                    }

                    newRows.push(oneRow);

                })

                setRows([...newRows]);
            }

        } catch (e) {
            console.log(e);
        }

    }


    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {


            if (newRow.justForFilter === "완료됨") {
                alert("완료된 이후에는 수정이 불가능합니다.");
                return;
            }

            var field = "";

            console.log("new");

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.id;
            const userId = newRow.userId;

            console.log(newRow);
            console.log(field);
            console.log(value);
            console.log(id);
            console.log(userId);

            if (!field) {
                console.log("noChanged");
                return newRow;
            }

            if(field !== "description"){
                alert("전달사항만 수정 가능합니다.");
                return newRow;
            }

            if (!id) {
                console.log("noId");
                return newRow;
            }

            const data = {
                value,
                id,
                userId,
                field
            }

            console.log(data);

            fetch("https://peetsunbae.com/dashboard/report/staffTodos", {
                method: "PATCH",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            }).then((res: any) => {
                res.json().then((result: any) => {
                    if (result.message === "success") {
                        notify3();
                        setUpdate(Math.random());
                    }
                })
            }).catch((e: any) => {
                console.log(e);
            });

            // try {

            //     const response = await fetch("https://peetsunbae.com/dashboard/report/staffTodos", {
            //         method: "PATCH",
            //         credentials: "include",
            //         headers: {
            //             "content-type": "application/json"
            //         },
            //         body: JSON.stringify(data)
            //     });

            //     const result = await response.json();

            //     if (result.message === "success") {
            //         setUpdate(Math.random());
            //     }

            // } catch (e) {
            //     console.log(e);
            // }

            return newRow;

        }, []
    );


    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log("error");
        alert(error.message);
    }, []);


    const deleteRow = async () => {

        const id = selectedRow.id;

        if (!id) {
            return;
        }

        try {

            if (!window.confirm("정말로 삭제하시겠습니까?")) {
                return;
            }

            setDeleteBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/report/staffTodos", {
                method: "DELETE",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                notify1();
                setUpdate(Math.random());
                setDeleteBtnLoading(false);
            }else{
                alert("삭제 실패");
                setDeleteBtnLoading(false);
            }

        } catch (e) {
            console.log(e);
        }

    }

    const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) {
            return;
        }

        setAlignment(newAlignment);

        //filtermodel중 id가 2인 것을 찾아서 newAlignment가 incomplete면 "미완료", total이면 ""로 바꾸기

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 2) {
                if (newAlignment === "incomplete") {
                    each.value = "미완료";
                } else {
                    each.value = "";
                }
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    const handleAlignmentChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) {
            return;
        }

        setAlignment2(newAlignment);

        //filtermodel중 id가 2인 것을 찾아서 newAlignment가 incomplete면 "미완료", total이면 ""로 바꾸기

        const newFilterModel = filterModel;

        // newFilterModel.items.forEach((each: any) => {
        //     if (each.id === 1) {
        //         if (newAlignment === "total") {
        //             each.value = "";
        //         } else if (newAlignment === "gangnam") {
        //             each.value = "강남점"
        //         } else if (newAlignment === "daechi") {
        //             each.value = "대치점";
        //         } else if (newAlignment === "daechi2") {
        //             each.value = "대치3층";
        //         } else if (newAlignment === "daechi3") {
        //             each.value = "대치6층";
        //         }
        //     }
        // })

        totalLocationData.forEach((each) => {
            if(each.english === newAlignment){
                newFilterModel.items.forEach((each2 : any) => {
                    if(each2.id === 1){
                        each2.value = each.filterValue_contains;
                    }
                })
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    const handleAlignmentChange3 = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) {
            return;
        }

        setAlignment3(newAlignment);

        //filtermodel중 id가 2인 것을 찾아서 newAlignment가 incomplete면 "미완료", total이면 ""로 바꾸기

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 3) {
                if(newAlignment === "total"){
                    each.value = "";
                }
                else if (newAlignment === "1_1") {
                    each.value = "오전 데스크";
                } else if (newAlignment === "1_2") {
                    each.value = "오후 데스크";
                } else if (newAlignment === "1_3") {
                    each.value = "저녁 데스크";
                } else if (newAlignment === "2_1"){
                    each.value = "주간 사감"
                } else if (newAlignment === "2_2"){
                    each.value = "야간 사감"
                }
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    const handleSearchTextChange = (e: any) => {

        setSearchText(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 1) {
                each.value = e.target.value;
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    const submit = async (e: any) => {

        console.log(location);
        console.log(position);
        console.log(description);

        const data = {
            location, position, description
        }

        try{

            setRegisterLoading(true);

            const result : any = await fetch("https://peetsunbae.com/dashboard/report/staffTodos", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body : JSON.stringify(data)
            });

            console.log(result);

            if(result.message !== "success"){
                new Error("error");
            }

            setRegisterLoading(false);
            notify2();

            setUpdate(Math.random());

        }catch(e){
            console.log(e);
        }

    }


    return (
        <div>
            <div style={{ height: "12px" }}>

            </div>
            <div style={{
                fontFamily: "Apple_SB",
                marginBottom: "24px",
                fontSize: "18px",
                display: "flex",
                alignItems: "center",
            }}>
                <div style={{
                    marginRight: "18px"
                }}>
                    업무 전달사항
                </div>
                <div>
                    <Button variant="solid" startDecorator={<Add />} size="sm" onClick={(e: any) => { setOpen(true); }}>
                        추가하기
                    </Button>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "16px", marginTop: "12px" }}>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        <ToggleButton value="incomplete">미완료</ToggleButton>
                        <ToggleButton value="total">전체</ToggleButton>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup
                        color="primary"
                        value={alignment2}
                        exclusive
                        onChange={handleAlignmentChange2}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        {/* <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="gangnam">강남점</ToggleButton>
                        <ToggleButton value="daechi">대치점</ToggleButton>
                        <ToggleButton value="daechi2">대치3층</ToggleButton>
                        <ToggleButton value="daechi3">대치6층</ToggleButton> */}
                        {
                            props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index) => {
                                return <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                            })
                        }
                    </ToggleButtonGroup>

                    <ToggleButtonGroup
                        color="primary"
                        value={alignment3}
                        exclusive
                        onChange={handleAlignmentChange3}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="1_1">오전 데스크</ToggleButton>
                        <ToggleButton value="1_2">오후 데스크</ToggleButton>
                        <ToggleButton value="1_3">저녁 데스크</ToggleButton>
                        <ToggleButton value="2_1">주간 사감</ToggleButton>
                        <ToggleButton value="2_2">야간 사감</ToggleButton>
                    </ToggleButtonGroup>

                    {/* <TextField sx={{ fontSize: "24px", marginLeft: "12px" }} label="이름 검색" variant="standard" value={searchText} onChange={handleSearchTextChange} /> */}
                </div>
                <div>
                    <Button variant="soft" size="sm" color="neutral" loading={deleteBtnLoading} disabled={disabled} style={{ marginRight: "12px" }}
                        onClick={(e: any) => {
                            if (!selectedRow) {
                                return;
                            }

                            setForImageUploadSelectedId(selectedRow.sqlId);
                            setImageUploadModalOpen(true);
                        }}
                    >
                        사진 업로드
                    </Button>
                    <Button variant="soft" size="sm" color="danger" loading={deleteBtnLoading} disabled={disabled} onClick={deleteRow}>
                        삭제하기
                    </Button>
                </div>
            </div>
            <div className={classes.root} style={{ height: 250, width: '100%', backgroundColor: "white", marginTop: "10px" }}>
                <DataGridPremium
                    hideFooter
                    filterModel={filterModel}
                    onFilterModelChange={(model) => {
                        console.log(model);
                        setFilterModel(model);
                    }}
                    // slots={{ toolbar: GridToolbar }}
                    loading={loading}
                    density="compact"
                    columns={columns}
                    getCellClassName={(params: any) => {

                        if (params.value === "[자동 입력]") {
                            return "autoinput";
                        }

                        if (params.field === "name") {
                            //params.value에 "중복"이 들어가면 duplicate라는 클래스를 준다.
                            if (params.value.includes("중복")) {
                                return "duplicate";
                            }
                        }

                        if (params.field === "sendCard") {

                            if (params.value && params.value.includes("제출 완료")) {
                                return "completed2";
                            }

                        }

                        if (params.field === "seat") {

                            if (params.value === "0") {
                                return "letsselect";
                            }

                        }

                        return ""
                    }}
                    getRowClassName={(params: GridRowClassNameParams<any>) => {

                        if (params.row.justForFilter === "완료됨") {
                            return "completed";
                        }

                        return ""
                    }}
                    rows={rows}
                    processRowUpdate={handleEditCommit}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    apiRef={apiRef}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        const data: any = apiRef.current.getSelectedRows();
                        if (!data) {
                            return;
                        }
                        console.log("---------------");
                        console.log(data);

                        console.log(newSelectionModel);

                        data.forEach((value: any, index: number) => {

                            const newSelectedInfo = {
                                id : value.id,
                                userId: value.userId
                            }

                            console.log(newSelectedInfo);
                            setSelectedRow(newSelectedInfo);
                        })
                    }}
                />
            </div>

            <ToastContainer />

            <ImageUploadModal open={imageUploadModalOpen} handleClose={handleImageUploadModalClose} update={getNewTodoList} id={selectedRow ? selectedRow.id : undefined} success={notify2} kind="staffTodos" />

            <ImageModal
                open={open3}
                handleClose={() => { setOpen3(false) }}
                src={imageSrc}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={btnLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Modal open={open} onClose={() => {setOpen(false); setLocation("gangnam"); setPosition([false, false, false, false, false]); setDescription("");}}>
                <ModalDialog>
                    <div style={{
                        fontFamily: "Apple_B",
                        fontSize: "18px",
                        marginBottom: "24px"
                    }}>
                        새로운 업무 등록하기
                    </div>

                    <div style={{
                        marginBottom: "6px",
                        fontSize: "17px",
                        fontFamily: "Apple_R",
                    }}>
                        호점 선택
                    </div>
                    <RadioGroup
                        value={location}
                        orientation="horizontal"
                        onChange={(e: any) => {
                            setLocation(e.target.value);
                        }}
                    >
                        {/* <Radio value="gangnam" label="강남점" />
                        <Radio value="daechi" label="대치점" />
                        <Radio value="daechi2" label="대치3층" />
                        <Radio value="daechi3" label="대치6층" /> */}
                        {
                            props.user && totalLocationData.filter((each) => each.academy.includes(props.user.academy)).map((each, index) => {

                                if(each.english === "total"){
                                    return
                                }

                                return <Radio value={each.english} label={each.korean} key={index} />
                            })
                        }
                    </RadioGroup>
                    <div style={{
                        marginBottom: "12px",
                        fontSize: "17px",
                        fontFamily: "Apple_R",
                        marginTop: "18px"
                    }}>
                        직책
                    </div>
                    <div>
                        <div style={{
                            display: "flex",
                            width: "300px",
                            flexWrap: "wrap"
                        }}>
                            {
                                positionList.map((each, index) => {
                                    return (
                                        <div
                                            key={each}
                                            style={{
                                                marginBottom: "6px",
                                                marginRight: `${index % 2 === 0 ? "18px" : "0px"}`,
                                            }}>
                                            <Checkbox label={each} checked={position[index]} onChange={(e) => {
                                                const newPosition = [...position];
                                                newPosition[index] = e.target.checked;
                                                setPosition([...newPosition]);
                                            }} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div style={{
                            marginBottom: "12px",
                            fontSize: "17px",
                            fontFamily: "Apple_R",
                            marginTop: "12px"
                        }}>
                            전달사항
                        </div>
                        <div>
                            <Textarea minRows={2} value={description} onChange={(e : any) => {setDescription(e.target.value)}} />
                        </div>
                    </div>

                    <div style={{
                        marginTop: "32px"
                    }}>
                        <Button variant="solid" size="sm" fullWidth onClick={submit} loading={registerLoading} disabled={disabled6}>
                            등록하기
                        </Button>
                    </div>
                </ModalDialog>
            </Modal>



        </div>
    )

}

export default Todos;