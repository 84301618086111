import React, { useEffect, useState, useRef } from "react";
import ClassSelect from "./classselect";
import { Button, CssVarsProvider, List, ListItem, ListItemDecorator, Modal, ModalClose, ModalDialog, Radio, RadioGroup, Textarea } from "@mui/joy";
import ExampleButtonCheckbox from "./phoneselect";
import { Call, ChatBubble, ChevronLeft, ChevronRight, ChildCare, Email, EscalatorWarning, Publish, Send } from "@mui/icons-material";
import { filter, get, set } from "lodash";
import { Backdrop, CircularProgress, SwipeableDrawer, TextField } from "@mui/material";
import InspectPhone from "./inspectphone";

import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    LicenseInfo,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { prettifyAccessControlData, calcRegularAttendance, calcSuddenAttendance, calcInspectPhone, filterStudentList, realtimeAccessControl, sortStudentList, calcOneStudentSuddenAttendance, correctRegularSudden } from "../functions/calcattendance";
import StudentAttendanceInfo from "./studentattendanceinfo";
import ReactModal from 'react-modal-resizable-draggable';
import { ReactComponent as XMarkSvg } from "../../../../../svg/circle-xmark-solid.svg"
import Person from '@mui/icons-material/Person';
import People from '@mui/icons-material/People';
import Apartment from '@mui/icons-material/Apartment';
import Chat from "../../chat";
import { returnLocationClassInfo } from "../../../../../data/locationFuntion";


LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

export interface studentList {
    accessControl: any[],
    afterClassAccessControl?: any[],
    beforeClassAccessControl?: any[],
    createdAt: string,
    currentStatus?: string,
    duringClassAccessControl?: any[],
    fingerprintId?: number,
    isNever?: boolean,
    firstCome: null | string,
    id: number,
    isNow: null | number,
    location: string,
    name: string,
    parentPhoneNumber: string,
    phoneNumber: string,
    regularJustified?: boolean,
    regularType?: string,
    seat: "string",
    suddenJustified?: boolean,
    suddenType?: string,
    value: string,
    phoneSubmit?: boolean,
    //phoneProblem 0이면 문제 없음, 1이면 사람은 없는데 폰은 있는 경우, 2이면 사람은 있는데 폰은 없는 경우
    phoneProblem?: number,
    phoneInspectMemo?: string,
    phoneInspectMessagedSentDate?: string,
    realTimeStatus?: string,
    realTimeBySudden?: boolean,
    realTimeByAccess?: boolean,
    chatRoomId?: string,
    phoneMessageSentTime? : [],
    isSuspicious? : boolean;
    grade? : string;
}




const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .suspicious_in': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .suspicious': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .phone_problem': {
                    color: "red"
                },
                '& .phone_noproblem': {
                    color: "black"
                },
                '& .phone_memo': {
                    color: "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);

var remain = true;
var isGangnamSending = false;
var isDaechiSending = false;
var isDaechi2Sending = false;
var isDaechi3Sending = false;
var isSongdoSending = false;


const AttendanceCheck: React.FC<any> = (props) => {

    const totalWidth = 1700;

    const [classNumber, setClassNumber] = useState("");
    const [classNumberString, setClassNumberString] = useState("0교시");
    const [modalOpen, setModalOpen] = useState(false);
    const [targetUsers, setTargetUsers] = useState<any[]>([]);

    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<boolean[]>(new Array(75).fill(false));

    const [targetDate, setTargetDate] = useState(new Date());

    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(0);
    const classes = useStyles2();
    const [selectedUserId, setSelectedUserId] = useState<number>();
    const [selectedUserName, setSelectedUserName] = useState<string>();
    const [selectedUserPhoneNumber, setSelectedUserPhoneNumber] = useState<string>();
    const [selectedParentPhoneNumber, setSelectedParentPhoneNumber] = useState<string>();

    const textareaRef = useRef<any>(null);
    const apiRef = useGridApiRef();

    const [smsModalOpen, setSmsModalOpen] = useState(false);

    const [targetDateIsToday, setTargetDateIsToday] = useState(true);

    const [rows, setRows] = useState<GridRowsProp[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([
        { field: "seat", headerName: "좌석", width: 80 },
        { field: "name", headerName: "이름", width: 100 },
        { field: "status", headerName: "상태", width: 80 },
        // { field : "etc1", headerName : "참고1", width : 100 },
        // { field : "etc2", headerName : "참고2", width : 100 },
        { field: "phoneStatus", headerName: "휴대폰", width: 60 },
        // { field : "phoneProblem", headerName : "휴대폰문제", width : 100 },
        { field: "phoneMemo", headerName: "휴대폰처리내역", width: 120, editable: true },
        { field: "sentMessage", headerName: "미제출 메세지 전송", width: 120 },
    ])

    const [loading2, setLoading2] = useState(false);
    const [noPhoneBtnLoading, setNoPhoneBtnLoading] = useState(false);

    const [disabled2, setDisabled2] = useState(false);
    const [noPhoneBtnDisabed, setNoPhoneBtnDisabled] = useState(false);


    const intervalIdRef = useRef<any>(null);
    const [smsText, setSmsText] = useState("");
    const [smsDisabled, setSmsDisabled] = useState(false);
    const [smsLoading, setSmsLoading] = useState(false);
    const [callLoading, setCallLoading] = useState(false);
    const [smsTarget, setSmsTarget] = useState("student");

    const [chatroomOpen, setChatroomOpen] = useState(false);
    const [selectedChatRoomId, setSelectedChatRoomId] = useState<number>();
    const [searchText, setSearchText] = useState("");

    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 2,
                field: "name",
                operator: "contains",
                value: ""
            },
        ],
    });

    useEffect(() => {

        if(!props.user){
            return;
        }

        const academy = props.user.academy;

        if(!academy){
            return;
        }

        if(academy !== "mom"){
            return;
        }

        //grade column 한개 추가
        const newColumns = [...columns];

        var isAlready = false;

        newColumns.forEach((column) => {
            if(column.field === "grade"){
                isAlready = true;
            }
        })

        if(isAlready){
            return;
        }

        //2번째 column에 grade column 추가
        newColumns.splice(2, 0, { field: "grade", headerName: "학년", width: 80 });

        setColumns([...newColumns]);

    }, [props.user]);

    const handleSearchTextChange = (e : any) => {

        setSearchText(e.target.value);

        const searchText = e.target.value;

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((item) => {

            if (item.field === "name") {
                item.value = searchText;
            }

        })

        setFilterModel({ ...newFilterModel });

    }


    useEffect(() => {

        if (smsText) {
            setSmsDisabled(false);
        } else {
            setSmsDisabled(true);
        }


    }, [smsText]);

    useEffect(() => {

        const today = new Date();

        if (targetDate.getFullYear() === today.getFullYear() && targetDate.getMonth() === today.getMonth() && targetDate.getDate() === today.getDate()) {
            setTargetDateIsToday(true);
        } else {
            setTargetDateIsToday(false);
        }

    }, [targetDate]);

    useEffect(() => {

        if (selectedUserId) {
            setDisabled2(false);
        } else {
            setDisabled2(true);
        }

    }, [selectedUserId]);

    useEffect(() => {

        if (selectedUserId) {
            
            //targetDate가 today 인지 확인
            const today = new Date();
            var isToday = false;

            if (targetDate.getFullYear() === today.getFullYear() && targetDate.getMonth() === today.getMonth() && targetDate.getDate() === today.getDate()) {
                isToday = true;
            }

            const classInfo = returnLocationClassInfo(props.location);

            //classInfo에서 현재 교시 정보 가져오기
            const currentClassInfo = classInfo.find((info) => info.classNumber === classNumber);
            
            if(!currentClassInfo){
                setNoPhoneBtnDisabled(true);
                return;
            }

            var isTodayTimeInCurrentClassTime = false;

            if (isToday) {

                const currentClassStartHours = currentClassInfo.start.hours;
                const currentClassStartMinutes = currentClassInfo.start.minutes;

                const currentClassEndHours = currentClassInfo.end.hours;
                const currentClassEndMinutes = currentClassInfo.end.minutes;

                const currentClassStartDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), currentClassStartHours, currentClassStartMinutes, 0);
                const currentClassEndDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), currentClassEndHours, currentClassEndMinutes, 0);

                const currentClassStartDateTime = currentClassStartDate.getTime();
                const currentClassEndDateTime = currentClassEndDate.getTime();

                const currentDateTime = new Date().getTime();

                if (currentDateTime >= currentClassStartDateTime && currentDateTime <= currentClassEndDateTime) {
                    isTodayTimeInCurrentClassTime = true;
                }

            }

            if (isTodayTimeInCurrentClassTime) {
                setNoPhoneBtnDisabled(false);
            } else {
                setNoPhoneBtnDisabled(true);
            }



        } else {
            setNoPhoneBtnDisabled(true);
        }

    }, [selectedUserId, classNumber, targetDate]);

    useEffect(() => {

        let websocket: WebSocket;


        const connectWebSocket = () => {

            websocket = new WebSocket("wss://stream.pushbullet.com/websocket/o.9ptXPdaj34uaiQv5qOgbeIqXmFdGdLjO");

            websocket.onopen = () => {
                console.log("connected");
                if (intervalIdRef.current) {
                    clearInterval(intervalIdRef.current);
                    intervalIdRef.current = null;
                }
            }

            websocket.onclose = () => {

                console.log("closed");

                console.log(intervalIdRef.current);

                if (intervalIdRef.current) {
                    console.log("letsClearInterval");
                    clearInterval(intervalIdRef.current);
                    intervalIdRef.current = null;
                }

                if (!remain) {
                    return;
                }

                intervalIdRef.current = setInterval(() => {
                    console.log("reconnecting");
                    connectWebSocket();
                }, 3000);

            }

            websocket.onmessage = (event) => {
                console.log(event);

                const data = JSON.parse(event.data);
                console.log(data);

                if (data && data.push && data.push.source_device_iden === "ujDRqY3QmAKsjDFLzbR7Su" && data.push.type === "sms_changed") {
                    //강남점
                    console.log("강남점");
                    if (data.push.notifications.length === 0) {
                        console.log("gangnam message sent");

                        if (isGangnamSending) {

                            console.log("sent");
                            setSmsLoading(false);
                            isGangnamSending = false;
                            props.notify2();

                        }


                    } else {
                        console.log("gangnam message received");
                    }
                }

                if (data && data.push && data.push.source_device_iden === "ujDRqY3QmAKsjw7m5IThCe" && data.push.type === "sms_changed") {
                    //강남점
                    console.log("대치점");
                    if (data.push.notifications.length === 0) {
                        console.log("daechi message sent")

                        if (isDaechiSending) {

                            console.log("sent");
                            setSmsLoading(false);
                            isDaechiSending = false;
                            props.notify2();


                        }


                    } else {
                        console.log("daechi message received");
                    }
                }

                if (data && data.push && data.push.source_device_iden === "ujDRqY3QmAKsjx2KCm1jMq" && data.push.type === "sms_changed") {
                    //강남점
                    console.log("대치3층");
                    if (data.push.notifications.length === 0) {
                        console.log("daechi message sent")

                        if (isDaechi2Sending) {

                            console.log("sent");
                            setSmsLoading(false);
                            isDaechiSending = false;
                            props.notify2();


                        }


                    } else {
                        console.log("daechi message received");
                    }
                }

                if (data && data.push && data.push.source_device_iden === "ujDRqY3QmAKsjAKAxcJ0oK" && data.push.type === "sms_changed") {
                    //강남점
                    console.log("대치6층");
                    if (data.push.notifications.length === 0) {
                        console.log("daechi message sent")

                        if (isDaechi3Sending) {

                            console.log("sent");
                            setSmsLoading(false);
                            isDaechiSending = false;
                            props.notify2();
                        }
                    }
                }

                if(data){
                    console.log(1);
                }

                if(data && data.push){
                    console.log(2);
                }

                if(data && data.push && data.push.source_device_iden === "ujDRqY3QmAKsjyZUAqJQg8"){
                    console.log(3);
                }

                if(data && data.push && data.push.source_device_iden === "ujDRqY3QmAKsjyZUAqJQg8" && data.push.type === "sms_changed"){
                    console.log(4);
                }

                if (data && data.push && data.push.source_device_iden === "ujDRqY3QmAKsjyZUAqJQg8" && data.push.type === "sms_changed") {

                    console.log(5);
                    
                    console.log("sms changed");
                    if(data.push.notifications.length === 0){
                        console.log("songdo message sent");

                        if(isSongdoSending){
                            console.log("sent");
                            setSmsLoading(false);
                            isSongdoSending = false;
                            props.notify2();
                        }

                    }

                }

            }

            websocket.onerror = (event) => {
                websocket.close();
            }

        }

        connectWebSocket();


        return () => {

            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
            }

            remain = false;

            websocket.close();
        }


    }, []);


    //출입기록 및 사유제출 실시간으로 업데이트 하기 위한 socket room join
    useEffect(() => {

        if (!props.location) {
            return;
        }

        if (!props.socket) {
            return;
        }

        props.socket.emit("joinAttendanceCheckRoom", props.location);

        return () => {
            props.socket.emit("leaveAttendanceCheckRoom", props.location);
        }


    }, [props.location, props.socket]);

    useEffect(() => {

        if (!props.location) {
            return;
        }

        if (!props.socket) {
            return;
        }

        const classInfo = returnLocationClassInfo(props.location);

        props.socket.on("attendanceCheckUpdate", (fingerprintId: number, location: string, direction: string) => {



            console.log(+fingerprintId);
            console.log("attendanceCheckUpdate");

            if (!isNowInClass()) {
                console.log("not in class")
                return;
            }

            if (props.location !== location) {
                console.log("not in this location")
                return;
            }

            console.log(direction);

            if (direction !== "outside") {
                console.log("not outside")
                return;
            }

            const newRows = [...rows];

            newRows.forEach((eachRow: any) => {

                if (+eachRow.fingerprintId === +fingerprintId) {
                    console.log(fingerprintId);
                    console.log("lets in");
                    eachRow.realTimeStatus = "in";
                    eachRow.realTimeByAccess = true;
                }

            })

            setRows([...newRows]);

        })

        props.socket.on("suddenUpdate", (suddenData: any) => {

            console.log("suddenUpdate");

            const targetDateStartDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), 0, 0, 0);
            const targetDateEndDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), 23, 59, 59);

            const targetDateStartDateTime = targetDateStartDate.getTime();
            const targetDateEndDateTime = targetDateEndDate.getTime();

            const suddenTargetDateDate = new Date(suddenData.targetDate);
            const suddenTargetDateDateTime = suddenTargetDateDate.getTime();

            if (suddenTargetDateDateTime < targetDateStartDateTime || suddenTargetDateDateTime > targetDateEndDateTime) {
                console.log("notForToday");
                return;
            }

            const newRows = [...rows];

            var haveToUpdate = false;



            newRows.forEach((eachRow: any) => {

                if (eachRow.id === suddenData.userId) {

                   
                    const result = calcOneStudentSuddenAttendance(suddenData, classNumber, classInfo);

                    if (result) {
                        eachRow.realTimeStatus = "in";
                        eachRow.realTimeBySudden = true;
                        haveToUpdate = true;
                    }

                }

            })

            if (haveToUpdate) {
                setRows([...newRows]);
            }

        });

        return () => {
            props.socket.off("attendanceCheckUpdate");
            props.socket.off("suddenUpdate");
        }

    }, [props.location, props.socket, rows]);


    const isNowInClass: () => boolean = () => {

        if (!classNumber) {
            return false;
        }

        const classInfo = returnLocationClassInfo(props.location);

        //현재 교시의 시작 시간과 종료 시간을 classInfo에서 가져온다.
        const currentClassInfo = classInfo.find((info) => info.classNumber === classNumber);

        if (!currentClassInfo) {
            return false;
        }

        const startHours = currentClassInfo.start.hours;
        const startMinutes = currentClassInfo.start.minutes;

        const endHours = currentClassInfo.end.hours;
        const endMinutes = currentClassInfo.end.minutes;

        //targetDate의 연 월 일 과 start, end를 합쳐서 startClassDate, endClassDate를 만든다.
        const startClassDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), startHours, startMinutes, 0);
        const endClassDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), endHours, endMinutes, 0);

        const startClassDateTime = startClassDate.getTime();
        const endClassDateTime = endClassDate.getTime();

        const currentDateTime = new Date().getTime();

        if (currentDateTime >= startClassDateTime && currentDateTime <= endClassDateTime) {
            return true;
        }

        return false;

    }

    useEffect(() => {

        const result = isNowInClass();
        console.log("isNowInClass");
        console.log(result);

    }, [classNumber, targetDate]);



    const handleTargetDateChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {

        if (type === "plus") {
            //오늘이 아닐 경우 하루 더하기

            const today = new Date();

            if (targetDate.getFullYear() === today.getFullYear() && targetDate.getMonth() === today.getMonth() && targetDate.getDate() === today.getDate()) {
                return;
            }

            const newDate = new Date(targetDate);
            newDate.setDate(newDate.getDate() + 1);

            setTargetDate(newDate);

        }

        if (type === "minus") {
            //하루 빼기

            const newDate = new Date(targetDate);
            newDate.setDate(newDate.getDate() - 1);

            setTargetDate(newDate);
        }

    }


    const handleClassNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setClassNumber(e.target.value);
    }

    const handleOpenInspectPhone = (e: any) => {
        setModalOpen(true);
    }

    useEffect(() => {

        switch (classNumber) {
            case "zero":
                setClassNumberString("0교시");
                break;
            case "first":
                setClassNumberString("1교시");
                break;
            case "second":
                setClassNumberString("2교시");
                break;
            case "third":
                setClassNumberString("3교시");
                break;
            case "fourth":
                setClassNumberString("4교시");
                break;
            case "fifth":
                setClassNumberString("5교시");
                break;
            case "sixth":
                setClassNumberString("6교시");
                break;
            case "seventh":
                setClassNumberString("7교시");
                break;
            case "eighth":
                setClassNumberString("8교시");
                break;
            case "ninth":
                setClassNumberString("9교시");
                break;
            case "tenth":
                setClassNumberString("10교시");
                break;
            case "eleventh":
                setClassNumberString("11교시");
                break;
            case "twelfth":
                setClassNumberString("12교시");
                break;
            case "thirteenth":
                setClassNumberString("13교시");
                break;
            case "fourteenth":
                setClassNumberString("14교시");
                break;
        }


    }, [classNumber]);


    const letsUpdate = () => {

        setUpdate(Math.random());

    }




    useEffect(() => {

        if(!classNumber){
            return;
        }

        getAttendanceInfo();

    }, [classNumber, targetDate, update]);


    const getAttendanceInfo = async () => {

        const targetDateTime = new Date(targetDate).getTime();

        const data = {
            classNumber,
            targetDateTime,
            location: props.location
        }
        

        if (!data.classNumber || !data.targetDateTime || !data.location) {
            return;
        }

        const response = await fetch("https://peetsunbae.com/dashboard/report/getattendanceinfo", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(data)
        })

        const result = await response.json();

        if (result.message === "success") {

            const studentList: studentList[] = result.studentList;
            const regularScheduleFormatData = result.regularScheduleFormatData;
            const suddenNoticeData = result.suddenNoticeData;
            const phoneInspectData = result.phoneInspectData;
            const phoneInspectMemoData = result.phoneInspectMemoData;


            if (!studentList || !regularScheduleFormatData || !suddenNoticeData || !phoneInspectData || !phoneInspectMemoData) {
                console.log(studentList, regularScheduleFormatData, suddenNoticeData, phoneInspectData, phoneInspectMemoData)
                console.log("데이터가 없습니다.")
                return;
            }

            console.log(1);

            const classInfo = returnLocationClassInfo(props.location);

            //beforeAccesscontrol, afteraccesscontrol로 나누기/ 해당 교시 기준 in, out 나누기
            prettifyAccessControlData(studentList, classNumber, targetDate, classInfo);


            //prettify로 만든 studentList데이터를 suddenNoticeData와 calcSuddenAttendance에 집어 넣는다.
            calcSuddenAttendance(studentList, suddenNoticeData, classNumber, targetDate, classInfo);

            //studentList데이터를 regularScheduleFormatData와 calcRegularAttendance에 집어 넣는다.
            calcRegularAttendance(studentList, regularScheduleFormatData, targetDate, classNumber, classInfo);


            //더 해야될것. (1. 휴대폰 검사 기록 추가시키기, 2. realtime으로 중간에 들어온 애들 체킹시켜주기 3. 필터링으로 필요없는 애들 빼주기)
            calcInspectPhone(studentList, phoneInspectData, phoneInspectMemoData, data.location);

            //realtime
            realtimeAccessControl(studentList);

            //correctRegularSudden
            correctRegularSudden(studentList, targetDate, classNumber, suddenNoticeData, classInfo);

            //필터링 (1. 필요 없는 id들 빼주기 2. firstcome 이전인 학생들 빼주기)
            const filteredStudentList = filterStudentList(studentList, targetDate);

            console.log("letscheck");
            console.log(studentList);

            //sort
            sortStudentList(filteredStudentList);

            makeRows(filteredStudentList);

            settingTargetUsers(filteredStudentList);

        }

    }

    const settingTargetUsers = (studentList: studentList[]) => {

        const newTargetUsers: any = [];

        studentList.forEach((eachStudent: studentList) => {

            if (eachStudent.currentStatus === "out" && (!eachStudent.regularJustified && !eachStudent.suddenJustified)) {

                const oneRow: any = {};
                oneRow.userId = eachStudent.id;
                oneRow.fingerprintId = eachStudent.fingerprintId;
                oneRow.name = eachStudent.name;

                newTargetUsers.push(oneRow);
            }

        });

        console.log(newTargetUsers);

        setTargetUsers([...newTargetUsers]);

    }

    const makeRows = (studentList: studentList[]) => {

        console.log(studentList);

        if (!studentList) {
            return;
        }

        const newRows: any[] = [];

        var phoneSubmitTrueCount = 0;

        studentList.forEach((eachStudent: studentList) => {
            if (eachStudent.phoneSubmit) {
                phoneSubmitTrueCount++;
            }
        })


        studentList.forEach((eachStudent: studentList, index : number) => {

            const oneRow: any = {};

            oneRow.id = eachStudent.id;
            oneRow.phoneNumber = eachStudent.phoneNumber;
            oneRow.parentPhoneNumber = eachStudent.parentPhoneNumber;
            
            const grade = eachStudent.grade ? eachStudent.grade : "";
            var gradeString = "";

            switch(grade){

                case "middle3" :
                    gradeString = "중3";
                    break;
                case "high1" :
                    gradeString = "고1";
                    break;
                case "high2" :
                    gradeString = "고2";
                    break;
                case "high3" :
                    gradeString = "고3";
                    break;
                case "n" :
                    gradeString = "N수생";
                    break;
            }

            oneRow.grade = gradeString;

            if(eachStudent.phoneMessageSentTime && eachStudent.phoneMessageSentTime.length > 0){
                console.log("gogogo!!");
                const lastPhoneMessageSentTime = eachStudent.phoneMessageSentTime[eachStudent.phoneMessageSentTime.length - 1];

                const lastPhoneMessageSentDate = new Date(lastPhoneMessageSentTime);

                //AM,PM HH:MM 형식으로 바꾸기   
                var lastPhoneMessageSentHours = lastPhoneMessageSentDate.getHours();
                var lastPhoneMessageSentMinutes = lastPhoneMessageSentDate.getMinutes();

                var lastPhoneMessageSentHoursString = "";
                var lastPhoneMessageSentMinutesString = "";

                var AMPM = "";

                if (lastPhoneMessageSentHours > 11) {
                    AMPM = "PM";
                }else{
                    AMPM = "AM";
                }

                if (lastPhoneMessageSentHours > 12) {
                    lastPhoneMessageSentHours -= 12;
                }

                if (lastPhoneMessageSentHours < 10) {
                    lastPhoneMessageSentHoursString = "0" + lastPhoneMessageSentHours;
                }else{
                    lastPhoneMessageSentHoursString = "" + lastPhoneMessageSentHours;
                }

                if (lastPhoneMessageSentMinutes < 10) {
                    lastPhoneMessageSentMinutesString = "0" + lastPhoneMessageSentMinutes;
                }else{
                    lastPhoneMessageSentMinutesString = "" + lastPhoneMessageSentMinutes;
                }

                const lastPhoneMessageSentTimeString = AMPM + " " + lastPhoneMessageSentHoursString + ":" + lastPhoneMessageSentMinutesString;

                oneRow.sentMessage = lastPhoneMessageSentTimeString;

            }

            oneRow.seat = eachStudent.seat;
            oneRow.name = eachStudent.name;
            oneRow.status = eachStudent.currentStatus;
            oneRow.fingerprintId = eachStudent.fingerprintId;
            if (phoneSubmitTrueCount > 0) {
                oneRow.phoneStatus = eachStudent.phoneSubmit ? "O" : "X";
                oneRow.phoneProblem = eachStudent.phoneProblem;
            }
            oneRow.phoneMemo = eachStudent.phoneInspectMemo;
            oneRow.realTimeStatus = eachStudent.realTimeStatus;
            oneRow.realTimeBySudden = eachStudent.realTimeBySudden;
            oneRow.realTimeByAccess = eachStudent.realTimeByAccess;
            oneRow.isNever = eachStudent.isNever;
            oneRow.chatRoomId = eachStudent.chatRoomId;
            oneRow.isSuspicious = eachStudent.isSuspicious;

            if(oneRow.name === "김채영"){
                console.log(eachStudent);
                console.log(oneRow);
            }

            //(currentStatus === "out" && (!regularJustified && !suddenJustified))
            if (eachStudent.currentStatus === "out" && (!eachStudent.regularJustified && !eachStudent.suddenJustified)) {

                oneRow.guilty = true;

            }



            newRows.push(oneRow);
        })

        console.log(newRows);

        setRows([...newRows]);

    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";


            console.log("new");

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const userId = newRow.id;
            const currentTargetDate = targetDate
            const currentClassNumber = classNumber;
            const location = props.location;

            if (!field) {
                console.log("noChanged");
                return;
            }

            if (field !== "phoneMemo") {
                console.log("noPhoneMemo");
                return;
            }

            if (!userId || !currentTargetDate || !currentClassNumber || !location) {
                console.log("noValue");
                return;
            }

            const data = {
                field,
                value: value ? value : "",
                userId,
                targetDateTime: currentTargetDate.getTime(),
                classNumber: currentClassNumber,
                location
            }

            console.log(data);

            fetch("https://peetsunbae.com/dashboard/report/attendnace/phonememo", {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(data)
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        console.log(result);
                        if (result.message === "success") {
                            setUpdate(Math.random());
                        }
                    })
            })

            return newRow;


        }, [targetDate, classNumber, props.location]
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log("error");
        alert(error.message);
    }, []);

    const handleCloseSms = (e: any) => {
        setSmsText("");
        setSmsModalOpen(false);
    }

    const sendSms = async () => {

        const value = smsText;
        const location = props.location;
        var targetPhoneNumber = ""

        if (!value) {
            return;
        }

        if (!location) {
            return;
        }

        switch (smsTarget){
            case "student":
                targetPhoneNumber = selectedUserPhoneNumber ? selectedUserPhoneNumber : "";
                break;
            case "parent":
                targetPhoneNumber = selectedParentPhoneNumber ? selectedParentPhoneNumber : "";
                break;
        }

        if(!targetPhoneNumber){
            alert("저장된 번호가 없습니다.");
            return;
        }

        switch (location) {
            case "gangnam":
                isGangnamSending = true;
                break;
            case "daechi":
                isDaechiSending = true;
                break;
            case "daechi2":
                isDaechi2Sending = true;
                break;
            case "daechi3":
                isDaechi3Sending = true;
                break;
            case "songdo_fixed":
                isSongdoSending = true;
                break;
            case "songdo_free":
                isSongdoSending = true;
                break;
        }

        setSmsLoading(true);

        const data = {
            value,
            location,
            phoneNumber: targetPhoneNumber,
        }

        console.log(data);

        setSmsText("");
        handleCloseSms("e");

        const response = await fetch("https://peetsunbae.com/managephone/sendsmsbypushbullet", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(data)
        })

        const result = await response.json();

        console.log(result);

        if (result.message === "success") {

        }

    }

    const letsCall = async () => {

        if (!props.location) {
            return;
        }

        if (!selectedUserPhoneNumber) {
            console.log("no selected user phone number")
            return;
        }

        setCallLoading(true);

        const location = props.location;

        var myPhoneNumber = "";

        switch (location) {
            case "gangnam":
                myPhoneNumber = "01071860489";
                break;
            case "daechi":
                myPhoneNumber = "01053360489";
                break;
            case "daechi2":
                myPhoneNumber = "01092030489";
                break;
            case "daechi3":
                myPhoneNumber = "01090620489";
                break;
            case "songdo_fixed" :
                myPhoneNumber = "01045231953";
                break;
            case "songdo_free" :
                myPhoneNumber = "01045231953";
                break;
        }

        const data = {
            location,
            targetPhoneNumber: selectedUserPhoneNumber,
            myPhoneNumber
        }

        const response = await fetch("https://peetsunbae.com/managephone/actioncall", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })

        const result = await response.json();

        console.log(result);

        if (result.message === "success") {
            props.notify3();
            setCallLoading(false);
        }



    }

    const toggleDrawer = (open : boolean) => {
        setChatroomOpen(open);
    }

    const sendNoPhoneMessage = async () => {

        if(!selectedUserId){
            return;
        }

        if(!window.confirm(selectedUserName + "학생 및 학부모님께 휴대폰 미제출 메세지를 보내시겠습니까?")){
            return;
        }

        setNoPhoneBtnLoading(true);

        const data = {
            selectedUserId,
            targetDate : targetDate.getTime(),
            classNumber,
            location : props.location,
        }

        const response = await fetch("https://peetsunbae.com/dashboard/report/attendance/nophonemessage", {
            method : "post",
            headers : {
                "Content-Type" : "application/json"
            },
            credentials : "include",
            body : JSON.stringify(
                data
            )
        })

        const result = await response.json();

        console.log(result);

        if(result.message === "success"){
            setNoPhoneBtnLoading(false);
            props.notify4();
            letsUpdate();
        }
    }


    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                width: `${totalWidth}px`,
                position : "relative"
            }}>
                <div>
                    <ClassSelect handleClassNumber={handleClassNumber} targetDate={targetDate} location={props.location} />
                </div>
                <div>
                    <div style={{
                        marginBottom: "6px", fontSize: "16px", fontFamily: "Apple_R", paddingRight: "4px"
                    }}>
                        {targetDate.getFullYear()}년 {targetDate.getMonth() + 1}월 {targetDate.getDate()}일
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <Button variant="outlined" color="neutral" sx={{
                            marginRight: "12px",
                            width: "40px",
                            height: "40px"
                        }}
                            onClick={(e: any) => {
                                handleTargetDateChange(e, "minus")
                            }}
                        >
                            <ChevronLeft />
                        </Button>
                        <Button variant="outlined" color="neutral" sx={{
                            width: "40px",
                            height: "40px"
                        }}
                            disabled={targetDateIsToday}
                            onClick={(e: any) => {
                                handleTargetDateChange(e, "plus")
                            }}
                        >
                            <ChevronRight />
                        </Button>
                    </div>
                    <div
                    style={{
                        position : "absolute",
                        top : "100px",
                        right : "0px"
                    }}
                    >
                        {
                            props.location && returnLocationClassInfo(props.location).map((eachClassInfo) => {
                                if(eachClassInfo.classNumber === classNumber){
                                    // AM/PM 02:32 ~  AM/PM 02:35 꼴로 나타낸다 

                                    var startTimeString = "";
                                    var endTimeString = "";

                                    eachClassInfo.start.hours < 12 ? startTimeString = "AM" : startTimeString = "PM";

                                    startTimeString += " ";

                                    var startHours;

                                    if(eachClassInfo.start.hours > 12){
                                        startHours = eachClassInfo.start.hours - 12;
                                    }else{
                                        startHours = eachClassInfo.start.hours;
                                    }

                                    startTimeString += startHours < 10 ? `0${startHours}` : `${startHours}`;

                                    startTimeString += ":";

                                    startTimeString += eachClassInfo.start.minutes < 10 ? `0${eachClassInfo.start.minutes}` : `${eachClassInfo.start.minutes}`;

                                    eachClassInfo.end.hours < 12 ? endTimeString = "AM" : endTimeString = "PM";

                                    endTimeString += " ";

                                    var endHours;

                                    if(eachClassInfo.end.hours > 12){
                                        endHours = eachClassInfo.end.hours - 12;
                                    }else{
                                        endHours = eachClassInfo.end.hours;
                                    }

                                    endTimeString += endHours < 10 ? `0${endHours}` : `${endHours}`;

                                    endTimeString += ":";

                                    endTimeString += eachClassInfo.end.minutes < 10 ? `0${eachClassInfo.end.minutes}` : `${eachClassInfo.end.minutes}`;


                                    return (
                                        <div key={Math.random()}>
                                            {
                                                startTimeString
                                            } ~ {
                                                endTimeString
                                            }
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            <div style={{
                marginTop: "32px"
            }}>
                <Button variant="soft" size="lg" onClick={
                    handleOpenInspectPhone
                }>
                    {classNumberString} 핸드폰 검사하기
                </Button>
            </div>

            <div style={{ height: "32px" }}>

            </div>

            <div style={{
                width: "600px",
                display: "flex",
                justifyContent: "space-between"
            }}>
                <div>
                    <Button variant="soft" size="md"
                        style={{
                            marginRight: "8px"
                        }}
                        color="neutral"
                        disabled={disabled2}
                        loading={callLoading}
                        onClick={(e: any) => {
                            letsCall();
                        }}
                    >
                        <Call />
                    </Button>
                    <Button variant="soft" size="md"
                        style={{
                            marginRight: "8px"
                        }}
                        color={
                            smsLoading ? "warning" : "neutral"
                        }
                        loading={smsLoading}
                        disabled={disabled2 || smsLoading}
                        onClick={(e: any) => {
                            setSmsTarget("student");
                            setSmsModalOpen(true);
                            if (textareaRef.current) {
                                textareaRef.current.focus();
                            }
                        }}
                    >
                        <Email />
                    </Button>
                    <Button variant="soft" size="md"
                        color="neutral"
                        disabled={disabled2}
                        onClick={(e: any) => {

                            if(!selectedChatRoomId){
                                alert("채팅방이 생성되지 않은 학생입니다. 먼저 '메시지' 메뉴에서 채팅방을 생성 후 시도해 주세요.");
                                return;
                            }

                            toggleDrawer(true);
                        }}
                    >
                        <ChatBubble />
                    </Button>
                    <TextField
                    variant="standard"
                    placeholder="학생 이름 검색"
                    value={searchText}
                    onChange={(e : any) => {
                        handleSearchTextChange(e);
                    }}
                    sx={{
                        marginLeft: "16px",
                    }}
                    />

                </div>
                <div>
                    <Button variant="soft" size="md"
                        color="neutral"
                        disabled={noPhoneBtnDisabed}
                        onClick={(e : any) => {
                            sendNoPhoneMessage();
                        }}
                        loading={noPhoneBtnLoading}
                    >
                        폰 미제출 메세지
                    </Button>
                </div>

            </div>

            <div style={{ height: "16px" }}>

            </div>

            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                height: "900px",
                width: "1650px",
            }}>
                <div className={classes.root} style={{
                    width: "600px",
                    height: "900px",
                    backgroundColor: "#ffffff",
                }}>
                    <DataGridPremium
                        rows={rows}
                        columns={columns}
                        density="compact"
                        getCellClassName={(params: any) => {

                            if (params.field === "phoneStatus") {

                                if (params.row.realTimeStatus === "in" && params.row.realTimeByAccess) {
                                    if (params.row.phoneStatus === "O") {
                                        return "phone_noproblem"
                                    }
                                }

                                if (params.row.realTimeStatus === "in" && params.row.realTimeByAccess) {
                                    if (params.row.phoneStatus === "X") {
                                        return "phone_problem"
                                    }
                                }



                                if (params.row.phoneProblem > 0) {
                                    return "phone_problem"
                                } else {
                                    return "phone_noproblem"
                                }
                            }

                            if (params.field === "phoneMemo") {
                                return "phone_memo"
                            }

                            return ""
                        }}
                        getRowClassName={(params: GridRowClassNameParams<any>) => {

                            if(params.row.name === "김태훈"){
                                console.log("go");
                                console.log(params);
                            }

                            //guilty true 인 row 중에 isNever이 true이면 "never" false면 "once"

                            if (params.row.guilty) {
                                if (params.row.isNever) {

                                    if (params.row.realTimeStatus === "in") {
                                        return "never_in"
                                    }

                                    return "never"
                                } else {

                                    if (params.row.realTimeStatus === "in") {
                                        return "once_in"
                                    }

                                    return "once"
                                }
                            }

                            if(params.row.isSuspicious){

                                if (params.row.realTimeStatus === "in") {
                                    return "suspicious_in"
                                }

                                return "suspicious"
                            }

                    
                            return ""
                        }}
                        processRowUpdate={handleEditCommit}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        apiRef={apiRef}
                        filterModel={filterModel}
                        onFilterModelChange={(model: GridFilterModel) => {
                            setFilterModel(model);
                        }}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            const data: any = apiRef.current.getSelectedRows();
                            if (!data) {
                                return;
                            }
                            console.log("---------------");
                            console.log(data);
                            data.forEach((value: any, index: number) => {

                                console.log(value);

                                console.log(value.id);
                                setSelectedUserId(value.id);
                                setSelectedUserName(value.name);
                                setSelectedUserPhoneNumber(value.phoneNumber);
                                setSelectedParentPhoneNumber(value.parentPhoneNumber);

                                console.log("chatRoom");
                                console.log(value.chatRoomId);
                                
                                if(value.chatRoomId){
                                    setSelectedChatRoomId(value.chatRoomId);
                                }else{
                                    setSelectedChatRoomId(undefined);
                                }

                                console.log(value.name, value.phoneNumber, value.parentPhoneNumber)

                            })
                        }}
                    />
                    <div
                    style={{
                        marginTop : "6px",
                        fontSize : "14px",
                        fontFamily : "Apple_L"
                    }}
                    >
                        ***빨간색 - 출입기록 0번 / 주황색 - 출입기록 있음 / 파랑색 - 정기일정 틀림 의심스러움
                    </div>
                </div>
                <div>
                    <StudentAttendanceInfo location={props.location} selectedUserId={selectedUserId} targetDate={targetDate} classNumber={classNumber} studentPhoneNumber={selectedUserPhoneNumber} parentPhoneNumber={selectedParentPhoneNumber} />
                </div>
            </div>


            <InspectPhone user={props.user} open={modalOpen} handleClose={() => setModalOpen(false)} location={props.location} targetDate={targetDate} classNumber={classNumber} classNumberString={classNumberString} notify1={props.notify1} letsUpdate={letsUpdate} />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading2}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


            <ReactModal
                isOpen={smsModalOpen}
                onRequestClose={() => {
                    handleCloseSms("e");
                }}
                initWidth={400}
                initHeight={400}
                className={"my-modal-custom-class"}
                disableResize
            >
                <div style={{ marginTop: "60px", padding: '30px', zIndex: 9999999999999999, backgroundColor: "white" }}>
                    <div style={{ fontFamily: "Apple_B", marginBottom: "8px", fontSize: "18px", textAlign: "center" }}>
                        {/* {selectedUserName} 학생에게 문자 보내기 */}
                        <RadioGroup aria-label="Your plan" name="people" value={smsTarget}
                        onChange={(e : any) => {
                            console.log(e.target.value)
                            setSmsTarget(e.target.value);
                        }}
                        >
                            <List
                                sx={{
                                    minWidth: 240,
                                    '--List-gap': '0.5rem',
                                    '--ListItem-paddingY': '1rem',
                                    '--ListItem-radius': '8px',
                                    '--ListItemDecorator-size': '32px',
                                }}
                            >
                                {[`To. ${selectedUserName} 학생`, `To. ${selectedUserName} 학부모`].map((item, index) => (
                                    <ListItem variant="outlined" key={item} sx={{ boxShadow: 'sm' }}>
                                        <ListItemDecorator>
                                            {[<ChildCare />, <EscalatorWarning />][index]}
                                        </ListItemDecorator>
                                        <Radio
                                            overlay
                                            value={index === 0 ? "student" : "parent"}
                                            label={item}
                                            sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                            slotProps={{
                                                action: ({ checked }) => ({
                                                    sx: (theme) => ({
                                                        ...(checked && {
                                                            inset: -1,
                                                            border: '2px solid',
                                                            borderColor: theme.vars.palette.primary[500],
                                                        }),
                                                    }),
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </RadioGroup>
                    </div>
                    <div style={{ lineHeight: "1.5", textAlign: "center" }}>

                    </div>

                    <div style={{ marginTop: "16px", marginBottom: "16px", width: "100%" }}>
                        <Textarea color="neutral" minRows={6}
                            value={smsText}
                            onChange={(e: any) => {
                                setSmsText(e.target.value);
                            }}
                        />
                    </div>

                    <div style={{ margin: "0 auto", width: "100%" }}>
                        <Button sx={{ width: '100%' }}
                            startDecorator={
                                <Send />
                            }
                            disabled={smsDisabled || smsLoading}
                            loading={smsLoading}
                            onClick={() => {
                                sendSms();
                            }}>
                            문자 전송하기
                        </Button>

                    </div>
                </div>


                <div onClick={handleCloseSms} style={{ width: "20px", position: "absolute", top: "20px", right: "10px", zIndex: 999, cursor: "pointer" }}>
                    <XMarkSvg fill="#ff5722" />
                </div>
            </ReactModal>


            {
                (selectedUserId && selectedChatRoomId) &&
                <SwipeableDrawer
                    anchor="right"
                    open={chatroomOpen}
                    onClose={() => { toggleDrawer(false) }}
                    onOpen={() => { toggleDrawer(true) }}
                >
                    <div
                        style={{ width: "550px", height : "100%"}}
                        role="presentation"
                        //className={styles.todoChat}
                    // onClick={(e) => { toggleDrawer(false) }}
                    // onKeyDown={(e) => { toggleDrawer(false) }}
                    >
                        <Chat messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage} socket={props.socket} studentName={selectedUserName} user={props.user} chatRoomId={selectedChatRoomId} />
                    </div>
                </SwipeableDrawer>
            }

        </div>
    )

}

export default AttendanceCheck;
