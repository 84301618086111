import { Check } from '@mui/icons-material';
import { Button, Input, Modal, ModalDialog } from '@mui/joy';

import React, { useEffect, useState } from 'react';
import SleepSelect from './sleepselect';

type SelectedType = "sleep" | "bad" | "phone" | "site" | "";

const Modalcomp: React.FC<any> = (props: any) => {

    const [page, setPage] = useState<number>(1);
    const [selectedType, setSelectedType] = useState<SelectedType>("");
    const [exitValue, setExitValue] = useState<any>(null);
    const [description, setDescription] = useState<string>("");
    const [demerit, setDemerit] = useState<number | undefined>(undefined);
    const [disabled, setDisabled] = useState<boolean>(true);

    const [userViolateInfo, setUserViolateInfo] = useState<any>();

    useEffect(() => {

        if (!props.userId) {
            return;
        }

        getUserViolateInfo(props.userId);

    }, [props.userId]);

    const getUserViolateInfo = async (userId: number) => {

        try {

            setUserViolateInfo(undefined);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/patrol/getUserViolateInfo?userId=" + userId, {
                method: "get",
                headers: {
                    "Content-Type": "application/json"
                },
            });

            const result = await response.json();

            if (result.message === "success") {
                console.log(result.data);
                setUserViolateInfo(result.data);
            } else {
                new Error("notSuccess");
            }

            console.log(result);

        } catch (e) {
            console.log(e);
        }

    }

    useEffect(() => {

        if (!props.open) {
            setDisabled(true);
            return;
        }

        switch (selectedType) {
            case "sleep":
                if (exitValue === null) {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
                break;
            case "bad":
            case "phone":
            case "site":
                if (description === "" || demerit === undefined) {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
                break;
        }

    }, [selectedType, exitValue, description, demerit, props.open]);

    useEffect(() => {

        if (selectedType === "phone") {
            setDemerit(10);
        }

        if (selectedType === "site") {
            setDemerit(5);
        }

    }, [selectedType]);

    const handleClick = (type: SelectedType) => {
        setPage(2);
        setSelectedType(type);
    }

    const handleClickPrev = () => {
        setPage(1);
        setSelectedType("");
        setExitValue(null);
    }

    const submit = async () => {

        try {

            props.handleLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/patrol/submit123", {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userId: props.userId,
                    type: selectedType,
                    exit: exitValue,
                    description: description,
                    demerit: demerit
                })
            })

            const result = await response.json();

            props.handleClose();
            setPage(1);
            setExitValue(null);
            setSelectedType("");
            setDescription("");
            setDemerit(undefined);

            props.handleLoading(false);
            props.notify1();


            console.log(result);

        } catch (e) {
            console.log(e);
        }

    }

    const handleSleepChange = (value: string) => {
        setExitValue(value);
    }

    return (
        <Modal
            open={props.open}
            onClose={() => {
                props.handleClose();
                setPage(1);
                setExitValue(null);
                setSelectedType("");
                setDescription("");
                setDemerit(undefined);
            }}
        >
            <ModalDialog>
                <div className='flex flex-col items-center' style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <div className='font-semibold mb-1'
                        style={{
                            fontSize: "20px",
                            marginBottom: "4px"
                        }}
                    >
                        순찰 내역 입력
                    </div>
                    <div className='font-semibold mb-4'
                        style={{
                            fontSize: "20px",
                            marginBottom: "24px"
                        }}
                    >
                        {
                            props.name
                        } ({
                            props.seat
                        }번)
                    </div>

                    {
                        page === 1 ?
                            <div>
                                <div>
                                    <div className='mb-2.5 flex justify-center'
                                        style={{
                                            marginBottom: "10px",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Button color='neutral'
                                            onClick={
                                                () => {
                                                    handleClick("sleep");
                                                }
                                            }
                                        >
                                            졸음
                                        </Button>
                                    </div>
                                    <div className='mb-2.5 flex justify-center'
                                        style={{
                                            marginBottom: "10px",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Button color='neutral'
                                            onClick={
                                                () => {
                                                    handleClick("site");
                                                }
                                            }
                                        >
                                            학습 외 사이트 접속
                                        </Button>
                                    </div>
                                    <div className='mb-2.5 flex justify-center'
                                        style={{
                                            marginBottom: "10px",
                                            display: "flex",
                                            justifyContent: "center"

                                        }}
                                    >
                                        <Button color='neutral'
                                            onClick={
                                                () => {
                                                    handleClick("phone");
                                                }
                                            }
                                        >
                                            자습실 내 휴대폰 사용
                                        </Button>
                                    </div>
                                    <div className='flex justify-center'
                                        style={{
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Button color='neutral'
                                            onClick={
                                                () => {
                                                    handleClick("bad");
                                                }
                                            }
                                        >
                                            학습분위기 저해 행동
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                    {
                        (selectedType === "sleep" && page === 2)
                        &&
                        <div>
                            <div className='flex justify-center'
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <Check fontSize='small' sx={{ marginRight: "0px" }} />졸음
                            </div>
                            <div className='flex justify-center'
                                style={{
                                    display: "flex",
                                    justifyContent: "center"

                                }}
                            >
                                휴게실로 나갔나요?
                            </div>
                            <div className='flex justify-between mt-4'
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "16px"
                                }}
                            >
                                <SleepSelect handleSleepChange={handleSleepChange} />
                            </div>
                        </div>
                    }
                    {
                        ((selectedType === "site" || selectedType === "phone" || selectedType === "bad") && page === 2)
                        &&
                        <div>
                            <div className='flex justify-center items-center'
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Check fontSize='small' sx={{ marginRight: "0px" }} />
                                {selectedType === "site" ? "학습 외 사이트 접속" : ""}
                                {selectedType === "phone" ? "자습실 내 휴대폰 사용" : ""}
                                {selectedType === "bad" ? "학습분위기 저해 행동" : ""}
                            </div>
                            <div className='flex justify-between items-center mt-4'
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "16px"
                                }}
                            >
                                <div className='mr-2'
                                    style={{
                                        marginRight: "8px"
                                    }}
                                >
                                    상세내용
                                </div>
                                <div className='w-40'
                                    style={{
                                        width: "160px"
                                    }}
                                >
                                    <Input
                                        value={description}
                                        onChange={(e: any) => {
                                            setDescription(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between items-center mt-4'
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "16px"
                                }}
                            >
                                <div className='mr-2'
                                    style={{
                                        marginRight: "8px"

                                    }}
                                >
                                    벌점 값
                                </div>
                                <div className='w-40'
                                    style={{
                                        width: "160px"

                                    }}
                                >
                                    <Input
                                        type='number'
                                        value={demerit}
                                        onChange={(e: any) => {
                                            setDemerit(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        page === 2 &&
                        <div className='mt-8 w-11/12'
                            style={{
                                marginTop: "32px",
                                width: "92%"
                            }}
                        >
                            <div>
                                <Button
                                    loading={props.loading}
                                    fullWidth
                                    onClick={submit}
                                    disabled={disabled}
                                >
                                    제출하기
                                </Button>
                            </div>
                        </div>

                    }
                    {
                        userViolateInfo && userViolateInfo.length > 0 ?
                            <div>
                                <div style={{
                                    marginTop: "24px",
                                    fontFamily: "Apple_SB",
                                    fontSize: "15px"
                                }}>
                                    최근 위반 내역({new Date().getMonth() + 1}월 {new Date().getDate()}일)
                                </div>
                                <div style={{
                                    marginTop: "4px",
                                    fontSize: "14px",
                                    textAlign: "center"
                                }}>
                                    {
                                        userViolateInfo[0].selectedType === "sleep" ?
                                            "졸음" : ""
                                    }
                                    {
                                        userViolateInfo[0].selectedType === "site" ?
                                            "사이트 접속" : ""
                                    }
                                    {
                                        userViolateInfo[0].selectedType === "phone" ?
                                            "휴대폰 사용" : ""
                                    }
                                    {
                                        userViolateInfo[0].selectedType === "bad" ?
                                            "학습분위기 저해" : ""
                                    }
                                     
                                    (
                                    {
                                        (new Date(userViolateInfo[0].createdAt).getHours() < 10 ? "0" + new Date(userViolateInfo[0].createdAt).getHours() : new Date(userViolateInfo[0].createdAt).getHours()) + ":" + (new Date(userViolateInfo[0].createdAt).getMinutes() < 10 ? "0" + new Date(userViolateInfo[0].createdAt).getMinutes() : new Date(userViolateInfo[0].createdAt).getMinutes())
                                    }
                                    )
                                </div>
                            </div>
                            :
                            ""
                    }

                </div>
            </ModalDialog>
        </Modal>
    )
}

export default Modalcomp;