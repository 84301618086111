import React, { useEffect, useState, useRef } from "react";
import styles from "./innerheader.module.css";
import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router-dom";


const InnerHeaderForModal: React.FC<any> = (props) => {

    const [platform, setPlatform] = useState("");
    const history = useHistory();

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

    }, []);

    return (
        <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
            <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`} ref={props.headerRef}>
                <div className={styles.backBtnDiv}
                    onClick={() => { props.handleClose(); }}
                >
                    <ArrowLeftLight
                        className={styles.arrowLeft}
                    />
                </div>

                <div className={styles.titleText}>
                </div>


                <div className={`${styles.submit}`}>
                </div>
            </div>
        </div>
    );
}

export default InnerHeaderForModal;