import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

export const getFetch = (url : string) => {

    return new Promise(async (resolve, reject) => {

        var token : any = "";

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }
    
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }
    
        fetch(`https://peetsunbae.com${url}`, {
            method : "GET",
            headers : {
                "Authorization" : token
            },
            credentials : "include"
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                resolve(result);
            })
        }).catch((e : Error) => {
            console.log(e);
            console.log("error");
            reject(e);
        })

    })
}

export const postFetch = (url : string, body : {}) => {

    return new Promise(async (resolve, reject) => {

        var token : any = "";

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }
    
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }
    
        fetch(`https://peetsunbae.com${url}`, {
            method : "post",
            headers : {
                "Authorization" : token,
                "content-type" : "application/json"
            },
            credentials : "include",
            body : JSON.stringify(body)
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                resolve(result);
            })
        }).catch((e : Error) => {
            console.log(e);
            console.log("error");
            reject(e);
        })

    })

}