import React, { useEffect, useState, useRef } from "react";
import InnerHeader from "../etc/innerheader";
import styles from "./mobileregular.module.css";
import TextField from '@mui/material/TextField';
import { IonApp, IonLoading, IonModal } from "@ionic/react";
import InnerHeaderForModal from "../etc/innerheaderformodal";
import HorizontalLinearStepperMobile from "../../dashboard/components/data/horizontallinearsteppermobile";
import { Preferences } from "@capacitor/preferences";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { useHistory } from "react-router-dom";
import { IonItem, IonList, IonSelect, IonSelectOption, useIonToast } from '@ionic/react';
import { LinearProgress } from "@mui/material";


const MobileRegular: React.FC<any> = (props) => {

    const [present] = useIonToast();

    const [today, setToday] = useState(new Date());
    const [next, setNext] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1));

    const [isOpen, setIsOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(-1);
    const [errorStep, setErrorStep] = useState(-1);
    const [isSafeArea, setIsSafeArea] = useState(false);
    const [data, setData] = useState<any>({
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
        etc: ""
    });
    const [update, setUpdate] = useState(0);
    const [loading, setLoading] = useState(false);
    const [platform, setPlatform] = useState("");
    const headerRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLDivElement>(null);
    const [bodyHeight, setBodyHeight] = useState(0);
    const history = useHistory();

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

    const presentToast = () => {
        present({
            message : "💁  제출하려는 '월'이 맞는지 확인해주세요.",
            duration : 2500,
            position : "top",
            color : "warning"
        })
    }

    useEffect(() => {

        console.log(next.getMonth());
        console.log(next.getDate());
        console.log(next.getHours());

        setTimeout(() => {

            presentToast();

        }, 100);

    }, []);

    useEffect(() => {

        console.log("hello world !");

        //backbutton 눌리면 console.log("pressed") 뜨게 하기
        const handleBackButton = () => {

            if(isOpen){
                setIsOpen(false);
                return;
            }


            console.log("pressed");
            history.goBack();
        }

        //backbutton 눌리면 handleBackButton 실행
        document.addEventListener("backbutton", handleBackButton);

        return () => {
            document.removeEventListener("backbutton", handleBackButton);
        }

    }, [isOpen]);


    useEffect(() => {

        const currentPlatform = Capacitor.getPlatform();
        setPlatform(currentPlatform);
    }, []);

    const caculateBodyHeight = () => {
        setTimeout(() => {

            if (!headerRef || !headerRef.current) {
                return;
            }

            if(!btnRef || !btnRef.current){
                return;
            }

            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;
            const btnHeight = btnRef.current.offsetHeight;
    
            const lastHeight = totalHeight - safeareaTop - headerHeight - btnHeight;

            console.log("height");
            console.log(lastHeight);
            setBodyHeight(lastHeight);

        }, 50);
    }


    useEffect(() => {

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef, btnRef]);


    useEffect(() => {

        console.log("change");

        setActiveStep(-1);
        setData({
            monday: "",
            tuesday: "",
            wednesday: "",
            thursday: "",
            friday: "",
            saturday: "",
            etc: ""
        })

        getRegularSchedule();

    }, [update, selectedMonth]);

    const getRegularSchedule = async () => {

        if (selectedMonth === 0 || selectedMonth) {

            setActiveStep(-1);
            setErrorStep(-1);

            setLoading(true);

            var year = today.getFullYear();
            const todayYear = today.getFullYear();
            const nextYear = next.getFullYear();
    
            if(todayYear !== nextYear){
                if(selectedMonth === 0){
                    year = year + 1;
                }
            }

            var token: any = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                token = value;
            }

            fetch("https://peetsunbae.com/dashboard/chart/regularSchedule/studentget?month=" + (selectedMonth + 1) + "&year=" + year, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Authorization": token
                }
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        console.log(result);
                        if (result.message === "success" && result.data.length > 0) {

                            console.log(result.data);

                            setData(result.data[0].data);
                            const data = result.data[0];
                            const parentpermit = data.parentpermit;
                            const staffpermit = data.staffpermit;

                            if (staffpermit === 1) {
                                setActiveStep(1);
                            }
                            if (parentpermit === 1) {
                                setActiveStep(2);
                            }
                            if (staffpermit === 0) {
                                setActiveStep(0);
                            }
                            if (staffpermit === 2) {
                                setActiveStep(0);
                                setErrorStep(1);
                            }
                            if (parentpermit === 2) {
                                setActiveStep(1);
                                setErrorStep(2);
                            }
                        }
                        setLoading(false);
                    })
            });
        }

    }


    const handleOpen = () => {
        setIsOpen(true);
    }


    const submit = async (e: any) => {


        console.log(data);

        // if(today.getDate() !== 1){
        //     alert("정기일정은 각 월 1일에만\n수정/등록 가능합니다.");
        //     return;
        // }

        var year = today.getFullYear();
        const todayYear = today.getFullYear();
        const nextYear = next.getFullYear();

        if(todayYear !== nextYear){
            if(selectedMonth === 0){
                year = year + 1;
            }
        }

        
        console.log(selectedMonth);

        setLoading(true);

        const body = {
            year, month: selectedMonth + 1, data
        }

        console.log(body);

        var token: any = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch(`https://peetsunbae.com/dashboard/chart/regularSchedule/studentwrite`, {
            method: "POST",
            headers: { "content-type": "application/json", "Authorization" : token },
            credentials: "include",
            body: JSON.stringify(body)
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    setLoading(false);
                    if(result.message === "notFirstDay"){
                        alert("정기일정은 각 월 1일에만\n수정/등록 가능합니다");
                        setUpdate(Math.random());
                        return;
                    }
                    if (result.message === "success") {
                        alert("정기일정 제출 성공");
                        setUpdate(Math.random());
                    }
                })
        })
    }

    useEffect(() => {

        const valueBottom = +getComputedStyle(document.documentElement).getPropertyValue("--sab").split("p")[0];

        if (valueBottom > 0) {
            setIsSafeArea(true);
        }

    }, []);

    const handleChange = (e: any, kind: string) => {
        const newData = data;
        newData[kind] = e.target.value;
        setData({ ...newData });
    }

    const handleClick = (e : any) => {
        history.goBack();
    }

    const handleMonthChange = (value : number) => {
        console.log(value);
        setSelectedMonth(value);
    }

    return (
        <IonApp>
            <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
                {/* <InnerHeader headerRef={headerRef} handleClickSubmitBtn={handleOpen} submitBtn="작성방법" title={`${today ? today.getMonth() + 1 : ""}월 정기일정`} /> */}

                <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`} ref={headerRef}>
                    <div className={styles.backBtnDiv}
                        onClick={(e: any) => { handleClick(e) }}
                    >
                        <ArrowLeftLight
                            fill="#2a2a2a"
                            className={styles.arrowLeft}
                        />
                    </div>

                    <div className={styles.titleText}>
                        <IonSelect value={selectedMonth} onIonChange={(e) => handleMonthChange(e.detail.value)}>
                            <IonSelectOption value={today.getMonth()}>{today.getMonth() + 1}월용 정기일정</IonSelectOption>
                            <IonSelectOption value={next.getMonth()}>{next.getMonth() + 1}월용 정기일정</IonSelectOption>
                        </IonSelect>
                        {/* <div className={styles.titleTextString}>
                            전체
                        </div>
                        <CaretDown fill="#9e9e9e" className={styles.caret} /> */}
                    </div>

                    <div className={`${styles.submit}`} onClick={handleOpen}>
                        작성방법
                    </div>
                </div>
                {
                    loading
                    &&
                    <LinearProgress />
                }

                <div className={`${styles.body} ${platform === "android" ? styles.android : ""}`} style={{height : `${bodyHeight}px`, overflow : "scroll"}}>
                    <div className={styles.textFieldWrapper}>
                        <TextField value={data.monday} onChange={(e: any) => { handleChange(e, "monday") }} sx={{ "& .MuiFilledInput-root": { backgroundColor: "white !important", borderBottom: 0, border: "1px solid #bdbdbd", borderRadius: "0.2rem", "&::before": { borderBottom: "none !important" }, "&::after": { borderBottom: 0 } }, "& .MuiFilledInput-root.Mui-focused": { border: "1px solid #1976d2" } }} label="월요일" variant="filled" fullWidth margin="dense" />
                    </div>
                    <div className={styles.textFieldWrapper}>
                        <TextField value={data.tuesday} onChange={(e: any) => { handleChange(e, "tuesday") }} sx={{ "& .MuiFilledInput-root": { backgroundColor: "white !important", borderBottom: 0, border: "1px solid #bdbdbd", borderRadius: "0.2rem", "&::before": { borderBottom: "none !important" }, "&::after": { borderBottom: 0 } }, "& .MuiFilledInput-root.Mui-focused": { border: "1px solid #1976d2" } }} label="화요일" variant="filled" fullWidth margin="dense" />
                    </div>
                    <div className={styles.textFieldWrapper}>
                        <TextField value={data.wednesday} onChange={(e: any) => { handleChange(e, "wednesday") }} sx={{ "& .MuiFilledInput-root": { backgroundColor: "white !important", borderBottom: 0, border: "1px solid #bdbdbd", borderRadius: "0.2rem", "&::before": { borderBottom: "none !important" }, "&::after": { borderBottom: 0 } }, "& .MuiFilledInput-root.Mui-focused": { border: "1px solid #1976d2" } }} label="수요일" variant="filled" fullWidth margin="dense" />
                    </div>
                    <div className={styles.textFieldWrapper}>
                        <TextField value={data.thursday} onChange={(e: any) => { handleChange(e, "thursday") }} sx={{ "& .MuiFilledInput-root": { backgroundColor: "white !important", borderBottom: 0, border: "1px solid #bdbdbd", borderRadius: "0.2rem", "&::before": { borderBottom: "none !important" }, "&::after": { borderBottom: 0 } }, "& .MuiFilledInput-root.Mui-focused": { border: "1px solid #1976d2" } }} label="목요일" variant="filled" fullWidth margin="dense" />
                    </div>
                    <div className={styles.textFieldWrapper}>
                        <TextField value={data.friday} onChange={(e: any) => { handleChange(e, "friday") }} sx={{ "& .MuiFilledInput-root": { backgroundColor: "white !important", borderBottom: 0, border: "1px solid #bdbdbd", borderRadius: "0.2rem", "&::before": { borderBottom: "none !important" }, "&::after": { borderBottom: 0 } }, "& .MuiFilledInput-root.Mui-focused": { border: "1px solid #1976d2" } }} label="금요일" variant="filled" fullWidth margin="dense" />
                    </div>
                    <div className={styles.textFieldWrapper}>
                        <TextField value={data.saturday} onChange={(e: any) => { handleChange(e, "saturday") }} sx={{ "& .MuiFilledInput-root": { backgroundColor: "white !important", borderBottom: 0, border: "1px solid #bdbdbd", borderRadius: "0.2rem", "&::before": { borderBottom: "none !important" }, "&::after": { borderBottom: 0 } }, "& .MuiFilledInput-root.Mui-focused": { border: "1px solid #1976d2" } }} label="토요일" variant="filled" fullWidth margin="dense" />
                    </div>
                    <div className={styles.textFieldWrapper}>
                        <TextField value={data.etc} onChange={(e: any) => { handleChange(e, "etc") }} sx={{ "& .MuiFilledInput-root": { backgroundColor: "white !important", borderBottom: 0, border: "1px solid #bdbdbd", borderRadius: "0.2rem", "&::before": { borderBottom: "none !important" }, "&::after": { borderBottom: 0 } }, "& .MuiFilledInput-root.Mui-focused": { border: "1px solid #1976d2" } }} label="기타" variant="filled" fullWidth margin="dense" />
                    </div>


                    <div className={styles.stepperDiv}>
                        <HorizontalLinearStepperMobile activeStep={activeStep} errorStep={errorStep} />
                    </div>

                    <div className={`${styles.height} ${platform === "android" ? styles.android : ""}`}>

                    </div>
                </div>

                <div ref={btnRef} onClick={submit} className={`${styles.submitButton} ${props.hideBackBtn ? "" : styles.active} ${isSafeArea ? styles.safeArea : ""}`}>
                    {selectedMonth + 1}월용 정기일정 등록
                </div>
            </div>



            <IonModal
                isOpen={isOpen}
            >
                <InnerHeaderForModal
                    handleClose={() => { setIsOpen(false); }}
                />
                <div className={styles.modalBody}>
                
                    <div className={styles.modalTitle}>
                         ✔ 제출하려고 하는 달이 맞는지 확인하세요
                    </div>
                    <div className={styles.text}>
                        - 정기일정은 매달 다시 새로 작성해야 합니다 !
                    </div>
                    <div className={styles.modalTitle2}>
                        ✍ 정기일정 작성방법 안내
                    </div>

                    <div className={styles.text}>
                        - 제출하려고 하는 '월'이 맞는지 확인하세요 !
                    </div>
                    <div className={styles.text}>
                        - 사유와 시간을 꼭 명시해주세요 !
                    </div>
                    <div className={styles.text}>
                        - 조퇴, 외출, 결석, 지각 키워드 포함시켜주세요 !
                    </div>
                    <div className={styles.text}>
                        ex) 도수치료 받고 오후 12시 등원 지각
                    </div>
                    <div className={styles.text}>
                        ex) 수학과외 오후 2시 퇴실 오후 5시 복귀 외출
                    </div>
                    <div className={styles.text}>
                        ex) 시대인재 현강 수강 오후 7시 조퇴
                    </div>

                    <div className={styles.modalTitle2}>
                        📆 정기일정 승인절차
                    </div>
                    <div className={styles.text}>
                        - 제출 후 사감, 학부모 알림톡 승인 절차가 필요합니다.
                    </div>
                    <div className={styles.text}>
                        - 학부모에게 승인을 위한 카카오톡이 전달됩니다.
                    </div>
                </div>
            </IonModal>

            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}
</IonApp>
    );
}

export default MobileRegular;