import { Button, Divider } from "@mui/joy";
import React, { useEffect, useState } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    LicenseInfo,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { ChatBubble, ChevronLeft, ChevronRight, PlaylistRemove } from "@mui/icons-material";
import { fil, ta, th } from "date-fns/locale";
import { drawingAccessChart, filterMainStudentList, judgeViolation, sortMainStudentList, classifyViolation, demeritAddWithDb, countNeededList, makeRowsForDemeritList, exceptRegularViolation } from "./demeritfunc/calcdemerit";
import DemeritList from "./demeritcomponents/demeritList";
import DemeritMemo from "./demeritcomponents/demeritmemo";
import DemeritRegularData from "./demeritcomponents/demeritregulardata";
import DemeritSuddenData from "./demeritcomponents/demeritsuddendata";
import { Backdrop, CircularProgress, SwipeableDrawer, TextField } from "@mui/material";
import Chat from "../../chat";
import DemeritChart from "./demeritcomponents/demeritChart";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal-resizable-draggable';
import { ReactComponent as XMarkSvg } from "../../../../../svg/circle-xmark-solid.svg"
import UserDemerit from "../components/userdemerit";
import UserAccesscontrol from "../components/userdaccesscontrol";
import { returnFirstLastTime, returnLocationClassInfo } from "../../../../../data/locationFuntion";

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

export interface userViolation {
    kind : "late" | "absent" | "early" | "among" | "lunch" | "dinner" | "lateOrAbsent" | "amongOrEarly" | "added";
    detailKind? : "regular" | "sudden" | "notDetermined" | "normal";
    orSuddenDetailKind? : "late" | "absent" | "early" | "among";
    notDeterminedReason? : string;
    enterDateString? : string,
    enterDateTime? : number,
    exitDateString? : string,
    exitDateTime? : number,
    outingDuringMealTimeMin? : number,
    outingDuringStudyTimeMin? : number,
    matchedSuddenNotices? : any[],
    matchedRegularSchedules? : any[],
    regularJustified? : boolean,
    suddenJustified? : boolean,
    enterHours? : number,
    enterMinutes? : number,
    exitHours? : number,
    exitMinutes? : number,
    etc? : string,
    suddenReason? : string,
    regularReason? : string,
    identifyId? : number,
    determinedKind? : string,
    demerit? : number,
    description? : string,
    decided? : string,
    sendDemerit? : string,
    memo? : string,
    cancelDemerit? : string,
    userId? : number,
    targetDate? : Date,
}


const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .suspicious_in': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .suspicious': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .phone_problem': {
                    color: "red"
                },
                '& .phone_noproblem': {
                    color: "black"
                },
                '& .phone_memo': {
                    color: "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                },
                '& .noUnCheck': {
                    color : "#B9B9C6"
                },
                '& .noUnSended': {
                    color : "#B9B9C6"
                },
            },
        };
    },
    { defaultTheme },
);

const width = 1750;

var preSelectedUserId : number | undefined = 0;

const DemeritMain: React.FC<any> = (props) => {

    const [rows, setRows] = useState<GridRowsProp>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field: "seat", headerName: "좌석", width: 30 },
        { field: "name", headerName: "이름", width: 80 },
        { field: "unCheck", headerName: "미검수", width: 60 },
        { field: "unSended", headerName: "미전송", width: 60 },
    ]);
    const [targetDate, setTargetDate] = useState(new Date());
    const classes = useStyles2();
    const [targetDateIsToday, setTargetDateIsToday] = useState(true);
    const apiRef = useGridApiRef();
    const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);
    const [selectedChatRoomId, setSelectedChatRoomId] = useState<number | undefined>(undefined);
    const [selectedRow, setSelectedRow] = useState<any>(undefined);
    const [selectedUserName, setSelectedUserName] = useState<string | undefined>(undefined);
    const [violationList, setViolationList] = useState<userViolation[]>();
    const [regularSchedule, setRegularSchedule] = useState<any[]>();
    const [regularScheduleMemo, setRegularScheduleMemo] = useState<any[]>();
    const [suddenNotice, setSuddenNotice] = useState<any[]>();
    const [chatroomOpen, setChatroomOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [drawingList, setDrawingList] = useState<any[]>();
    const [update, setUpdate] = useState(0);
    const [selectedViolationListOrder, setSelectedViolationListOrder] = useState<number>(0);
    const [selectedIdentifyId, setSelectedIdentifyId] = useState(0);
    const [mainLoading, setMainLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 2,
                field: "name",
                operator: "contains",
                value: ""
            },
        ],
    });
    const [disabled1, setDisabled1] = useState(false);
    const [disabled2, setDisabled2] = useState(false);
    const [disabled3, setDisabled3] = useState(false);
    const [listRows, setListRows] = useState<any[]>([]);
    const [smsModalOpen, setSmsModalOpen] = useState(false);
    const [demeritData, setDemeritData] = useState<any[]>([]);
    const [accessModalOpen, setAccessModalOpen] = useState(false);

    // useEffect(() => {

    //     if(!props.user){
    //         return;
    //     }

    //     const academy = props.user.academy;

    //     if(!academy){
    //         return;
    //     }

    //     if(academy !== "mom"){
    //         return;
    //     }

    //     //grade column 한개 추가
    //     const newColumns = [...columns];

    //     var isAlready = false;

    //     newColumns.forEach((column) => {
    //         if(column.field === "grade"){
    //             isAlready = true;
    //         }
    //     })

    //     if(isAlready){
    //         return;
    //     }

    //     //2번째 column에 grade column 추가
    //     newColumns.splice(2, 0, { field: "grade", headerName: "학년", width: 80 });

    //     setColumns([...newColumns]);

    // }, [props.user]);


    useEffect(() => {

        const today = new Date();

        if (targetDate.getFullYear() === today.getFullYear() && targetDate.getMonth() === today.getMonth() && targetDate.getDate() === today.getDate()) {
            setTargetDateIsToday(true);
        } else {
            setTargetDateIsToday(false);
        }

    }, [targetDate]);

    useEffect(() => {

        if (selectedUserId) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [selectedUserId]);


    useEffect(() => {

        console.log("listRows");
        console.log(listRows);
        console.log("gogogoogogogogogogo");

        //disabled1은 비활성화 버튼에 대한 것인데 선택된 것이 없으면 true
        //disabled2는 활성화 버튼에 대한 것인데 선택된 것이 없으면 true
        if(!selectedRow){
            setDisabled1(true);
        }else{
            if(selectedRow.decided || selectedRow.sendDemerit){
                setDisabled1(true);
            }else{

                const clickedRow = listRows.find((eachRow : any) => {
                    return eachRow.identifyId === selectedRow.identifyId;
                })

                if(clickedRow){
                    if(clickedRow.decided || clickedRow.sendDemerit){
                        setDisabled1(true);
                    }else{
                        setDisabled1(false);
                    }
                }else{
                    setDisabled1(true);
                }
            }
        }

        if(!selectedRow){
            setDisabled2(true);
        }else{
            if(selectedRow.sendDemerit){
                if(selectedRow.sendDemerit.includes("canceled")){
                    setDisabled2(true);
                }else{
                    
                    const clickedRow = listRows.find((eachRow : any) => {
                        return eachRow.identifyId === selectedRow.identifyId;
                    })

                    if(clickedRow){
                        if(!clickedRow.sendDemerit || clickedRow.sendDemerit.includes("canceled")){
                            setDisabled2(true);
                        }else{
                            setDisabled2(false);
                        }
                    }else{
                        setDisabled2(true);
                    }


                }
            }else{
                setDisabled2(true);
            }
        }


    }, [selectedRow, update, listRows]);

    useEffect(() => {

        if(selectedUserId){
            setDisabled3(false);
        }else{
            setDisabled3(true);
        }

    }, [selectedUserId])



    useEffect(() => {

        if (!targetDate) {
            return;
        }

        letsStart();

    }, [targetDate, update]);

    const notify1 = () => toast.success("내역 추가 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify2 = () => toast.success("내역 취소 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify3 = () => toast.success("확정 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify4 = () => toast.success("벌점사유(확정)을 설정해주세요!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify5 = () => toast.success("벌점값을 설정해주세요!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify6 = () => toast.success("확정을 취소했습니다!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify7 = () => toast.success("벌점을 전송했습니다!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify8 = () => toast.success("벌점을 차감했습니다!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const handleChangeSelectedViolationListOrder = (order : number) => {

        setSelectedViolationListOrder(order);

    }

    const handleChangeSelectedIdentifyId = (identifyId : number) => {

        console.log("letsChangeIdentify");
        
        setSelectedIdentifyId(identifyId);
        
    }

    const handleChangeListRows = (newRows : any[]) => {

        setListRows([...newRows]);

    }

    const letsStart = async () => {

        try {

            const studentList = await getDemeritInfo();
            //firstcome이 아직 안된 학생들은 제외
            //const filteredStudentList = filterMainStudentList(studentList, targetDate);

            if(!studentList){
                return;
            }

            const classInfo = returnLocationClassInfo(props.location);

            const startlastTime = returnFirstLastTime(props.location);

            studentList.forEach((eachStudent : any) => {

                const accessControlData = eachStudent.accessControlData;
                const regularScheduleFormatData = eachStudent.regularScheduleFormatData;
                const suddenNoticeData = eachStudent.suddenNoticeData;
                const demeritListData = eachStudent.demeritListData;

                //위반사항 판별
                const userViolationList = judgeViolation(accessControlData, targetDate, classInfo, startlastTime);

                classifyViolation(userViolationList, suddenNoticeData, regularScheduleFormatData, targetDate, classInfo, startlastTime);

                demeritAddWithDb(userViolationList, demeritListData);

                const filteredViolationList = exceptRegularViolation(userViolationList);

                filteredViolationList.forEach((eachViolation : any) => {
                    eachViolation.userId = eachStudent.id;
                    eachViolation.targetDate = targetDate;
                })

                const newRows = makeRowsForDemeritList(filteredViolationList);

                const countResult = countNeededList(newRows);

                eachStudent.countResult = countResult;

            });

            //좌석순 번호대로 정렬
            sortMainStudentList(studentList);

            console.log("totalStudentList");
            console.log(studentList);

            makeRows(studentList);

        } catch (e) {
            console.log(e);
        }

    }

    const makeRows = async (studentList: any) => {

        const newRows: any = [];

        studentList.forEach((eachStudent: any) => {

            const oneRow: any = {};

            oneRow.id = eachStudent.id;

            const grade = eachStudent.grade ? eachStudent.grade : "";
            var gradeString = "";

            switch(grade){

                case "middle3" :
                    gradeString = "중3";
                    break;
                case "high1" :
                    gradeString = "고1";
                    break;
                case "high2" :
                    gradeString = "고2";
                    break;
                case "high3" :
                    gradeString = "고3";
                    break;
                case "n" :
                    gradeString = "N수생";
                    break;
            }

            oneRow.seat = eachStudent.seat;
            oneRow.name = eachStudent.name;
            if(gradeString){
                oneRow.name += `(${gradeString})`;
            }
            oneRow.demeritTotal = eachStudent.totalDemerit;
            oneRow.chatRoomId = eachStudent.chatRoomId;
            oneRow.phoneNumber = eachStudent.phoneNumber;
            oneRow.unCheck = eachStudent.countResult.notDecidedRowCounter;
            oneRow.unSended = eachStudent.countResult.notSendDemeritRowCounter;

            newRows.push(oneRow);

        });

        setRows([...newRows]);

    }

    const getDemeritInfo = async () => {

        //필요한 정보
        //1. 학생 이름
        //2. 학생 좌석
        //3. 학생 총 벌점
        //4. 채팅룸 아이디

        try {

            const location = props.location;

            if (!targetDate) {
                return;
            }

            if (!location) {
                return;
            }

            const targetDateTime = targetDate.getTime();

            const response = await fetch(`https://peetsunbae.com/dashboard/report/demerit/students?targetDateTime=${targetDateTime}&location=${location}`, {
                method: "GET",
                credentials: "include"
            });

            const result = await response.json();

            console.log(result);

            if (result.message === "success") {

                return result.data;

            } else {
                //에러
                console.log(result);
                throw new Error(result.message);
            }

        } catch (e) {
            console.log(e);
        }


    }

    const handleTargetDateChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {

        if (type === "plus") {
            //오늘이 아닐 경우 하루 더하기

            const today = new Date();

            if (targetDate.getFullYear() === today.getFullYear() && targetDate.getMonth() === today.getMonth() && targetDate.getDate() === today.getDate()) {
                return;
            }

            const newDate = new Date(targetDate);
            newDate.setDate(newDate.getDate() + 1);

            setTargetDate(newDate);

        }

        if (type === "minus") {
            //하루 빼기

            const newDate = new Date(targetDate);
            newDate.setDate(newDate.getDate() - 1);

            setTargetDate(newDate);
        }

    }

    useEffect(() => {

        if (!selectedUserId) {
            return;
        }

        if (!targetDate) {
            return;
        }

        setSelectedViolationListOrder(0);
        setSelectedIdentifyId(0);

        makeUserInfo();


    }, [selectedUserId, targetDate, update]);

    const makeUserInfo = async () => {

        const classInfo = returnLocationClassInfo(props.location);
        const startlastTime = returnFirstLastTime(props.location);


        //getUserInfo로부터 받아온 정보를 바탕으로
        //DemeritList, DemeritMemo, DemeritRegularData, DemeritSuddenData를 만들어야 함

        if(preSelectedUserId !== selectedUserId){
            setLoading(true);
            preSelectedUserId = selectedUserId;
        }

        const userInfo = await getUserInfo();

        console.log("userInfo");
        console.log(userInfo);

        if (!userInfo) {
            console.log("userInfo2");
            return;
        }

        const accessControlData = userInfo.accessControlData;
        const regularScheduleData = userInfo.regularScheduleData;
        const regularScheduleFormatData = userInfo.regularScheduleFormatData;
        const suddenNoticeData = userInfo.suddenNoticeData;
        const userData = userInfo.userData;
        const demeritListData = userInfo.demeritListData;
        const regularScheduleMemoData = userInfo.regularScheduleMemoData;
        const demeritData = userInfo.demeritData;

        setDemeritData([...demeritData]);

        const userViolationList = judgeViolation(accessControlData, targetDate, classInfo, startlastTime);

        classifyViolation(userViolationList, suddenNoticeData, regularScheduleFormatData, targetDate, classInfo, startlastTime);


        demeritAddWithDb(userViolationList, demeritListData);


        const filteredViolationList = exceptRegularViolation(userViolationList);

        filteredViolationList.forEach((eachViolation : any) => {
            eachViolation.userId = selectedUserId;
            eachViolation.targetDate = targetDate;
        });

        console.log("*&*&*&*&*&*&*&*&*&*&");
        console.log(filteredViolationList, suddenNoticeData, regularScheduleFormatData, targetDate, startlastTime);

        const drawingList = drawingAccessChart(filteredViolationList, suddenNoticeData, regularScheduleFormatData, targetDate, startlastTime);
        setDrawingList([...drawingList]);

        console.log(drawingList);

        setViolationList([...filteredViolationList]);
        setSuddenNotice([...suddenNoticeData]);
        setRegularSchedule(regularScheduleData);
        if(regularScheduleMemoData){
            setRegularScheduleMemo(regularScheduleMemoData);
        }


        setLoading(false);


    }

    const getUserInfo = async () => {

        if (!selectedUserId) {
            return;
        }

        if (!targetDate) {
            return;
        }

        const targetDateTime = targetDate.getTime();

        try {

            const response = await fetch(`https://peetsunbae.com/dashboard/report/demerit/studentinfo?userId=${selectedUserId}&targetDateTime=${targetDateTime}`, {
                method: "GET",
                credentials: "include"
            });

            const result = await response.json();

            console.log(result);

            if (result.message === "success") {

                const accessControlData = result.accessControlData;
                const regularScheduleData = result.regularScheduleData;
                const regularScheduleFormatData = result.regularScheduleFormatData;
                const suddenNoticeData = result.suddenNoticeData;
                const userData = result.userData;
                const demeritListData = result.demeritListData;
                const regularScheduleMemoData = result.regularScheduleMemoData;
                const demeritData = result.demeritData;

                //accessControlData에 알아볼 수 있는 시간 형식 넣어준다.
                accessControlData.forEach((eachData: any) => {

                    const date = new Date(+eachData.time);

                    //yyyy-MM-dd HH:mm:ss
                    const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}`;

                    eachData.timeString = dateString;
                })

                //accessControlData를 +time으로 오름차순 정렬
                accessControlData.sort((a: any, b: any) => {
                    return (+a.time) - (+b.time);
                });

                return {
                    accessControlData, regularScheduleData, regularScheduleFormatData, suddenNoticeData, userData, demeritListData, regularScheduleMemoData, demeritData
                }

            } else {
                console.log("error");
                throw new Error(result.message);
            }

        } catch (e) {
            console.log(e);
        }

    }

    const toggleDrawer = (open: boolean) => {
        setChatroomOpen(open);
    }

    const handleSearchTextChange = (e : any) => {

        setSearchText(e.target.value);

        const searchText = e.target.value;

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((item) => {

            if (item.field === "name") {
                item.value = searchText;
            }

        })

        setFilterModel({ ...newFilterModel });

    }

    const letsUpdate = () => {

        setUpdate(Math.random());

    }

    const handleSelectedRow = (row: any) => {

        console.log("handleSelectedRow");
        console.log(row);

        setSelectedRow(row);
    }

    const loadingChange = (loading : boolean) => {
        setMainLoading(loading);
    }

    const addList = async () => {

        if(!window.confirm("새로운 벌점 내역을 추가하시겠습니까?")){
            return;
        }

        if(!selectedUserId){
            return;
        }

        if(!targetDate){
            return;
        }

        setMainLoading(true);

        const data = {
            userId : selectedUserId,
            targetDateTime : targetDate.getTime(),
        }

        try{

            const response = await fetch("https://peetsunbae.com/dashboard/report/demerit/addlist", {
                method : "POST",
                credentials : "include",
                headers : {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify(data)
            });

            const result = await response.json();

            if(result.message === "success"){

                letsUpdate();
                setTimeout(()=> {
                    setMainLoading(false);
                    notify1();
                }, 300);

            }else{
                alert("서버에 문제가 있습니다. 다시 시도해주세요.");
            }

        }catch(e){
            console.log(e);
        }

    }

    const cancelList = async () => {

        console.log("cancelList");

        if(!window.confirm("해당 내역을 비활성화 시키겠습니까?")){
            return;
        }

        if(!selectedUserId){
            console.log("noSelectedUserId");
            return;
        }

        if(!targetDate){
            console.log("noTargetDate");
            return;
        }

        if(!selectedIdentifyId){
            console.log("noSelectedIdentifyId");
            return;
        }

        console.log(selectedIdentifyId);

        setMainLoading(true);

        const data = {
            userId : selectedUserId,
            targetDateTime : targetDate.getTime(),
            identifyId : selectedIdentifyId,
        }

        try{

            const response = await fetch("https://peetsunbae.com/dashboard/report/demerit/cancellist", {
                method : "POST",
                credentials : "include",
                headers : {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify(data)
            });

            const result = await response.json();

            if(result.message === "success"){

                letsUpdate();

                setTimeout(()=> {
                    setMainLoading(false);
                    notify2();
                }, 300);

            }

        }catch(e){

        }

    }

    const cancelSendedDemerit = async () => {
            
            if(!window.confirm("해당 벌점 부여 내역을 취소하시겠습니까?")){
                return;
            }
    
            if(!selectedUserId){
                return;
            }
    
            if(!targetDate){
                return;
            }

            if(!selectedIdentifyId){
                return;
            }
    
            setMainLoading(true);
    
            const data = {
                userId : selectedUserId,
                targetDateTime : targetDate.getTime(),
                identifyId : selectedIdentifyId,
            }
    
            try{
    
                const response = await fetch("https://peetsunbae.com/dashboard/report/demerit/cancelsendeddemerit", {
                    method : "POST",
                    credentials : "include",
                    headers : {
                        "Content-Type" : "application/json"
                    },
                    body : JSON.stringify(data)
                });
    
                const result = await response.json();
    
                if(result.message === "success"){
    
                    letsUpdate();
    
                    setTimeout(()=> {
                        setMainLoading(false);
                        notify8();
                    }, 300);
    
                }
    
            }catch(e){
                console.log(e);
            }
    
    }

    const handleCloseSms = (e : any) => {
        setSmsModalOpen(false);
    }

    const handleCloseAccess = (e : any) => {
        setAccessModalOpen(false);
    }
        

    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                width: `${width}px`
            }}>
                <div style={{
                    marginBottom: "6px", fontSize: "16px", fontFamily: "Apple_R", paddingRight: "4px"
                }}>
                    {targetDate.getFullYear()}년 {targetDate.getMonth() + 1}월 {targetDate.getDate()}일
                </div>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                width: `${width}px`,
                // paddingBottom : "20px"
            }}>
                <Button variant="outlined" color="neutral" sx={{
                    marginRight: "12px",
                    width: "40px",
                    height: "40px"
                }}
                    onClick={(e: any) => {
                        handleTargetDateChange(e, "minus")
                    }}
                >
                    <ChevronLeft />
                </Button>
                <Button variant="outlined" color="neutral" sx={{
                    width: "40px",
                    height: "40px"
                }}
                    disabled={targetDateIsToday}
                    onClick={(e: any) => {
                        handleTargetDateChange(e, "plus")
                    }}
                >
                    <ChevronRight />
                </Button>
            </div>
            <div
                style={{
                    paddingTop: "40px",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: "20px",
                    width: `${width}px`
                }}
            >
                <Button variant="solid" onClick={(e: any) => {
                    alert("추후 도입 예정입니다.")
                }}>
                    확정된 벌점 모두 보내기
                </Button>
            </div>
            <div
                style={{
                    width: `${width}px`
                }}
            >
                <Divider />
            </div>
            <div
                style={{
                    width: `${width}px`,
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop : "40px",
                }}
            >
                <div style={{
                    display: "flex",
                    width : "700px",
                    justifyContent : "space-between",
                    alignItems : "baseline"
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: `250px`,
                    }}>
                        <div>
                            <Button variant="soft"
                                disabled={disabled}
                                onClick={(e: any) => {
                                    if (!selectedChatRoomId) {
                                        alert("채팅방이 생성되지 않은 학생입니다. 먼저 '메시지' 메뉴에서 채팅방을 생성 후 시도해 주세요.");
                                        return;
                                    }
                                    toggleDrawer(true);
                                }}
                                color="neutral"
                            >
                                <ChatBubble />
                            </Button>
                            <Button variant="soft"
                                disabled={disabled}
                                color="neutral"
                                style={{
                                    marginLeft: "8px"
                                }}
                                onClick={(e: any) => {
                                    setSmsModalOpen(true);
                                }}
                            >
                                <PlaylistRemove />
                            </Button>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            width: "120px"
                        }}>
                            <TextField
                                variant="standard"
                                placeholder="이름 검색"
                                value={searchText}
                                onChange={(e: any) => {
                                    handleSearchTextChange(e);
                                }}
                                sx={{
                                    marginLeft: "16px",
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <Button variant="soft" color="neutral" disabled={disabled} onClick={(e : any) => {
                            setAccessModalOpen(true);
                        }}>
                            전체 출입기록
                        </Button>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    <Button variant="soft" style={{
                        marginRight: "12px"
                    }} onClick={(e: any) => { addList(); }}
                        disabled={disabled3}
                    >
                        벌점 내역 추가
                    </Button>
                    <Button variant="soft" onClick={(e: any) => {
                        cancelList();
                    }}
                        style={{
                            marginRight: "12px"
                        }}
                        disabled={disabled1}
                    >
                        비활성화
                    </Button>
                    <Button variant="soft" color="danger"
                        disabled={disabled2}
                        onClick={(e: any) => {
                            cancelSendedDemerit();
                        }}
                    >
                        벌점 부여내역 취소
                    </Button>
                </div>

            </div>

            <div style={{
                paddingTop: "20px",
                width: `${width}px`,
                display: "flex",
                justifyContent: "space-between"
            }}>
                <div className={classes.root} style={{
                    width: "250px",
                    height: "910px",
                    backgroundColor: "#ffffff",
                }}>
                    <DataGridPremium
                        rows={rows}
                        columns={columns}
                        density="compact"
                        apiRef={apiRef}
                        filterModel={filterModel}
                        getCellClassName={(params) => {

                            if(params.field === "unCheck"){
                                if(params.value > 0){
                                    return "unCheck";
                                }else{
                                    return "noUnCheck";
                                }
                            }

                            if(params.field === "unSended"){
                                if(params.value > 0){
                                    return "unSended";
                                }else{
                                    return "noUnSended";
                                }
                            }

                            return "";
                        }}
                        onFilterModelChange={(model: GridFilterModel) => {
                            setFilterModel(model);
                        }}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            const data: any = apiRef.current.getSelectedRows();
                            if (!data) {
                                return;
                            }
                            console.log("---------------");
                            console.log(data);
                            data.forEach((value: any, index: number) => {

                                console.log(value);

                                console.log("userId");
                                console.log(value.id);
                                setSelectedUserId(value.id);
                                setSelectedUserName(value.name);
                                console.log("chatRoom");
                                console.log(value.chatRoomId);

                                if (value.chatRoomId) {
                                    setSelectedChatRoomId(value.chatRoomId);
                                } else {
                                    setSelectedChatRoomId(undefined);
                                }

                            })
                        }}
                    />
                </div>
                <div>
                    <DemeritChart loading={loading} drawingList={drawingList} targetDate={targetDate} selectedUserId={selectedUserId} selectedViolationListOrder={selectedViolationListOrder} />
                </div>
                <div>
                    <div>
                        <DemeritList 
                        handleSelectedRow={handleSelectedRow} handleChangeListRows={handleChangeListRows}
                         loading={loading} notify2={notify4} notify3={notify5} notify4={notify6} notify5={notify7} violationList={violationList} selectedUserId={selectedUserId} targetDate={targetDate} letsUpdate={letsUpdate} handleChangeSelectedViolationListOrder={handleChangeSelectedViolationListOrder} handleChangeSelectedIdentifyId={handleChangeSelectedIdentifyId} loadingChange={loadingChange} notify={notify3} />
                    </div>
                    <div>
                        <DemeritMemo loading={loading} selectedUserId={selectedUserId} targetDate={targetDate} />
                    </div>
                    <div>
                        <DemeritRegularData loading={loading} regularScheduleData={regularSchedule} regularScheduleMemoData={regularScheduleMemo} targetDate={targetDate} selectedUserId={selectedUserId} />
                    </div>
                    <div>
                        <DemeritSuddenData loading={loading} suddenNoticeData={suddenNotice} targetDate={targetDate} selectedUserId={selectedUserId} />
                    </div>
                </div>
            </div>


            {
                (selectedUserId && selectedChatRoomId) &&
                <SwipeableDrawer
                    anchor="right"
                    open={chatroomOpen}
                    onClose={() => { toggleDrawer(false) }}
                    onOpen={() => { toggleDrawer(true) }}
                >
                    <div
                        style={{ width: "550px", height: "100%" }}
                        role="presentation"
                    //className={styles.todoChat}
                    // onClick={(e) => { toggleDrawer(false) }}
                    // onKeyDown={(e) => { toggleDrawer(false) }}
                    >
                        <Chat messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage} socket={props.socket} studentName={selectedUserName} user={props.user} chatRoomId={selectedChatRoomId} />
                    </div>
                </SwipeableDrawer>
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={mainLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ToastContainer />

            <ReactModal
                isOpen={smsModalOpen}
                onRequestClose={() => {
                    handleCloseSms("e");
                }}
                initWidth={750}
                initHeight={500}
                className={"my-modal-custom-class"}
                disableResize

            >
                <div style={{ marginTop: "60px", padding: '30px', zIndex: 9999999999999999, backgroundColor: "white" }}>
                    <UserDemerit demeritData={demeritData} />
                </div>

                <div onClick={handleCloseSms} style={{ width: "20px", position: "absolute", top: "20px", right: "10px", zIndex: 999, cursor: "pointer" }}>
                    <XMarkSvg fill="#ff5722" />
                </div>
            </ReactModal>

            <ReactModal
                isOpen={accessModalOpen}
                onRequestClose={() => {
                    handleCloseAccess("e");
                }}
                initWidth={450}
                initHeight={350}
                className={"my-modal-custom-class"}
                disableResize
            >
                <div style={{ marginTop: "60px", padding: '30px', zIndex: 9999999999999999, backgroundColor: "white" }}>
                    <UserAccesscontrol open={accessModalOpen} targetDate={targetDate} studentId={selectedUserId} />
                </div>

                <div onClick={handleCloseAccess} style={{ width: "20px", position: "absolute", top: "20px", right: "10px", zIndex: 999, cursor: "pointer" }}>
                    <XMarkSvg fill="#ff5722" />
                </div>
            </ReactModal>
        </div>
    )
}

export default DemeritMain;