import { IonAvatar } from "@ionic/react";
import { Alert, Autocomplete, Avatar, Box, Button, LinearProgress, Modal, Stack, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from '../../componentsStyle/chatforteacher.module.css';
import Chat from "./chat";
import ChatRoom from "./chatroom";
import TotalMessagesTable from "./totalMessagesTable";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Backdrop, CircularProgress } from "@mui/material";
import TotalMessageSend from "./totalmessagesend";



const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "24px",
    boxShadow: 24,
    p: 3,
    paddingLeft: 5,
    paddingRight: 5
};

const style2 = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1050,
    height: 787.5,
    bgcolor: 'background.paper',
    boxShadow: 10,
    outline: 'none'
};




const ChatForTeacher: React.FC<any> = (props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [searchMenu, setSearchMenu] = useState("write");
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = useState(false);
    const open5 = Boolean(anchorEl);
    const [loading2, setLoading2] = useState(false);

    const [selectedUser, setSelectedUser] = useState<any>();
    const [selectedStudentName, setSelectedStudentName] = useState("");

    const [active, setActive] = useState(true);
    const [users, setUsers] = useState<any>([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [uploadBool, setUploadBool] = useState(false);
    const [update, setUpdate] = useState(0);

    const [data, setData] = useState<any>();

    const [selectedChatRoomId, setSelectedChatRoomId] = useState<any>();

    const handleOpen = () => setOpen(true);
    const handleClose = () => { setActive(true); setOpen(false); }

    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => { setOpen2(false); getChatRoom();}

    const [selectedChatRoomId2, setSelectedChatRoomId2] = useState<any>();


    const handleClose5 = () => {
        setAnchorEl(null);
    }

    const getChatRoom = () => {

        fetch("https://peetsunbae.com/dashboard/envelope/getChatRooms", {
            method: "GET",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);

                    result.data.sort(function (a: any, b: any) {
                        if (new Date(a.lastMessage.createdAt).getTime() > new Date(b.lastMessage.createdAt).getTime()) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });

                    //unreadNumber가 0인 애들보다 큰 애들이 앞으로 오게 하기
                    result.data.sort(function (a: any, b: any) {
                        if(a.unreadNumber !== 0 && b.unreadNumber === 0){
                            return -1;
                        }else{
                            return 1;
                        }
                    });

                    const data = result.data;

                    setData([...data]);
                })
        })

    }

    useEffect(() => {
        getChatRoom()
    }, [props.messageUpdate, update, searchMenu]);


    useEffect(() => {

        async function start() {
            var token = "";
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/chart/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        console.log(result);
                        const rows: any = [];
                        if (result.data) {
                            result.data.forEach((each: any, index: number) => {
                                var data: any = {};
                                data.id = each.id;
                                data.label = each.name;
                                data.phoneNumber = each.phoneNumber;
                                data.value = each.value;
                                data.key = index;
                                if (data.value === "student" || data.value === "parent") {
                                    rows.push(data);
                                }
                            })

                            //학생 리스트가 학부모 리스트보다 먼저 나오게 하기
                            rows.sort(function (a: any, b: any) {
                                if (a.value === "student" && b.value === "parent") {
                                    return -1;
                                } else {
                                    return 1;
                                }
                            });

                            setUsers([...rows]);
                        }
                    })
            })
        }

        start();

    }, [props.user]);

    const onchange = (e: any, value: any) => {
        console.log(value);
        if(value){
            setSelectedStudentName(value.label);
        }else{
            setSelectedStudentName("");
        }
        setSelectedUser(value);
        if (value) {
            setActive(false);
        } else {
            setActive(true);
        }
    }

    const openChatRoom = (e: any) => {

        if (!selectedUser) {
            alert("학생을 먼저 선택해주세요.");
            return;
        }

        fetch("https://peetsunbae.com/dashboard/envelope/createroom", {
            method: "post",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                studentId: selectedUser.id
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "created") {
                        handleClose();
                        setSelectedChatRoomId(result.roomNumber);
                        handleOpen2();
                    }
                    if (result.message === "already") {
                        handleClose();
                        setSelectedChatRoomId(result.roomNumber);
                        handleOpen2();
                    }
                })
        })

    }

    const handleRead = () => {

        setAnchorEl(null);

        if (window.confirm("해당 메세지를 읽음 처리 하시겠습니까?")) {

        } else {
            return;
        }

        setLoading2(true);

        console.log(selectedChatRoomId2);

        fetch("https://peetsunbae.com/dashboard/envelope/readMessage", {
            method: "post",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                chatRoomId: selectedChatRoomId2
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);

                    if (result.message === "success") {
                        setUpdate(Math.random());
                        setLoading2(false);
                    }

                })
        })

    }


    const handleRightClick = (e : any, chatRoomId : number) => {

        if(!props.user || props.user.value === "student" || props.user.value === "parent"){
            return;
        }

        if(props.user.id !== 11 && props.user.id !== 14 && props.user.id !== 345 && props.user.id !== 16){
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        setSelectedChatRoomId2(chatRoomId);
        setAnchorEl(e.currentTarget);

    }


    return (
        <div className={styles.main}>
            <div className={styles.mainBoard}>
                <div className={styles.title}>
                    <img src="img/off/envelope.svg" alt="envelope" />
                    나에게 온 메세지
                </div>

                <div className={styles.searchMenu}>
                    <div onClick={(e) => { setSearchMenu("write") }} className={`${styles.searchMenuDiv} ${searchMenu === "write" ? styles.active : ""}`}>
                        메세지함
                    </div>
                    <div onClick={(e) => { setSearchMenu("watch") }} className={`${styles.searchMenuDiv} ${searchMenu === "watch" ? styles.active : ""}`}>
                        전체 메세지
                    </div>
                    <div onClick={(e) => { setSearchMenu("totalSend") }} className={`${styles.searchMenuDiv} ${searchMenu === "totalSend" ? styles.active : ""}`}>
                        단체 보내기
                    </div>
                </div>

                <div className={styles.chatroomListDiv}>
                    {
                        searchMenu === "write" &&
                        <div className={styles.chatRoomList}>
                            <div className={styles.innerChatRoomList}>

                                {
                                    data &&
                                    data.map((eachChatRoom: any) => {

                                        const lastMessageCreatedAt = eachChatRoom.lastMessage.createdAt;
                                        const date = new Date(lastMessageCreatedAt);
                                        const lastMessageMonth = date.getMonth() + 1;
                                        const lastMessageDate = date.getDate();

                                        return (
                                            <div key={eachChatRoom.id} className={styles.eachChatRoomList}
                                                onClick={(e: any) => { setSelectedChatRoomId(eachChatRoom.id); setSelectedStudentName(`${eachChatRoom.name} (${eachChatRoom.location})`); handleOpen2(); }}
                                                onContextMenu={(e : any) => {
                                                    handleRightClick(e, eachChatRoom.id);
                                                }}
                                            >
                                                <div className={styles.innerEachChatRoomList}>
                                                    <div className={styles.item_info}>
                                                        <span className={styles.wrap_thumb}>
                                                            {
                                                                eachChatRoom.avatar ?
                                                                    <IonAvatar style={{ width: "40px", height: "40px" }}>
                                                                        <img alt="avatar" src={`/img/avatar/Avatar-${eachChatRoom.avatar}.png`} />
                                                                    </IonAvatar>
                                                                    :
                                                                    <Avatar sx={{ bgcolor: "#b0dbf1" }}><img src="img/user-solid.svg" alt="user" className="avatarImg" /></Avatar>
                                                            }
                                                        </span>
                                                        <strong className={styles.tit_info}>
                                                            <span className={styles.txt_name}>{eachChatRoom.name} ({eachChatRoom.location})</span>
                                                            <span className={`${styles.num_round} ${eachChatRoom.unreadNumber === 0 ? styles.numTransparent : ""}`}>{eachChatRoom.unreadNumber}</span>
                                                        </strong>
                                                        <div className={styles.bubble_g}>
                                                            <p className={styles.txt_info}>{eachChatRoom.lastMessage.message}</p>
                                                            <span className={styles.ico_arr}>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className={styles.txt_date}><span>{lastMessageMonth}월 {lastMessageDate}일</span></span>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    }

                    {
                        searchMenu === "watch" &&
                        <div>
                            <TotalMessagesTable />
                        </div>
                    }

                    {
                        searchMenu === "totalSend" &&
                        <div>
                            <TotalMessageSend socket={props.socket} user={props.user} />
                        </div>
                    }

                </div>
            </div>


            {
                searchMenu === "write" &&
                <div onClick={handleOpen} className={`${styles.message} qnaWrite`}>
                    <img src="./img/pencil.svg" alt="pencil" />
                    채팅방 열기
                </div>
            }
            


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.messageTitle}>
                        채팅방 열기
                    </div>
                    <div className={styles.autocompleteDiv}>
                        <Autocomplete
                            onChange={onchange}
                            disablePortal
                            id="combo-box-demo"
                            options={users}
                            sx={{ width: "100%", borderRadius: "40px !important" }}
                            renderInput={(params) => <TextField {...params} sx={{ borderRadius: "24px" }} label={<span className={styles.nameText}>이름</span>} />}
                        />
                    </div>
                    {/* <div className={styles.textfieldDiv}>
                        <TextField value={message} onChange={(e) => { changeMessage(e) }} fullWidth id="outlined-basic" label={<span className={styles.nameText}>메세지</span>} variant="outlined" />
                    </div> */}


                    {loading &&
                        <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
                            <LinearProgress />
                        </Box>
                    }



                    {/* {uploadBool &&
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2 }}><span className={styles.spanStyles2}>업로드 성공 !</span></Alert>
                        </Stack>
                    } */}

                    <div className={styles.buttonDiv}>
                        <Button onClick={openChatRoom} disabled={active} variant="contained"><span className={styles.buttonText}>시작하기</span></Button>
                    </div>
                </Box>
            </Modal>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open5}
                onClose={handleClose5}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleRead}>읽음 처리하기</MenuItem>
            </Menu>

            <Modal
                open={open2}
                onClose={handleClose2}
            >
                <Box sx={style2}>
                    <Chat messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage} socket={props.socket} studentName={selectedStudentName} user={props.user} chatRoomId={selectedChatRoomId} />
                </Box>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading2}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default ChatForTeacher;