import { Button, Divider} from "@mui/joy";
import React, { useEffect, useState, useCallback } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    LicenseInfo,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
    GridPinnedRowsProp,
    gridDetailPanelExpandedRowIdsSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { Check, ChevronLeft, ChevronRight, Send } from "@mui/icons-material";
import { renderCellExpand } from "../../infos/suddenData";
import BlockIcon from '@mui/icons-material/Block';
import { de } from "date-fns/locale";
import { makeRowsForDemeritList } from "../demeritfunc/calcdemerit";
import { IconButton } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .suspicious_in': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .suspicious': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .phone_problem': {
                    color: "red"
                },
                '& .phone_noproblem': {
                    color: "black"
                },
                '& .phone_memo': {
                    color: "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .decided': {
                    color : "blue"
                },
                '& .sendDemerit': {
                    color : "blue"
                },
                '& .demeritNotSame': {
                    color : "#a10e25"
                },
                '& .canceled': {
                    backgroundColor : "#ebebeb",
                    color : "gray"
                },
                '& .overedDemerit': {
                    color : "red"
                }
            },
        };
    },
    { defaultTheme },
);



const absentSelectList = [
    "일반결석", "사유결석", "정기결석", "미정"
];

const lateSelectList = [
    "일반지각", "사유지각", "정기지각", "미정"
];

const earlySelectList = [
    "일반조퇴", "사유조퇴", "정기조퇴", "미정"
];

const amongSelectList = [
    "일반외출", "사유외출", "정기외출", "미정", "사유조퇴"
];

const amongOrEarlySelectList = [
    "일반외출", "사유외출", "정기외출", "일반조퇴", "사유조퇴", "정기조퇴", "미정"
];

const lateOrAbsentSelectList = [
    "일반지각", "사유지각", "정기지각", "일반결석", "사유결석", "정기결석", "미정"
];

const lateSeatList = [
    "늦은 착석"
]

const totalSelectList = [
    "일반지각", "사유지각", "정기지각", "일반결석", "사유결석", "정기결석", "일반조퇴", "사유조퇴", "정기조퇴", "일반외출", "사유외출", "정기외출", "늦은 착석", "미정"
]

const demeritList = [
    {value : 0, label : 0},
    {value : 1, label : 1},
    {value : 2, label : 2},
    {value : 3, label : 3},
    {value : 4, label : 4},
    {value : 5, label : 5},
    {value : 6, label : 6},
    {value : 7, label : 7},
    {value : 8, label : 8},
    {value : 9, label : 9},
    {value : 10, label : 10},
    {value : 11, label : 11},
    {value : 12, label : 12},
    {value : 13, label : 13},
    {value : -1, label : -1},
    {value : -2, label : -2},
    {value : -3, label : -3},
    {value : -4, label : -4},
    {value : -5, label : -5},
    {value : -6, label : -6},
    {value : -7, label : -7},
    {value : -8, label : -8},
    {value : -9, label : -9},
    {value : -10, label : -10},
    {value : 999, label : "미정"}
]

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
    const { id, value: isExpanded }: any = props;
    const apiRef = useGridApiContext();


    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowIdsSelector,
    );

    const currentRow = apiRef.current.getRow(id);

    if(!currentRow){
        return <div></div>
    }

    var letsShow = false;

    if(currentRow.etc || currentRow.notDeterminedReason ){
        letsShow = true;
    }



    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    if(letsShow){

        return (
            <IconButton
                size="small"
                tabIndex={-1}
                disabled={false}
                aria-label={isExpanded ? 'Close' : 'Open'}
            >
                <ExpandMore
                    sx={{
                        transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                        transition: (theme) =>
                            theme.transitions.create('transform', {
                                duration: theme.transitions.duration.shortest,
                            }),
                    }}
                    fontSize="inherit"
                />
            </IconButton>
        );
        
    }else{
        return <div></div>
    }


}





const DemeritList: React.FC<any> = (props) => {

    const [studentTotalDemerit, setStudentTotalDemerit] = useState<number>(0);
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field : "justOrder", headerName : "", width : 50, sortable : false, filterable : false },
        { field: "kind", headerName: "벌점사유(예상)", width: 120, sortable : false },
        { field : "determinedKind", headerName : "벌점사유(확정)", width : 120,sortable: false ,type : "singleSelect", valueOptions : (params) => {
            //console.log(params.row);
            //parmas.row.kind가 "외출OR조퇴 를 포함하면"
            if(!params.row.kind){
                return [];
            }

            if(params.row.kind.includes("수동추가")){
                return totalSelectList;
            }
            if(params.row.kind.includes("외출OR조퇴")){
                return amongOrEarlySelectList;
            }
            if(params.row.kind.includes("지각OR결석")){
                return lateOrAbsentSelectList;
            }
            if(params.row.kind.includes("결석")){
                return absentSelectList;
            }
            if(params.row.kind.includes("지각")){
                return lateSelectList;
            }
            if(params.row.kind.includes("조퇴")){
                return earlySelectList;
            }
            if(params.row.kind.includes("외출")){
                return amongSelectList;
            }
            if(params.row.kind.includes("늦은 착석")){
                return lateSeatList;
            }

            return [];
        }, editable : true },
        { field: "demerit", headerName: "벌점값", width: 80, type: "singleSelect", valueOptions: demeritList, editable: true, sortable : false },
        { field: "description", headerName: "상세사유", width: 250, renderCell: renderCellExpand, editable: true, sortable : false },
        {
            field: "decided", headerName: "검수하기", width: 120, renderCell: (params) => {

                const id = params.row.id;
                const userId = params.row.userId;
                const identifyId = params.row.identifyId;
                const targetDate = params.row.targetDate;

                var disabled = false;

                if(params.row.determinedKind === "총 벌점"){
                    return "";
                }

                if(params.row.demerit === 999){
                    disabled = true;
                }

                if (params.value) {

                    //params.value = "11__고용범__1634696828761" 꼴;

                    const name = params.value.split("__")[1];
                    const time = params.value.split("__")[2];
                    const date = new Date(+time);

                    //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                    const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                    return (
                        <span>
                            {name} {dateString} &nbsp;
                            <Button variant="soft" size="sm" color="neutral"
                                sx={{
                                    marginLeft: "30px"
                                }}
                                onClick={(e: any) => {
                                    submitCancel(targetDate, userId, identifyId);
                                }}
                            >
                                취소하기
                            </Button>
                        </span>
                    )
                } else if(params.row.cancelDemerit){
                    return (
                        <div>

                        </div>
                    )
                }else{

                    const determinedKind = params.row.determinedKind;
                    const demerit = params.row.demerit;
                    const description = params.row.description;


                    return (
                        <Button variant="soft" size="sm" color="neutral"
                            onClick={(e: any) => {
                                //console.log("gogogoggo");
                                submitCheck(targetDate, userId, identifyId, determinedKind, demerit, description);
                            }}
                            disabled={disabled}
                        >
                            <Check />
                        </Button>
                    )
                }
            }
        },
        { field : "sendDemerit", headerName : "벌점부여", width : 120, sortable : false, renderCell : (params) => {
                
                const id = params.row.id;
                const userId = params.row.userId;
                const identifyId = params.row.identifyId;
                const targetDate = params.row.targetDate;
                const decided = params.row.decided;

                var disabled = false;
    
                if(params.row.determinedKind === "총 벌점"){
                    return "";
                }
    
                if(params.row.cancelDemerit){
                    return "";
                }
    
                if(params.row.determinedKind === "미정"){
                    disabled = true;
                }
    
                if(params.row.determinedKind.includes("미정")){
                    disabled = true;
                }

                if(params.row.demerit === 999){
                    disabled = true;
                }

                if(!params.row.decided){
                    disabled = true;
                }

                if (params.value) {

                    //params.value = "11__고용범__1634696828761" 꼴;

                    const name = params.value.split("__")[1];
                    const time = params.value.split("__")[2];
                    const canceled = params.value.split("__")[6];
                    const date = new Date(+time);

                    //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                    const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                    //console.log(canceled);
                    //console.log("cancel");

                    if (canceled && canceled === "canceled") {

                        return (
                            <span>
                                <del>
                                    (취소됨) {name} {dateString} &nbsp;
                                </del>
                            </span>
                        )
                    }else{

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                            </span>
                        )

                    }

                } else{

                    const determinedKind = params.row.determinedKind;
                    const demerit = params.row.demerit;
                    const description = params.row.description;


                    return (
                        <Button variant="soft" size="sm" color="neutral"
                            onClick={(e: any) => {
                                //console.log("gogogoggo");
                                sendDemerit(targetDate, userId, identifyId, determinedKind, demerit, description, decided);
                            }}
                            disabled={disabled}
                        >
                            <Send />
                        </Button>
                    )
                }
        }},
        { field : "memo", headerName : "근무자메모", width : 100, editable : true, sortable : false },
        { field : "cancelDemerit", headerName : "비활성화", width : 100, sortable : false, renderCell : (params) => {

            if(params.value){

                //params.value = "11__고용범__1634696828761" 꼴;

                const name = params.value.split("__")[1];
                const time = params.value.split("__")[2];
                const date = new Date(+time);

                //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                return (
                    <span>
                        {name} {dateString} &nbsp;
                    </span>
                )

            }

        }},
        {field : "suddenReason", headerName : "사유", width : 120, sortable : false},
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell : (params) => {
                return <CustomDetailPanelToggle id={params.id} value={params.value} />   
            }  
        }
    ]);
    const classes = useStyles2();
    const apiRef = useGridApiRef();

    const getDetailPanelContent = useCallback(
        ({row} : any) => 
        {       

            //console.log(row);
            //console.log("detailRow");

            return (
                <div style={{
                    color : "gray"
                }}>
                    {
                        row.notDeterminedReason ?
                            <div style={{
                                padding: "10px",
                                paddingLeft: "25px",
                                fontSize: "12px",
                            }}>
                                <span>
                                    미정인 이유 : {row.notDeterminedReason}
                                </span>
                            </div>
                            :
                            <div></div>
                    }
                    {
                        row.etc ?
                            <div style={{
                                padding: "10px",
                                paddingLeft: "25px",
                                paddingBottom : "5px",
                                fontSize: "12px",
                            }}>
                                <span>
                                    기타 : {row.etc}
                                </span>
                            </div>
                            :
                            <div></div>
                    }
                    {
                        row.notDeterminedDemeritReason ?
                        <div style={{
                            paddingTop: (row.etc || row.notDeterminedReason) ? "0px" : "10px",
                            paddingLeft: "25px",
                            paddingBottom : "5px",
                            fontSize: "12px",
                        }}>
                            <span>
                                벌점이 미정인 이유 : {row.notDeterminedDemeritReason}
                            </span>
                        </div>
                        :
                        <div></div>
                    }
                </div>
            )

        },
        [],
    );


    useEffect(() => {

        if(!rows){
            return;
        }

        props.handleChangeListRows(rows);

    }, [rows]);


    useEffect(() => {

        letsStart();

    }, [props.violationList]);

    useEffect(() => {

        var totalDemerit = 0;

        const newRows = rows;

        newRows.forEach((eachRow: any) => {
            //eachRow.demerit이 숫자가 아니면 return;
            if (eachRow.demerit === 999) {
                return;
            }

            if (eachRow.cancelDemerit) {
                return;
            }

            // const canceled = params.value.split("__")[6];
            const sendDemerit = eachRow.sendDemerit;
            const canceled = sendDemerit ? sendDemerit.split("__")[6] : null;

            if(canceled && canceled === "canceled"){
                return;
            }

            if (eachRow.demerit || eachRow.demerit === 0) {
                totalDemerit += eachRow.demerit;
            }
        });

        setStudentTotalDemerit(totalDemerit);

    }, [rows, props.violationList]);


    const letsStart = async () => {

        try{

            if(!props.violationList){
                return;
            }

            const newRows : any = makeRowsForDemeritList(props.violationList);

            setRows([...newRows]);



        }catch(e){
            console.log(e);
        }

    }

    const sendDemerit = async (targetDate : Date, userId : number, identifyId : number, determinedKind : string, demerit : number, description : string, decided : string) => {

        if(!userId){
            return;
        }

        if(!identifyId){
            return;
        }

        if(!targetDate){
            return;
        }

        if(!determinedKind){
            props.notify2();
            return;
        }

        if(determinedKind.includes("미정")){
            props.notify2();
            return;
        }

        if(!demerit && demerit !== 0){
            //console.log("hi");
            props.notify3();
            return;
        }

        if(demerit === 999){
            props.notify3();
            return;
        }

        if(!decided){
            //console.log("noDecided");
            return;
        }

        var demeritDescription = "";

        if(description){
            demeritDescription = description;
        }

        console.log("sendDemerit");
        console.log(userId, identifyId, targetDate, determinedKind, demerit, demeritDescription, decided);

        props.loadingChange(true);

        const data = {
            userId,
            identifyId,
            targetDateTime : targetDate.getTime(),
            determinedKind,
            demerit,
            description : demeritDescription,
        }


        try{

            const response = await fetch("https://peetsunbae.com/dashboard/report/demerit/send", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                credentials: "include",
            });

            const result = await response.json();

            if(result.message === "success"){
                console.log("success");
                props.letsUpdate();
                setTimeout(() => {
                    props.notify5();
                    props.loadingChange(false);
                }, 300);
            }

        }catch(e){
            console.log(e);
        }

    }


    const submitCheck = async (targetDate : Date, userId : number, identifyId : number, determinedKind : string, demerit : number, description : string) => {

        console.log("go1111111");

        if(!userId){
            console.log("noUserId");
            return;
        }


        if(!identifyId){
            console.log("noIdentifyId");
            return;
        }

        if(!targetDate){
            console.log("noTargetDate");
            return;
        }

        if(!determinedKind){
            props.notify2();
            return;
        }

        if(determinedKind.includes("미정")){
            props.notify2();
            return;
        }

        if(!demerit && demerit !== 0){
            console.log("hi");
            props.notify3();
            return;
        }

        if(demerit === 999){
            props.notify3();
            return;
        }

        console.log("gow");

        props.loadingChange(true);

        
        const data = {
            userId : userId,
            identifyId,
            targetDateTime : targetDate.getTime(),
            determinedKind,
            demerit,
            description
        }

        console.log(data);

        try{

            const response = await fetch("https://peetsunbae.com/dashboard/report/demerit/decided", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                credentials: "include",
            });

            const result = await response.json();

            if(result.message === "success"){
                console.log("success");
                props.letsUpdate();
                setTimeout(() => {
                    props.notify();
                    props.loadingChange(false);
                }, 300);
            }

        }catch(e){
            console.log(e);
        }

    }

    const submitCancel = async (targetDate : Date, userId : number, identifyId : number) => {

        if(!userId){
            return;
        }


        if(!identifyId){
            return;
        }

        if(!targetDate){
            return;
        }

        props.loadingChange(true);

        const data = {
            userId : userId,
            identifyId,
            targetDateTime : targetDate.getTime()
        }

        console.log(data);

        try{

            const response = await fetch("https://peetsunbae.com/dashboard/report/demerit/canceldecided", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                credentials: "include",
            });

            const result = await response.json();

            if(result.message === "success"){
                console.log("success");
                props.letsUpdate();
                setTimeout(() => {
                    props.notify4();
                    props.loadingChange(false);
                }, 300);
            }

        }catch(e){
            console.log(e);
        }

    }

    const pinnedRow : GridPinnedRowsProp = {
        bottom : [{
            id : 99999,
            determinedKind : "총 벌점",
            demerit : studentTotalDemerit,
        }]
    }



    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {

            var field = "";

            console.log("new");

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            if(!props.targetDate){
                console.log("noTargetDate");
                return newRow;
            }

            
            const targetDateTime = props.targetDate.getTime();
            const value = (newRow[field] || newRow[field] === 0) ? newRow[field] : null;
            const id = newRow.id;
            const userId = props.selectedUserId;
            const identifyId = newRow.identifyId;

            console.log(newRow);
            console.log(field);
            console.log(value);
            console.log(id);
            console.log(userId);

            if(!userId){
                console.log("noUserId");
                return newRow;
            }

            if(!identifyId){
                console.log("noIdentifyId");
                return newRow;
            }

            if (!field) {
                console.log("noChanged");
                return newRow;
            }

            if (!id) {
                console.log("noId");
                return newRow;
            }



            const data = {
                value,
                id,
                userId,
                field,
                identifyId,
                targetDateTime
            }

            try{

                fetch("https://peetsunbae.com/dashboard/report/demerit/edit", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                    credentials: "include",
                }).then((response: any) => {
                    response.json()
                    .then((result: any) => {
                        console.log(result);
                        if (result.message === "success") {
                            console.log("success");
                            props.letsUpdate();
                        }else{
                            alert("서버에 문제가 있습니다. 다시 시도해주세요.");
                        }
                    })
                })

                return newRow;

            }catch(e){
                console.log(e);
            }



        }, [rows, props.selectedUserId, props.targetDate]
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log("error");
        alert(error.message);
    }, []);





    return (
        <div>
            <div>
                <div className={classes.root} style={{
                    width: "1000px",
                    height: "280px",
                    backgroundColor: "#ffffff",
                }}>
                    <DataGridPremium
                        loading={props.loading}
                        rows={rows}
                        columns={columns}
                        density="compact"
                        apiRef={apiRef}
                        hideFooter={true}
                        processRowUpdate={handleEditCommit}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        
                        getRowClassName={(params)=> {

                            if(params.row.cancelDemerit){
                                return "canceled";
                            }

                            return ""
                        }}
                        getCellClassName={(params) => {

                            if(params.field === "demerit"){
                                if(params.id === 99999){
                                    if(params.value > 5){
                                        return "overedDemerit"
                                    }
                                }
                                if(params.row.isDemeritNotSame){
                                    return "demeritNotSame"
                                }
                            }

                            if(params.field === "decided"){
                                if(params.value){
                                    return "decided";
                                }
                            }

                            if(params.field === "sendDemerit"){
                                if(params.value){
                                    return "sendDemerit";
                                }
                            }

                            return "";
                        }}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            const data: any = apiRef.current.getSelectedRows();
                            if (!data) {
                                return;
                            }

                            data.forEach((value: any, index: number) => {

                                console.log(value);

                                console.log(value.id);

                                props.handleSelectedRow(value);
                                props.handleChangeSelectedViolationListOrder(value.id);
                                props.handleChangeSelectedIdentifyId(value.identifyId);
                                
                            });
                        }}
                        isCellEditable={(params) => {

                            //비활성화 됬으면 수정 불가
                            if(params.row.cancelDemerit){
                                return false;
                            }

                            //검수하기가 됬으면 수정 불가
                            if(params.row.decided){
                                return false;
                            }

                            //벌점이 이미 보내졌으면 수정 불가
                            if(params.row.sendDemerit){
                                return false;
                            }

                            //"총 벌점" 이면 수정 불가
                            if(params.row.determinedKind === "총 벌점"){
                                return false;
                            }

                            if(params.field === "determinedKind"){
                                //값이 "미정"이면 수정 가능
                                if(params.value === "미정"){
                                    return true;
                                }
                            }

                            if(params.field === "demerit"){

                                if(params.row.determinedKind === "미정"){
                                    return false;
                                }

                                return true;
                            }

                            if(params.field === "description"){
                                return true;
                            }

                            if(params.field === "memo"){
                                return true;
                            }

                            return false;
                        }}
                        pinnedRows={pinnedRow}
                        getDetailPanelContent={getDetailPanelContent}
                        getDetailPanelHeight={({ row }) => 'auto'}
                        initialState={{
                            detailPanel : {
                                expandedRowIds : [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default DemeritList;