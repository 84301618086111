import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import InnerHeader from "../etc/innerheader";
import styles from "./mobilerestaurant.module.css";
import MobileRestaurantStatus from "./mobilerestaurantstatus";
import MobileRestaurantSubmit from "./mobilerestaurantsubmit";

import { IonApp } from "@ionic/react";


const MobileRestaurant: React.FC<any> = (props) => {

    const [currentMenu, setCurrentMenu] = useState("submit");
    const [underBarPosition, setUnderBarPosition] = useState(6);

    const topDivRef = useRef<any>(null);
    const [statusDivHeight, setStatusDivHeight] = useState(0);
    const history = useHistory();

    useEffect(() => {

        console.log("hello world !");

        //backbutton 눌리면 console.log("pressed") 뜨게 하기
        const handleBackButton = () => {

            if(currentMenu === "status"){
                return;
            }

            console.log("pressed");
            history.goBack();
        }

        //backbutton 눌리면 handleBackButton 실행
        document.addEventListener("backbutton", handleBackButton);

        return () => {
            document.removeEventListener("backbutton", handleBackButton);
        }

    }, [currentMenu]);


    useEffect(() => {

        if (!topDivRef || !topDivRef.current) {
            return;
        }

        const calculateStatusDivHeight = () => {

            setTimeout(() => {

                if (!topDivRef || !topDivRef.current) {
                    return;
                }
    
                const totalHeight = window.innerHeight;
                const topDivHeight = topDivRef.current.offsetHeight;
    
                const statusDivHeight = totalHeight - topDivHeight;
    
                console.log("statusDivHeight");
                console.log(statusDivHeight);
    
                setStatusDivHeight(statusDivHeight);

            }, 20);

        }

        calculateStatusDivHeight();

        window.addEventListener("resize", () => calculateStatusDivHeight());

        return () => window.removeEventListener("resize", () => calculateStatusDivHeight());

    }, [topDivRef]);



    const moveUnderBar = async (index: number) => {

        switch (index) {
            case 1:
                setUnderBarPosition(6);
                break;
            case 2:
                setUnderBarPosition(54);
                break;
        }

    }




    return (
        <IonApp>
            <div>
                <div ref={topDivRef}>
                    <InnerHeader title={"도시락 신청"} />
                    <div className={styles.underBorder}>

                    </div>

                    <div className={styles.menuBar}>
                        <div className={`${styles.menuBarSection} ${currentMenu === "submit" ? styles.active : ""}`} onClick={() => { moveUnderBar(1); setCurrentMenu("submit"); }}>
                            도시락 신청
                        </div>
                        <div className={`${styles.menuBarStatus} ${currentMenu === "status" ? styles.active : ""}`} onClick={() => { moveUnderBar(2); setCurrentMenu("status"); }}>
                            신청 현황
                        </div>
                        <div className={styles.menuUnderBar} style={{ left: `${underBarPosition}%` }}>

                        </div>
                    </div>
                </div>

                {
                    currentMenu === "submit" &&
                    <MobileRestaurantSubmit />
                }
                {
                    currentMenu === "status" &&
                    <MobileRestaurantStatus socket={props.socket} statusDivHeight={statusDivHeight} />
                }


            </div>
        </IonApp>
    );
}

export default MobileRestaurant;