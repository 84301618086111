import { CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styles from "../../componentsStyle/profileStyles.module.css";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { display } from '@mui/system';
import Button from '@mui/material/Button';
import { Subject } from '@mui/icons-material';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import koLocale from 'date-fns/locale/ko'

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const ProfileFirst: React.FC<any> = (props: any) => {
    const [loading, setLoading] = useState(false);

    const [currentMenu, setCurrentMenu] = useState("information");

    const [previousUniversity, setPreviousUniversity] = useState("");
    const [major, setMajor] = useState("");
    const [semester, setSemester] = useState("");
    const [schoolStatus, setSchoolStatus]= useState("");
    const [degree, setDegree] = useState("");
    const [toeic, setToeic] = useState("");
    const [whenToeic, setWhenToeic] = useState("");
    const [highschoolKind, setHighschoolKind] = useState("");
    const [mathGrade, setMathGrade] = useState("");


    const [name, setName] = useState("");
    const [birthday, setBirthday] = useState(new Date());
    const [highschool, setHighSchool] = useState("");
    const [highschoolGrade, setHighschoolGrade] = useState("");
    const [suneungNumber, setSuneungNumber] = useState(5);
    const [applyKind, setApplyKind] = useState([false, false, false, false]);
    const [university, setUniversity] = useState("");
    const [system, setSystem] = useState(3);
    const [koreanKind, setKoreanKind] = useState(3);
    const [mathKind, setMathKind] = useState(3);
    const [satamKind, setSatamKind] = useState([false, false, false, false, false, false, false, false, false]);
    const [gatamKind, setGatamKind] = useState([false, false, false, false, false, false, false, false, false]);
    const [freepassCor, setFreepassCor] = useState("");

    
    const [whatScienceChecked, setWhatScienceChecked] = useState([false, false, false, false, false, false, false, false])
    const [scienceGrade, setScienceGrade] = useState(["", "", "", "", "", "", "", ""])

    const birthdayChange = (newValue : any) => {


        if(props.selectedUser){
            return;
        }


        setBirthday(newValue);
    }

    const suneungNumberChange = (e : any) => {
        if(props.selectedUser){
            return;
        }


        setSuneungNumber(e.target.value);
    };

    const applyKindChange = (e : any, number : number) => {

        if(props.selectedUser){
            return;
        }

        console.log(e.target.checked);
        console.log(111);
        const newApplyKind = applyKind;
        newApplyKind[number] = e.target.checked;
        setApplyKind([...newApplyKind]);
    }

    const systemChange = (e : any) => {

        if(props.selectedUser){
            return;
        }


        setSystem(e.target.value);
    }

    const koreanKindChange = (e : any) => {

        if(props.selectedUser){
            return;
        }


        setKoreanKind(e.target.value);
    }

    const mathKindChange = (e : any) => {

        if(props.selectedUser){
            return;
        }


        setMathKind(e.target.value);
    }

    const satamKindChange = (e : any, number : number) => {


        if(props.selectedUser){
            return;
        }


        const newSatamKind = satamKind;
        newSatamKind[number] = e.target.checked;
        setSatamKind([...newSatamKind]);
    }

    const gatamKindChange = (e : any, number : number) => {

        if(props.selectedUser){
            return;
        }

        const newGatamKind = gatamKind;
        newGatamKind[number] = e.target.checked;
        setGatamKind([...newGatamKind]);
    }

    useEffect(() => {
        const start = async () => {
            var token = "";
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            var isSelectedUser = "no";
            var selectedUser = props.selectedUser;

            console.log(selectedUser);


            if(selectedUser){
                isSelectedUser = "yes"
            }



            fetch(`https://peetsunbae.com/dashboard/profile/first?isSelectedUser=${isSelectedUser}&selectedUser=${selectedUser ? selectedUser.id : ""}`, {
                method: "GET",
                headers: {"Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        if(result.data){
                            const data = result.data.message;
                            console.log(data);

                            setName(data.name);
                            setBirthday(data.birthday);
                            setHighSchool(data.highschool);
                            setHighschoolGrade(data.highschoolGrade);
                            setSuneungNumber(data.suneungNumber);
                            setApplyKind(data.applyKind);
                            setUniversity(data.university);
                            setSystem(data.system);
                            setKoreanKind(data.koreanKind);
                            setMathKind(data.mathKind);
                            setSatamKind(data.satamKind);
                            setGatamKind(data.gatamKind);
                            setFreepassCor(data.freepassCor);
                            
                        }
                    })
            })
        }

        start();
    }, []);

    const change = (e: any, kind: string) => {

        if(props.selectedUser){
            return;
        }

        switch (kind) {
            case "name" :
                setName(e.target.value);
                break;
            case "highschool" :
                setHighSchool(e.target.value);
                break;
            case "highschoolGrade" :
                setHighschoolGrade(e.target.value);
                break;
            case "university" :
                setUniversity(e.target.value);
                break;
            case "freepassCor" :
                setFreepassCor(e.target.value);
                break;
        }
    }

    const submit = async (e : any) => {
        if(props.selectedUser){
            props.changeProfilePage("second");
        }else{
            setLoading(true);

            const message = {
                name, birthday, highschool, highschoolGrade, suneungNumber, applyKind, university, system,
                koreanKind, mathKind, satamKind, gatamKind, freepassCor
            }
    
            console.log(message);
    
            var token = "";
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }
    
            fetch("https://peetsunbae.com/dashboard/profile/first", {
                method: "POST",
                headers: { "Content-Type": "application/json", "Authorization": token },
                credentials: "include",
                body: JSON.stringify({
                    message
                })
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        setLoading(false);
                        console.log(result);
                        if(result.message === "success"){
                            props.changeProfilePage("second");
                        }
                    })
            })
    
        }
    } 


    return (
        <div className={styles.body}>
            <div className={styles.header}>
                프로필 설정
            </div>
            <div className={styles.currentMenu}>
                <div className={`${styles.circle} ${currentMenu === "information" ? styles.active : ""}`}>
                    학생정보
                </div>
                <img src="img/chevron-right-profile.svg" />
                <div className={`${styles.circle}`}>
                    학습정보
                </div>
                <img src="img/chevron-right-profile.svg" />
                <div className={`${styles.circle}`}>
                    확인사항
                </div>
            </div>
            <div className={styles.description}>

            </div>

            <div className={styles.profileBoard}>
                <div className={styles.profileBoardDescription}>
                    <div className={styles.descriptionText}>
                        *학생카드의 모든 항목은 담임 멘토와의 상담 시 참고될 항목들입니다.<br>
                        </br>
                        빠짐 없이 작성해주시면 감사하겠습니다.
                    </div>
                    <div className={styles.beforeUniversity}>
                        {/* <div className={styles.beforeUniversityTitle}>
                            전적대학
                        </div> */}

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                이름
                            </div>
                            <div className={styles.textField}>
                                <TextField value={name} onChange={e => { change(e, "name") }} fullWidth id="outlined-basic" placeholder="ex)김민수" variant="outlined" />
                            </div>
                        </div>
                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                생년월일
                            </div>
                            <div className={styles.textField}>
                                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        value={birthday}
                                        onChange={birthdayChange}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                출신 고등학교 (특목고일 경우 함께 명시)
                            </div>
                            <div className={styles.textField}>
                                <TextField value={highschool} onChange={e => { change(e, "highschool") }} fullWidth id="outlined-basic" placeholder="ex)휘문고등학교" variant="outlined" />
                            </div>
                        </div>
                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                내신등급
                            </div>
                            <div className={styles.textField}>
                                <TextField value={highschoolGrade} onChange={e => { change(e, "highschoolGrade") }} fullWidth id="outlined-basic" placeholder="" variant="outlined" />
                            </div>
                        </div>
                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                수능 준비 횟수
                            </div>
                            <div className={styles.textField}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={suneungNumber}
                                        onChange={suneungNumberChange}
                                    >
                                        <FormControlLabel value={0} control={<Radio />} label={<span className={styles.justFont}>현역</span>} />
                                        <FormControlLabel value={1} control={<Radio />} label={<span className={styles.justFont}>재수</span>} />
                                        <FormControlLabel value={2} control={<Radio />} label={<span className={styles.justFont}>삼수</span>} />
                                        <FormControlLabel value={3} control={<Radio />} label={<span className={styles.justFont}>사수</span>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                지원 예정 모집 유형 (복수 체크)
                            </div>
                            <div className={styles.textField}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={applyKind[0]} onChange={(e : any) => {applyKindChange(e, 0);}} />} label={<span className={styles.justFont}>정시</span>} />
                                    <FormControlLabel control={<Checkbox checked={applyKind[1]} onChange={(e : any) => {applyKindChange(e, 1);}} />} label={<span className={styles.justFont}>수시 (학종)</span>} />
                                    <FormControlLabel control={<Checkbox checked={applyKind[2]} onChange={(e : any) => {applyKindChange(e, 2);}} />} label={<span className={styles.justFont}>수시 (교과)</span>} />
                                    <FormControlLabel control={<Checkbox checked={applyKind[3]} onChange={(e : any) => {applyKindChange(e, 3);}} />} label={<span className={styles.justFont}>수시 (논술)</span>} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                (반수생일 경우) 현재 재학 중인 대학교, 학과
                            </div>
                            <div className={styles.textField}>
                                <TextField value={university} onChange={e => { change(e, "university") }} fullWidth id="outlined-basic" placeholder="ex) 경희대학교 생물학과 1-1학기 재학 중" variant="outlined" />
                            </div>
                        </div>
                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                계열
                            </div>
                            <div className={styles.textField}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={system}
                                        onChange={systemChange}
                                    >
                                        <FormControlLabel value={0} control={<Radio />} label={<span className={styles.justFont}>문과</span>} />
                                        <FormControlLabel value={1} control={<Radio />} label={<span className={styles.justFont}>이과</span>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                국어 선택과목 (2023학년도 응시 예정 기준)
                            </div>
                            <div className={styles.textField}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={koreanKind}
                                        onChange={koreanKindChange}
                                    >
                                        <FormControlLabel value={0} control={<Radio />} label={<span className={styles.justFont}>언어와 매체</span>} />
                                        <FormControlLabel value={1} control={<Radio />} label={<span className={styles.justFont}>화법과 작문</span>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                수학 선택과목 (2023학년도 응시 예정 기준)
                            </div>
                            <div className={styles.textField}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={mathKind}
                                        onChange={mathKindChange}
                                    >
                                        <FormControlLabel value={0} control={<Radio />} label={<span className={styles.justFont}>확률과 통계</span>} />
                                        <FormControlLabel value={1} control={<Radio />} label={<span className={styles.justFont}>미적분</span>} />
                                        <FormControlLabel value={2} control={<Radio />} label={<span className={styles.justFont}>기하</span>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                (문과) 사탐 선택과목 2개 (2023학년도 응시 예정 기준)
                            </div>
                            <div className={styles.textField}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={satamKind[0]} onChange={(e : any) => {satamKindChange(e, 0);}} />} label={<span className={styles.justFont}>해당없음</span>} />
                                    <FormControlLabel control={<Checkbox checked={satamKind[1]} onChange={(e : any) => {satamKindChange(e, 1);}} />} label={<span className={styles.justFont}>생활과 윤리</span>} />
                                    <FormControlLabel control={<Checkbox checked={satamKind[2]} onChange={(e : any) => {satamKindChange(e, 2);}} />} label={<span className={styles.justFont}>윤리와 사상</span>} />
                                    <FormControlLabel control={<Checkbox checked={satamKind[3]} onChange={(e : any) => {satamKindChange(e, 3);}} />} label={<span className={styles.justFont}>한국지리</span>} />
                                    <FormControlLabel control={<Checkbox checked={satamKind[4]} onChange={(e : any) => {satamKindChange(e, 4);}} />} label={<span className={styles.justFont}>세계사</span>} />
                                    <FormControlLabel control={<Checkbox checked={satamKind[5]} onChange={(e : any) => {satamKindChange(e, 5);}} />} label={<span className={styles.justFont}>세계지리</span>} />
                                    <FormControlLabel control={<Checkbox checked={satamKind[6]} onChange={(e : any) => {satamKindChange(e, 6);}} />} label={<span className={styles.justFont}>동아시아사</span>} />
                                    <FormControlLabel control={<Checkbox checked={satamKind[7]} onChange={(e : any) => {satamKindChange(e, 7);}} />} label={<span className={styles.justFont}>법과 정치</span>} />
                                    <FormControlLabel control={<Checkbox checked={satamKind[8]} onChange={(e : any) => {satamKindChange(e, 8);}} />} label={<span className={styles.justFont}>사회 문화</span>} />
                                </FormGroup>
                            </div>
                        </div>

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                (이과) 과탐 선택과목 2개 (2023학년도 응시 예정 기준)
                            </div>
                            <div className={styles.textField}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={gatamKind[0]} onChange={(e : any) => {gatamKindChange(e, 0);}} />} label={<span className={styles.justFont}>해당없음</span>} />
                                    <FormControlLabel control={<Checkbox checked={gatamKind[1]} onChange={(e : any) => {gatamKindChange(e, 1);}} />} label={<span className={styles.justFont}>물리1</span>} />
                                    <FormControlLabel control={<Checkbox checked={gatamKind[2]} onChange={(e : any) => {gatamKindChange(e, 2);}} />} label={<span className={styles.justFont}>화학1</span>} />
                                    <FormControlLabel control={<Checkbox checked={gatamKind[3]} onChange={(e : any) => {gatamKindChange(e, 3);}} />} label={<span className={styles.justFont}>생명과학1</span>} />
                                    <FormControlLabel control={<Checkbox checked={gatamKind[4]} onChange={(e : any) => {gatamKindChange(e, 4);}} />} label={<span className={styles.justFont}>지구과학1</span>} />
                                    <FormControlLabel control={<Checkbox checked={gatamKind[5]} onChange={(e : any) => {gatamKindChange(e, 5);}} />} label={<span className={styles.justFont}>물리2</span>} />
                                    <FormControlLabel control={<Checkbox checked={gatamKind[6]} onChange={(e : any) => {gatamKindChange(e, 6);}} />} label={<span className={styles.justFont}>화학2</span>} />
                                    <FormControlLabel control={<Checkbox checked={gatamKind[7]} onChange={(e : any) => {gatamKindChange(e, 7);}} />} label={<span className={styles.justFont}>생명과학2</span>} />
                                    <FormControlLabel control={<Checkbox checked={gatamKind[8]} onChange={(e : any) => {gatamKindChange(e, 8);}} />} label={<span className={styles.justFont}>지구과학2</span>} />
                                </FormGroup>
                            </div>
                        </div>

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                 프리패스 이용사
                            </div>
                            <div className={styles.textField}>
                                <TextField value={freepassCor} onChange={e => { change(e, "freepassCor") }} fullWidth id="outlined-basic" placeholder="ex) 메가스터디, 대성, 이투스 중 이용하고 있는 회사" variant="outlined" />
                            </div>
                        </div>

                        {/* <div className={styles.twoTextField}>
                            <div className={styles.firstTextField}>
                                <div className={styles.subtitle}>
                                    학과
                                </div>
                                <div className={styles.textField}>
                                    <TextField value={major} onChange={e => { change(e, "major") }} fullWidth id="outlined-basic" placeholder="컴퓨터공학과" variant="outlined" />
                                </div>
                            </div>
                            <div className={styles.secondTextField}>
                                <div className={styles.subtitle}>
                                    이수학기
                                </div>
                                <div className={styles.textField}>
                                    <TextField fullWidth value={semester} onChange={e => { change(e, "semester") }} id="outlined-basic" placeholder="2학년 2학기" variant="outlined" />
                                </div>
                            </div>
                        </div>
                        <div className={styles.twoTextField}>
                            <div className={styles.firstTextField}>
                                <div className={styles.subtitle}>
                                    학력상태
                                </div>
                                <div className={styles.textField}>
                                    <TextField fullWidth value={schoolStatus} onChange={e => { change(e, "schoolStatus") }} id="outlined-basic" placeholder="휴학중" variant="outlined" />
                                </div>
                            </div>
                            <div className={styles.secondTextField}>
                                <div className={styles.subtitle}>
                                    학점
                                </div>
                                <div className={styles.textField}>
                                    <TextField value={degree} onChange={e => { change(e, "degree") }} fullWidth id="outlined-basic" placeholder="4.2/4.5" variant="outlined" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.beforeUniversity} ${styles.secondDiv}`}>
                        <div className={styles.beforeUniversityTitle}>
                            영어 및 고등학교
                        </div>

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                공인영어 점수
                            </div>
                            <div className={styles.textField}>
                                <TextField value={toeic} onChange={e=>{change(e, "toeic")}}   fullWidth id="outlined-basic" placeholder="토익 850점" variant="outlined" />
                            </div>
                        </div>

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                공인영어 점수 취득 날짜
                            </div>
                            <div className={styles.textField}>
                                <TextField value={whenToeic} onChange={e=>{change(e, "whenToeic")}}  fullWidth id="outlined-basic" placeholder="2021.3" variant="outlined" />
                            </div>
                        </div>

                        <div className={styles.radioGroup}>
                            <div className={styles.subtitle}>
                                고등학교 계열
                            </div>
                            <RadioGroup row aria-label="gender" name="row-radio-buttons-group" value={highschoolKind}  onChange={e=>{change(e, "highschoolKind")}} >
                                <FormControlLabel value="이과" control={<Radio sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 28,
                                    },
                                }} />} label={<div className={styles.radioText}>이과</div>} />
                                <FormControlLabel value="문과" control={<Radio
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 28,
                                        },
                                    }}
                                />} label={<div className={styles.radioText}>문과</div>} />
                                <FormControlLabel value="기타" control={<Radio
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 28,
                                        },
                                    }}
                                />} label={<div className={styles.radioText}>기타</div>} />
                            </RadioGroup>
                        </div>

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                수학등급
                            </div>
                            <div className={styles.textField}>
                                <TextField fullWidth id="outlined-basic" value={mathGrade} onChange={e=>{change(e, "mathGrade")}}  placeholder="2등급" variant="outlined" />
                            </div>
                        </div>

                        <div className={styles.radioDiv}>
                            <div className={styles.subtitle}>
                                과탐 선택과목
                            </div>
                            <div>
                                <FormGroup>
                                    <div className={styles.radiosetDiv}>
                                        <div className={`${styles.radioset} ${styles.radioset1}`}>
                                            <FormControlLabel control={<Checkbox value={whatScienceChecked[0]} checked={whatScienceChecked[0]} onChange={(e)=>{change(e, "whatScience-0")}} />} label={<div className={styles.formcontroldiv}>물리1</div>} />
                                            <TextField value={scienceGrade[0]} onChange={(e)=>{change(e, "scienceGrade-0")}} id="standard-basic" placeholder="등급" variant="standard" />
                                        </div>
                                        <div className={`${styles.radioset} ${styles.radioset2}`}>
                                            <FormControlLabel control={<Checkbox value={whatScienceChecked[1]}  checked={whatScienceChecked[1]} onChange={(e)=>{change(e, "whatScience-1")}} />} label={<div className={styles.formcontroldiv}>물리2</div>} />
                                            <TextField value={scienceGrade[1]} onChange={(e)=>{change(e, "scienceGrade-1")}}  id="standard-basic" placeholder="등급" variant="standard" />
                                        </div>
                                    </div>
                                    <div className={styles.radiosetDiv}>
                                        <div className={`${styles.radioset} ${styles.radioset1}`}>
                                            <FormControlLabel control={<Checkbox value={whatScienceChecked[2]} checked={whatScienceChecked[2]}  onChange={(e)=>{change(e, "whatScience-2")}} />} label={<div className={styles.formcontroldiv}>화학1</div>} />
                                            <TextField value={scienceGrade[2]} onChange={(e)=>{change(e, "scienceGrade-2")}}  id="standard-basic" placeholder="등급" variant="standard" />
                                        </div>
                                        <div className={`${styles.radioset} ${styles.radioset2}`}>
                                            <FormControlLabel control={<Checkbox value={whatScienceChecked[3]}  checked={whatScienceChecked[3]} onChange={(e)=>{change(e, "whatScience-3")}} />} label={<div className={styles.formcontroldiv}>화학2</div>} />
                                            <TextField value={scienceGrade[3]} onChange={(e)=>{change(e, "scienceGrade-3")}}  id="standard-basic" placeholder="등급" variant="standard" />
                                        </div>
                                    </div>
                                    <div className={styles.radiosetDiv}>
                                        <div className={`${styles.radioset} ${styles.radioset1}`}>
                                            <FormControlLabel control={<Checkbox value={whatScienceChecked[4]}  checked={whatScienceChecked[4]} onChange={(e)=>{change(e, "whatScience-4")}} />} label={<div className={styles.formcontroldiv}>지학1</div>} />
                                            <TextField value={scienceGrade[4]} onChange={(e)=>{change(e, "scienceGrade-4")}}  id="standard-basic" placeholder="등급" variant="standard" />
                                        </div>
                                        <div className={`${styles.radioset} ${styles.radioset2}`}>
                                            <FormControlLabel control={<Checkbox value={whatScienceChecked[5]}  checked={whatScienceChecked[5]} onChange={(e)=>{change(e, "whatScience-5")}} />} label={<div className={styles.formcontroldiv}>지학2</div>} />
                                            <TextField value={scienceGrade[5]} onChange={(e)=>{change(e, "scienceGrade-5")}}  id="standard-basic" placeholder="등급" variant="standard" />
                                        </div>
                                    </div>
                                    <div className={styles.radiosetDiv}>
                                        <div className={`${styles.radioset} ${styles.radioset1}`}>
                                            <FormControlLabel control={<Checkbox value={whatScienceChecked[6]}  checked={whatScienceChecked[6]} onChange={(e)=>{change(e, "whatScience-6")}} />} label={<div className={styles.formcontroldiv}>생물1</div>} />
                                            <TextField value={scienceGrade[6]} onChange={(e)=>{change(e, "scienceGrade-6")}}  id="standard-basic" placeholder="등급" variant="standard" />
                                        </div>
                                        <div className={`${styles.radioset} ${styles.radioset2}`}>
                                            <FormControlLabel control={<Checkbox value={whatScienceChecked[7]}  checked={whatScienceChecked[7]} onChange={(e)=>{change(e, "whatScience-7")}} />} label={<div className={styles.formcontroldiv}>생물2</div>} />
                                            <TextField  value={scienceGrade[7]} onChange={(e)=>{change(e, "scienceGrade-7")}} id="standard-basic" placeholder="등급" variant="standard" />
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
 */}
                        <div className={styles.nextBtnDiv}>
                            <div onClick={submit} className={styles.nextBtn}>
                                    {loading ? <CircularProgress style={{color : "white"}} /> : <div>{
                                        props.selectedUser ? "다음" : "저장 후 다음"
                                    }</div>}
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileFirst;