import React, { useState, useEffect, useRef } from "react";
import styles from "./mobilesignupstyle.module.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { useHistory } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useIonAlert, IonAlert, IonLoading } from "@ionic/react";
import { Helmet } from "react-helmet";
import AspectRatio from "@mui/joy/AspectRatio";
import { CssVarsProvider } from "@mui/joy/styles";



const MobileSignUp: React.FC<any> = (props) => {

    const [showAlert, setShowAlert] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const [presentAlert] = useIonAlert();
    const history = useHistory();

    const [platform, setPlatform] = useState("");



    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verifiedNumber, setVerifiedNumber] = useState("");
    const [value, setValue] = useState<any>("student");
    const [studentPhoneNumber, setStudentPhoneNumber] = useState("");
    const [forParentPassword, setForParentPassword] = useState("");
    const [forStaffPassword, setForStaffPassword] = useState("");
    const [forStudentPassword, setForStudentPassword] = useState("");

    const [certBtnActive, setCertBtnActive] = useState(false);

    const [focus, setFocus] = useState([false, false, false, false, false, false, false]);

    const [isParent, setIsParent] = useState(false);
    const [isTeacher, setIsTeacher] = useState(false);
    const [isStudent, setIsStudent] = useState(true);

    const [emailError, setEmailError] = useState(false);
    const [duplicatedEmailError, setDuplicatedEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [verifyPasswordError, setVerifyPasswordError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [verifiedNumberError, setVerifiedNumberError] = useState(false);
    const [expiredVerifyNumberError, setExpiredVerifyNumberError] = useState(false);
    const [studentPhoneNumberError, setStudentPhoneNumberError] = useState(false);
    const [forParentPasswordError, setForParentPasswordError] = useState(false);
    const [forStaffPasswordError, setForStaffPasswordError] = useState(false);
    const [forStudentPasswordError, setForStudentPasswordError] = useState(false);

    const [sendCertBool, setSendCertBool] = useState(false);

    const [height, setHeight] = useState(0);
    const headerRef = useRef<any>();



    useEffect(() => {


        if (!headerRef || !headerRef.current) {
            console.log("noRef");
            return;
        }

        const calcBodyHeight = () => {

            if (!headerRef || !headerRef.current) {
                console.log("noRef");
                return;
            }

            console.log("gogogo");

            const totalHeight = window.innerHeight;
            const safeAreaTop = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const headerHeight = headerRef.current.offsetHeight;
            const bodyHeight = totalHeight - headerHeight - safeAreaTop;

            console.log(bodyHeight);
            console.log("headerHeight");
            console.log(headerHeight);
            setHeight(bodyHeight);
        }

        calcBodyHeight();

        window.addEventListener("resize", () => calcBodyHeight);

        return () => window.removeEventListener("resize", () => calcBodyHeight);

    }, [headerRef]);

    useEffect(() => {

        console.log("hello world !");

        //backbutton 눌리면 console.log("pressed") 뜨게 하기
        const handleBackButton = () => {
            console.log("pressed");
            history.goBack();
        }

        //backbutton 눌리면 handleBackButton 실행
        document.addEventListener("backbutton", handleBackButton);

        return () => {
            document.removeEventListener("backbutton", handleBackButton);
        }

    }, []);





    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

        console.log(platform);
        console.log(platform === "ios");

    }, [])


    //가입하기 버튼 눌렀을 때 서버로 데이터 전송------------------------------------------//
    const submit = (e: any) => {

        setShowLoading(true);
        e.preventDefault();

        setEmailError(false);
        setPasswordError(false);
        setVerifyPasswordError(false);
        setNameError(false);
        setPhoneNumberError(false);
        setVerifiedNumberError(false);
        setDuplicatedEmailError(false);
        setExpiredVerifyNumberError(false);
        setStudentPhoneNumberError(false);
        setForParentPasswordError(false);
        setForStaffPasswordError(false);
        setForStudentPasswordError(false);


        fetch("https://peetsunbae.com/signup/submit", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email.split(" ").join(""),
                password: password,
                verifyPassword: verifyPassword,
                name: name,
                phoneNumber: phoneNumber,
                verifiedNumber: verifiedNumber,
                value: value,
                studentPhoneNumber: studentPhoneNumber,
                forParentPassword: forParentPassword,
                forStaffPassword: forStaffPassword,
                forStudentPassword: forStudentPassword
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    setShowLoading(false);
                    console.log(result);
                    //invalid email 일 경우 --------------------------------------------------------
                    if (result.message === "NOT_VALID_EMAIL") {
                        setEmailError(true);
                        presentAlert({
                            header: "이메일 주소 에러",
                            message: "유효하지 않은 이메일 주소 입니다.",
                            buttons: ["확인"]
                        })
                    }
                    //------------------------------------------------------------------------------
                    //중복된 email 적었을 경우-------------------------------------------------------
                    if (result.message === "DUP_EMAIL_ADDRESS") {
                        setDuplicatedEmailError(true);
                        presentAlert({
                            header: "중복된 이메일 주소",
                            message: "중복된 이메일 주소입니다.",
                            buttons: ["확인"]
                        })
                    }
                    //-----------------------------------------------------------------------------
                    //8자리 미만 비밀번호 적었을 경우-------------------------------------------------
                    if (result.message === "NOT_VALID_PASSWORD") {
                        setPasswordError(true);
                        presentAlert({
                            header: "비밀번호 에러",
                            message: "8자리 이상 비밀번호를 적어주세요.",
                            buttons: ["확인"]
                        })
                    }
                    //-------------------------------------------------------------------------------
                    //비밀번호 확인이랑 비밀번호 같지 않을 경우-----------------------------------------
                    if (result.message === "NOT_COINCIDE_PASSWORD") {
                        setVerifyPasswordError(true);
                        presentAlert({
                            header: "비밀번호 확인 에러",
                            message: "비밀번호 확인이 맞지 않습니다.",
                            buttons: ["확인"]
                        })
                    }
                    //-------------------------------------------------------------------------------
                    //이름 안적었을 때 처리-------------------------------------------------------------
                    if (result.message === "NOT_VALID_NAME") {
                        setNameError(true);
                        presentAlert({
                            header: "이름 에러",
                            message: "이름을 적어주세요.",
                            buttons: ["확인"]
                        })
                    }
                    //---------------------------------------------------------------------------------
                    //폰번호에 - 포함되어있을 때 처리--------------------------------------------------
                    if (result.message === "NOT_VALID_PHONENUMBER") {
                        setPhoneNumberError(true);
                        presentAlert({
                            header: "폰번호 에러",
                            message: "번호에서 -를 제거해주세요.",
                            buttons: ["확인"]
                        })
                    }
                    //-------------------------------------------------------------------------------
                    //전송한 인증번호랑 같지 않을 때 처리-----------------------------------------------
                    if (result.message === "NOT_COINCIDE_CERT" || result.message === "GET_CERT_NUMBER") {
                        setVerifiedNumberError(true);
                        presentAlert({
                            header: "인증번호 에러",
                            message: "인증번호를 확인해주세요.",
                            buttons: ["확인"]
                        })
                    }
                    //--------------------------------------------------------------------------------
                    //인증번호 유효시간 지났을 때 처리--------------------------------------------------
                    if (result.message === "CERT_IS_EXPIRED") {
                        setExpiredVerifyNumberError(true);
                        presentAlert({
                            header: "인증번호 유효시간 만료",
                            message: "인증번호를 다시 발급해주세요.",
                            buttons: ["확인"]
                        })
                    }
                    //---------------------------------------------------------------------------------
                    //학부모일 경우 학생 핸드폰 번호 있는지 확인------------------------------------------
                    if (result.message === "NO_STUDENT_PHONENUMBER") {
                        setStudentPhoneNumberError(true);
                        presentAlert({
                            header: "학생 번호 에러",
                            message: "해당 학생번호가 존재하지 않습니다.",
                            buttons: ["확인"]
                        })
                    }
                    //--------------------------------------------------------------------------------

                    //학부모일 경우 비밀번호 맞는지 확인---------------------------------------------
                    if (result.message === "NOT_CORRECT_PARENT_PASSWORD") {
                        setForParentPasswordError(true);
                        presentAlert({
                            header: "학부모 비밀번호 에러",
                            message: "잘못된 학부모 비밀번호 입니다.",
                            buttons: ["확인"]
                        })
                    }

                    //담임선생님일 경우 비밀번호 맞는지 확인---------------------------------------------
                    if (result.message === "NOT_CORRECT_PASSWORD") {
                        setForStaffPasswordError(true);
                        presentAlert({
                            header: "직원 비밀번호 에러",
                            message: "잘못된 직원 비밀번호 입니다.",
                            buttons: ["확인"]
                        })
                    }
                    //------------------------------------------------------------------------------

                    //학생일 경우 비밀번호 맞는지 확인------------------------------------------------
                    if (result.message === "NOT_CORRECT_STUDENT_PASSWORD") {
                        setForStudentPasswordError(true);
                        presentAlert({
                            header: "학생 비밀번호 에러",
                            message: "잘못된 학생 비밀번호 입니다.",
                            buttons: ["확인"]
                        })
                    }


                    //회원가입 성공했을 경우 로그인 페이지로 이동---------------------------------------
                    if (result.message === "success") {
                        setShowAlert(true);
                    }
                    //--------------------------------------------------------------------------------
                })
        }).catch((error: any) => {
            console.log(error);
        })
    }
    //-----------------------------------------------------------------

    //인증번호 요청----------------------------------------------------------------------------

    const sendCert = (e: any) => {
        e.preventDefault();
        setSendCertBool(true);

        fetch("https://peetsunbae.com/signup/cert", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                phoneNumber: phoneNumber
            })
        }).then((result) => {
            result.json()
                .then((body) => {
                    console.log(body);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    //-----------------------------------------------------------------------------------------

    //라디오버튼 눌렀을 때 처리-------------------------------------------------------------------
    const handleChange = (event: any) => {
        setIsParent(false);
        setIsTeacher(false);
        setIsStudent(false);

        setValue(event.target.value);
        if (event.target.value === "parent") {
            setIsParent(true);
        }
        if (event.target.value === "teacher") {
            setIsTeacher(true);
        }
        if (event.target.value === "student") {
            setIsStudent(true);
        }
    };
    //------------------------------------------------------------------------------------------


    const handleFocus = (e: any, number: number) => {
        const newFocus = focus;
        newFocus[number] = true;
        setFocus([...newFocus]);
    }

    const handleBlur = (e: any, number: number) => {
        const newFocus = focus;
        newFocus[number] = false;
        setFocus([...newFocus]);
    }


    return (

        <div className={`${styles.main} ${platform === "ios" ? styles.ios : ""}`} style={{ paddingTop: "env(safe-area-inset-top)" }}>
            <Helmet>
                <meta name="viewport" content="initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width, viewport-fit=cover" />
            </Helmet>

            <div className={styles.headerDiv} ref={headerRef}>
                <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`}>
                    <div className={styles.backBtnDiv}
                        onClick={() => { history.goBack(); }}
                    >
                        <ArrowLeftLight
                            className={styles.arrowLeft}
                        />
                    </div>
                    <div className={styles.titleText}>
                        회원가입
                    </div>
                </div>
            </div>

            <div className={styles.bodyDiv} style={{height : `${height}px`, overflow : "scroll"}}>
                <div className={styles.body}>
                    <CssVarsProvider>
                        <div className={`${styles.section} ${styles.first}`}>
                            <div className={styles.sectionTitle}>
                                정보 입력
                            </div>

                            <div className={`${styles.inputDiv} ${styles.first}`}>
                                <div className={styles.inputTitle}>
                                    이메일 주소
                                </div>
                                <AspectRatio ratio="100/13.826" variant="plain">
                                    <div className={`${styles.inputWrapper} ${focus[0] ? styles.active : ""} ${emailError || duplicatedEmailError ? styles.error : ""}`}>
                                        <input value={email} onChange={(e: any) => { setEmail(e.target.value) }} onFocus={(e: any) => { handleFocus(e, 0) }} onBlur={(e: any) => { handleBlur(e, 0) }} type="email" className={styles.input} placeholder="이메일 주소 입력" />
                                    </div>
                                </AspectRatio>
                            </div>

                            <div className={`${styles.inputDiv} ${styles.second}`}>
                                <div className={styles.inputTitle}>
                                    비밀번호
                                </div>
                                <AspectRatio ratio="100/13.826" variant="plain">
                                    <div className={`${styles.inputWrapper} ${focus[1] ? styles.active : ""} ${passwordError ? styles.error : ""}`}>
                                        <input value={password} onChange={(e: any) => { setPassword(e.target.value) }} onFocus={(e: any) => { handleFocus(e, 1) }} onBlur={(e: any) => { handleBlur(e, 1) }} type="password" className={styles.input} placeholder="8~20자 입력" />
                                    </div>
                                </AspectRatio>
                            </div>

                            <div className={`${styles.inputDiv} ${styles.second}`}>
                                <div className={styles.inputTitle}>
                                    비밀번호 확인
                                </div>
                                <AspectRatio ratio="100/13.826" variant="plain">
                                    <div className={`${styles.inputWrapper} ${focus[2] ? styles.active : ""} ${verifyPasswordError ? styles.error : ""}`}>
                                        <input value={verifyPassword} onChange={(e: any) => { setVerifyPassword(e.target.value) }} onFocus={(e: any) => { handleFocus(e, 2) }} onBlur={(e: any) => { handleBlur(e, 2) }} type="password" className={styles.input} placeholder="비밀번호 확인 입력" />
                                    </div>
                                </AspectRatio>
                            </div>
                        </div>
                    </CssVarsProvider>
                    <div className={`${styles.section} ${styles.second}`}>
                        <CssVarsProvider>
                            <div className={styles.sectionTitle}>
                                상세 정보
                            </div>

                            <div className={`${styles.inputDiv} ${styles.first}`}>
                                <div className={styles.inputTitle}>
                                    이름
                                </div>
                                <AspectRatio ratio="100/13.826" variant="plain">
                                    <div className={`${styles.inputWrapper} ${focus[3] ? styles.active : ""} ${nameError ? styles.error : ""}`}>
                                        <input value={name} onChange={(e: any) => { setName(e.target.value) }} onFocus={(e: any) => { handleFocus(e, 3) }} onBlur={(e: any) => { handleBlur(e, 3) }} type="text" className={styles.input} placeholder="이름을 입력해주세요" />
                                    </div>
                                </AspectRatio>
                            </div>

                            <div className={`${styles.inputDiv} ${styles.second}`}>
                                <div className={styles.inputTitle}>
                                    핸드폰 번호
                                </div>
                                <AspectRatio ratio="100/13.826" variant="plain">
                                    <div className={styles.inputGridWrapper}>
                                        <div className={styles.inputGrid}>
                                            <div style={{ height: "100%" }} className={`${styles.inputWrapper} ${focus[4] ? styles.active : ""} ${phoneNumberError ? styles.error : ""}`}>
                                                <input value={phoneNumber} onChange={(e: any) => { if (e.target.value.length === 11) { setCertBtnActive(true) } else { setCertBtnActive(false) }; setPhoneNumber(e.target.value); }} onFocus={(e: any) => { handleFocus(e, 4) }} onBlur={(e: any) => { handleBlur(e, 4) }} type="tel" className={styles.input} placeholder="'-' 없이 입력하세요" />
                                            </div>
                                            <div onClick={sendCert} style={{ height: "100%" }} className={`${styles.certBtn} ${certBtnActive ? styles.active : ""}`}>
                                                인증번호
                                            </div>
                                        </div>
                                    </div>
                                </AspectRatio>
                            </div>

                            {
                                sendCertBool &&
                                <div className={`${styles.inputDiv} ${styles.second}`}>
                                    <div className={styles.inputTitle}>
                                        인증번호
                                    </div>
                                    <AspectRatio ratio="100/13.826" variant="plain">
                                        <div className={`${styles.inputWrapper} ${focus[5] ? styles.active : ""}  ${verifiedNumberError || expiredVerifyNumberError ? styles.error : ""}`}>
                                            <input value={verifiedNumber} onChange={(e: any) => { setVerifiedNumber(e.target.value) }} onFocus={(e: any) => { handleFocus(e, 5) }} onBlur={(e: any) => { handleBlur(e, 5) }} type="tel" className={styles.input} placeholder="인증번호를 입력해주세요" />
                                        </div>
                                    </AspectRatio>
                                </div>
                            }

                        </CssVarsProvider>

                        <div className={styles.radioDiv}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="student" control={<Radio />} label={<span className={styles.radioLabel}>학생</span>} />
                                <FormControlLabel value="parent" control={<Radio />} label={<span className={styles.radioLabel}>학부모</span>} />
                                <FormControlLabel value="teacher" control={<Radio />} label={<span className={styles.radioLabel}>담임선생님</span>} />
                            </RadioGroup>
                        </div>

                        <CssVarsProvider>
                            {
                                isParent &&
                                <div className={`${styles.inputDiv} ${styles.second}`}>
                                    <div className={styles.inputTitle}>
                                        학생 핸드폰 번호
                                    </div>
                                    <AspectRatio ratio="100/13.826" variant="plain">
                                        <div className={`${styles.inputWrapper} ${focus[6] ? styles.active : ""}  ${studentPhoneNumberError ? styles.error : ""}`}>
                                            <input value={studentPhoneNumber} onChange={(e: any) => { setStudentPhoneNumber(e.target.value) }} onFocus={(e: any) => { handleFocus(e, 6) }} onBlur={(e: any) => { handleBlur(e, 6) }} type="tel" className={styles.input} placeholder="'-' 없이 입력" />
                                        </div>
                                    </AspectRatio>
                                </div>
                            }
                            {
                                isParent &&
                                <div className={`${styles.inputDiv} ${styles.second}`}>
                                    <div className={styles.inputTitle}>
                                        학부모 가입용 비밀번호
                                    </div>
                                    <AspectRatio ratio="100/13.826" variant="plain">
                                        <div className={`${styles.inputWrapper} ${focus[6] ? styles.active : ""}  ${forParentPasswordError ? styles.error : ""}`}>
                                            <input value={forParentPassword} onChange={(e: any) => { setForParentPassword(e.target.value) }} onFocus={(e: any) => { handleFocus(e, 6) }} onBlur={(e: any) => { handleBlur(e, 6) }} type="tel" className={styles.input} placeholder="안내 받은 비밀번호를 입력하세요" />
                                        </div>
                                    </AspectRatio>
                                </div>
                            }

                            {
                                isTeacher &&
                                <div className={`${styles.inputDiv} ${styles.second}`}>
                                    <div className={styles.inputTitle}>
                                        직원 가입 비밀번호
                                    </div>
                                    <AspectRatio ratio="100/13.826" variant="plain">
                                        <div className={`${styles.inputWrapper} ${focus[7] ? styles.active : ""}  ${forStaffPasswordError ? styles.error : ""}`}>
                                            <input value={forStaffPassword} onChange={(e: any) => { setForStaffPassword(e.target.value) }} onFocus={(e: any) => { handleFocus(e, 7) }} onBlur={(e: any) => { handleBlur(e, 7) }} type="tel" className={styles.input} placeholder="직원 가입 비밀번호 입력하세요" />
                                        </div>
                                    </AspectRatio>
                                </div>
                            }

                            {
                                isStudent &&
                                <div className={`${styles.inputDiv} ${styles.second}`}>
                                    <div className={styles.inputTitle}>
                                        학생 가입 비밀번호
                                    </div>
                                    <AspectRatio ratio="100/13.826" variant="plain">
                                        <div className={`${styles.inputWrapper} ${focus[7] ? styles.active : ""}  ${forStudentPasswordError ? styles.error : ""}`}>
                                            <input value={forStudentPassword} onChange={(e: any) => { setForStudentPassword(e.target.value) }} onFocus={(e: any) => { handleFocus(e, 7) }} onBlur={(e: any) => { handleBlur(e, 7) }} type="tel" className={styles.input} placeholder="학생 가입 비밀번호 입력하세요" />
                                        </div>
                                    </AspectRatio>
                                </div>
                            }
                        </CssVarsProvider>
                    </div>

                    <div className={styles.loginTextDiv}>
                        이미 가입하셨나요?<span className={styles.goLogin} onClick={() => { history.push("/") }}>로그인</span>
                    </div>

                    <div className={styles.submitBtnDiv}>
                        <CssVarsProvider>
                            <AspectRatio ratio="100/16.226" variant="plain">
                                <div onClick={submit} className={styles.submitBtn}>
                                    가입하기
                                </div>
                            </AspectRatio>
                        </CssVarsProvider>

                    </div>
                </div>
            </div>

            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'잠시만 기다려주세요'}
            />

            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => history.push("/")}
                header="회원가입 성공"
                subHeader="회원가입을 축하드립니다 :)"
                message="로그인 페이지로 이동합니다"
                buttons={["확인"]}
            />
        </div>
    )
}

export default MobileSignUp;