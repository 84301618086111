import { fi } from "date-fns/locale";
import { each } from "lodash";
import { studentList } from "../components/attendancecheck";
import { ClassInfo } from "../../../../../data/locationdata";



// export const classInfo = [
//     {
//         classNumber: "zero",
//         start: {
//             hours: 8,
//             minutes: 0
//         },
//         end: {
//             hours: 8,
//             minutes: 45
//         }

//     },
//     {
//         classNumber: "first",
//         start: {
//             hours: 9,
//             minutes: 0
//         },
//         end: {
//             hours: 10,
//             minutes: 15
//         }
//     },
//     {
//         classNumber: "second",
//         start: {
//             hours: 10,
//             minutes: 30
//         },
//         end: {
//             hours: 11,
//             minutes: 45
//         }
//     },
//     {
//         classNumber: "third",
//         start: {
//             hours: 13,
//             minutes: 0
//         },
//         end: {
//             hours: 14,
//             minutes: 15
//         }
//     },
//     {
//         classNumber: "fourth",
//         start: {
//             hours: 14,
//             minutes: 30
//         },
//         end: {
//             hours: 15,
//             minutes: 45
//         }

//     },
//     {
//         classNumber: "fifth",
//         start: {
//             hours: 16,
//             minutes: 0
//         },
//         end: {
//             hours: 17,
//             minutes: 15
//         }
//     },
//     {
//         classNumber: "sixth",
//         start: {
//             hours: 18,
//             minutes: 30
//         },
//         end: {
//             hours: 19,
//             minutes: 30
//         }

//     },
//     {
//         classNumber: "seventh",
//         start: {
//             hours: 19,
//             minutes: 45
//         },
//         end: {
//             hours: 20,
//             minutes: 45
//         }

//     },
//     {
//         classNumber: "eighth",
//         start: {
//             hours: 21,
//             minutes: 0
//         },
//         end: {
//             hours: 22,
//             minutes: 0
//         }
//     },
//     {
//         classNumber : "lunch",
//         start : {
//             hours : 11,
//             minutes : 45
//         },
//         end : {
//             hours : 13,
//             minutes : 0
//         }
//     },
//     {
//         classNumber : "dinner",
//         start : {
//             hours : 17,
//             minutes : 15
//         },
//         end : {
//             hours : 18,
//             minutes : 30
//         }
//     }

// ];


export const sortStudentList = (studentList: studentList[]) => {


    //studentList의 +seat 순으로 오름차순 정렬한다. seat 값이 없을 경우 맨 밑으로 내린다.
    studentList.sort((a: studentList, b: studentList) => {

        if (!a.seat && !b.seat) {
            return 0;
        }

        if (!a.seat && b.seat) {
            return 1;
        }

        if (a.seat && !b.seat) {
            return -1;
        }

        if (!a.seat) {
            return 0;
        }

        if (!b.seat) {
            return 0;
        }

        if (+a.seat < +b.seat) {
            return -1;
        }

        if (+a.seat > +b.seat) {
            return 1;
        }

        return 0;
    })

    var phoneSubmitTrueCount = 0;

    studentList.forEach((eachStudent: studentList) => {
        if (eachStudent.phoneSubmit) {
            phoneSubmitTrueCount++;
        }
    })


    //studentList의 phoneProblem 크기 순으로 정렬한다. phoneproblem이 없을 경우 맨 밑으로 내린다.
    studentList.sort((a: studentList, b: studentList) => {

        if(phoneSubmitTrueCount === 0){
            return 0;
        }

        if (!a.phoneProblem && !b.phoneProblem) {
            return 0;
        }

        if (!a.phoneProblem && b.phoneProblem) {
            return 1;
        }

        if (a.phoneProblem && !b.phoneProblem) {
            return -1;
        }

        if (!a.phoneProblem) {
            return 0;
        }

        if (!b.phoneProblem) {
            return 0;
        }

        if (a.phoneProblem < b.phoneProblem) {
            return 1;
        }

        if (a.phoneProblem > b.phoneProblem) {
            return -1;
        }

        return 0;
    })

    //(currentStatus === "out" && (!regularJustified && !suddenJustified)) 인 경우를 위로 올린다.
    studentList.sort((a: studentList, b: studentList) => {

        if (a.currentStatus === "out" && (!a.regularJustified && !a.suddenJustified)) {
            if (!(b.currentStatus === "out" && (!b.regularJustified && !b.suddenJustified))) {
                return -1;
            }
        }

        if (!(a.currentStatus === "out" && (!a.regularJustified && !a.suddenJustified))) {
            if (b.currentStatus === "out" && (!b.regularJustified && !b.suddenJustified)) {
                return 1;
            }
        }

        return 0;
    })



    // a와 b가 둘 다 (currentStatus === "out" && (!regularJustified && !suddenJustified)) 이면서 isNever가 true인 경우 위로 올린다.
    studentList.sort((a: studentList, b: studentList) => {

        if ((a.currentStatus === "out" && (!a.regularJustified && !a.suddenJustified)) && (b.currentStatus === "out" && (!b.regularJustified && !b.suddenJustified))) {

            if (a.isNever && !b.isNever) {
                return -1;
            }

            if (!a.isNever && b.isNever) {
                return 1;
            }


        }


        return 0;
    })

    //currentStatus가 in 이거나 regularJustified가 true 이거나 suddenJustified가 true인 애들 중에서 isSuspicious가 true면 위로 올린다.
    studentList.sort((a, b) => {

        if((a.currentStatus === "in" || a.regularJustified || a.suddenJustified) && (b.currentStatus === "in" || b.regularJustified || b.suddenJustified) ){

            if(a.isSuspicious && !b.isSuspicious){
                return -1;
            }

            if(!a.isSuspicious && b.isSuspicious){
                return 1;
            }

        }

        return 0;
    })

}


export const filterStudentList = (studentList: studentList[], targetDate: Date) => {

    var filteredStudentList: studentList[] = [];

    const exceptIdList: number[] = [];

    const targetDateTime = targetDate.getTime();


    studentList.forEach((eachStudent: studentList) => {

        //exceptIdList에 eachStudent.id가 있으면 제외한다.
        if (exceptIdList.includes(eachStudent.id)) {
            return;
        }

        if (eachStudent.firstCome) {

            const firstComeDate = new Date(eachStudent.firstCome);
            const firstComeDateTime = firstComeDate.getTime();

            if (firstComeDateTime > targetDateTime) {
                return;
            }

        }


        filteredStudentList.push(eachStudent);

    })

    return filteredStudentList;

}

export const calcInspectPhone = (studentList: studentList[], phoneInspectData: any, phoneInspectMemoData: any, location = "") => {

    //phoneInspectData를 가지고 studentList의 phoneInspect에 넣는다.

    studentList.forEach((eachStudent: any) => {

        eachStudent.phoneSubmit = false;

        phoneInspectData.forEach((eachPhoneInspectData: boolean, index: number) => {

            const eachPhoneInspectDataSeat = index + 1;

            if (!eachStudent.seat) {
                return;
            }

            var correctionNumber = 0;

            if(location === "daechi2"){
                correctionNumber = 100;
            }

            if(location === "daechi3"){
                correctionNumber = 200;
            }

            if(location === "songdo_free"){

                console.log("songdo_free");
                console.log(eachStudent.phoneSeat);
                console.log(eachPhoneInspectDataSeat + 44, eachPhoneInspectData);

                correctionNumber = 44;
            }

            if (+eachStudent.seat === (eachPhoneInspectDataSeat + correctionNumber) && eachPhoneInspectData && location !== "songdo_free") {
                eachStudent.phoneSubmit = true;
            }

            if(+eachStudent.phoneSeat === (eachPhoneInspectDataSeat + correctionNumber) && eachPhoneInspectData && location === "songdo_free"){
                eachStudent.phoneSubmit = true;
            }
        })
    })

    //eachStudent의 currentStatus가 out 이면서 phoneSubmit이 true인 경우 phoneProblem을 1로 바꾼다.
    //eachStudent의 currentStatus가 in 이면서 phoneSubmit이 false인 경우 phoneProblem을 2로 바꾼다.
    studentList.forEach((eachStudent: any) => {

        eachStudent.phoneProblem = 0;

        if (eachStudent.currentStatus === "out" && eachStudent.phoneSubmit) {
            eachStudent.phoneProblem = 1;
        }

        if (eachStudent.currentStatus === "in" && !eachStudent.phoneSubmit) {
            eachStudent.phoneProblem = 2;
        }

    })

    //eachStudent와 phoneInspectMemoData를 가지고 eachStudent.id와 phoneInspectMemoData.studentId가 같으면 eachStudent.phoneInspectMemo에 phoneInspectMemoData.memo를 넣는다.
    studentList.forEach((eachStudent: any) => {

        console.log("gogogoggogogoo memo");

        phoneInspectMemoData.forEach((eachPhoneInspectMemoData: any) => {

            if (eachStudent.id === eachPhoneInspectMemoData.studentId) {

                console.log("gogogoggogogoo memo2");
                eachStudent.phoneInspectMemo = eachPhoneInspectMemoData.description;
                eachStudent.phoneInspectMessagedSentDate = eachPhoneInspectMemoData.sentMessageTime;
            }

        })

    })

}

export const realtimeAccessControl = (studentList: any) => {

    //studentList.currentStatus가 out인 학생들 중 stuentList.duringClassAccessControl에 한 개라도 direction이 outside 인 것이 있으면 studentList.realTimeStatus를 in으로 바꾼다.
    studentList.forEach((eachStudent: any) => {

        if (eachStudent.currentStatus !== "out") {
            return;
        }

        //eachStudent.realTimeStatus = "out";

        eachStudent.duringClassAccessControl.forEach((eachAccessControl: any) => {

            if (eachAccessControl.direction === "outside") {
                eachStudent.realTimeStatus = "in";
                eachStudent.realTimeByAccess = true;
            }

        })

    })


}

export const calcRegularAttendance = (studentList: any, regularScheduleFormatData: any, targetDate: Date, classNumber: string, classInfo : ClassInfo[]) => {


    //studentList의 currentStatus가 out인 학생들의 regularScheduleFormatData와 비교해 정당하면 regularJustified를 true로 바꾼다.

    studentList.forEach((eachStudent: any) => {

        if (eachStudent.currentStatus !== "out") {
            return;
        }

        eachStudent.regularJustified = false;

        regularScheduleFormatData.forEach((eachRegularScheduleFormat: any) => {

            if (eachRegularScheduleFormat.userId !== eachStudent.id) {
                return;
            }

            if (eachRegularScheduleFormat.staffpermit !== 1) {
                return;
            }

            const data = eachRegularScheduleFormat.data;

            data.forEach((eachData: any) => {


                const parsedDataEach: any = {};
                parsedDataEach.kind = eachData["종류"];
                parsedDataEach.date = eachData["날짜"];

                //eachRegularScheduleFormat.day 가 "etc" 인 경우 parsedDataEach.date가 없는 경우 그냥 넘어간다.
                if (eachRegularScheduleFormat.day === "etc" && !parsedDataEach.date) {
                    return;
                }

                //parsedDataEach.date가 있는 경우 targetDate의 date가 parsedDataEach.date array에 포함이 안되면 넘어간다.
                if (parsedDataEach.date) {

                    const targetDateDate = targetDate.getDate();
                    const targetDateDateToString = `${targetDateDate}`;

                    if (!parsedDataEach.date.includes(targetDateDateToString)) {
                        return;
                    }
                }

                if (eachData["들어오는 시간"]) {

                    //eachData["들어오는 시간"]이 "~시 ~분" 혹은 "~시" 형식인데 이걸 hour, minute으로 나누기
                    const time = eachData["들어오는 시간"].split(" ");
                    if (time.length === 2) {
                        parsedDataEach.hour1 = +time[0].split("시")[0];
                        parsedDataEach.minute1 = +time[1].split("분")[0];
                    } else if (time.length === 1) {
                        parsedDataEach.hour1 = +time[0].split("시")[0];
                        parsedDataEach.minute1 = 0;
                    }
                    //eachData.timeNumber에 시간을 분으로 환산해서 넣기


                    parsedDataEach.timeNumber1 = parsedDataEach.hour1 * 60 + parsedDataEach.minute1;
                }

                if (eachData["나가는 시간"]) {
                    //eachData["나가는 시간"]이 "~시 ~분" 혹은 "~시" 형식인데 이걸 hour, minute으로 나누기
                    const time = eachData["나가는 시간"].split(" ");
                    if (time.length === 2) {
                        parsedDataEach.hour2 = +time[0].split("시")[0];
                        parsedDataEach.minute2 = +time[1].split("분")[0];
                    } else if (time.length === 1) {
                        parsedDataEach.hour2 = +time[0].split("시")[0];
                        parsedDataEach.minute2 = 0;
                    }
                    //eachData.timeNumber에 시간을 분으로 환산해서 넣기
                    parsedDataEach.timeNumber2 = parsedDataEach.hour2 * 60 + parsedDataEach.minute2;
                }

                var targetTimeNumber = 99999999999;

                const classNumberInfo = classInfo.find((eachClassInfo) => eachClassInfo.classNumber === classNumber);

                if (!classNumberInfo) {
                    console.log("classNumberInfo is undefined")
                    return;
                }

                targetTimeNumber = classNumberInfo.start.hours * 60 + classNumberInfo.start.minutes;


                switch (parsedDataEach.kind) {
                    case "지각":
                        if (targetTimeNumber < parsedDataEach.timeNumber1) {

                            eachStudent.regularJustified = true;
                            eachStudent.regularType = "late";

                        }
                        break;
                    case "조퇴":
                        if (targetTimeNumber >= parsedDataEach.timeNumber2) {

                            eachStudent.regularJustified = true;
                            eachStudent.regularType = "early";

                        }
                        break;
                    case "결석":

                        eachStudent.regularJustified = true;
                        eachStudent.regularType = "absent";

                        break;
                    case "외출":
                        if (targetTimeNumber < parsedDataEach.timeNumber1 && targetTimeNumber >= parsedDataEach.timeNumber2) {
                            eachStudent.regularJustified = true;
                            eachStudent.regularType = "among";
                        }

                        break;
                }

            })

        })

    })



}


export const calcOneStudentSuddenAttendance = (eachSuddenNotice: any, classNumber: string, classInfo : ClassInfo[]): boolean => {

    var timeNumber1 = 0;
    var timeNumber2 = 0;

    if (eachSuddenNotice.startHours) {
        timeNumber1 = eachSuddenNotice.startHours * 60 + eachSuddenNotice.startMinutes;
    }

    if (eachSuddenNotice.endHours) {
        timeNumber2 = eachSuddenNotice.endHours * 60 + eachSuddenNotice.endMinutes;
    }

    var targetTimeNumber = 999999999999;

    // scheduleTime.forEach((eachScheduleTime : any) => {
    //     if(eachScheduleTime.class === currentClass){
    //         targetTimeNumber = eachScheduleTime.time;
    //     }
    // })

    //classInfo 데이터를 가지고 targetTimeNumber를 구한다.
    const classNumberInfo = classInfo.find((eachClassInfo) => eachClassInfo.classNumber === classNumber);

    if (!classNumberInfo) {
        console.log("classNumberInfo is undefined")
        return false;
    }

    targetTimeNumber = classNumberInfo.start.hours * 60 + classNumberInfo.start.minutes;

    switch (eachSuddenNotice.type) {
        case "long": //지각이랑 같음
            if (timeNumber1 && targetTimeNumber < timeNumber1) {
                return true;
            }
            break;
        case "among": //외출이랑 같음
            if (timeNumber1 && timeNumber2 && targetTimeNumber >= timeNumber1 && targetTimeNumber < timeNumber2) {
                return true;
            }
            break;
        case "early": //조퇴랑 같음
            if (timeNumber2 && targetTimeNumber >= timeNumber2) {
                return true;
            }
            break;
        case "absent":
            return true;
            break;
    }

    return false;

}





export const calcSuddenAttendance = (studentList: any, suddenNoticeData: any, classNumber: string, targetDate: Date, classInfo : ClassInfo[]) => {

    //console.log(studentList);
    //console.log(suddenNoticeData);

    //studentList의 currentStatus가 out인 학생들의 suddenNoticeData와 비교해 정당하면 suddenJustified를 true로 바꾼다.

    studentList.forEach((eachStudent: studentList) => {

        if (eachStudent.currentStatus !== "out") {
            return;
        }

        eachStudent.suddenJustified = false;

        suddenNoticeData.forEach((eachSuddenNotice: any) => {

            if (eachSuddenNotice.userId === eachStudent.id) {


                var timeNumber1 = 0;
                var timeNumber2 = 0;

                if (eachSuddenNotice.startHours) {
                    timeNumber1 = eachSuddenNotice.startHours * 60 + eachSuddenNotice.startMinutes;
                }

                if (eachSuddenNotice.endHours) {
                    timeNumber2 = eachSuddenNotice.endHours * 60 + eachSuddenNotice.endMinutes;
                }

                var targetTimeNumber = 999999999999;

                // scheduleTime.forEach((eachScheduleTime : any) => {
                //     if(eachScheduleTime.class === currentClass){
                //         targetTimeNumber = eachScheduleTime.time;
                //     }
                // })

                //classInfo 데이터를 가지고 targetTimeNumber를 구한다.
                const classNumberInfo = classInfo.find((eachClassInfo) => eachClassInfo.classNumber === classNumber);



                if (!classNumberInfo) {
                    console.log("classNumberInfo is undefined")
                    return;
                }

                targetTimeNumber = classNumberInfo.start.hours * 60 + classNumberInfo.start.minutes;

                const createdAtDate = new Date(eachSuddenNotice.DateInserted);
                const createdAtGetTime = createdAtDate.getTime();

                const classStartAtGetTime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), classNumberInfo.start.hours, classNumberInfo.start.minutes).getTime();

                if(eachStudent.name === "김서연"){
                    console.log(eachStudent);
                }

                switch (eachSuddenNotice.type) {
                    case "long": //지각이랑 같음
                        if (timeNumber1 && targetTimeNumber < timeNumber1) {
                            if (createdAtGetTime > classStartAtGetTime) {
                                if(eachStudent.name === "김서연"){
                                    console.log("abc");
                                }
                                eachStudent.realTimeStatus = "in";
                                eachStudent.realTimeBySudden = true;
                                if(eachStudent.name === "김서연"){
                                    console.log(eachStudent.realTimeStatus);
                                    console.log(eachStudent);
                                }
                            } else {
                                if(eachStudent.name === "김서연"){
                                    console.log("def");
                                }
                                eachStudent.suddenJustified = true;
                            }
                            eachStudent.suddenType = "long";
                        }
                        break;
                    case "among": //외출이랑 같음
                        if (timeNumber1 && timeNumber2 && targetTimeNumber >= timeNumber1 && targetTimeNumber < timeNumber2) {
                            if (createdAtGetTime > classStartAtGetTime) {
                                eachStudent.realTimeStatus = "in";
                                eachStudent.realTimeBySudden = true;
                            } else {
                                eachStudent.suddenJustified = true;
                            }
                            eachStudent.suddenType = "among";
                        }
                        break;
                    case "early": //조퇴랑 같음
                        if (timeNumber2 && targetTimeNumber >= timeNumber2) {
                            if (createdAtGetTime > classStartAtGetTime) {
                                eachStudent.realTimeStatus = "in";
                                eachStudent.realTimeBySudden = true;
                            } else {
                                eachStudent.suddenJustified = true;
                            }
                            eachStudent.suddenType = "early";
                        }
                        break;
                    case "absent":
                        if (createdAtGetTime > classStartAtGetTime) {
                            eachStudent.realTimeStatus = "in";
                            eachStudent.realTimeBySudden = true;
                        } else {
                            eachStudent.suddenJustified = true;
                        }
                        eachStudent.suddenType = "absent";
                        break;
                }

                if(eachStudent.name === "김서연"){
                    console.log(eachStudent);
                }

            }

        })

    });

}

export const prettifyAccessControlData = (studentList: any, classNumber: string, targetDate: Date, classInfo : ClassInfo[]) => {

    if (!studentList) {
        return;
    }

    studentList.forEach((eachStudent: any) => {

        eachStudent.accessControl.forEach((eachAccessControl: any) => {

            const date = new Date(+eachAccessControl.time);

            //datestring YYYY-MM-DD HH:MM:SS
            const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":" + date.getSeconds();

            eachAccessControl.dateString = dateString;

        })

    })

    //classNumber 시작 시간보다 이전인 accessControl은 studentList.beforeClassAccessControl에 넣고 중간인 accessControl은 studentList.duringClassAccessControl에 넣고 이후인 accessControl은 studentList.afterClassAccessControl에 넣는다.

    const classNumberInfo = classInfo.find((eachClassInfo) => eachClassInfo.classNumber === classNumber);

    if (!classNumberInfo) {
        console.log("classNumberInfo is undefined")
        return;
    }

    const classNumberStartTime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), classNumberInfo.start.hours, classNumberInfo.start.minutes + 1, 0).getTime();
    const classNumberEndTime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), classNumberInfo.end.hours, classNumberInfo.end.minutes, 0).getTime();

    studentList.forEach((eachStudent: any) => {

        eachStudent.beforeClassAccessControl = [];
        eachStudent.duringClassAccessControl = [];
        eachStudent.afterClassAccessControl = [];

        eachStudent.accessControl.forEach((eachAccessControl: any) => {

            if (+eachAccessControl.time <= classNumberStartTime) {
                eachStudent.beforeClassAccessControl.push(eachAccessControl);
            }

            if (+eachAccessControl.time > classNumberStartTime && +eachAccessControl.time <= classNumberEndTime) {
                eachStudent.duringClassAccessControl.push(eachAccessControl);
            }

            if (+eachAccessControl.time > classNumberEndTime) {
                eachStudent.afterClassAccessControl.push(eachAccessControl);
            }

        })

    })



    //studentList.beforeClassAccessControl을 가지고 마지막 기록의 direction이 outside면 student.currentStatus를 in으로 바꾸고 direction이 inside면 student.currentStatus를 out으로 바꾼다. beforeclassaccesscontrol이 없으면 student.currentStatus를 out으로 바꾼다.
    studentList.forEach((eachStudent: any) => {

        eachStudent.currentStatus = "out";

        if (eachStudent.beforeClassAccessControl.length === 0) {
            return;
        }

        const lastAccessControl = eachStudent.beforeClassAccessControl[eachStudent.beforeClassAccessControl.length - 1];

        if (lastAccessControl.direction === "outside") {
            eachStudent.currentStatus = "in";
        }

        if (lastAccessControl.direction === "inside") {
            eachStudent.currentStatus = "out";
        }

    })


    //studentList.accessControl의 length가 0이면 studentList.isNever을 true로 바꾼다.

    studentList.forEach((eachStudent: any) => {
        if (eachStudent.beforeClassAccessControl.length === 0) {
            eachStudent.isNever = true;
        }
    })


}


export const correctRegularSudden = async (studentList : studentList[], targetDate : Date, classNumber : string, suddenNoticeData : any[], classInfo : ClassInfo[]) => {

    console.log("correctRegularSudden");
    console.log(studentList);

    const currentClassInfo = classInfo.find((eachClassInfo) => eachClassInfo.classNumber === classNumber);

    if(!currentClassInfo){
        console.log("noClassInfo");
        return;
    }


    //1. 정기결석인데 출입기록이 있는 경우 -- 확인완료
    //정기결석이야?
    //근데 출입기록이 있어?
    //그 출입기록이 현재 교시 시작보다 이전이야?
    //사유로 정당화 안됬어?
    //리스트업하자

    studentList.forEach((eachStudent, index) => {

        if(eachStudent.currentStatus && eachStudent.currentStatus === "in"){
            return;
        }

        if(!eachStudent.regularJustified || !eachStudent.regularType){
            return;
        }

        if(eachStudent.regularType !== "absent"){
            return;
        }

        if(eachStudent.accessControl.length === 0){
            return;
        }

        const firstAccessControlTime = +eachStudent.accessControl[0].time;

        const currentClassStartTime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), currentClassInfo.start.hours, currentClassInfo.start.minutes, 0).getTime();

        if(firstAccessControlTime > currentClassStartTime){
            return;
        }

        //정기 결석으로 정당화 됬는데 출입기록이 있고 그 출입기록이 현재 기록보다 이전임
        if(eachStudent.suddenJustified){
            return;
        }
        //사유로 정당화도 안됨

        eachStudent.isSuspicious = true;

    })



    //2. 정기결석인데 사유제출 된 경우 -- 확인완료
    //정기결석이야?
    //근데 사유제출이 있어?
    //그 사유제출 시간이 현재 교시 시작보다 이전이야?
    //사유로 정당화 안됬어?
    //리스트업하자

    studentList.forEach((eachStudent, index) => {

        if(eachStudent.currentStatus && eachStudent.currentStatus === "in"){
            return;
        }

        if(!eachStudent.regularJustified || !eachStudent.regularType){
            return;
        }

        if(eachStudent.regularType !== "absent"){
            return;
        }  
        
        var hasBeforeSuddenNotice = false;

        
        
        //해당 날짜에 제출된 사유가 있고 제출된 시간이 해당 교시 이전임
        suddenNoticeData.forEach((eachSuddenNotice: any) => {


            if (eachSuddenNotice.userId === eachStudent.id) {


            if(eachStudent.name ==="박시연"){
                console.log("박시연");
                console.log(eachSuddenNotice);
            }

                //사유 제출 시간
                const createdAtDate = new Date(eachSuddenNotice.DateInserted);
                const createdAtGetTime = createdAtDate.getTime();

                //해당 수업 시작 시간
                const classStartAtGetTime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), currentClassInfo.start.hours, currentClassInfo.start.minutes).getTime();

                if(createdAtGetTime < classStartAtGetTime){
                    hasBeforeSuddenNotice = true;
                }

            }

        })

        if(eachStudent.suddenJustified){
            return;
        }
        //사유로 정당화도 안됨

        console.log("isSuspliciousLast");
        console.log(eachStudent);
    
        if(hasBeforeSuddenNotice){
            eachStudent.isSuspicious = true;
        }

    })



    //3. 정기지각인데 사유 지각도 있는 경우 - 확인완료 8/15, 8/11 윤동섭
    //정기지각이야?
    //근데 사유지각도 있어?
    //사유로 정당화 안됬어?
    //리스트업하자

    studentList.forEach((eachStudent, index) => {

        if(eachStudent.currentStatus && eachStudent.currentStatus === "in"){
            return;
        }

        if(!eachStudent.regularJustified || !eachStudent.regularType){
            return;
        }

        if(eachStudent.regularType !== "late"){
            return;
        }
        //정기일정 지각으로 처리된 상태

        var hasBeforeSuddenNotice = false;
        var hasLateSuddenNotice = false;

        //해당 날짜에 제출된 사유가 있고 제출된 시간이 해당 교시 이전임
        suddenNoticeData.forEach((eachSuddenNotice: any) => {

            if (eachSuddenNotice.userId === eachStudent.id) {

                //사유 제출 시간
                const createdAtDate = new Date(eachSuddenNotice.DateInserted);
                const createdAtGetTime = createdAtDate.getTime();

                //해당 수업 시작 시간
                const classStartAtGetTime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), currentClassInfo.start.hours, currentClassInfo.start.minutes).getTime();

                if(eachSuddenNotice.type === "long"){
                    hasLateSuddenNotice = true;
                    if (createdAtGetTime < classStartAtGetTime) {
                        hasBeforeSuddenNotice = true;
                    }
                }
            }
        })

        if(eachStudent.suddenJustified){
            return;
        }
        //사유로 정당화도 안됨

        if(hasLateSuddenNotice && hasBeforeSuddenNotice){
            eachStudent.isSuspicious = true;
        }

    })


    //4. 정기조퇴인데 사유 조퇴도 있는 경우 - 확인완료 7/14 대치점 서유빈 6교시 시간 6시로 조정 후 확인
    //정기조톼야?
    //근데 사유조퇴도 있어?
    //사유로 정당화 안됬어?
    //리스트업하자

    studentList.forEach((eachStudent, index) => {

        if(eachStudent.currentStatus && eachStudent.currentStatus === "in"){
            return;
        }

        if(!eachStudent.regularJustified || !eachStudent.regularType){
            return;
        }

        if(eachStudent.regularType !== "early"){
            return;
        }
        //정기일정 지각으로 처리된 상태

        var hasBeforeSuddenNotice = false;
        var hasLateSuddenNotice = false;

        //해당 날짜에 제출된 사유가 있고 제출된 시간이 해당 교시 이전임
        suddenNoticeData.forEach((eachSuddenNotice: any) => {

            if (eachSuddenNotice.userId === eachStudent.id) {

                //사유 제출 시간
                const createdAtDate = new Date(eachSuddenNotice.DateInserted);
                const createdAtGetTime = createdAtDate.getTime();

                //해당 수업 시작 시간
                const classStartAtGetTime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), currentClassInfo.start.hours, currentClassInfo.start.minutes).getTime();

                if(eachSuddenNotice.type === "early"){
                    hasLateSuddenNotice = true;
                    if (createdAtGetTime < classStartAtGetTime) {
                        hasBeforeSuddenNotice = true;
                    }
                }
            }
        })

        if(eachStudent.suddenJustified){
            return;
        }
        //사유로 정당화도 안됨
        if(hasLateSuddenNotice
            && hasBeforeSuddenNotice
            ){
            eachStudent.isSuspicious = true;
        }

    })



}


