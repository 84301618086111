import * as React from 'react';
import Box from '@mui/joy/Box';
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import styles from "../styles/attendancestyles.module.css";
import { returnLocationClassInfo } from '../../../../../data/locationFuntion';
// import { classInfo } from '../functions/calcattendance';

export default function ClassSelect(props : any) {
  const [alignment, setAlignment] = React.useState('zero');
  const [limit, setLimit] = React.useState(100);
  const [classInfo, setClassInfo] = React.useState(
    returnLocationClassInfo(props.location)
  );
  const [classNumbers, setClassNumbers] = React.useState<string[]>([]);

  React.useEffect(() => {

    if(!props.targetDate) return;

    makeAlignmentDisable();

  }, [props.targetDate]);

  React.useEffect(() => {

    const newClassNumbers : string[] = [];

    classInfo.forEach((item) => {

      if(item.classNumber === "lunch" || item.classNumber === "dinner"){
        return;
      }

      newClassNumbers.push(item.classNumber);
    })

    setClassNumbers([...newClassNumbers]);

  }, [classInfo]);


  const makeAlignmentDisable = () => {

    if(!props.targetDate) return;

    //targetDate가 오늘이 아니면 return 시킨다.
    const today = new Date();

    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth() + 1;
    const todayDate = today.getDate();

    console.log(todayYear, todayMonth, todayDate);

    const targetYear = props.targetDate.getFullYear();
    const targetMonth = props.targetDate.getMonth() + 1;
    const targetDate = props.targetDate.getDate();

    console.log(targetYear, targetMonth, targetDate);

    if(todayYear !== targetYear || todayMonth !== targetMonth || todayDate !== targetDate){
      setLimit(100);
      return;
    }


    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();

    const currentTime = currentHour * 60 + currentMinute;

    //classInfo를 돌리면서 모든 수업의 시작시간을 *60 + 분 으로 해서 currentTime과 비교한다.

    var limitClass = 0;
    var currentClassNumber = "";

    classInfo.forEach((item, index) => {

      if(item.classNumber === "lunch" || item.classNumber === "dinner"){
        return;
      }

      const classStartTime = item.start.hours * 60 + item.start.minutes;

      if(classStartTime <= currentTime) {
        limitClass = index;
        currentClassNumber = item.classNumber;
      }

    })

    if(todayYear === targetYear || todayMonth === targetMonth || todayDate === targetDate){
      
      if(!currentClassNumber ){
        currentClassNumber = "zero";
      }

      setAlignment(currentClassNumber);
      props.handleClassNumber({target: {value: currentClassNumber}});
    }

    setLimit(limitClass);

  }




  return (
    <RadioGroup
      orientation="horizontal"
      aria-label="Alignment"
      name="alignment"
      variant="outlined"
      value={alignment}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        {
            setAlignment(event.target.value)
            props.handleClassNumber(event);
        }
      }
    >
      {classNumbers.map((item, index) => (
        <Box
          key={item}
          sx={(theme) => ({
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 64,
            height: 58,
            '&:not([data-first-child])': {
              borderLeft: '1px solid',
              borderColor: 'divider',
            },
            [`&[data-first-child] .${radioClasses.action}`]: {
              borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
              borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
            },
            [`&[data-last-child] .${radioClasses.action}`]: {
              borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
              borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
            },
          })}
        >
          <Radio
            value={item}
            disableIcon
            overlay
            label={
              {
                zero: <span className={styles.basicFont}>0교시</span>,
                first: <span className={styles.basicFont}>1교시</span>,
                second: <span className={styles.basicFont}>2교시</span>,
                third: <span className={styles.basicFont}>3교시</span>,
                fourth: <span className={styles.basicFont}>4교시</span>,
                fifth: <span className={styles.basicFont}>5교시</span>,
                sixth: <span className={styles.basicFont}>6교시</span>,
                seventh: <span className={styles.basicFont}>7교시</span>,
                eighth: <span className={styles.basicFont}>8교시</span>,
                ninth: <span className={styles.basicFont}>9교시</span>,
                tenth : <span className={styles.basicFont}>10교시</span>,
                eleventh: <span className={styles.basicFont}>11교시</span>,
                twelfth: <span className={styles.basicFont}>12교시</span>,
                thirteenth: <span className={styles.basicFont}>13교시</span>,
                fourteenth: <span className={styles.basicFont}>14교시</span>,
              }[item]
            }
            variant={alignment === item ? 'solid' : 'plain'}
            slotProps={{
              input: { 'aria-label': item },
              action: {
                sx: { borderRadius: 0, transition: 'none' },
              },
              label: { sx: { lineHeight: 0 } },
            }}
            disabled={index > limit}
          />
        </Box>
      ))}
    </RadioGroup>
  );
}