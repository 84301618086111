import React, { useEffect, useState } from "react";
import PatrolClassSelect from "../components/patrolclassselect";
import { Button } from "@mui/joy";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { GridColDef, DataGridPremium } from "@mui/x-data-grid-premium";
import LocationSelect from "../components/locationselect";
import { didPatrol, distinguishInAndOut, prettifyAccessControl, prettifyQrCheckData, sortStudentList } from "../functions/functions";
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { returnPatrolTime } from "../../../../data/locationFuntion";
import totalLocationData from "../../../../data/locationdata";

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .status3': {

                },
                '& .noPatrol': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .out': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                },
                '& .fault': {
                    backgroundColor : getBackgroundColor(theme.palette.secondary.main),
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);

const totalWidth = 1500;

const col: GridColDef[] = [
    { field: "seat", headerName: "좌석번호", width: 80 },
    { field: "name", headerName: "이름", width: 80 },
]

const TotalPatrol: React.FC<any> = (props) => {

    const [where, setWhere] = useState<string>("");
    const [classNumber, setClassNumber] = useState<string>("total");
    const [targetDate, setTargetDate] = useState<Date>(new Date());
    const [targetDateIsToday, setTargetDateIsToday] = useState(true);
    const classes = useStyles2();
    const [rows, setRows] = useState<any[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>(col);
    const [targetCols, setTargetCols] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if(!where || !classNumber || !targetDate || !props.user){
            return;
        }

        start();

    }, [where, classNumber, targetDate, props.user]);

    React.useEffect(() => {

        if(!props.user || !props.user.academy){
            return;
        }
    
        const academy = props.user.academy;
    
        const filteredLocationData = totalLocationData.filter((locationData) => {
    
            if(locationData.english === "total"){
                return false;
            }
    
            return locationData.academy.includes(academy);
        })
    
        const firstLocationData = filteredLocationData[0];
    
        setWhere(firstLocationData.english);
    
    }, [props.user]);

    const start = async () => {

        try {

            const academy = props.user.academy;

            setLoading(true);

            makeColumnList();

            const { data, qrCheckData } = await getUsersData();

            const studentList = data;

            prettifyAccessControl(studentList);
            prettifyQrCheckData(qrCheckData);
            const seatPatrolData = didPatrol(qrCheckData, where, academy);
            distinguishInAndOut(studentList, seatPatrolData, targetDate, academy);
            sortStudentList(studentList);

            makeRows(studentList);

            setLoading(false);

        } catch (e) {
            console.log(e);
        }


    }

    const makeRows = (studentList : any) => {

        const newRows : any = [];

        studentList.forEach((eachStudent : any) => {

            const oneRow : any = {};
            oneRow.id = eachStudent.id;
            oneRow.seat = eachStudent.seat;
            oneRow.name = eachStudent.name;

            for(const key in eachStudent.patrolData){
                oneRow[key] = eachStudent.patrolData[key];
            }

            newRows.push(oneRow);

        });

        setRows([...newRows]);

    }

    const makeColumnList = () => {

        const academy = props.user.academy;

        if(!academy){
            return;
        }

        setColumns([...col]);

        var minClass = 0;
        var maxClass = 0;

        switch (classNumber) {
            case "total" :
                minClass = 1;
                maxClass = 9;
                break;
            case "first" :
                minClass = 1;
                maxClass = 3;
                break;
            case "second" :
                minClass = 4;
                maxClass = 6;
                break;
            case "third" :
                minClass = 7;
                maxClass = 9;
                break;
        }

        const newColumns : GridColDef[] = [...col];

        const patrolTime = returnPatrolTime(academy);

        patrolTime.forEach((eachPatrolTime, classNumber) => {

            if(eachPatrolTime.class < minClass || eachPatrolTime.class > maxClass){
                return;
            }

            eachPatrolTime.time.forEach((eachTime, number) => {
                
                const oneRow: any = {};
                const title = `${classNumber}교시_${number}`;

                oneRow.field = title;
                oneRow.headerName = title;
                oneRow.width = 80;
                oneRow.renderCell = colRenderCell;
            
                newColumns.push(oneRow);

            })

        });

        setColumns([...newColumns]);

    }

    const colRenderCell = (params: any) => {
        if(!params){
            return <div></div>
        }

        if(!params.value){
            return <div></div>
        }

        const {didPatrol, createdAt, dateString, status, fault, isFault} = params.value;

        if(!didPatrol){
            return (
                <div style={{
                    width : "100%",
                }}>
                    <div style={{
                        width : "100%",
                        borderBottom : "1px solid black",
                    }}>
                        순찰 안함
                    </div>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        width: "100%",
                    }}>
                        <div
                        style={{
                            textAlign : "center",
                            borderRight : "1px solid black"
                        }}
                        >
                            -
                        </div>
                        <div
                        style={{
                            textAlign : "center"
                        }}
                        >
                            -
                        </div>
                    </div>
                </div>
            );
        }

        if(status === "out"){
            return (
                <div style={{
                    width : "100%",
                }}>
                    <div style={{
                        width : "100%",
                        borderBottom : "1px solid black",
                        textAlign : "center"
                    }}>
                        OUT
                    </div>
                    <div style={{
                        display: "flex",
                        width: "100%",
                    }}>
                        <div
                        style={{
                            flexGrow : 1,
                            textAlign : "center",
                            borderRight : "1px solid black"
                        }}
                        >
                            -
                        </div>
                        <div
                        style={{
                            flexGrow : 1,
                            textAlign : "center"
                        }}
                        >
                            -
                        </div>
                    </div>
                </div>
            );
        }

        if(!isFault){
            return (
                <div style={{
                    width : "100%",
                }}>
                    <div style={{
                        width : "100%",
                        borderBottom : "1px solid black",
                        textAlign : "center"
                    }}>
                        공부중
                    </div>
                    <div style={{
                        display: "flex",
                        width: "100%",
                    }}>
                        <div
                        style={{
                            flexGrow : 1,
                            textAlign : "center",
                            borderRight : "1px solid black"
                        }}
                        >
                            -
                        </div>
                        <div
                        style={{
                            flexGrow : 1,
                            textAlign : "center"
                        }}
                        >
                            -
                        </div>
                    </div>
                </div>
            );
        }

        var selectedTypeString = ""
        const selectedType = fault.selectedType;

        switch (selectedType) {
            case "sleep" :
                selectedTypeString = "졸음";
                break;
            case "site" :
                selectedTypeString = "학습 외 사이트";
                break;
            case "phone" :
                selectedTypeString = "자습실 내 휴대폰 사용";
                break;
            case "bad" :
                selectedTypeString = "학습 분위기 저해 행동";
                break;
        }

        var exitValueString = "";

        const isDemerit = fault.isDemerit;
        const demerit = fault.demerit;
        const exitValue = fault.exitValue;

        switch (exitValue) {
            case "study" :
                exitValueString = "자습실";
                break;
            case "rest" :
                exitValueString = "휴게실";
                break;
        }

        const description = fault.description;

        if(selectedTypeString){
            return (
                <div style={{
                    width : "100%",
                }}>
                    <div style={{
                        width : "100%",
                        borderBottom : "1px solid black",
                        textAlign : "center"
                    }}>
                        {selectedTypeString}
                    </div>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}>
                        <div
                        style={{
                            textAlign : "center",
                            borderRight : "1px solid black",
                        }}
                        >
                            {
                                selectedType === "sleep" ? exitValueString : description ? description : "-"
                            }
                        </div>
                        <div
                        style={{
                            textAlign : "center"
                        }}
                        >
                            {
                                isDemerit ? demerit + "점" : "-"
                            }
                        </div>
                    </div>
                </div>
            );
        }



        return (
            <div>

            </div>
        )

    }

    const getUsersData = async () => {

        try {

            const data = {
                location: where,
                targetDateTime: targetDate.getTime()
            }

            const response = await fetch("https://peetsunbae.com/dashboard/report/patrolmanager/gettotal", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();
            console.log(result);

            if (result.message !== "success") {
                throw new Error("서버와의 통신에 실패했습니다.");
            }

            return result;

        } catch (e) {
            console.log(e);
            throw new Error("서버와의 통신에 실패했습니다.");
        }

    }

    useEffect(() => {

        const today = new Date();

        if (targetDate.getFullYear() === today.getFullYear() && targetDate.getMonth() === today.getMonth() && targetDate.getDate() === today.getDate()) {
            setTargetDateIsToday(true);
        } else {
            setTargetDateIsToday(false);
        }

    }, [targetDate]);

    const handleTargetDateChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {

        if (type === "plus") {
            //오늘이 아닐 경우 하루 더하기

            const today = new Date();

            if (targetDate.getFullYear() === today.getFullYear() && targetDate.getMonth() === today.getMonth() && targetDate.getDate() === today.getDate()) {
                return;
            }

            const newDate = new Date(targetDate);
            newDate.setDate(newDate.getDate() + 1);

            setTargetDate(newDate);

        }

        if (type === "minus") {
            //하루 빼기

            const newDate = new Date(targetDate);
            newDate.setDate(newDate.getDate() - 1);

            setTargetDate(newDate);
        }

    }

    const handleClassChange = (classNumber: string) => {
        setClassNumber(classNumber);
    }

    const handleLocationChange = (location: "gangnam" | "daechi") => {
        setWhere(location);
    }

    return (
        <div>
            <div>
                <div style={{
                    display: "flex",
                }}>
                    <LocationSelect handleLocationChange={handleLocationChange} user={props.user} />
                </div>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                width: `${totalWidth}px`,
                position: "relative",
                marginTop: "24px"
            }}>
                <div>
                    <PatrolClassSelect handleClassChange={handleClassChange} />
                </div>
                <div>
                    <div style={{
                        marginBottom: "6px", fontSize: "16px", fontFamily: "Apple_R", paddingRight: "4px"
                    }}>
                        {targetDate.getFullYear()}년 {targetDate.getMonth() + 1}월 {targetDate.getDate()}일
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <Button variant="outlined" color="neutral" sx={{
                            marginRight: "12px",
                            width: "40px",
                            height: "40px"
                        }}
                            onClick={(e: any) => {
                                handleTargetDateChange(e, "minus")
                            }}
                        >
                            <ChevronLeft />
                        </Button>
                        <Button variant="outlined" color="neutral" sx={{
                            width: "40px",
                            height: "40px"
                        }}
                            disabled={targetDateIsToday}
                            onClick={(e: any) => {
                                handleTargetDateChange(e, "plus")
                            }}
                        >
                            <ChevronRight />
                        </Button>
                    </div>
                </div>
            </div>

            <div className={classes.root} style={{
                width: `${totalWidth}px`,
                marginTop : "18px",
                height: "600px",
            }}>
                <DataGridPremium 
                rows={rows} 
                columns={columns}
                loading={loading}
                getCellClassName={(params) => {

                    if(params.field === "seat" || params.field === "name"){
                        return ""
                    }

                    if(params && params.value){
                        const {didPatrol, createdAt, dateString, status, fault, isFault} = params.value;

                        if(!didPatrol){
                            return "noPatrol";
                        }

                        if(status === "out"){
                            return "out";
                        }

                        if(isFault){
                            return "fault";
                        }

                    }

                    return ""
                }}
                />
            </div>
        </div>
    )
}

export default TotalPatrol;