import { CssVarsProvider } from "@mui/joy";
import React, { useEffect, useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { DataGridPremium, GridColDef, GridFilterModel, LicenseInfo } from "@mui/x-data-grid-premium";
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { GridRowProps } from "@mui/x-data-grid";
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");


const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .status3': {

                },
                '& .complete': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                },
                '& .incomplete': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);


const columns : GridColDef[] = [
    {field : "location", headerName : "지점", width : 100},
    {field : "name", headerName : "신청 아이디명", width : 160},
    {field : "appliedName", headerName : "신청한 입금자명", width : 160},
    {field : "appliedAmount", headerName : "신청 금액", width : 160},
    {field : "appliedTime", headerName : "신청 시간", width : 160},
    {field : "completed", headerName : "매칭 여부", width : 100},
    {field : "completedTime", headerName : "매칭 시간", width : 160},
]



const TotalAppliedCharge: React.FC<any> = (props: any) => {

    const [alignment, setAlignment] = useState("total");
    const [findName, setFindName] = useState("");
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState<GridRowProps[]>([]);

    const classes = useStyles2();
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 2,
                field: "name",
                operator: "contains",
                value: ""
            },
            {
                id : 3,
                field : "completed",
                operator : "equals",
                value : ""
            }
        ]
    });

    useEffect(() => {

        getData();

    }, []);


    const getData = () => {

        setLoading(true);

        fetch("https://peetsunbae.com/dashboard/restaurant/totalappliedcharge", {
            method: "get",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if(result.message === "success"){

                        const newRows : any = [];
                        const data = result.data;

                        data.forEach((each : any) => {
                            const oneRow : any = {};
                            oneRow.id = each.id;
                            oneRow.name = each.studentName;
                            oneRow.location = each.location;
                            oneRow.appliedName = each.name;
                            oneRow.appliedAmount = each.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + " 원";
                            
                            const date1 = new Date(each.createdAt);
                            oneRow.appliedTime = `${date1.getMonth() + 1}/${date1.getDate()}  ${date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()}:${date1.getMinutes() < 10 ? "0" + date1.getMinutes() : date1.getMinutes()}:${date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds()}`

                            if(each.paid === 1){
                                oneRow.completed = "매칭";
                                if(each.paidAt){
                                    const date1 = new Date(+each.paidAt);
                                    oneRow.completedTime = `${date1.getMonth() + 1}/${date1.getDate()}  ${date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()}:${date1.getMinutes() < 10 ? "0" + date1.getMinutes() : date1.getMinutes()}:${date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds()}`
                                }
                            }

                            if(each.paid === 0){
                                oneRow.completed = "비매칭";
                            }

                            newRows.push(oneRow);
                        })

                        setRows([...newRows]);

                        setLoading(false);
                        
                    }
                })
        })

    }


    const handleTextChange = (e : any) => {
        setFindName(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((item) => {
            if(item.id === 2){
                item.value = e.target.value;
            }
        })

        setFilterModel({...newFilterModel});
    }

    const handleAlignmentChange = (e : any, newAlignment : string) => {

        if(!newAlignment){
            return;
        }

        setAlignment(newAlignment);

        const newFilterModel = filterModel;

        if(newAlignment === "total"){

            newFilterModel.items.forEach((eachFilter : any) => {

                if(eachFilter.id === 3){
                    eachFilter.value = "";
                }

            })

        }

        if(newAlignment === "incomplete"){

            newFilterModel.items.forEach((eachFilter : any) => {

                if(eachFilter.id === 3){
                    eachFilter.value = "비매칭";
                }

            })

        }

        setFilterModel({...newFilterModel});

    }


    return (
        <div>


            <div style={{width : "1500px", justifyContent : "space-between", marginTop : "32px", display : "flex"}}>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        <ToggleButton value="total">매칭</ToggleButton>
                        <ToggleButton value="incomplete">비매칭</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div>
                    <TextField placeholder="신청 아이디명" variant="standard" value={findName} onChange={(e) => {handleTextChange(e);}} />
                </div>
            </div>
            <div className={classes.root} style={{ height: 650, width: '1500px', backgroundColor: "white", marginTop : "12px" }}>
                <DataGridPremium
                rows={rows}
                columns={columns}
                loading={loading}
                density="compact"
                getCellClassName={(params) => {
                    if(params.field === "completed"){
                        if(params.value === "매칭"){
                            return "complete"
                        }
                        if(params.value === "비매칭"){
                            return "incomplete"
                        }
                    }
                    return "";
                }}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                />
            </div>

        </div>
    );
}

export default TotalAppliedCharge;