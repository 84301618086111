import { DataGridPremium } from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .timeout': {
                    color: theme.palette.error.main,
                },
                '& .매칭실패': {
                    color: theme.palette.error.main,
                },
            },
        };
    },
    { defaultTheme },
);

const CatchBioStarErrorByAws = (props: any) => {

    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState([
        { field: 'floors', headerName: '층수', width: 70 },
        { field: "direction", headerName: "방향", width: 70 },
        { field: "time", headerName: "시간", width: 180 },
        { field: "fingerprintId", headerName: "지문ID", width: 70 },
        { field: "name", headerName: "이름", width: 70 },
        { field: "matched", headerName: "매칭여부", width: 150 },
        { field: "confidence", headerName: "신뢰도", width: 70 },
        { field: "error", headerName: "에러메시지", width: 150 },
        { field: "isError", headerName: "에러여부", width: 10 }
    ]);
    const [filterModel, setFilterModel] = useState<any>({
        items: [
            { id: 2, field: 'matched', operator: 'contains', value: "매칭실패" },
            { id: 3, field: "error", operator: "contains", value: "-" }
        ]
    });
    const classes = useStyles2();
    const [menu, setMenu] = useState(1);

    const getBiostarErrorData = async () => {

        setLoading(true);

        const response = await fetch("https://peetsunbae.com/dashboard/report/awsanalysisresult", {
            method: "GET",
            credentials: "include",
        })

        const result = await response.json();

        console.log(result);

        if (result.message === "success") {
            showTables(result.data);
        }

        setLoading(false);

    }

    const showTables = (data: any) => {

        const newRows: any = [];

        data.forEach((eachData: any, index: number) => {

            const oneRow: any = {};

            oneRow.id = index;

            oneRow.sqlId = eachData.id;

            var floors = "-";

            switch (eachData.kinds) {
                case "daechi":
                    floors = "2층";
                    break;
                case "daechi2":
                    floors = "3층";
                    break;
            }

            oneRow.floors = floors;

            var direction = "-";

            switch (eachData.direction) {
                case "inside":
                    direction = "out";
                    break;
                case "outside":
                    direction = "in";
                    break;
            }

            oneRow.direction = direction;

            const time = new Date(+eachData.time);
            const timeString = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours() < 10 ? "0" + time.getHours() : time.getHours()}:${time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()}:${time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds()}`;

            oneRow.time = timeString;
            oneRow.fingerprintId = eachData.fingerprintId;
            oneRow.name = eachData.name;

            oneRow.matched = eachData.matched === 1 ? "매칭성공" : "매칭실패";
            oneRow.confidence = eachData.confidence ? eachData.confidence + "%" : "-";
            oneRow.error = eachData.errorMessage ? eachData.errorMessage : "-";

            if (eachData.profileImage) {
                oneRow.profileImage = `data:image/jpeg;base64,${eachData.profileImage}`;
            }

            if (eachData.logImage) {
                oneRow.logImage = `data:image/jpeg;base64,${eachData.logImage}`;
            }

            if (eachData.errorMessage) {

                if (eachData.errorMessage === "InvalidParameterException") {
                    oneRow.matched = "사진에 얼굴 없음";
                }

                oneRow.isError = "O";
            }

            newRows.push(oneRow);

        })

        setRows([...newRows]);

    }

    useEffect(() => {
        getBiostarErrorData();
    }, []);

    const handleMenuChange = (newMenu: any) => {

        if (!newMenu) {
            return;
        }

        setMenu(newMenu);

        var newFilterModel: any = {
            items: []
        };

        if (newMenu === 1) {

            newFilterModel = {
                items: [
                    { id: 2, field: 'matched', operator: 'contains', value: "매칭실패" },
                    { id: 3, field: "error", operator: "contains", value: "-" }
                ]
            }


        } else if (newMenu === 2) {

            newFilterModel = {
                items: []
            }

        } else if (newMenu === 3) {

            newFilterModel = {
                items: [
                    { id: 3, field: "isError", operator: "contains", value: "O" }
                ]
            }
        }

        setFilterModel({ ...newFilterModel });

    }

    return (
        <div>
            <div>
                {/* <ToggleButtonGroup value={menu} exclusive onChange={(event, newMenu) => { handleMenuChange(newMenu) }} color='primary'>
                    <ToggleButton value={1}>비매칭 기록</ToggleButton>
                    <ToggleButton value={2}>모든 기록</ToggleButton>
                    <ToggleButton value={3}>에러 내역</ToggleButton>
                </ToggleButtonGroup> */}
            </div>

            {/* <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white", marginTop: "12px" }}> */}
            {/* <DataGridPremium
                    rows={rows}
                    columns={columns}
                    filterModel={filterModel}
                    onFilterModelChange={(model: any) => {
                        setFilterModel(model);
                    }}
                    density="compact"
                    getCellClassName={(params) => {
                        if (params.field === "matched") {
                            if(params.value === "매칭실패") {
                                return "매칭실패";
                            }
                        }
                        return "";
                    }}
                /> */}
            {/* datagridpremium 안쓰고 그냥 표현 */}

            {/* </div> */}
            <div style={{ marginTop: "40px", paddingBottom : "200px" }}>

                {
                    rows.map((eachRow: any, index: number) => {

                        return (
                            <div style={{ paddingTop: "15px", paddingBottom: "15px", backgroundColor: "white", borderTop: "1px solid gray" }}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "70px", height: "30px", textAlign: "center", lineHeight: "30px" }}>{eachRow.floors}</div>
                                    <div style={{ width: "70px", height: "30px", textAlign: "center", lineHeight: "30px" }}>{eachRow.direction}</div>
                                    <div style={{ width: "180px", height: "30px", textAlign: "center", lineHeight: "30px" }}>{eachRow.time}</div>
                                    <div style={{ width: "70px", height: "30px", textAlign: "center", lineHeight: "30px" }}>{eachRow.fingerprintId}</div>
                                    <div style={{ width: "70px", height: "30px", textAlign: "center", lineHeight: "30px", overflow: "hidden" }}>{eachRow.name}</div>
                                    <div style={{ width: "150px", height: "30px", textAlign: "center", lineHeight: "30px", color : "red" }}>{eachRow.matched}</div>
                                    <div style={{ width: "70px", height: "30px", textAlign: "center", lineHeight: "30px" }}>{eachRow.confidence}</div>
                                    <div style={{ width: "150px", height: "30px", textAlign: "center", lineHeight: "30px" }}>{eachRow.error}</div>
                                    {
                                        eachRow.profileImage &&
                                        <div>
                                            <img src={eachRow.profileImage} style={{ marginRight: "30px", width : "180px" }} />

                                        </div>
                                    }
                                    {
                                        eachRow.logImage &&
                                        <div>
                                            <img src={eachRow.logImage} style={{width : "180px"}} />

                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default CatchBioStarErrorByAws;