import React, { useEffect, useState } from "react";
import styles from "./bottomButton.module.css";

const BottomButton: React.FC<any> = (props) => {

    const [isSafeArea, setIsSafeArea] = useState(false);

    useEffect(() => {

        const valueBottom = +getComputedStyle(document.documentElement).getPropertyValue("--sab").split("p")[0];

        if (valueBottom > 0) {
            setIsSafeArea(true);
        }

    }, []);

    return (
        <div onClick={props.submit} className={`${styles.submitButton} ${isSafeArea ? styles.safeArea : ""}`}>
            {props.text}
        </div>
    )
}

export default BottomButton;