import { IonApp, IonAvatar } from "@ionic/react";
import { LinearProgress } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { getFetch } from "../../fetch/fetch";
import { returnDayString } from "../etc/functions";
import InnerHeader from "../etc/innerheader";
import InnerHeaderForModal from "../etc/innerheaderformodal";
import styles from "./notification.module.css";
import ImageViewer from "../etc/imageviewer/imageviewer";
import { Avatar } from "@mui/material";
import { ReactComponent as ShapeLogoSvg } from '../../../src/svg/onlyshape.svg';
import { englishAcademyNameToKorean, renderLogoSvg } from "../../data/locationFuntion";

const NotificationDetail: React.FC<any> = (props) => {

    const headerRef = useRef<HTMLDivElement>(null);
    const [bodyHeight, setBodyHeight] = useState(0);
    const [eachData, setEachData] = useState<any>();
    const [targetDay, setTargetDay] = useState<any>();
    const [loading, setLoading] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [src, setSrc] = useState("");

    const handleClose = () => {
        setIsOpen(false);
        setSrc("");
    }

    const caculateBodyHeight = () => {

        setTimeout(() => {

            if (!headerRef || !headerRef.current) {
                return;
            }

            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;

            const lastHeight = totalHeight - safeareaTop - headerHeight

            console.log("height");
            console.log(lastHeight);
            setBodyHeight(lastHeight);

        }, 50);
    }

    useEffect(() => {

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef]);

    const getNotification = async () => {

        try {
            setLoading(true)
            const result: any = await getFetch("/dashboard/notification/geteach?id=" + props.id);
            console.log(result.message);
            // result.message[0].description += "<div><br></br><a href='https://naver.com'>설문지 작성 링크</div>"
    
            setEachData(result.message[0]);
            const dayString = returnDayString(result.message[0].createdAt.day);
            setTargetDay(dayString);
            setLoading(false);

        } catch (e) {
            console.log(e);
        }


    }

    useEffect(() => {

        if (!props.isOpen || !props.id) {
            console.log("return");
            return;
        }

        console.log("gogogo");

        getNotification();

    }, [props.isOpen, props.id]);


    return (
        <IonApp>
            <div>
                <InnerHeaderForModal headerRef={headerRef} handleClose={props.handleClose} />
                {
                    loading &&
                    <LinearProgress />
                }
                <div className={styles.body} style={{ height: `${bodyHeight}px`, overflow: "scroll" }}>
                    {
                        eachData
                        &&
                        <div key={eachData.id} className={styles.notificationWrapper}>
                            <div className={styles.eachNotificationDetail}>
                                <div className={styles.authorDiv}>
                                    <div className={styles.avatarDiv}>
                                        {/* {
                                            !eachData.authorAvatar ?
                                                <div className={`${styles.profileContainer} ${styles.ofStudent}`}>
                                                    <img src="img/user-solid.svg" alt="user" className={styles.avatar} />
                                                </div>
                                                :
                                                ""
                                        }
                                        {
                                            eachData.authorAvatar ?
                                                <IonAvatar className={styles.profileContainer}>
                                                    <img alt="" src={`/img/avatar/Avatar-${eachData.authorAvatar}.png`} className={styles.ionAvatarImg} />
                                                </IonAvatar>
                                                :
                                                ""
                                        } */}
                                            <Avatar sx={{ bgcolor: "#fff", border: "#bdbdbd 1px solid", marginRight: "0.5rem" }}>
                                                {/* <ShapeLogoSvg className={styles.shapeLogo} /> */}
                                                {
                                                    (props.user && props.user.academy) &&
                                                    renderLogoSvg(props.user.academy, "shape", styles.shapeLogo)
                                                }
                                            </Avatar>
                                        <div className={styles.avatarNameDiv}>
                                            <div className={styles.avatarName}>
                                                {/* {
                                                    eachData.author
                                                } */}
                                                {/* 수능선배 */}
                                                {
                                                    (props.user && props.user.academy) &&
                                                    englishAcademyNameToKorean(props.user.academy)
                                                }
                                            </div>
                                            <div className={styles.uploadDate}>
                                                {eachData.createdAt.month}월 {eachData.createdAt.date}일 ({targetDay}) {eachData.createdAt.hours > 11 ? "오후" : "오전"} {eachData.createdAt.hours === 0 ? 12 : eachData.createdAt.hours < 13 ? eachData.createdAt.hours : eachData.createdAt.hours - 12}:{eachData.createdAt.minutes < 10 ? "0" + eachData.createdAt.minutes : eachData.createdAt.minutes}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.notificationDetailTitle}>
                                        {
                                            eachData.title
                                        }
                                    </div>
                                    <div className={styles.notificationDetailDescription} dangerouslySetInnerHTML={{__html : eachData.description}}>
                                        {/* {
                                            eachData.description
                                        } */}
                                    </div>
                                    {
                                        eachData.images.length > 0
                                            ?
                                            eachData.images.map((eachImage: any) => {
                                                return (
                                                    <div className={styles.notificationImgDiv} onClick={(e) => { setSrc(`https://peetsunbae.com/${eachImage.split("/public/")[1]}`); setIsOpen(true); }}>
                                                        <img className={styles.notificationImg} src={`https://peetsunbae.com/${eachImage.split("/public/")[1]}`} alt="notificationImg" />
                                                    </div>
                                                );
                                            })
                                            :
                                            ""

                                    }
                                    {/* <div className={styles.notificationViews}>
                                            <div className={styles.notificationViewBox}>
                                                😃&nbsp;&nbsp;{eachData.number}
                                            </div>
                                        </div> */}
                                </div>
                                <div className={styles.numberDiv}>
                                    전체 조회수 {eachData.number}
                                </div>
                            </div>
                        </div>
                    }

                </div>

                <ImageViewer
                    isModalOpen={isOpen}
                    src={src}
                    handleClose={handleClose}
                />

            </div>
        </IonApp>
    );
}

export default NotificationDetail;