import React, { useState, useEffect, useRef } from 'react';
import TotalCheckList from './totalchecklist';
import SubmitCheckList from './submitchecklist';
import MyCheckList from './mychecklist';

const Checklist: React.FC<any> = (props) => {

    const [select, setSelect] = useState("submit");

    const changeSelect = (e: any, value: string) => {
        setSelect(value);
    }

    useEffect(() => {
        props.activateMenuList("checklist");
    }, []);


    return (
        <div className="main22">
            <div className="title" style={{
                display: "flex", alignItems: "center",
            }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "6px",
                        marginRight: "10px"
                    }}
                >
                    <img src="img/off/checklist.svg" alt="playlist" style={{ width: "18px" }} />
                </div>
                <div>계획표 검사</div>
            </div>
            <div className="menu">
                <div className="submenu">
                    <div className={`menu_status ${select === "submit" ? "active" : ""}`} onClick={(e) => { changeSelect(e, "submit") }}>
                        # 계획표 검사 신청
                    </div>
                    <div className={`menu_status ${select === "check" ? "active" : ""}`} onClick={(e) => { changeSelect(e, "check") }}>
                        나의 신청 현황
                    </div>
                    {
                        (props.user && (props.user.value === "teacher" || props.user.value === "staff")) &&
                        <div className={`menu_status ${select === "total" ? "active" : ""}`} onClick={(e) => { changeSelect(e, "total") }}>
                            전체 제출 현황
                        </div>
                    }
                </div>
            </div>

            <div>
                {
                    select === "total"
                    &&
                    <TotalCheckList user={props.user} socket={props.socket} />
                }
                {
                    select === "submit"
                    &&
                    <SubmitCheckList user={props.user} socket={props.socket} />
                }
                {
                    select === "check"
                    &&
                    <MyCheckList user={props.user} socket={props.socket} />
                }
            </div>


        </div>
    )
}

export default Checklist;