import React, { useEffect, useState, useRef } from "react";
import styles from "./mobileofflinequestionregister.module.css";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { Autocomplete, Button, CircularProgress, Divider, LinearProgress, Skeleton } from "@mui/material";
import AspectRatio from '@mui/joy/AspectRatio';
import { CssVarsProvider } from "@mui/joy";
import { LicenseInfo } from "@mui/x-license-pro";
import { IonLoading, IonToast } from "@ionic/react";
import { ReactComponent as Mail } from "../../../src/svg/mobiletotal/paper-plane-light.svg";
import { ReactComponent as MailActive } from "../../../src/svg/paper-plane-duotone.svg";

import { useHistory } from "react-router-dom";

LicenseInfo.setLicenseKey("3124a63cf796a421a7ac55c9bf753845Tz01NjM1MCxFPTE3MDMwMzU5NjMxNzEsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=");


const MobileOfflineQuestionRegister: React.FC<any> = (props) => {

    const [isNull, setIsNull] = useState(false);

    const [currentId, setCurrentId] = useState(0);

    const [selectedMenu, setSelectedMenu] = useState("enroll");

    const [dateValue, setDateValue] = useState(new Date());
    const [availableDate, setAvailableDate] = useState<any>();
    const [enrolled, setEnrolled] = useState<any>();
    const [afterEnroll, setAfterEnroll] = useState(false);

    const [data, setData] = useState<any>();

    const [random, setRandom] = useState(1);
    const [enrollStatus, setEnrollStatus] = useState<any>("");

    const [showLoading, setShowLoading] = useState(false);
    const [showLoading2, setShowLoading2] = useState(false);
    const [ionLoading, setIonLoading] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [showToast2, setShowToast2] = useState(false);
    const [showToast3, setShowToast3] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const history = useHistory();
    const [isErrorArray, setIsErrorArray] = useState(new Array(200).fill(false));


    useEffect(() => {

        const start = async () => {

            if (random === 1) {
                setShowLoading(true);
            } else {
                setShowLoading2(true);
            }

            var token: any = "";
            if (window.electron) {
                token = window.electron.sendMessageApi.getToken();
            }

            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                token = value;
            }

            await fetch(`https://peetsunbae.com/dashboard/question/offline/get`, {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include"
            }).then((response) => {
                response.json()
                    .then((result) => {
                        console.log(result);
                        var sortedData = result.data;
                        if (sortedData) {
                            sortedData.sort(function (a: any, b: any) {
                                const aTargetTime = new Date(a.targetDate).getTime();
                                const bTargetTime = new Date(b.targetDate).getTime();
                                console.log(aTargetTime);

                                if (aTargetTime > bTargetTime) {
                                    return 1;
                                }
                                if (aTargetTime === bTargetTime) {
                                    return 0;
                                }
                                if (aTargetTime < bTargetTime) {
                                    return -1;
                                }
                            });

                            sortedData.sort(function (a: any, b: any) {
                                const aTargetYear = new Date(a.targetDate).getFullYear();
                                const aTargetMonth = new Date(a.targetDate).getMonth();
                                const aTargetDate = new Date(a.targetDate).getDate();

                                const bTargetYear = new Date(b.targetDate).getFullYear();
                                const bTargetMonth = new Date(b.targetDate).getMonth();
                                const bTargetDate = new Date(b.targetDate).getDate();

                                if (aTargetYear === bTargetYear && aTargetMonth === bTargetMonth && aTargetDate === bTargetDate) {
                                    const aStarttHour = new Date(a.startTime).getHours();
                                    const aStartMinute = new Date(a.startTime).getMinutes();

                                    const bStartHour = new Date(b.startTime).getHours();
                                    const bStartMinute = new Date(b.startTime).getMinutes();

                                    if (aStarttHour * 60 + aStartMinute < bStartHour * 60 + bStartMinute) {
                                        return -1;
                                    } else {
                                        return 1;
                                    }

                                } else {
                                    return 1;
                                }
                            })
                        }

                        console.log(sortedData);

                        setData(sortedData);
                        if (sortedData.length > 0) {
                            setEnrolled([...result.enrolled]);
                            var newAvailableDate: any = [];
                            sortedData.forEach((each: any) => {
                                newAvailableDate.push(new Date(each.targetDate));
                            })
                            console.log(newAvailableDate[0].getFullYear());
                            if (!afterEnroll) {
                                setDateValue(newAvailableDate[0]);
                                setCurrentId(sortedData[0].id);
                                console.log(sortedData[0].id);
                            }
                            setAvailableDate([...newAvailableDate]);

                        } else {
                            setCurrentId(0);
                            setIsNull(true);
                            var VOID: any;
                            setDateValue(VOID);

                        }

                        setShowLoading(false);
                        setShowLoading2(false);
                    })
            })
        }

        if (!(selectedMenu === "status")) {
            setAvailableDate("");
            start();
        }

    }, [selectedMenu, random]);

    //선생님 목록 가져오는 기능---------------------------------

    const voidFunction = (e: any) => {

    }

    const enrollQuestion = async (e: any) => {

        setShowLoading2(true);

        console.log(e.target.dataset.questionid);
        console.log(e.target.dataset.time);

        var token: any = "";

        if (window.electron) {
            token = window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        const message = { questionId: e.target.dataset.questionid, time: e.target.dataset.time }

        await fetch("https://peetsunbae.com/dashboard/question/offline/enroll", {
            method: "POST",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                message: message
            })
        }).then((response) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    setEnrollStatus(result.message);
                    const message = result.message;
                    if (message === "ALREADY") {
                        alert("이미 해당 날짜에 등록하셨습니다.")
                        setToastMessage("이미 해당 날짜에 등록하셨습니다.");
                        setShowToast(true);
                    }
                    if (message === "DUPLICATE") {
                        alert("다른 학생이 예약한 시간입니다.");
                        setToastMessage("다른 학생이 이미 예약한 시간입니다.");
                        setShowToast(true);
                    }
                    if (message === "DELETED") {
                        setShowToast3(true);
                    }
                    if (message === "success") {
                        setShowToast2(true);
                    }

                    setRandom(Math.random());
                    setShowLoading2(false);
                })
        }).catch((e) => {
            console.log(e);
            setShowLoading2(false);
        })
    }

    const sendMessage = (questionId: number, teacherId: number, teacherName: string, month: number, date: number, dayString: string, enrolledId: number, enrolledQuestionTime: number) => {

        console.log(questionId, teacherId, month, date, dayString, enrolledId, enrolledQuestionTime);

        if (!enrolledQuestionTime) {
            alert("시간 예약 후 질의 내용 전송 가능합니다.");
            return;
        }

        history.push({
            pathname: "/mobiledashboard/offlinequestionwrite",
            state: {
                questionId,
                teacherId,
                teacherName,
                month,
                date,
                dayString,
                enrolledId,
                enrolledQuestionTime
            }
        })

    }

    const handleImageError = (index: number) => {

        const newIsErrorArray = [...isErrorArray];
        newIsErrorArray[index] = true;

        setIsErrorArray([...newIsErrorArray]);

    }


    return (
        <div className={styles.mainStatus} style={{ height: `${props.statusDivHeight}px`, overflow: "scroll" }}>
            {
                showLoading
                &&
                <div style={{ width: "90.6%", margin: "0 auto" }}>
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                </div>
            }
            {
                (!showLoading && data) && data.map((each: any, dataIndex: number) => {
                    const targetDate = new Date(each.targetDate);
                    const currentDate = new Date();
                    var today = false;
                    var currentTiming = 0;

                    var enrolledQuestionTime = 0;
                    var enrolledId = 0;

                    const month = targetDate.getMonth() + 1;
                    const date = targetDate.getDate();

                    if (targetDate.getFullYear() === currentDate.getFullYear() && targetDate.getMonth() === currentDate.getMonth() && targetDate.getDate() === currentDate.getDate()) {
                        today = true;
                        const endDate = new Date(each.endTime);
                        const endDateTiming = endDate.getHours() * 60 + endDate.getMinutes();
                        const currentDateTiming = currentDate.getHours() * 60 + currentDate.getMinutes();
                        currentTiming = currentDateTiming;

                        if (currentDateTiming + 15 >= endDateTiming) {
                            return (
                                <div>

                                </div>
                            )
                        }
                    }

                    var tomorrow = false;
                    const tomorrowDate = new Date();
                    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

                    if (targetDate.getFullYear() === tomorrowDate.getFullYear() && targetDate.getMonth() === tomorrowDate.getMonth() && targetDate.getDate() === tomorrowDate.getDate()) {
                        tomorrow = true;
                    }

                    var theDayAfter = false;
                    const theDayAfterDate = new Date();
                    theDayAfterDate.setDate(theDayAfterDate.getDate() + 2);
                    if (targetDate.getFullYear() === theDayAfterDate.getFullYear() && targetDate.getMonth() === theDayAfterDate.getMonth() && targetDate.getDate() === theDayAfterDate.getDate()) {
                        theDayAfter = true;
                    }




                    const dateString = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일`
                    var dayString = "";
                    switch (targetDate.getDay()) {
                        case 0:
                            dayString = "일요일"
                            break;
                        case 1:
                            dayString = "월요일"
                            break;
                        case 2:
                            dayString = "화요일"
                            break;
                        case 3:
                            dayString = "수요일"
                            break;
                        case 4:
                            dayString = "목요일"
                            break;
                        case 5:
                            dayString = "금요일"
                            break;
                        case 6:
                            dayString = "토요일"
                            break;
                    }

                    return (
                        <div key={each.id}>
                            <div className={`${styles.eachTeacher} ${dataIndex === 0 ? styles.first : ""}`}>
                                <div className={styles.teacherDiv}>
                                    <CssVarsProvider>
                                        <AspectRatio ratio={"1/1"} variant="plain">
                                            <div className={styles.teacherImg}>
                                                {
                                                    isErrorArray[dataIndex] ?
                                                        <img
                                                            className={styles.teacherImage} src={`https://peetsunbae.com/img/questionteacherimage/default.webp`} alt="사진 업로드 예정"
                                                        ></img>
                                                        :
                                                        <img
                                                            onError={(e) => { handleImageError(dataIndex); }}
                                                            className={styles.teacherImage} src={`https://peetsunbae.com/img/questionteacherimage/${each.teacherName.split(" ")[0]}.webp`} alt="사진 업로드 예정"
                                                        ></img>
                                                }                                            </div>
                                        </AspectRatio>
                                    </CssVarsProvider>
                                    <div className={styles.teacherDescription}>
                                        <div className={styles.teacherName}>
                                            {each.teacherName}
                                        </div>
                                        <div className={styles.teacherSubject}>
                                            {dateString} {dayString} {today && `(오늘)`} {tomorrow && `(내일)`} {theDayAfter && `(모레)`}
                                        </div>
                                        <div className={styles.teacherSubject}>
                                            {each.subject}
                                        </div>
                                        <div className={styles.location}>
                                            {each.uploadTeacherDescription}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.selectDiv}>
                                    <div className={styles.timeSelect}>

                                        <div className={styles.times}>
                                            {
                                                props.user && each.availableTimes.map((one: any) => {
                                                    var status;
                                                    var count = 0;
                                                    enrolled && enrolled.forEach((enrolledTime: any) => {
                                                        if ((+one.hours * 60 + +one.minutes) === +enrolledTime.questionTime && each.id === enrolledTime.questionId) {
                                                            status = "occupied"
                                                            if (+enrolledTime.userId === +props.user.id) {
                                                                status = "mine";
                                                                //첫번째 등록한 시간 기준으로 카톡가게 하려고
                                                                if (!enrolledQuestionTime) {
                                                                    enrolledQuestionTime = (+one.hours * 60 + +one.minutes);
                                                                }
                                                                if (!enrolledId) {
                                                                    enrolledId = enrolledTime.id;
                                                                }
                                                                //--------------------------------------------------
                                                            }
                                                        }
                                                    })

                                                    if (today && currentTiming - 14 > (+one.hours * 60 + +one.minutes)) {
                                                        return <></>
                                                    }

                                                    return (
                                                        <div key={+one.hours * 60 + +one.minutes} onClick={status === "occupied" ? voidFunction : enrollQuestion} data-questionid={each.id} data-time={+one.hours * 60 + +one.minutes} className={`${styles.timeDiv} ${status === "occupied" ? styles.occupied : ""} ${status === "mine" ? styles.mine : ""}`}>
                                                            {one.hours >= 12 ? "오후" : "오전"} {one.hours > 12 ? one.hours - 12 : one.hours}:{one.minutes < 10 ? "0" + one.minutes : one.minutes}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={`${styles.mailDiv} ${enrolledQuestionTime ? styles.active : ""}`} onClick={(e) => { sendMessage(each.id, each.teacherId, each.teacherName, month, date, dayString, enrolledId, enrolledQuestionTime); }}>
                                    <MailActive className={`${styles.mail} ${enrolledQuestionTime ? styles.active : ""}`} fill={enrolledQuestionTime ? "#304ffe" : "rgba(0, 0, 0, 0.12)"} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading2}
                onDidDismiss={() => setShowLoading2(false)}
                message={'잠시만 기다려주세요'}
            />

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => { setShowToast(false); setToastMessage(""); }}
                message={toastMessage}
                duration={1500}
                position="top"
                color="danger"
            />

            <IonToast
                isOpen={showToast2}
                onDidDismiss={() => { setShowToast2(false); }}
                message="😃 성공 ! 종이비행기를 눌러 내용 전송해주세요 !"
                duration={1500}
                position="top"
                color="primary"
            />

            <IonToast
                isOpen={showToast3}
                onDidDismiss={() => { setShowToast3(false); }}
                message="😃  질의응답 취소 성공 !"
                duration={1500}
                position="top"
                color="primary"
            />

        </div>
    );
}

export default MobileOfflineQuestionRegister;