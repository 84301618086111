import React, { useEffect, useRef } from "react";
import SeatSvg from "../etc/gangnamseatsvg";

const SeatPractice: React.FC<any> = (props) => {

    useEffect(() => {

        const handleClick = (id: number) => () => {
            alert(`Clicked ID: ${id}`);
        };

        for (var i = 1; i < 76; i++) {
            for (var j = 0; j < 3; j++) {

                var targetId = i * 10 + j;

                const element = document.getElementById(targetId.toString());
                console.log(element);

                if (!element) {
                    return;
                }

                element.addEventListener("click", handleClick(targetId));

                //element 밑에 있는 태그 path를 가져온다.
                const path = element.getElementsByTagName("path")[0];

                //path의 fill을 red로 바꾼다.


                //element밑에 있는 text를 가져온다.
                const text = element.getElementsByTagName("text")[0];
                //text의 text-anchor를 middle로 바꾼다.
                text.setAttribute("text-anchor", "middle");


                //element밑에 있는 tspan을 가져온다.
                const tspan = element.getElementsByTagName("tspan")[0];

                //tspan의 text를 바꾼다. 하이로
                // if (targetId % 10 !== 0)
                //     tspan.textContent = targetId.toString() + "!@31";

                //tspan의 background color를 바꾼다.
                if(targetId === 621){
                    tspan.setAttribute("fill", "blue");
                    //tspan의 글씨 크기를 바꾼다.
                    tspan.setAttribute("font-size", "16px");
                }

                if(targetId === 620){
                    tspan.setAttribute("fill", "blue");
                    //tspan의 글씨 크기를 바꾼다.
                    tspan.setAttribute("font-size", "16px");
                }

                if(targetId === 622){
                    tspan.setAttribute("fill", "blue");
                    //tspan의 글씨 크기를 바꾼다.
                    tspan.setAttribute("font-size", "16px");
                }

                //tsapn의 attribute중 x를 값을 바꾼다.
                //27.5가 정중앙
                tspan.setAttribute("x", "45.5");
                tspan.setAttribute("y", "18.7");


            }
        }






    }, []);

    return (
        <div  style={{
            backgroundColor : "#f5f5f5",
            width: "100%",
        }}>
            <div style={{
                width: "1150px",
                margin: "0 auto",
            }}>
                <SeatSvg />
            </div>

        </div>

    )

}

export default SeatPractice;