import React, { useState, useEffect } from 'react';
import styles from "../money.module.css";
import { Button } from '@mui/material';

const ModalBottomForMoney : React.FC<any> = (props) => {

    return (
        <div className={styles.modalBottom}>
            <Button
                variant="contained"
                size='large'
                sx={{backgroundColor : "#3d50b0"}}
                onClick={props.handleClick}
                disabled={props.disabled}
            >
                {props.buttonName}
            </Button>
        </div>
    )

}

export default ModalBottomForMoney;