import React, { useEffect, useState } from "react";
import ClassSelect from "./classselect";
import { Button, CssVarsProvider, Modal, ModalClose, ModalDialog } from "@mui/joy";
import ExampleButtonCheckbox from "./phoneselect";
import { Publish } from "@mui/icons-material";
import { set } from "lodash";
import { Backdrop, CircularProgress } from "@mui/material";



const InspectPhone: React.FC<any> = (props) => {


    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<boolean[]>(new Array(75).fill(false));
    const [selectedPhoneNumbersCount, setSelectedPhoneNumbersCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const [totalNumber, setTotalNumber] = useState(75);

    useEffect(() => {

        console.log("lets insepect phone location");
        console.log(props.location);

        if (!props.location) {
            return;
        }

        if(props.location === "daechi2"){
            setTotalNumber(85);
        }

        if(props.location === "songdo_fixed"){
            setTotalNumber(60);
        }

        if(props.location === "songdo_free"){
            setTotalNumber(120);
        }
        

    }, [props.location]);


    useEffect(() => {

        if(!props.open){
            setImageSrc("");
            return;
        }

        getSavedSelectedPhoneNumbers();
        getSavedImgSrc();

    }, [props.open]);



    const getSavedImgSrc = async () => {

        if(!props.targetDate || !props.location || !props.classNumber){
            console.log("날짜, 호점, 반을 선택해주세요");
            return;
        }

        const targetDateTime = new Date(props.targetDate).getTime();

        const data = {
            location : props.location,
            targetDateTime: targetDateTime,
            classNumber: props.classNumber
        }

        const response = await fetch("https://peetsunbae.com/dashboard/report/getinspectphonesimgsrc", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(data)
        });

        const result = await response.json();

        console.log(result);

        if(result.message === "success"){
            setImageSrc("https://peetsunbae.com/phonesimage/" + result.src);
        }else{
            setImageSrc("");
        }

    }



    const getSavedSelectedPhoneNumbers = async () => {

        if(!props.targetDate || !props.location || !props.classNumber){
            return;
        }

        const targetDateTime = new Date(props.targetDate).getTime();

        
        const response = await fetch("https://peetsunbae.com/dashboard/report/getinspectphones", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                classNumber: props.classNumber,
                targetDateTime: targetDateTime,
                location: props.location
            })
        })

        const result = await response.json();

        if (result.message === "success") {
            setSelectedPhoneNumbers(result.selectedPhoneNumbers);
        }

    }

    const handlePhoneBtnClick = (index: number) => () => {
        setSelectedPhoneNumbers((prev) => {
            const newSelectedPhoneNumbers = [...prev];
            newSelectedPhoneNumbers[index] = !newSelectedPhoneNumbers[index];
            return newSelectedPhoneNumbers;
        })
    }

    useEffect(() => {

        setSelectedPhoneNumbersCount(selectedPhoneNumbers.filter((item) => item === true).length);

    }, [selectedPhoneNumbers]);


    const submitPhoneNumbers = async () => {

        if(!props.targetDate){
            alert("날짜를 없습니다. 에러");
            return;
        }

        if(!props.location){
            alert("호점이 없습니다. 에러");
            return;
        }

        const targetDateTime = new Date(props.targetDate).getTime();

        console.log(targetDateTime)

        const data = {
            classNumber: props.classNumber,
            targetDateTime: targetDateTime,
            selectedPhoneNumbers: selectedPhoneNumbers,
            location: props.location
        }

        if (!data.classNumber || !data.targetDateTime || !data.selectedPhoneNumbers || !data.location) {
            return;
        }

        setLoading(true);

        const response = await fetch("https://peetsunbae.com/dashboard/report/inspectphones", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(data)
        })

        const result = await response.json();

        if (result.message === "success") {
            setLoading(false);
            setSelectedPhoneNumbers(new Array(75).fill(false));
            props.letsUpdate();
            props.notify1();            
            props.handleClose();
        }

    }


    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => {
                    setSelectedPhoneNumbers(new Array(75).fill(false));
                    props.handleClose();
                }}
            >
                <ModalDialog
                    layout="fullscreen"
                    variant="soft"
                    sx={{
                        paddingBottom: "60px",
                        height: "100%",
                        overflowY: "scroll"
                    }}
                >
                    <ModalClose size="lg" color="danger" />

                    <div>
                        <div style={{
                            fontFamily: "Apple_SB", fontSize: "22px",
                            width: "1700px",
                            margin: "0 auto",
                            marginBottom: "24px",
                        }}>
                            {props.classNumberString} 핸드폰 검사하기
                        </div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            width: "1700px",
                            height: "900px",
                            margin: "0 auto",
                            gap: "16px",

                        }}>
                            <div>
                                {
                                    imageSrc ?
                                    <img src={imageSrc} alt="phone" style={{
                                        width: "100%"
                                    }} />
                                    :
                                    ""
                                }

                            </div>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                                gridTemplateRows: "repeat(15, 1fr)",
                                gap: "16px",
                                paddingTop: props.location === "songdo_free" ? "30px" : "125px",
                                paddingBottom: "125px",
                            }}>
                                <CssVarsProvider>
                                    {
                                        (props.user && props.user.academy) &&
                                        new Array(totalNumber).fill(0).map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <Button
                                                        onClick={handlePhoneBtnClick(index)}
                                                        variant={selectedPhoneNumbers[index] ? "solid" : "outlined"}
                                                        sx={{
                                                            width: "100%",
                                                            minHeight: "1px",
                                                            height: "100%"
                                                        }}
                                                        color={selectedPhoneNumbers[index] ? "primary" : "neutral"}
                                                        size="sm"
                                                    >
                                                        {
                                                            (props.location && props.location !== "songdo_free") &&
                                                            index + 1 + "번"
                                                        }
                                                        {
                                                            (props.location && props.location === "songdo_free" && index + 1 > 56) &&
                                                            index + 1 + 44 + "번"
                                                        }
                                                    </Button>
                                                </div>
                                            )
                                        })
                                    }

                                </CssVarsProvider>
                            </div>
                        </div>
                        <div style={{
                            width: "1700px",
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "0 auto",
                        }}>
                            <Button variant="soft" size="lg" color="primary" startDecorator={<Publish />} 
                            onClick={submitPhoneNumbers}
                            loading={loading}
                            >
                                총 {selectedPhoneNumbersCount}개의 핸드폰이 있어요 !
                            </Button>
                        </div>
                    </div>
                </ModalDialog>
            </Modal>
        </div>
    )

}

export default InspectPhone;
