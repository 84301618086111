export const returnDayString = (dayNumber : number) => {

    var dayString = "";

    switch (dayNumber) {
        case 0:
            dayString = "일";
            break;
        case 1:
            dayString = "월";
            break;
        case 2:
            dayString = "화";
            break;
        case 3:
            dayString = "수";
            break;
        case 4:
            dayString = "목";
            break;
        case 5:
            dayString = "금";
            break;
        case 6:
            dayString = "토";
            break;
    }

    return dayString;
}

