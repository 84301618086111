import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import CatchBioStarError from "./catchbiostarerror";
import CatchBioStarErrorByAws from "./catchbiostarerrorbyaws";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CatchBioStarErrorMain = (props: any) => {

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div>
            <div style={{ width: "100%", margin: "0 auto" }}>
                <Box sx={{ width: "100%", marginBottom: "0px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                            <Tab label="AI 검사 결과 보기" {...a11yProps(0)} />
                            <Tab label="실제 내역 보기" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                </Box>
            </div>

            {
                value === 0 &&
                <div style={{
                    marginTop : "32px"
                }}>
                    <CatchBioStarErrorByAws />
                </div>
            }


            {
                value === 1 &&
                <div style={{
                    marginTop : "32px"
                }}>
                    <CatchBioStarError scrollTop={props.scrollTop} />
                </div>
            }
        </div>
    )
}

export default CatchBioStarErrorMain;