import React, { useEffect, useState, useRef } from "react";
import styles from "./mobileonlinequestiondetail.module.css";
import { IonModal } from "@ionic/react"
import InnerHeaderForModal from "../etc/innerheaderformodal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Zoom } from "swiper"

import { IonAvatar, useIonActionSheet } from "@ionic/react";

import { ReactComponent as Thumb } from "../../../src/svg/mobiletotal/thumbs-up-solid.svg";
import { ReactComponent as Message } from "../../../src/svg/mobiletotal/message-dots-light.svg";
import { ReactComponent as Exapnd } from "../../../src/svg/mobiletotal/expand-light.svg";
import { ReactComponent as Plus } from "../../../src/svg/mobiletotal/plus-light.svg";
import { ReactComponent as ArrowUp } from "../../../src/svg/mobiletotal/circle-arrow-up-solid.svg";
import { ReactComponent as XMark } from "../../../src/svg/xmark-light.svg";


import AspectRatio from '@mui/joy/AspectRatio';
import { CssVarsProvider } from '@mui/joy/styles';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/zoom/zoom.min.css'
import ImageViewer from "../etc/imageviewer/imageviewer";

import { LinearProgress, TextareaAutosize } from "@mui/material";

import { Camera, CameraResultType } from "@capacitor/camera";
import { NativeSettings, AndroidSettings, IOSSettings } from "capacitor-native-settings";
import { useIonAlert, IonLoading } from "@ionic/react";
import { Filesystem } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { Socket } from "socket.io-client";
import { getFetch } from "../../fetch/fetch";
import { ReactComponent as Trash } from "../../../src/svg/mobiletotal/trash-can-xmark-light.svg";

interface props {
    isOpen: boolean;
    handleClose: () => void;
    eachQuestion: any;
    understand: (e: any, questionId: number, userId: number, isUnderStand: boolean) => Promise<void>;
    user: any;
    socket: Socket;
    handleUpdate: () => void;
}


const MobileOnlineQuestionDetail: React.FC<props> = (props) => {

    const headerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const handleClose = () => { setIsModalOpen(false); setImgSrc(""); }
    const [messageText, setMessageText] = useState("");

    const [bodyHeight, setBodyHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const bodyRef = useRef<HTMLDivElement>(null);


    const [isFocus, setIsFocus] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const [presentAlert] = useIonAlert();
    const [showLoading, setShowLoading] = useState(false);

    const [imageWebpathArray, setImageWebPathArray] = useState<any>([]);
    const [imageNativePathArray, setImageNativePathArray] = useState<any>([]);
    const [images, setImages] = useState<any>([]);

    const [reviews, setReviews] = useState<any>([]);
    const [update, setUpdate] = useState(0);

    const [present] = useIonActionSheet();

    var updateVar = 0;

    useEffect(() => {

        if (!props.isOpen) {
            setReviews([]);
            return;
        }

        if (props.isOpen) {
            var isByUpdate = false;

            if (update !== updateVar) {
                isByUpdate = true;
            }

            getReivews(isByUpdate);
        }


    }, [props.isOpen, update]);

    const getReivews = async (isByUpdate: boolean) => {

        setShowLoading(true);

        try {
            const result: any = await getFetch(`/dashboard/question/answer/get?questionId=${props.eachQuestion.id}`);
            console.log(result);
            console.log("review");
            if (result.message === "success") {
                setReviews(result.data);
                if (isByUpdate) {
                    setTimeout(() => {
                        if (bodyRef && bodyRef.current) {
                            bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
                        }
                    }, 20);
                }
            }

        } catch (e) {
            console.log(e);
            return;
        }

        setShowLoading(false);
    }


    const calculateBodyHeight = () => {
        setTimeout(() => {

            if (!headerRef || !headerRef.current) {
                return;
            }

            if (!footerRef || !footerRef.current) {
                return;
            }

            const totalHeight = window.innerHeight;

            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;
            const footerHeight = footerRef.current.offsetHeight;
            setFooterHeight(footerHeight);

            const lastHeight = totalHeight - safeareaTop - headerHeight - footerHeight;

            console.log("height");
            console.log(lastHeight);
            setBodyHeight(lastHeight);

        }, 1);
    }

    useEffect(() => {

        setTimeout(() => {

            if (!footerRef || !footerRef.current) {
                return;
            }

            const footerHeight = footerRef.current.offsetHeight;
            setFooterHeight(footerHeight);

        }, 5);

    }, [isFocus, messageText]);

    useEffect(() => {

        if (props.isOpen === false) {
            setMessageText("");
            setImageWebPathArray([]);
            setImageNativePathArray([]);
            setImages([]);

            return;
        }

        calculateBodyHeight();

        window.addEventListener("resize", () => calculateBodyHeight());

        return window.removeEventListener("resize", () => calculateBodyHeight());

    }, [props.isOpen]);


    const handleImgClick = (newImgSrc: string) => {
        setImgSrc(newImgSrc);
        setIsModalOpen(true);
    }

    const handleChange = (e: any) => {
        setMessageText(e.target.value);
    }

    const handleFocus = (kind: string) => {

        switch (kind) {
            case "focus":
                setIsFocus(true);
                break;
            case "blur":
                setIsFocus(false);
                break;
        }
    }

    useEffect(() => {
        console.log(11111);
    }, []);

    useEffect(() => {
        if (messageText) {
            setIsReady(true);
        } else {
            setIsReady(false);
        }
    }, [messageText]);


    const getImage = async () => {

        const permissionStatus = await Camera.checkPermissions();

        console.log(permissionStatus);

        if (permissionStatus.camera === "denied" || permissionStatus.photos === "denied") {
            console.log("go");

            presentAlert({
                header: "접근 권한이 필요합니다",
                subHeader: "카메라, 사진첩 접근 권한이 필요합니다.",
                buttons: [
                    {
                        text: "Cancel",
                        role: "cancel",
                    },
                    {
                        text: "OK",
                        role: "confirm",
                        handler: () => {
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]
            })

            return;
        }


        var image;

        try {
            image = await Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Uri,
            })
        } catch (e) {
            console.log(e);
            return;
        }

        if (!image) {
            return;
        }
        setShowLoading(true);



        if (image.webPath) {
            const newImageWebPathArray = [image.webPath];
            setImageWebPathArray(newImageWebPathArray);
        }

        if (Capacitor.isNativePlatform()) {

            if (!image.path) {
                console.log("noImagePath");
                return;
            }

            const newImageNativePathArray = [image.path]
            setImageNativePathArray(newImageNativePathArray);

            const base64data : any = await Filesystem.readFile({
                path: image.path
            });

            const byteData : any = atob(base64data.data);

            const byteNumbers = new Array(byteData.length);

            for (var i = 0; i < byteData.length; i++) {
                byteNumbers[i] = byteData.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            console.log(byteArray);

            const blob = new Blob([byteArray], { type: "image/jpeg" });

            console.log("blob");
            console.log(blob.size);
            console.log(blob.type);

            const file = new File([blob], "opinionImage.jpeg", { type: blob.type });
            console.log(file.name);

            const newImages: any = [file];
            setImages([...newImages]);
        } else {

            if (!image.webPath) {
                return;
            }

            const byteArray = await fetch(image.webPath);
            const blob = await byteArray.blob();
            console.log(blob);

            const file = new File([blob], "opinionImage.jpeg", { type: blob.type });
            console.log(file.name);

            const newImages: any = [file]
            setImages([...newImages]);
        }

        setShowLoading(false);
    }

    const removeImage = (index: number) => {
        const newImages = images.filter((eachImage: any, imageIndex: any) =>
            index !== imageIndex
        );

        const newImageWebPathArray = imageWebpathArray.filter((eachImagePath: any, imagePathIndex: any) =>
            index !== imagePathIndex
        );

        const newImageNativePathArray = imageNativePathArray.filter((eachImagePath: any, imagePathIndex: number) =>
            index !== imagePathIndex
        );

        setImages([...newImages]);
        setImageWebPathArray([...newImageWebPathArray]);
        setImageNativePathArray([...newImageNativePathArray]);

    }


    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    function niceBytes(x: any) {

        let l = 0, n = parseInt(x, 10) || 0;

        while (n >= 1024 && ++l) {
            n = n / 1024;
        }

        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + '' + units[l]);
    }


    const submit = async (event: any, questionId: number, questionUserId: number) => {
        event.preventDefault();
        setShowLoading(true);

        var formData = new FormData();
        var message: any = { message: messageText, questionId: questionId, author: props.user.name, userId: props.user.id };
        formData.append("message", JSON.stringify(message));

        images.forEach((file: any) => {
            formData.append("answer_picture", file);
        })

        console.log(message);


        console.log(message);

        var token: any = "";

        if (window.electron) {
            token = window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/question/answer/write", {
            method: "POST",
            headers: { "Authorization": token },
            credentials: "include",
            body: formData
        }).then((response) => {
            response.json()
                .then((response) => {
                    console.log(response);
                    if (response.message === "success") {

                        setMessageText("");
                        setImageNativePathArray([]);
                        setImageWebPathArray([]);
                        setImages([]);

                        console.log("----------------")
                        console.log(props.user.id);
                        props.socket.emit("newAnswer", questionUserId, props.user.id);
                        props.handleUpdate();


                        const updateNumber = Math.random();
                        setUpdate(updateNumber);
                    }

                    setShowLoading(false);
                })
        }).catch((error) => {
            console.log(error);
        })

    }



    //본인 글 삭제하는 기능------------------------------------------------------------------------
    const reviewDelete = async (reviewId: number) => {

        setShowLoading(true);

        var token: any = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch(`https://peetsunbae.com/dashboard/question/review/delete?id=${reviewId}`, {
            method: "DELETE",
            headers: { "Authorization": token },
            credentials: "include"
        }).then((response) => {
            response.json()
                .then((result) => {
                    console.log(result.message);
                    if (result.message === "success") {
                        const newUpdate = Math.random();
                        updateVar = newUpdate;
                        setUpdate(newUpdate);
                        props.handleUpdate();
                    }
                    setShowLoading(false);
                })
        }).catch((error) => {
            console.log(error);
            setShowLoading(false);
        })
    }
    //---------------------------------------------------------------------------------------------

    const letsDelete = (reviewId: number) => {
        present({
            header: "삭제 안내",
            subHeader: "삭제를 진행할까요?",
            buttons: [{
                text: "삭제",
                role: "destructive"
            },
            {
                role: "cancel",
                text: "취소",
            }
            ],
            onDidDismiss: ({ detail }) => {
                console.log(detail.role);
                switch (detail.role) {
                    case "destructive":
                        reviewDelete(reviewId);
                        break;
                    case "cancel":
                        break;
                }
            },
        })
    }



    return (
        <IonModal
            isOpen={props.isOpen}
            onDidDismiss={props.handleClose}
        >
            <InnerHeaderForModal headerRef={headerRef} handleClose={props.handleClose} />
            {
                showLoading
                &&
                <LinearProgress />
            }

            <div className={styles.border}>
            </div>

            {
                !showLoading
                &&
                <div>
                    <div className={styles.body} ref={bodyRef} style={{ height: `${bodyHeight}px`, overflow: "scroll" }}>

                        <div className={styles.eachQuestion}>
                            <div className={styles.eachQuestionName}>
                                <IonAvatar className={styles.avatar}>
                                    <img alt="Silhouette of a person's head" src={(props.eachQuestion && props.eachQuestion.avatar) ? `/img/avatar/Avatar-${props.eachQuestion ? props.eachQuestion.avatar : ""}.png` : "https://ionicframework.com/docs/img/demos/avatar.svg"} className={styles.avatarImg} />
                                </IonAvatar>
                                <div className={styles.eachQuestionNameText}>
                                    <div className={styles.author}>
                                        {props.eachQuestion ? props.eachQuestion.email.split("@")[0] : ""}
                                    </div>
                                    <div className={styles.dateString}>
                                        {
                                            (props.eachQuestion && props.eachQuestion.createdAt) ?
                                                `${props.eachQuestion.createdAt.year}/${props.eachQuestion.createdAt.month}/${props.eachQuestion.createdAt.date} ${props.eachQuestion.createdAt.hours > 12 ? "PM" : "AM"} ${props.eachQuestion.createdAt.hours > 12 ? props.eachQuestion.createdAt.hours - 12 : props.eachQuestion.createdAt.hours}:${props.eachQuestion.createdAt.minutes < 10 ? "0" + props.eachQuestion.createdAt.minutes : props.eachQuestion.createdAt.minutes}`
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={styles.eachQuestionDescription}>
                                <div className={styles.eachQuestionTitle}>
                                    {props.eachQuestion ? props.eachQuestion.title : ""}
                                </div>
                                <div className={styles.eachQuestionText}>
                                    {props.eachQuestion ? props.eachQuestion.description : ""}
                                </div>
                            </div>

                            <div className={styles.imagesWrapper}>
                                <Swiper
                                    modules={[Pagination, Zoom]}
                                    pagination
                                    zoom={{ maxRatio: 3.5, minRatio: 1 }}
                                >
                                    {
                                        props.eachQuestion && props.eachQuestion.images.map((eachImageSrc: string, imageIndex: number) => {
                                            return (
                                                <SwiperSlide key={imageIndex}>
                                                    <CssVarsProvider>
                                                        <AspectRatio ratio="1/0.64">
                                                            <div className={styles.imageBox}
                                                            //  onClick={(e) => {handleImgClick(`https://peetsunbae.com/${eachImageSrc.split("/public/")[1]}`);}}
                                                            >
                                                                <div className="swiper-zoom-container">
                                                                    <img className={styles.questionImg} src={`https://peetsunbae.com/${eachImageSrc.split("/public/")[1]}`} />
                                                                </div>
                                                                <div className={styles.expandDiv}
                                                                    onClick={(e) => { handleImgClick(`https://peetsunbae.com/${eachImageSrc.split("/public/")[1]}`); }}
                                                                >
                                                                    <Exapnd fill="#e0e0e0" className={styles.expand} />
                                                                </div>
                                                            </div>

                                                        </AspectRatio>
                                                    </CssVarsProvider>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                            <div className={styles.eachQuestionFooter}>
                                <div className={styles.eachQuestionInnerFooter}>
                                    <div className={styles.likeDiv} onClick={(e) => { props.understand(e, props.eachQuestion.id, props.eachQuestion.userId, props.eachQuestion.isUnderStand) }}>
                                        <Thumb fill={(props.eachQuestion && props.eachQuestion.isUnderStand) ? "#3d50b0" : "#d0d0d0"} className={styles.thumb} />
                                        <span className={`${styles.understandText} ${(props.eachQuestion && props.eachQuestion.isUnderStand) ? styles.active : ""}`}>
                                            이해했어요
                                        </span>
                                    </div>
                                    <div className={styles.replyDiv}>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.replyDiv} ${!reviews || (reviews.length === 0) ? styles.none : ""}`}>
                            {
                                reviews &&
                                reviews.map((eachReview: any, index: number) => {

                                    const date = new Date(+eachReview.time);
                                    eachReview.createdAt = { year: date.getFullYear(), month: date.getMonth() + 1, date: date.getDate(), hours: date.getHours(), minutes: date.getMinutes() }

                                    return (
                                        <div className={styles.eachReply} key={eachReview.id}>
                                            <div className={styles.replyAvatarDiv}>
                                                <IonAvatar className={styles.avatar}>
                                                    <img alt="Silhouette of a person's head" src={(eachReview.avatar) ? `/img/avatar/Avatar-${eachReview.avatar}.png` : "https://ionicframework.com/docs/img/demos/avatar.svg"} className={styles.avatarImg} />
                                                </IonAvatar>
                                            </div>
                                            <div className={styles.replyDescriptionDiv}>
                                                <div className={styles.name}>
                                                    {eachReview.value === "teacher" ? eachReview.userName : eachReview.email.split("@")[0]}
                                                </div>
                                                <div className={styles.reviewDescription}>
                                                    {eachReview.description}
                                                </div>
                                                {
                                                    eachReview.src &&
                                                    <div className={styles.reviewImageDiv} onClick={(e) => { handleImgClick(`https://peetsunbae.com/${eachReview.src.split("/public/")[1]}`); }}>
                                                        <img className={styles.reviewImage} src={`https://peetsunbae.com/${eachReview.src.split("/public/")[1]}`} />
                                                    </div>
                                                }
                                                <div className={styles.reviewDate}>
                                                    {`${eachReview.createdAt.year}/${eachReview.createdAt.month}/${eachReview.createdAt.date} ${eachReview.createdAt.hours > 12 ? "PM" : "AM"} ${eachReview.createdAt.hours > 12 ? eachReview.createdAt.hours - 12 : eachReview.createdAt.hours}:${eachReview.createdAt.minutes < 10 ? "0" + eachReview.createdAt.minutes : eachReview.createdAt.minutes}`}
                                                </div>
                                            </div>
                                            {
                                                eachReview.userId === props.user.id
                                                &&
                                                <Trash onClick={(e) => { letsDelete(eachReview.id); }} className={styles.trash} />
                                            }
                                        </div>

                                    );
                                })
                            }

                        </div>

                    </div>

                </div>
            }



            {
                (imageWebpathArray.length > 0 && images.length > 0)
                    ?
                    <div className={styles.imageInfoDiv} style={{ position: "fixed", width: "100%", bottom: `${footerHeight}px` }}>
                        <div className={styles.uploadImageDiv} onClick={(e) => { handleImgClick(imageWebpathArray[0]); }}>
                            <img src={imageWebpathArray[0]} className={styles.uploadImage} />
                        </div>
                        <div className={styles.uploadImageDescriptionDiv}>
                            <div className={styles.uploadImageName}>
                                첨부파일-1
                            </div>
                            <div className={styles.uploadImageSize}>
                                {niceBytes(images[0].size)}
                            </div>
                        </div>
                        <div className={styles.cancelBtnDiv} onClick={(e) => { removeImage(0); }}>
                            <XMark fill="#bdbdbd" className={styles.xMark} />
                        </div>
                    </div>
                    :
                    ""
            }
            <div ref={footerRef} className={`${styles.bottomTextDiv} ${isFocus ? styles.focus : ""}`}>
                <div className={styles.bottomTextWrapper}>
                    <div className={styles.plusDiv} onClick={(e) => { getImage(); }}>
                        <Plus fill="#9e9e9e" className={styles.plus} />
                    </div>
                    <div className={styles.inputTextWrapper}>
                        <TextareaAutosize onFocus={(e) => { handleFocus("focus"); }} onBlur={(e) => { handleFocus("blur"); }} value={messageText} onChange={handleChange} className={styles.input} placeholder="댓글 쓰기..." />
                    </div>
                    <div className={styles.arrowDiv}
                        onClick={(e) => { submit(e, props.eachQuestion.id, props.eachQuestion.userId) }}
                    >
                        <ArrowUp fill={isReady ? "33d50b0" : "#e0e0e0"} className={styles.arrowUp} />
                    </div>
                </div>
            </div>

            <ImageViewer
                isModalOpen={isModalOpen}
                handleClose={handleClose}
                src={imgSrc}
            />


            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}

        </IonModal>
    );
}

export default MobileOnlineQuestionDetail;