import React, { useEffect, useState } from "react";
import styles from "../../componentsStyle/manageregular.module.css";
import Divider from "../data/divider";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ManageRegularTable from "./manageregulartable";
import totalLocationData from "../../../data/locationdata";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

var currentNumber = 0;


const ManageRegular: React.FC<any> = (props) => {

    const [month, setMonth] = useState<any>();
    const [year, setYear] = useState<any>();
    const [data, setData] = useState<any>();
    const [update, setUpdate] = useState<any>(0);
    const [alignment, setAliment] = useState("this");
    const [alignment2, setAlignment2] = useState("total");
    const [how, setHow] = useState("total");
    const [maxNumber, setMaxNumber] = useState(5);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };



    const letsUpdate = () => {
        setUpdate(Math.random());
    }

    useEffect(() => {

        if (alignment === "before") {
            const date = new Date(new Date().getFullYear(), new Date().getMonth() - 1);
            setYear(date.getFullYear());
            setMonth(date.getMonth() + 1);
        }

        if (alignment === "this") {
            const date = new Date();
            setYear(date.getFullYear());
            setMonth(date.getMonth() + 1);
        }

        if (alignment === "next") {
            const date = new Date(new Date().getFullYear(), new Date().getMonth() + 1);
            setYear(date.getFullYear());
            setMonth(date.getMonth() + 1);
        }

    }, [alignment]);


    useEffect(() => {

        if (!month || !year) {
            return;
        }

        fetch("https://peetsunbae.com/dashboard/chart/regularSchedule/totalget?month=" + month + "&year=" + year, {
            method: "GET",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    console.log(result);

                    result.data.sort(function (a: any, b: any) {
                        if (a.staffpermit === 0 && b.staffpermit === 1) {
                            return -1;
                        } else if (a.staffpermit === 0 && b.staffpermit === 2) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });

                    console.log("newdata");
                    console.log(result.data);

                    result.data.sort(function (a: any, b: any) {
                        if (a.staffpermit === 2 && b.staffpermit === 1) {
                            return -1;
                        } else {
                            return 1;
                        }
                    })

                    result.data.sort((a: any, b: any) => {
                        if (a.parentpermit === 0 && b.parentpermit === 1) {
                            return -1
                        }
                        if (a.parentpermit === 0 && b.parentpermit === 2) {
                            return -1;
                        }
                        if (a.parentpermit === 2 && b.parentpermit === 1) {
                            return -1;
                        }
                    })

                    if(how === "submit"){
                        result.data = result.data.filter((eachData: any) => {
                            if(eachData.staffpermit !== 0){
                                return false;
                            }else{
                                return true;
                            }
                        })
                    }

                    if(how === "staff"){
                        result.data = result.data.filter((eachData: any) => {
                            if(eachData.staffpermit === 0 || eachData.parentpermit !== 0){
                                return false;
                            }else{
                                return true;
                            }
                        })
                    }

                    if(how === "parent"){
                        result.data = result.data.filter((eachData: any) => {
                            if(eachData.parentpermit === 0){
                                return false;
                            }else{
                                return true;
                            }
                        })
                    }

                    // if(alignment2 === "daechi2"){
                    //     result.data = result.data.filter((eachData: any) => {
                    //         if(eachData.location !== "대치3층"){
                    //             return false;
                    //         }else{
                    //             return true;
                    //         }
                    //     })
                    // }

                    // if(alignment2 === "daechi3"){
                    //     result.data = result.data.filter((eachData: any) => {
                    //         if(eachData.location !== "대치6층"){
                    //             return false;
                    //         }else{
                    //             return true;
                    //         }
                    //     })
                    // }

                    // if(alignment2 === "gangnam"){
                    //     result.data = result.data.filter((eachData: any) => {
                    //         if(eachData.location !== "강남점"){
                    //             return false;
                    //         }else{
                    //             return true;
                    //         }
                    //     })
                    // }

                    // if(alignment2 === "daechi"){
                    //     result.data = result.data.filter((eachData: any) => {
                    //         if(eachData.location !== "대치점"){
                    //             return false;
                    //         }{
                    //             return true;
                    //         }
                    //     })
                    // }

                    totalLocationData.forEach((eachLocation) => {

                        if(eachLocation.english === "total"){
                            return true;
                        }

                        if(eachLocation.english === alignment2){
                            result.data = result.data.filter((eachData: any) => {
                                if(eachData.location !== eachLocation.korean){
                                    return false;
                                }else{
                                    return true;
                                }
                            })
                        }

                    });

                    //maxNumber보다 크면 maxNumber만큼만 보여주기
                    if(result.data.length > maxNumber && value === 0){
                        result.data = result.data.slice(0, maxNumber);
                    }

                    if (result.message === "success") {
                        setData(result.data);
                    }
                })
        })

    }, [month, update, year, how, alignment2, maxNumber, value]);

    useEffect(() => {

        if(!data){
            return;
        }

        if(value === 0){
            
            const newData = data;

            newData.sort(function (a: any, b: any) {
                if (a.staffpermit === 0 && b.staffpermit === 1) {
                    return -1;
                } else if (a.staffpermit === 0 && b.staffpermit === 2) {
                    return -1;
                } else {
                    return 1;
                }
            });



            

            newData.sort(function (a: any, b: any) {
                if (a.staffpermit === 2 && b.staffpermit === 1) {
                    return -1;
                } else {
                    return 1;
                }
            })

            newData.sort((a: any, b: any) => {
                if (a.parentpermit === 0 && b.parentpermit === 1) {
                    return -1
                }
                if (a.parentpermit === 0 && b.parentpermit === 2) {
                    return -1;
                }
                if (a.parentpermit === 2 && b.parentpermit === 1) {
                    return -1;
                }
            })

            setData([...newData]);


        }

    }, [value]);


    const handleAlignmentChange = (e: any, newAlignment: string) => {

        if(!newAlignment){
            return;
        }

        setAliment(newAlignment);
    }

    const handleAlignmentChange2 = (e : any, newAlignment : string) => {

        if(!newAlignment){
            return;
        }

        setAlignment2(newAlignment);

    }

    const handleSelectChange = (e: any) => {

    }

    

    return (
        <div>

            <div style={{ width: "900px", margin: "0 auto" }}>
                <Box sx={{ width: "100%", marginBottom: "0px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                            <Tab label="승인하기" {...a11yProps(0)} />
                            <Tab label="표로 보기" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                </Box>
            </div>


            <div className={styles.main}>
                <div className={styles.whichMonth} style={{ width: value === 0 ? "650px" : "1650px", margin: "0 auto", marginBottom : "24px" }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment2}
                        exclusive
                        onChange={handleAlignmentChange2}
                    >
                        {/* <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="gangnam">강남</ToggleButton>
                        <ToggleButton value="daechi">대치</ToggleButton>
                        <ToggleButton value="daechi2">대치3층</ToggleButton>
                        <ToggleButton value="daechi3">대치6층</ToggleButton> */}
                        {
                            props.user && totalLocationData.filter((each) => each.academy.includes(props.user.academy)).map((each, index) => {
                                return (
                                    <ToggleButton key={index} value={each.english}>{each.korean}</ToggleButton>
                                );
                            })
                        }

                    </ToggleButtonGroup>
                </div>
                <div className={styles.whichMonth} style={{ width: value === 0 ? "650px" : "1650px", margin: "0 auto" }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                    >
                        <ToggleButton value="before">지난달</ToggleButton>
                        <ToggleButton value="this">이번달</ToggleButton>
                        <ToggleButton value="next">다음달</ToggleButton>
                    </ToggleButtonGroup>
                </div>

                {
                    value === 1
                    &&
                    <ManageRegularTable data={data} alignment2={alignment2} />
                }

                {
                    value === 0
                    &&
                    <div style={{ width: "650px", margin: "0 auto", marginTop: "48px" }}>
                        <div className={styles.title}>
                            {month}월 정기일정 관리
                            <div style={{ fontSize: "0.8rem", fontFamily: "Apple_L", marginTop: "8px" }}>
                                * 승인 버튼을 다시 누르면 학부모에게 승인을 요청하는 알림톡이 다시 전송됩니다.
                                <br></br>
                                학부모님이 못 보셨을 수도 있으니 학부모 승인이 안나면 승인을 다시 눌러보세요.
                            </div>
                        </div>
                        <div className={styles.whichShow} style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                                <div
                                style={{
                                    width: "350px",
                                }}
                                >
                                    <Select defaultValue="total" onChange={(e: any, newValue: any) => { setHow(newValue); }}>
                                        <Option value="total">전체 보기</Option>
                                        <Option value="submit">제출만 끝낸 단계만 보기</Option>
                                        <Option value="staff">사감 결제까지만 끝낸 단계만 보기</Option>
                                        <Option value="parent">학부모 결제까지 모두 끝낸 단계만 보기</Option>
                                    </Select>
                                </div>
                                <div>
                                    <Select defaultValue="5" onChange={(e: any, newValue: any) => { setMaxNumber(+newValue); }}>
                                        <Option value="5">5개씩 보기</Option>
                                        <Option value="10">10개씩 보기</Option>
                                        <Option value="30">30개씩 보기</Option>
                                        <Option value="9999">전체 보기</Option>
                                    </Select>
                                </div>
                        </div>
                        <div className={styles.dividerDiv}>
                            {
                                data &&
                                data.map((eachData: any, index: number) => {

                                    

                                    const modifiedAt = eachData.modifiedAt;


                                    const modifiedDate = new Date(modifiedAt);
                                    const modifiedString = `${modifiedDate.getMonth() + 1}월 ${modifiedDate.getDate()}일 ${modifiedDate.getHours()}시 수정`;


                                    return (
                                        <div key={eachData.id} className={styles.eachDividerDiv}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div className={styles.name}>
                                                    {eachData.name}
                                                </div>
                                                <div style={{ fontFamily: "Apple_L", fontSize: "14px" }}>
                                                    {modifiedString} {eachData.staffpermitName ? `(${eachData.staffpermitName} 승인처리)` : ""}
                                                </div>
                                            </div>

                                            <div className={styles.dividerDivDiv}>
                                                <Divider update={letsUpdate} data={eachData} month={month} userId={eachData.userId} />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                }


            </div>



        </div>

    );
}

export default ManageRegular;