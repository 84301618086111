import React, { useState, useEffect } from 'react';
import styles from "../money.module.css";

const ModalHeaderForMoney : React.FC<any> = (props) => {

    return (
        <div className={styles.modalHeader}>
            <div>
                {props.title}
            </div>
        </div>
    )
}

export default ModalHeaderForMoney;
