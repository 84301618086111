import React, { useEffect, useState, useRef, useCallback } from "react";
import Footer from "../etc/footer";
import styles from "./mobileedit.module.css";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as PenToSqure } from "../../../src/svg/pen-to-square-regular.svg";
import { useHistory } from "react-router-dom";
import { info } from "console";
import MobileEditSubmit from "./mobileeditsubmit";
import InnerHeader from "../etc/innerheader";
import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { IonLoading } from "@ionic/react";
import MobileEditStatus from "./mobileeditstatus";


const MobileEdit: React.FC<any> = (props) => {
    const footerRef = useRef<any>(null);
    const [platform, setPlatform] = useState("");
    const history = useHistory();

    const topDivRef = useRef<any>(null);
    // const [initialTopDivHeight, setInitialTopDivHeight] = useState(1);
    const [topDivHeight, setTopDivHeight] = useState(200);
    const [statusDivHeight, setStatusDivHeight] = useState(0);

    const [hideTop, setHideTop] = useState(false);
    const [hideBackBtn, setHideBackBtn] = useState(true);

    const [underBarPosition, setUnderBarPosition] = useState(6);
    const [currentMenu, setCurrentMenu] = useState("submit");

    const [targetDate, setTargetDate] = useState(0);

    const menuHeaderRef = useRef<any>(null);
    const arrowHeaderRef = useRef(null);

    const [loading, setLoading] = useState(false);


    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

        console.log(platform);
        console.log(platform === "ios");

    }, []);

    // //topDiv 좁아지게
    // useEffect(() => {

    //     if(topDivRef && topDivRef.current){
    //         const topDivHeight = topDivRef.current.offsetHeight;
    //         console.log("gogo");
    //         if(topDivHeight !== 0){
    //             console.log("gogogo");
    //             setInitialTopDivHeight(topDivHeight + 50);
    //             setTopDivHeight(topDivHeight);    
    //         }
    //     }

    // }, [topDivRef]);

    //상태 바 바디 높이 정하기
    useEffect(() => {
        console.log("gogogo")
        if (!menuHeaderRef || !footerRef || !menuHeaderRef.current || !footerRef.current ) {
            console.log("not Go");
            return;
        }


        const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];

        const caculateBodyHeight = () => {

            if (!menuHeaderRef || !footerRef || !menuHeaderRef.current || !footerRef.current ) {
                console.log("not Go");
                return;
            }

            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = menuHeaderRef.current.offsetHeight;
            const footerHeight = footerRef.current.offsetHeight;

            console.log(totalHeight);
            console.log(safeareaTop);
            console.log(headerHeight);
            console.log(footerHeight);

            const lastHeight = totalHeight - safeareaTop - headerHeight - footerHeight;
            console.log(lastHeight);
            console.log("lastHeight");
            setStatusDivHeight(lastHeight);
        }

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());
    }, [menuHeaderRef, footerRef, platform]);



    const moveUnderBar = (index : number) => {

        switch (index) {
            case 1 : 
                setUnderBarPosition(6);
                break;
            case 2 :
                setUnderBarPosition(54);
                break;
        }

    }

    const handleHideTop = (boolean : boolean) => {
        // setHideTop(boolean);
        setTopDivHeight(0);
        
        setTimeout(() => {
            setHideBackBtn(false);
        }, 75)
    }

    const handleShowTop = (boolean : boolean) => {

        setTopDivHeight(200);
        
        
        setTimeout(() => {
            setHideBackBtn(true);
        }, 0)
        setTargetDate(0);
    }


    //dayClick 확인
    const handleDayClick = (year : number, month : number, date : number, past : boolean) => {
        
        if(past){
            return;
        }

        handleHideTop(true);

        const dayConfigure = year * 100000 + (month + 1) * 1000 + date;
        setTargetDate(dayConfigure);
    }


    const showEditStatus = () => {
        setHideBackBtn(true);
        setCurrentMenu("status");
    }
    





    return (
        <div>
            <div style={{ paddingTop: "env(safe-area-inset-top)" }}>

                <div ref={topDivRef} className={`${styles.topDiv}`} style={{ maxHeight: `${topDivHeight}px` }}>
                    <div ref={menuHeaderRef}>
                        <div className={styles.bodyDiv}>
                            <div className={`${styles.body} ${platform === "ios" ? styles.ios : ""}`}>
                                <div className={styles.title}>
                                    사유 제출
                                </div>
                            </div>
                        </div>
                        <div className={styles.menuBar}>
                            <div className={`${styles.menuBarSection} ${currentMenu === "submit" ? styles.active : ""}`} onClick={() => { moveUnderBar(1); setCurrentMenu("submit"); }}>
                                제출하기
                            </div>
                            <div className={`${styles.menuBarStatus} ${currentMenu === "status" ? styles.active : ""}`} onClick={() => { moveUnderBar(2); setCurrentMenu("status"); }}>
                                제출현황
                            </div>
                            <div className={styles.menuUnderBar} style={{ left: `${underBarPosition}%` }}>

                            </div>
                        </div>
                    </div>
                </div>



                    {
                        currentMenu === "submit" &&
                        <div>
                            <div className={styles.backBtnDiv}>
                                <div className={`${styles.backBtn} ${hideBackBtn ? "" : styles.active} ${platform === "ios" ? styles.ios : ""}`}>
                                    <div ref={arrowHeaderRef}>
                                        <div className={styles.backBtnDiv} onClick={() => { handleShowTop(true) }}>
                                            <ArrowLeftLight
                                                className={styles.arrowLeft}
                                            />
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <MobileEditSubmit showEditStatus={showEditStatus} menuHeaderRef={menuHeaderRef} arrowHeaderRef={arrowHeaderRef} hideBackBtn={hideBackBtn} handleHideTop={handleHideTop} targetDate={targetDate} handleDayClick={handleDayClick} />
                        </div>
                    }

                    {
                        currentMenu === "status" &&
                        <div style={{ height: `${statusDivHeight}px`, overflow: "scroll" }}>
                            <MobileEditStatus statusDivHeight={statusDivHeight} />
                        </div>
                    }

                </div>








            {
                hideBackBtn &&
                <Footer unreadMessage={props.unreadMessage} unread={props.unread} footerRef={footerRef} location="edit" />
            }

            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            />
        </div>
    );
}

export default MobileEdit;