import React, { useState, useEffect } from 'react';

const Payment : React.FC<any> = (props) => {


    return (
        <div>
            Payment
        </div>
    )
}

export default Payment;