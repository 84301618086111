import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styles from "../../componentsStyle/attendance.module.css";
import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';



import TextField from "@mui/material/TextField";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, Stack } from '@mui/material';

import koLocale from 'date-fns/locale/ko'
import { CssVarsProvider } from '@mui/joy';
import totalLocationData from '../../../data/locationdata';


type currentSideBarMenuList = "home" | "notification" | "alarm" | "edit" | "book" | "question" | "restaurant" | "envelope" | "search" | "chart" | "attendance" | "출석 관리 보고";



const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 5,
    borderRadius: 4
};

var i = -1;

const WorkloadModal: React.FC<any> = (props: any) => {

    const [disabled, setDisabled] = useState(true);

    const [loading, setLoading] = useState(false);
    const [uploadBool, setUploadBool] = useState(false);

    const [searchMenu, setSearchMenu] = useState("write");
    const [show, setShow] = useState("no");
    const [update, setUpdate] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [selectedId, setSelectedId] = useState<number>();
  
    const [location, setLocation] = useState([false, false, false]);
    const [day, setDay] = useState([false, false, false, false, false, false, false]);
    const [description, setDescription] = useState("");
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {

        if(props.open){
            console.log(props.id);
            console.log(props.location);
            console.log(props.day);
            console.log(props.startTime);
            console.log(props.endTime);
            console.log(props.description);

            setSelectedId(props.id);
            setLocation(props.location);
            setDay(props.day);
            setStartTime(props.startTime);
            setEndTime(props.endTime);
            setDescription(props.description);


        }

    }, [props.open]);



    const change = (e: any, type: string) => {
        const parsedType = type.split("-")[0];
        const index = +(type.split("-")[1] ? type.split("-")[1] : 99);
        console.log(parsedType);

        switch (parsedType) {
            case "location":
                const newLocation = location;
                newLocation[index] = e.target.checked;
                setLocation([...newLocation]);
                break;
            case "day":
                const newDay = day;
                newDay[index] = e.target.checked;
                setDay([...newDay]);
                break;
            case "description":
                setDescription(e.target.value);
                break;
            case "show":
                setShow(e.target.value);
                break;
        }
    }


    useEffect(() => {

        var locationCount = 0;

        location.forEach((each) => {
            if (each) {
                locationCount++;
            }
        });

        var dayCount = 0;

        day.forEach((each) => {
            if (each) {
                dayCount++;
            }
        })

        var timeCount = 0;

        if (new Date(startTime).getTime() <= new Date(endTime).getTime()) {
            timeCount++;
        }

        if (locationCount && dayCount && timeCount && description) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }


    }, [location, day, startTime, endTime, description, show]);

    const submit = async (e: any) => {
        setLoading(true);

        if(!selectedId || !location || !day || !startTime || !description){
            console.log("noData");
            return;
        }

        const message = {
            selectedId, location, day, startTime, endTime, description, show
        }

        console.log(message);

        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/report/totalwork", {
            method: "PATCH",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(message)
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    setLoading(false);
                    if (result.message === "success") {
                        setDescription("");
                        setUploadBool(true);
                        props.handleRefresh();
                        setTimeout(() => {
                            setUploadBool(false);
                        }, 1000);
                    }
                })
        })
    }


    useEffect(() => {
        const date = new Date();
        setStartTime(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9));
        setEndTime(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9));
    }, [])

    return (

        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className={styles.blockDiv}>
                    <div>위치</div>
                    <FormGroup row>
                        {/* <FormControlLabel control={<Checkbox checked={location[0]} onChange={(e) => { change(e, "location-0") }} />} label={<span className={styles.text}>강남 데스크</span>} />
                        <FormControlLabel control={<Checkbox checked={location[1]} onChange={(e) => { change(e, "location-1") }} />} label={<span className={styles.text}>강남 사감</span>} />
                        <FormControlLabel control={<Checkbox checked={location[2]} onChange={(e) => { change(e, "location-2") }} />} label={<span className={styles.text}>대치 데스크</span>} />
                        <FormControlLabel control={<Checkbox checked={location[3]} onChange={(e) => { change(e, "location-3") }} />} label={<span className={styles.text}>대치 사감</span>} />
                        <FormControlLabel control={<Checkbox checked={location[4]} onChange={(e) => { change(e, "location-4") }} />} label={<span className={styles.text}>대치 3층 데스크</span>} />
                        <FormControlLabel control={<Checkbox checked={location[5]} onChange={(e) => { change(e, "location-5") }} />} label={<span className={styles.text}>대치 3층 사감</span>} />
                        <FormControlLabel control={<Checkbox checked={location[6]} onChange={(e) => { change(e, "location-6") }} />} label={<span className={styles.text}>대치 6층 데스크</span>} />
                        <FormControlLabel control={<Checkbox checked={location[7]} onChange={(e) => { change(e, "location-7") }} />} label={<span className={styles.text}>대치 6층 사감</span>} /> */}
                        {
                            props.user && totalLocationData.map((eachLocation, index) => {

                                if(eachLocation.english === "total"){
                                    return;
                                }

                                if(!eachLocation.jobs){
                                    return;
                                }

                                if(!eachLocation.academy.includes(props.user.academy)){
                                    return;
                                }


                                return eachLocation.jobs.map((eachJob,index2) => {

                                    const locationIndex = eachJob.index;

                                    return (
                                        <FormControlLabel control={<Checkbox checked={location[locationIndex]} onChange={(e) => { change(e, `location-${locationIndex}`) }} />} label={<span className={styles.text}>{eachJob.korean}</span>} />
                                    )
                                })

                            })
                        }
                    </FormGroup>
                </div>
                <div className={styles.blockDiv}>
                    <div>요일11</div>
                    <FormGroup row>
                        <FormControlLabel control={<Checkbox checked={day[1]} onChange={(e) => { change(e, "day-1") }} />} label={<span className={styles.text}>월</span>} />
                        <FormControlLabel control={<Checkbox checked={day[2]} onChange={(e) => { change(e, "day-2") }} />} label={<span className={styles.text}>화</span>} />
                        <FormControlLabel control={<Checkbox checked={day[3]} onChange={(e) => { change(e, "day-3") }} />} label={<span className={styles.text}>수</span>} />
                        <FormControlLabel control={<Checkbox checked={day[4]} onChange={(e) => { change(e, "day-4") }} />} label={<span className={styles.text}>목</span>} />
                        <FormControlLabel control={<Checkbox checked={day[5]} onChange={(e) => { change(e, "day-5") }} />} label={<span className={styles.text}>금</span>} />
                        <FormControlLabel control={<Checkbox checked={day[6]} onChange={(e) => { change(e, "day-6") }} />} label={<span className={styles.text}>토</span>} />
                        <FormControlLabel control={<Checkbox checked={day[0]} onChange={(e) => { change(e, "day-0") }} />} label={<span className={styles.text}>일</span>} />
                    </FormGroup>
                </div>
                <div className={styles.blockDiv}>
                    <div className={styles.timeDiv}>수행 시간</div>
                    <div className={styles.timeSelect}>
                        <div>
                            <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    value={startTime}
                                    onChange={(newValue: any) => {
                                        console.log(newValue);
                                        setStartTime(newValue);
                                    }}
                                    renderInput={(params: any) => <TextField sx={{ width: "160px" }} {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className={styles.dash}>
                            ~
                        </div>
                        <div>
                            <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    value={endTime}
                                    onChange={(newValue: any) => {
                                        console.log(newValue);
                                        setEndTime(newValue);
                                    }}
                                    renderInput={(params: any) => <TextField sx={{ width: "160px" }} {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
                <div className={styles.blockDiv}>
                    <div className={styles.workDescription}>
                        업무내용
                    </div>
                    <div>
                        <TextField value={description} onChange={(e) => { change(e, "description") }} fullWidth id="outlined-basic" variant="outlined" />
                    </div>
                </div>
                <div className={styles.blockDiv}>
                    <div>
                        학생 노출
                    </div>
                    <div>
                        <RadioGroup value={show} onChange={(e) => { change(e, "show") }} row aria-label="gender" name="row-radio-buttons-group">
                            <FormControlLabel value="yes" control={<Radio />} label={<span className={styles.text}>YES</span>} />
                            <FormControlLabel value="no" control={<Radio />} label={<span className={styles.text}>NO</span>} />
                        </RadioGroup>
                    </div>
                </div>
                <div className={styles.submit}>
                    <CssVarsProvider>
                        <Button disabled={disabled} size="lg" fullWidth variant="solid" onClick={submit}>수정하기</Button>
                    </CssVarsProvider>
                </div>

                {loading &&
                    <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
                        <LinearProgress />
                    </Box>
                }



                {uploadBool &&
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2 }}><span className={styles.spanStyles2}>업로드 성공 !</span></Alert>
                    </Stack>
                }
            </Box>
        </Modal>


    )
}

export default WorkloadModal;