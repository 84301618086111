import React, { useEffect, useRef, useState } from "react";
import InnerHeader from "../etc/innerheader";
import styles from "./mobileconsultreport.module.css";
import { Preferences } from "@capacitor/preferences";
import { Capacitor } from "@capacitor/core";
import { IonLoading } from "@ionic/react";
import { ReactComponent as ChevronLeft } from "../../../src/svg/mobiletotal/chevron-left-light.svg";
import { ReactComponent as ChevronRight } from "../../../src/svg/mobiletotal/chevron-right-light.svg";
import { LinearProgress } from "@mui/material";


const MobileConsultReport: React.FC<any> = (props) => {

    const headerRef = useRef<HTMLDivElement>(null);
    const [bodyHeight, setBodyHeight] = useState(0);
    const [index, setIndex] = useState(0)
    const [loading, setLoading] = useState(false);

    const [month, setMonth] = useState<any>();
    const [date, setDate] = useState<any>();
    const [teacherName, setTeacherName] = useState("");

    const [data, setData] = useState<{ index: number; value: any; }[]>([]);


    const caculateBodyHeight = () => {
        setTimeout(() => {

            if (!headerRef || !headerRef.current) {
                return;
            }


            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;

            const lastHeight = totalHeight - safeareaTop - headerHeight

            console.log("height");
            console.log(lastHeight);
            setBodyHeight(lastHeight);

        }, 50);
    }


    useEffect(() => {

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef]);


    useEffect(() => {

        if (!props.user) {
            return;
        }

        console.log("---------");
        async function start() {
            setLoading(true);
            console.log(111);

            var token: any = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                token = value;
            }

            fetch(`https://peetsunbae.com/dashboard/chart/before?userId=${props.user ? props.user.id : ""}&index=${index}`, {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        if (result.message === "success") {
                            console.log(result);
                            setMonth(result.month);
                            setDate(result.date);
                            setTeacherName(result.data.name);

                            const information = result.data.information;

                            const newData = [
                                {
                                    index: 7,
                                    value: information.correctChemistry
                                },
                                {
                                    index: 8,
                                    value: information.correctOrganic
                                },
                                {
                                    index: 9,
                                    value: information.correctPhysics
                                },
                                {
                                    index: 10,
                                    value: information.correctBiology
                                },
                                {
                                    index: 12,
                                    value: information.feedbackChemistry
                                },
                                {
                                    index: 13,
                                    value: information.feedbackOrganic
                                },
                                {
                                    index: 14,
                                    value: information.feedbackPhysics
                                },
                                {
                                    index: 15,
                                    value: information.feedbackBiology
                                },
                                {
                                    index: 18,
                                    value: information.lectureChemistry
                                },
                                {
                                    index: 19,
                                    value: information.lectureOrganic
                                },
                                {
                                    index: 20,
                                    value: information.lecturePhysics
                                },
                                {
                                    index: 21,
                                    value: information.lectureBiology
                                },
                                {
                                    index: 23,
                                    value: information.beforeWeekChemistry
                                },
                                {
                                    index: 24,
                                    value: information.beforeWeekOrganic
                                },
                                {
                                    index: 25,
                                    value: information.beforeWeekPhysics
                                },
                                {
                                    index: 26,
                                    value: information.beforeWeekBiology
                                },
                                {
                                    index: 28,
                                    value: information.nextWeekChemistry
                                },
                                {
                                    index: 29,
                                    value: information.nextWeekOrganic
                                },
                                {
                                    index: 30,
                                    value: information.nextWeekPhysics
                                },
                                {
                                    index: 31,
                                    value: information.nextWeekBiology
                                },
                                {
                                    index: 33,
                                    value: information.descriptionChemistry
                                },
                                {
                                    index: 34,
                                    value: information.descriptionOrganic
                                },
                                {
                                    index: 35,
                                    value: information.descriptionPhysics
                                },
                                {
                                    index: 36,
                                    value: information.descriptionBiology
                                },
                                {
                                    index: 38,
                                    value: information.addText
                                }
                            ]

                            setData([...newData]);

                            setLoading(false);
                        } else if (result.message === "NOT") {
                            // alert("존재하지 않습니다.");
                            setLoading(false);
                        }
                        setLoading(false);
                    })
            })
        }

        start();

    }, [index, props.user]);

    const handleIndexChange = (kind: string) => {
        switch (kind) {
            case "plus":
                const newIndex1 = index + 1;
                setIndex(newIndex1);
                break;
            case "minus":
                if (index === 0) {
                    return;
                }



                const newIndex2 = index - 1;
                setIndex(newIndex2);
                break;
        }
    }

    return (
        <div>
            <InnerHeader title="상담일지" headerRef={headerRef} />
            {
                loading
                &&
                <LinearProgress />
            }

            {
                !loading
                &&
                <div className={styles.main} style={{ height: `${bodyHeight}px`, overflow: "scroll" }}>
                    <div className={styles.titleDiv}>
                        <div>
                            <div className={styles.date}>
                                {
                                    month ? month + "월" : ""
                                }
                                &nbsp;
                                {
                                    date ? date + "일" : ""
                                }
                                &nbsp;
                                학습관리
                            </div>
                            <div className={styles.author}>
                                담임멘토 : {teacherName ? teacherName : ""}
                            </div>
                        </div>
                        <div className={styles.chevronsDiv}>
                            <div>
                                <ChevronLeft onClick={(e) => { handleIndexChange("plus") }} fill="#607d8b" className={styles.chevron}></ChevronLeft>
                            </div>
                            <div>
                                <ChevronRight fill="#607d8b" onClick={(e) => { handleIndexChange("minus") }} className={styles.chevron}></ChevronRight>
                            </div>
                        </div>

                    </div>

                    <div className={styles.tableWrapper}>
                        <div className={styles.tableDiv}>
                            {
                                new Array(39).fill(undefined).map((each: undefined, index: number) => {

                                    const col = (index + 1) % 6;
                                    const row = Math.floor(index / 6) + 1;

                                    return (
                                        <div key={index} className={`${styles.cell} ${styles["cell" + index]}`}>
                                            {
                                                index === 0
                                                &&
                                                "과목"
                                            }
                                            {
                                                index === 1
                                                &&
                                                "국어"
                                            }
                                            {
                                                index === 2
                                                &&
                                                "수학"
                                            }
                                            {
                                                index === 3
                                                &&
                                                "영어"
                                            }
                                            {
                                                index === 4
                                                &&
                                                "탐구"
                                            }
                                            {
                                                index === 5
                                                &&
                                                "Weekly\nABC\ntest"
                                            }
                                            {
                                                index === 6
                                                &&
                                                "점수"
                                            }
                                            {
                                                index === 11
                                                &&
                                                "피드백"
                                            }
                                            {
                                                index === 16
                                                &&
                                                "수강진도"
                                            }
                                            {
                                                index === 17
                                                &&
                                                "수강강좌"
                                            }
                                            {
                                                index === 22
                                                &&
                                                "전주 이행도"
                                            }
                                            {
                                                index === 27
                                                &&
                                                "다음주 계획"
                                            }
                                            {
                                                index === 32
                                                &&
                                                "학습내용\n상세기입란"
                                            }
                                            {
                                                index === 37
                                                &&
                                                "추가사항"
                                            }
                                            {
                                                data && data.map((eachData) => {
                                                    if (eachData.index === index) {
                                                        return (
                                                            <span className={styles.dataText}>
                                                                {eachData.value}
                                                            </span>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            }



            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}

        </div>
    );
}

export default MobileConsultReport;
