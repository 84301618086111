import React, { useEffect, useState } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    LicenseInfo,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
    DataGridPremiumProps,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
// import { classInfo } from "../functions/calcattendance";
import { each, set } from "lodash";
import { returnLocationClassInfo } from "../../../../../data/locationFuntion";

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .phone_problem': {
                    color : "red"
                },
                '& .phone_noproblem': {
                    color : "black"
                },
                '& .phone_memo': {
                    color : "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                },
                '& .in': {
                    color :  "blue"
                },
                '& .out': {
                    color : "red"
                }
            },
        };
    },
    { defaultTheme },
);


const AttendanceCheckData : React.FC<any> =(props) => {

    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field : "class", headerName : "교시", width : 80 },
        { field : "status", headerName : "위치", width : 80 },
        { field : "result", headerName : "결과", width : 200, editable : true },
        { field : "call", headerName : "전화", width : 80 },
        { field : "sms", headerName : "문자", width : 80 },
    ]);
    const [classNumberToNumber, setClassNumberToNumber] = useState<number>(999);

    useEffect(() => {

        if(!props.selectedUserId){
            setRows([]);
            return;
        }

        if(!props.classNumber){
            setRows([]);
            return;
        }

        const standardRows = makeStandardRows();

        if(!props.attendanceCheckData){
            setRows([...standardRows]);
            return;
        }

        makeRows(standardRows);

        setRows([...standardRows]);


    }, [props.attendanceCheckData, props.classNumber, props.selectedUserId, props.phoneLogData, props.targetDate]);

    const makeRows = (standardRows : any) => {

        if(!props.attendanceCheckData){
            setRows([...standardRows]);
            return;
        }

        props.attendanceCheckData.forEach((data : any) => {

            standardRows.forEach((row : any) => {

                console.log(row);
                console.log(data);

                if(row.classNumber === data.classNumber){
                    row.result = data.description;
                }

            })

        });

        if(!props.accessControlData){
            return;
        }

        if(!props.targetDate){
            return;
        }

        const targetDate = props.targetDate;

        const inoutStatusData : any = [];

        const classInfo = returnLocationClassInfo(props.location);

        classInfo.forEach((classNumberInfo) => {

            const classNumberStartTime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), classNumberInfo.start.hours, classNumberInfo.start.minutes + 1, 0).getTime();

            var classStatus = "out";

            //props.accessControlData 를 +props.accessControlData.time 오름차순으로 정렬
            props.accessControlData.sort((a : any, b : any) => {
                return (+a.time) - (+b.time);
            });

            props.accessControlData.forEach((eachAccessControlData : any) => {

                const date = new Date(+eachAccessControlData.time);

                //datestring YYYY-MM-DD HH:MM:SS
                const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":" + date.getSeconds();
    
                eachAccessControlData.dateString = dateString;

                const accessControlTime = +eachAccessControlData.time;

                if(accessControlTime <= classNumberStartTime){

                    switch(eachAccessControlData.direction){

                        case "outside" :
                            classStatus = "in";
                            break;
                        case "inside" :
                            classStatus = "out";
                            break;
    
                    }

                    console.log(1);
                    
                }


            })



            const oneRow = {
                classNumber : classNumberInfo.classNumber,
                status : classStatus
            }


            inoutStatusData.push(oneRow);
        })

        inoutStatusData.forEach((eachInoutStatusData : any) => {

            standardRows.forEach((eachStandardRow : any) => {

                if(eachInoutStatusData.classNumber === eachStandardRow.classNumber){
                    eachStandardRow.status = eachInoutStatusData.status;
                }

            })

        })

        if(props.phoneLogData){

            console.log("startPhoneLogData");


            standardRows.forEach((eachStandardRow : any) => {

                console.log("startPhoneLogData2");

                const currentClassNumber = eachStandardRow.classNumber;

                const currentClassInfo = classInfo.find((data) => {
                    return data.classNumber === currentClassNumber;
                })

                if(!currentClassInfo){
                    return;
                }

                const currentClassStartTime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), currentClassInfo.start.hours, currentClassInfo.start.minutes + 1, 0).getTime();
                const currentClassEndTime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), currentClassInfo.end.hours, currentClassInfo.end.minutes + 1, 0).getTime();
    
                //currentClassStartTime과 EndTIime 사이의 phoneLogData.timeNumber를 가지는 phoneLogData를 가져온다.
                const currentClassPhoneLogData = props.phoneLogData.filter((data : any) => {
                    return data.timeNumber >= currentClassStartTime && data.timeNumber <= currentClassEndTime;
                })
    
                //currentClassPhoneLogData중에서 .kind 값이 "sms"인 것과 아닌 것을 나눈다.
                const currentClassPhoneLogDataSms = currentClassPhoneLogData.filter((data : any) => {
                    return data.kind === "sms";
                })
    
                const currentClassPhoneLogDataCall = currentClassPhoneLogData.filter((data : any) => {
                    return !data.kind;
                })
    
                if(currentClassPhoneLogDataSms.length > 0){

                    console.log("startPhoneLogData3");

                    const lastCurrentClassPhoneLogDataSms = currentClassPhoneLogDataSms[0];
    
                    const lastCurrentClassPhoneLogDataSmsDate = new Date(lastCurrentClassPhoneLogDataSms.timeNumber);
    
                    //AMPM HH:MM
                    var finalString = "";
    
                    var hours = lastCurrentClassPhoneLogDataSmsDate.getHours();
                    var minutes = lastCurrentClassPhoneLogDataSmsDate.getMinutes();
    
                    if(hours > 11){
                        finalString += "오후 ";
                    }else{
                        finalString += "오전 ";
                    }
    
                    if(hours > 12){
                        hours -= 12;
                    }
    
                    finalString += hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
    
                    eachStandardRow.sms = finalString;
                }
    
                if(currentClassPhoneLogDataCall.length > 0){
                    const lastCurrentClassPhoneLogDataCall = currentClassPhoneLogDataCall[0];
    
                    const lastCurrentClassPhoneLogDataCallDate = new Date(lastCurrentClassPhoneLogDataCall.timeNumber);
    
                    //AMPM HH:MM
                    var finalString = "";
    
                    var hours = lastCurrentClassPhoneLogDataCallDate.getHours();
                    var minutes = lastCurrentClassPhoneLogDataCallDate.getMinutes();
    
                    if(hours > 11){
                        finalString += "오후 ";
                    }else{
                        finalString += "오전 ";
                    }
    
                    if(hours > 12){
                        hours -= 12;
                    }
    
                    finalString += hours + ":" + (minutes < 10 ? "0" + minutes : minutes);

                    eachStandardRow.call = finalString;
    
                }

            })


        }

    }


    const makeStandardRows = () => {

        if(!props.classNumber){
            return [];
        }

        const classInfo = returnLocationClassInfo(props.location);

        var number = 0;

        // switch(props.classNumber){
        //     case "zero" :
        //         number = 1;
        //         break;
        //     case "first" :
        //         number = 2;
        //         break;
        //     case "second" :
        //         number = 3;
        //         break;
        //     case "third" :
        //         number = 4;
        //         break;
        //     case "fourth" :
        //         number = 5;
        //         break;
        //     case "fifth" :
        //         number = 6;
        //         break;
        //     case "sixth" :
        //         number = 7;
        //         break;
        //     case "seventh" :
        //         number = 8;
        //         break;
        //     case "eighth" :
        //         number = 9;
        //         break;
        // }

        classInfo.forEach((data, index) => {
            if(data.classNumber === props.classNumber){
                number = index + 1;
            }
        })

        setClassNumberToNumber(number);

        //number 수 만큼 index를 가지는 array를 만든다.

        const tempArray = new Array(number).fill(undefined);

        const newRows : any = [];

        var i = 0;

        var isZero = false;

        classInfo.forEach((data) => {
            if(data.classNumber === "zero"){
                isZero = true;
            }
        });

        if(!isZero){
            i = 1;
        }

        tempArray.forEach((data, index) => {

            const oneRow : any = {};


            if(isZero){
            oneRow.id = i + 1;
            }else{
                oneRow.id = i;
            }
            //수정
            oneRow.class = (i) + "교시";

            oneRow.classNumber = classInfo[index].classNumber;

            newRows.push(oneRow);

            i++;
        })


        return newRows;

    }


    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";


            console.log("new");

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.id;
            const currentTargetDate = props.targetDate;
            const currentClassNumber = props.classNumber;

            if(!field){
                console.log("noChanged");
                return;
            }

            if(field !== "result"){
                console.log("noResult");
                return;
            }

            if(!id || !currentTargetDate || !currentClassNumber){
                console.log("noValue");
                return;
            }

            if(!props.selectedUserId){
                console.log("noUserId");
                return;
            }

            const data = {
                userId : props.selectedUserId,
                field,
                value : value ? value : "",
                id,
                targetDateTime : currentTargetDate.getTime(),
                classNumber : currentClassNumber,
            }

            console.log(data);

            fetch("https://peetsunbae.com/dashboard/report/attendance/checkdata", {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(data)
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        console.log(result);
                        if (result.message === "success") {

                        }else{
                            alert("서버에 문제가 있습니다. 다시 시도해주세요.");
                        }
                    })
            })

            return newRow;


        }, [props.targetDate, props.classNumber, props.selectedUserId]
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log("error");
        alert(error.message);
    }, []);


    return (
        <div className={classes.root}
        style={{
            width : "550px",
            height : "400px"
        }}
        >
            <div
            style={{
                display : "flex",
                justifyContent : "center",
                paddingBottom : "10px",
                fontFamily : "Apple_SB"
            }}
            >
                오늘의 출석 현황
            </div>
            <DataGridPremium
            sx={{
                backgroundColor : "white"
            }}
            rows={rows}
            columns={columns}
            density="compact"
            hideFooter
            loading={props.loading}
            isCellEditable={(params) => {

                if(params.field === "result"){

                    if(classNumberToNumber === params.row.id){
                        return true;
                    }
                }

                return false;
            }}
            getCellClassName={(params) => {

                if(params.field === "status"){
                    if(params.formattedValue === "in"){
                        return "in";
                    }else if(params.formattedValue === "out"){
                        return "out";
                    }
                }

                return ""
            }}
            processRowUpdate={handleEditCommit}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            />
        </div>
    )

}

export default AttendanceCheckData;