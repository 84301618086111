import React, { useEffect, useState, useRef } from "react";
import styles from "./mobilecheckregister.module.css";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { Autocomplete, Button, CircularProgress, Divider, LinearProgress, Skeleton } from "@mui/material";
import AspectRatio from '@mui/joy/AspectRatio';
import { CssVarsProvider } from "@mui/joy";
import { LicenseInfo } from "@mui/x-license-pro";
import { IonLoading, IonToast } from "@ionic/react";
import { ReactComponent as Mail } from "../../../src/svg/mobiletotal/paper-plane-light.svg";
import { ReactComponent as MailActive } from "../../../src/svg/paper-plane-duotone.svg";

import { useHistory } from "react-router-dom";

LicenseInfo.setLicenseKey("3124a63cf796a421a7ac55c9bf753845Tz01NjM1MCxFPTE3MDMwMzU5NjMxNzEsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=");


const MobileCheckRegister: React.FC<any> = (props) => {

    const [data, setData] = useState<any[]>([]);

    const [showLoading, setShowLoading] = useState(false);
    const [showLoading2, setShowLoading2] = useState(false);
    const [ionLoading, setIonLoading] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [showToast2, setShowToast2] = useState(false);
    const [showToast3, setShowToast3] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const history = useHistory();
    const [isErrorArray, setIsErrorArray] = useState(new Array(200).fill(false));

    const [loading, setLoading] = useState(false);

    const [update, setUpdate] = useState(0);


    //data 설정하는 기능
    useEffect(() => {

        if(!props.user){
            return;
        }

        start();

    }, [update, props.user]);

    const start = async () => {

        const data = await getData();
        makeFirstData(data);

    }

    const getData = async () => {

        try{

            if(update === 0){
                setShowLoading(true);
            }else{
                setShowLoading2(true);
            }
    
            var token : any = "";
    
            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                token = value;
            }

            const targetDate = new Date();
            const time = targetDate.getTime();
    
            const response = await fetch("https://peetsunbae.com/dashboard/checklist/enrollchecklist?time=" + time, {
                method : "GET",
                headers : { "Authorization" : token },
                credentials : "include"
            });
    
            const result = await response.json();

            if(result.message === "success"){
                setShowLoading(false);
                setShowLoading2(false);

                return result.data;
            }


        }catch(e){
            console.log(e);
        }

    }

    const makeFirstData = async (occupiedData : any) => {

        const interval = 60;
        const due = 50;
        const startTime = 480;
        const endTime = 1399;
        const max = 24;

        const currentDate = new Date();
        const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();

        const data = [{
            teacherName : "검사 조교",
            dateString : `오늘(${currentDate.getMonth() + 1}월 ${currentDate.getDate()}일)`,
            message : "하원 전 검사 필수",
            subMessage : "(시간별 최대 신청자수 제한)",
            availableTimes : []
        }];

        var isBeforeStartTimeDue = false;

        if(startTime + due >= currentTime){
            isBeforeStartTimeDue = true;
        }

        const currentHour = currentDate.getHours();
        const currentMinues = currentDate.getMinutes();
        
        var currentStartTime = currentHour * 60;

        if(currentMinues > due){
            currentStartTime +=  interval;
        }

        if(isBeforeStartTimeDue){
            currentStartTime = startTime;
        }

        const availableTimes : any = [];

        while(currentStartTime < endTime){

            const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), Math.floor(currentStartTime / 60), currentStartTime % 60);
            const id = newDate.getTime();

            availableTimes.push(
                {
                    id,
                    time : currentStartTime,
                    interval,
                    max,
                    number : 0,
                    isMax : false,
                    isMine : false
                }
            );
            currentStartTime += interval;
        }

        availableTimes.forEach((one : any) => {
            occupiedData.forEach((occupied : any) => {
                if(!occupied.enrolledId){
                    return;
                }

                if(one.id === +occupied.enrolledId){
                    one.number++;

                    if(props.user.id === occupied.userId){
                        one.isMine = true;

                        if(occupied.status === "completed"){
                            one.isCompleted = true;
                        }
                    }
                }


            });

            if(one.number >= max){
                one.isMax = true;
            }
        });

        console.log(availableTimes);

        data[0].availableTimes = availableTimes;

        setData(data);

    }
    


    const voidFunction = (e: any) => {

    }

    const enrollCheckList = async (id : number, status : any, interval : number, max : number) => {

        setShowLoading2(true);

        if(!id || !interval || !max){
            console.log("no id or interval or max");
        }

        console.log(id);
        console.log(status);

        var token: any = "";

        if (window.electron) {
            token = window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }


        try{

            const response = await fetch("https://peetsunbae.com/dashboard/checklist/enrollchecklist", {
                method : "POST",
                headers : { "Authorization" : token, "Content-Type" : "application/json" },
                credentials : "include",
                body : JSON.stringify({
                    id, interval, max
                })
            });

            const result = await response.json();

            const message = result.message;

            if(message === "success"){
                setShowToast2(true);
            }

            if(message === "DELETED"){
                setShowToast3(true);
            }

            if(message === "MAX_COUNT"){
                setToastMessage("등록 가능한 인원이 초과되었습니다.");
                setShowToast(true);
            }

            if(message === "ALREADY"){
                setToastMessage("이미 등록하셨습니다.");
                setShowToast(true);
            }

            setUpdate(Math.random());


        }catch(e){
            console.log(e);
        }

        setShowLoading2(false);


    }

    const sendMessage = (questionId: number, teacherId: number, teacherName: string, month: number, date: number, dayString: string, enrolledId: number, enrolledQuestionTime: number) => {

        console.log(questionId, teacherId, month, date, dayString, enrolledId, enrolledQuestionTime);

        if (!enrolledQuestionTime) {
            alert("시간 예약 후 질의 내용 전송 가능합니다.");
            return;
        }

        history.push({
            pathname: "/mobiledashboard/offlinequestionwrite",
            state: {
                questionId,
                teacherId,
                teacherName,
                month,
                date,
                dayString,
                enrolledId,
                enrolledQuestionTime
            }
        })

    }

    const handleImageError = (index: number) => {

        const newIsErrorArray = [...isErrorArray];
        newIsErrorArray[index] = true;

        setIsErrorArray([...newIsErrorArray]);

    }


    return (
        <div className={styles.mainStatus} style={{ height: `${props.statusDivHeight}px`, overflow: "scroll" }}>
            {
                showLoading
                &&
                <div style={{ width: "90.6%", margin: "0 auto" }}>
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                </div>
            }
            {
                data.map((each: any, dataIndex: number) => {
                   

                    return (
                        <div key={dataIndex}>
                            <div className={`${styles.eachTeacher} ${dataIndex === 0 ? styles.first : ""}`}>
                                <div className={styles.teacherDiv}>
                                    <CssVarsProvider>
                                        <AspectRatio ratio={"1/1"} variant="plain">
                                            <div className={styles.teacherImg}>
                                                <img
                                                    className={styles.teacherImage} src={`https://peetsunbae.com/img/questionteacherimage/default.webp`} alt="사진 업로드 예정"
                                                ></img>
                                            </div>
                                        </AspectRatio>
                                    </CssVarsProvider>
                                    <div className={styles.teacherDescription}>
                                        <div className={styles.teacherName}>
                                            {each.teacherName}
                                        </div>
                                        <div className={styles.teacherSubject}>
                                            {each.dateString}
                                        </div>
                                        <div className={styles.teacherSubject}>
                                            {each.message}
                                        </div>
                                        <div className={styles.location}>
                                            {each.subMessage}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.selectDiv}>
                                    <div className={styles.timeSelect}>

                                        <div className={styles.times}>
                                            {
                                                props.user && each.availableTimes.map((one: any, index : number) => {
                                                    
                                                    var status : any;
                                                    var count = 0;
                                                    var isCompleted = false;

                                                    if(one.isMax){
                                                        status = "max";
                                                    }

                                                    if(one.isMine){
                                                        status = "mine";
                                                    }

                                                    if(one.isCompleted){
                                                        isCompleted = true;
                                                    }

                                                    return (
                                                        <div key={"b" + index} onClick={() => {

                                                            if(status === "max"){
                                                                return;
                                                            }

                                                            if(isCompleted){
                                                                return;
                                                            }

                                                            enrollCheckList(one.id, status, one.interval, one.max)}
                                                        } className={`${styles.timeDiv} ${status === "max" ? styles.occupied : ""} ${status === "mine" ? styles.mine : ""}`}>
                                                            {Math.floor(one.time/60)}시~{Math.floor((one.time + one.interval)/60)}시
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading2}
                onDidDismiss={() => setShowLoading2(false)}
                message={'잠시만 기다려주세요'}
            />

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => { setShowToast(false); setToastMessage(""); }}
                message={toastMessage}
                duration={1500}
                position="top"
                color="danger"
            />

            <IonToast
                isOpen={showToast2}
                onDidDismiss={() => { setShowToast2(false); }}
                message="😃 성공 ! 메시지를 남기고 싶으면 종이비행기 PUSH !"
                duration={1500}
                position="top"
                color="primary"
            />

            <IonToast
                isOpen={showToast3}
                onDidDismiss={() => { setShowToast3(false); }}
                message="😃  예약 취소 성공 !"
                duration={1500}
                position="top"
                color="primary"
            />

        </div>
    );
}

export default MobileCheckRegister;