import { withStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import "../componentsStyle/edit.css";
// import styles from "../componentsStyle/editStyles";
import TextField from "@mui/material/TextField";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import StaticDatePicker from '@mui/lab/StaticDatePicker';
// import TimePicker from '@mui/lab/TimePicker'

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress'
import { Alert, Modal, Stack } from '@mui/material';
import koLocale from 'date-fns/locale/ko'
import Totaledits from './controls/totaledits';

import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey("3124a63cf796a421a7ac55c9bf753845Tz01NjM1MCxFPTE3MDMwMzU5NjMxNzEsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=");


type currentSideBarMenuList = "home" | "notification" | "alarm" | "edit" | "book" | "question" | "restaurant" | "envelope" | "search" | "chart" | "attendance" | "출석 관리 보고" | "wifipermitregister";

interface editProps extends RouteComponentProps {
    activateMenuList: (curret: currentSideBarMenuList) => void;
    // classes: any;
    user: {
        name: string;
        value: "student" | "teacher" | "parent" | "staff";
        id: number;
    } | undefined | null
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const WifipermitForPc: React.FC<editProps> = (props) => {
    // const classes = props.classes;
    const date = new Date();
    const [select, setSelect] = useState("submit");

    const [loading, setLoading] = useState(false);
    const [uploadBool, setUploadBool] = useState(false);

    const [currentMenu, setCurrentMenu] = useState("among");

    const [noRemain, setNoremain] = useState(false);
    const [remain, setRemain] = useState(0);
    const [count, setCount] = useState(0);
    const exitArriveDefaultDate = new Date(2021, 11, 8, 9);
    const [dateValue, setDateValue] = useState(date);
    const [exitValue, setExitValue] = useState(exitArriveDefaultDate);
    const [arriveValue, setArriveValue] = useState(exitArriveDefaultDate);
    const [description, setDescription] = useState("");
    const [open2, setOpen2] = useState(false);

    const [checkResult, setCheckResult] = useState<any>();
    const [targetArray, setTargetArray] = useState<any>();

    const [update, setUpdate] = useState(0);

    useEffect(() => {
        start();
    }, [update]);

    async function start() {

        setLoading(true);
        var token: any = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }


        fetch("https://peetsunbae.com/dashboard/wifipermit/status", {
            method: "GET",
            headers: { "Authorization": token },
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);

                    const message = result.message;

                    if (!message) {
                        return;
                    }

                    message.sort(function (a: any, b: any) {
                        const afterTime = new Date(a.createdAt).getTime();
                        const beforeTime = new Date(b.createdAt).getTime();

                        if (afterTime < beforeTime) {
                            return 1;
                        }
                        if (afterTime > beforeTime) {
                            return -1;
                        }
                    })

                    setCheckResult(message);
                    setLoading(false);
                    setTargetArray(result.deviceList);
                })
        })
    }



    useEffect(() => {
        props.activateMenuList("wifipermitregister");
    }, []);


    const changeSelect = (event: any, menu: string) => {
        switch (menu) {
            case "submit": setSelect("submit"); break;
            case "check": setSelect("check"); break;
            case "total": setSelect("total");
        }
    }

    const menuChange = (event: any, type: string) => {
        switch (type) {
            case 'long': setCurrentMenu("long"); break;
            case 'absent': setCurrentMenu('absent'); break;
            case 'early': setCurrentMenu("early"); break;
            case 'among': setCurrentMenu('among');
        }
    }

    const submit = async (e: any) => {
        // if (description === "" || description === "구체적인 사유를 적어주세요.") {
        //     alert("사유를 적은 후에 제출 부탁드립니다 : )");
        //     return;
        // }
        console.log(description);

        if (!description) {
            alert("사유 내용을 적은 후 제출해주세요");
            return;
        }

        setLoading(true);
        var dateInfo = new Date(dateValue);
        const year = dateInfo.getFullYear();
        const month = dateInfo.getMonth() + 1;
        const date = dateInfo.getDate();
        var exitInfo = new Date(exitValue);
        var exitHours = exitInfo.getHours();
        var exitMinutes = exitInfo.getMinutes();
        var arriveInfo = new Date(arriveValue);
        var arriveHours = arriveInfo.getHours();
        var arriveMinutes = arriveInfo.getMinutes();

        const exitTimes = exitHours * 60 + exitMinutes;
        const arriveTimes = arriveHours * 60 + arriveMinutes;

        if(exitTimes >= arriveTimes){
            alert("시작 시간이 종료 시간보다 늦을 수 없습니다.");
            setLoading(false);
            return;
        }

        if(exitTimes + 300 < arriveTimes){
            alert("최대 5시간까지 신청 가능합니다.");
            setLoading(false);
            return;
        }

        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/wifipermit/submit", {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": token },
            credentials: "include",
            body: JSON.stringify({
                type: currentMenu,
                year: year,
                month: month,
                date: date,
                exitHours: exitHours,
                exitMinutes: exitMinutes,
                arriveHours: arriveHours,
                arriveMinutes: arriveMinutes,
                description: description
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    setLoading(false);
                    console.log(result);

                    if(result.message === "NOT_ADDRESS"){
                        alert("등록된 기기가 없습니다.(데스크 문의)");
                        return;
                    }
                    
                    if (result.message === "success") {
                        setUploadBool(true);
                        setUpdate(Math.random());
                    }
                })
        })
    }







    const cancel = async (e: any) => {
        console.log(e.target.dataset.id);

        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/edit/delete", {
            method: "DELETE",
            headers: { "Content-Type": "application/json", "Authorization": token },
            credentials: "include",
            body: JSON.stringify({
                id: e.target.dataset.id
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "success") {
                        setCheckResult(
                            checkResult.filter((each: any) => each.id != e.target.dataset.id)
                        )
                    }
                })
        })
    }


    return (
        <div className="main2">
            <div className="title" style={{
                display: "flex", alignItems: "center",
            }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "6px",
                        marginRight: "10px"
                    }}
                >
                    <img src="img/off/wifipermit.svg" alt="playlist" style={{ width: "18px" }} />
                </div>
                <div>방화벽 해제 신청</div>
            </div>
            <div className="menu">
                <div className="submenu">
                    <div className={`menu_status ${select === "submit" ? "active" : ""}`} onClick={(e) => { changeSelect(e, "submit") }}>
                        # 방화벽 해제 신청
                    </div>
                    <div className={`menu_status ${select === "check" ? "active" : ""}`} onClick={(e) => { changeSelect(e, "check") }}>
                        나의 신청 현황
                    </div>
                    {
                        (props.user && (props.user.value === "teacher" || props.user.value === "staff")) &&
                        <div className={`menu_status ${select === "total" ? "active" : ""}`} onClick={(e) => { changeSelect(e, "total") }}>
                            전체 제출 현황
                        </div>
                    }
                </div>
                <div className="remain" style={{ color: "#3d50b0", cursor: "pointer" }} onClick={() => { setOpen2(true); }}>
                    나의 등록 기기
                </div>
            </div>


            {select === "submit" &&
                <div className="wrap">
                    <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            openTo="day"
                            value={dateValue}
                            disablePast

                            // minDate={minDate}
                            onChange={(newValue: any) => {
                                console.log(newValue);
                                setDateValue(newValue);
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </LocalizationProvider>


                    <div className="select">
                        <div className="selectTitle">
                            시간 선택
                        </div>

                        <div className="timePicker">

                            {(currentMenu === "early" || currentMenu === "among") &&
                                <div className="timePickerExit">
                                    <div className="timePickerExitTitle">
                                        해제 시작 시간
                                    </div>
                                    <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            value={exitValue}
                                            onChange={(newValue: any) => {
                                                console.log(newValue);
                                                setExitValue(newValue);
                                            }}
                                            renderInput={(params: any) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            }

                            {(currentMenu === "long" || currentMenu === "among") &&
                                <div className="timePickerArrive">
                                    <div className="timePickerArriveTitle">
                                        해제 종료 시간
                                    </div>
                                    <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            value={arriveValue}
                                            onChange={(newValue: any) => {
                                                console.log(newValue);
                                                setArriveValue(newValue);
                                            }}
                                            renderInput={(params: any) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            }

                        </div>
                    </div>

                    <div className="description">
                        <div className="descriptionTitle">
                            해제 사유 및 접속할 사이트명
                        </div>
                        <div className="descriptionWrite">
                            <TextField
                                id="filled-multiline-static"
                                multiline
                                rows={8}
                                placeholder="구체적으로 적어주세요."
                                variant="filled"
                                fullWidth
                                onChange={(e: any) => { setDescription(e.target.value); }}
                            />
                        </div>
                    </div>
                </div>}

            {(noRemain && select === "submit") &&
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error" sx={{ marginTop: 2, marginBottom: 2 }}><span>잔여 횟수가 없습니다.</span></Alert>
                </Stack>
            }

            {(loading && select === "submit") &&
                <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
                    <LinearProgress />
                </Box>
            }

            {(uploadBool && select === "submit") &&
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2 }}><span>업로드 성공 !</span></Alert>
                </Stack>
            }

            {(select === "submit") &&
                <div className="submitDiv" style={{ marginTop: "0px", paddingTop: "0px" }}>
                    <div className="submit" onClick={submit}>
                        제출하기
                        <img src="img/navigate_next.svg" alt="right"></img>
                    </div>
                </div>
            }

            {
                select === "submit" &&
                <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "1180px",
                    marginTop: "24px",
                }}
            >
                <div>
                    <div style={{display : "flex", justifyContent : "end", marginBottom : "8px"}} >
                        <div>
                            ** 해제 신청 후 <span style={{fontWeight : 700}}>학부모 알림톡 승인</span>이 필요합니다.
                        </div>
                    </div>
                    <div style={{marginBottom : "4px"}}>
                        * 해제 프로그램은 <span style={{ fontWeight: 700 }}>각 시간별 0분, 15분, 30분, 45분에 적용</span>됩니다
                    </div>
                    <div style={{marginBottom : "4px", display : "flex", justifyContent : "end"}}>
                        ex. 신청시간 (10:03 - 12:40) = 적용시간 (10:15 - 12:45)
                    </div>
                    <div style={{display : "flex", justifyContent : "end", marginBottom : "4px"}} >
                        ex. 10:03분부터 사용하기를 원하면 '10:00 시작'으로 신청해주세요
                    </div>

                </div>
            </div>
            }


            {select === "check" &&
                <div className="checkBoard">
                    <div className="checkBoardTitle">
                        해제 신청 현황
                    </div>
                    <div className="checkBoardBody">
                        {
                            checkResult && checkResult.map((each: any) => {

                                console.log(each);

                                const date = new Date(each.createdAt);
                                const description = each.description;

                                const startDate = new Date(each.startDate);
                                each.startHours = startDate.getHours();
                                each.startMinutes = startDate.getMinutes();

                                const endDate = new Date(each.endDate);
                                each.endHours = endDate.getHours();
                                each.endMinutes = endDate.getMinutes();

                                return (
                                    <div key={each.id} className="checklist" data-id={each.id}>
                                        <div className="checklistTitle">
                                            <div className="checklistTitle_1">
                                                <div className="date">
                                                    {date.getFullYear()}.{date.getMonth() + 1}.{date.getDate()}
                                                </div>
                                                <div>
                                                    <span>[{description}] </span>
                                                    <span>
                                                        {`${each.endHours > 11 ? "오후" : "오전"}  ${each.startHours > 12 ? each.startHours - 12 : each.startHours}시 ${each.startMinutes.toString().length === 1 ? "0" + each.startMinutes : each.startMinutes}분 시작 -
                                                    ${each.endHours > 11 ? "오후" : "오전"} ${each.endHours > 12 ? each.endHours - 12 : each.endHours}시 ${each.endMinutes.toString().length === 1 ? "0" + each.endMinutes : each.endMinutes}분 종료
                                                    `}
                                                    </span>
                                                    <span style={{ color: "black" }}>
                                                        {each.permitstatus === 0 && " (학부모 승인 대기 중)"}
                                                    </span>
                                                    <span style={{ color: "blue" }}>
                                                        {each.permitstatus === 1 && " (학부모 승인 완료)"}
                                                    </span>
                                                    <span style={{ color: "red" }}>
                                                        {each.permitstatus === 2 && " (학부모 승인 거절)"}
                                                    </span>
                                                    <span style={{ color: "blue" }}>
                                                        {each.permitstatus === 3 && " (관리자 승인)"}
                                                    </span>
                                                    <span style={{ color: "red" }}>
                                                        {each.permitstatus === 2 && " (관리자 취소)"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {bool ?
                                            <div className="cancel" onClick={cancel} data-id={each.id}>
                                                취소하기
                                            </div>
                                            :
                                            <div>
                                            </div>
                                        } */}

                                    </div>
                                );
                            })
                        }
                    </div>

                </div>}

            {
                select === "total" &&
                <Totaledits />
            }

            <Modal
                open={open2}
                onClose={() => { setOpen2(false) }}
            >
                <Box sx={style}>
                    {
                        (targetArray && targetArray.length > 0) &&
                        targetArray.map((eachData: any, index: number) => {
                            return (
                                <div key={index} style={{ marginBottom: "6px", marginTop: "6px" }}>
                                    <div>

                                    </div>
                                    <div>
                                        <div style={{ fontFamily: "Apple_B", marginBottom: "6px" }}>
                                            {
                                                eachData.name
                                            }
                                        </div>
                                        <div>
                                            {
                                                eachData.macaddr
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    {
                        (targetArray && targetArray.length === 0) &&
                        <div style={{ fontWeight: 400 }}>
                            등록된 기기가 없습니다. 🥹<br></br>(방화벽 기기 등록 필요)
                        </div>
                    }
                </Box>
            </Modal>


        </div>
    )
}

export default WifipermitForPc;