import React, { useEffect, useState } from "react";
import { DataGridPremium, GridColDef, GridColumnGroupingModel, GridFilterModel, LicenseInfo, GridRenderCellParams, GridRowModel, GridRowProps } from "@mui/x-data-grid-premium";
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { createStyles } from '@mui/styles';

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import koLocale from 'date-fns/locale/ko'
import TextField from '@mui/material/TextField';
import { DatePicker } from "@mui/x-date-pickers";
import { Button } from "@mui/joy";
import { da } from "date-fns/locale";
import { set } from "lodash";
import { Backdrop, CircularProgress } from "@mui/material";
import { getFetch, postFetch } from "../../../../../fetch/fetch";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .status3': {

                },
                '& .complete': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                },
                '& .incomplete': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                },
                '& .canceled': {
                    color: "red"
                },
                '& .notSend1': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .notReply1': {
                    color: "red"
                }
            },
        };
    },
    { defaultTheme },
);

interface GridCellExpandProps {
    value: string;
    width: number;
}


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    }),
);


const PureStudyTimeCalc: React.FC<any> = (props) => {

    const [dateValue, setDateValue] = useState(new Date(Date.now() - 86400000));
    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowProps[]>([]);
    const [name, setName] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field: "name", headerName: "이름", width: 200 },
        { field: "purestudytime", headerName: "순공부시간", width: 300 },
    ]);
    const [isSunday, setIsSunday] = useState<boolean>(false);
    const [isAlreadySended, setIsAlreadySended] = useState<boolean>(false);

    useEffect(() => {

        if (dateValue) {
            setIsSunday(dateValue.getDay() === 0);
        }else{
            setIsSunday(false);
        }

    }, [dateValue]);

    useEffect(() => {

        const newColumns = [...columns];

        if(!isSunday){
            
            const filteredColumns = newColumns.filter((item : any) => item.field !== "bonus");
            setColumns([...filteredColumns]);

        }else{

            alreadySend();

            var isAlreadyExist = false;

            newColumns.forEach((item : any) => {
                if(item.field === "bonus"){
                    isAlreadyExist = true;
                }
            })

            if(isAlreadyExist){
                return;
            }

            newColumns.push({ field: "bonus", headerName: "벌점차감계산", width: 100 });

            setColumns([...newColumns]);

        }

    }, [isSunday]);

    const alreadySend = async () => {

        const location = props.location;
        const year = dateValue.getFullYear();
        const month = dateValue.getMonth() + 1;
        const date = dateValue.getDate();

        const result : any = await getFetch("/dashboard/avatar/purestudytimealready?" + `year=${year}&month=${month}&date=${date}&location=${location}`);

        if(result.message === "success"){
            console.log("isAlready");
            const isAlready = result.isAlready;
            console.log(isAlready);
            setIsAlreadySended(isAlready);
        }

        console.log(result);

    }



    useEffect(() => {

        if (dateValue && props.location) {
            submit(null);
        }else{
            setRows([]);
        }

    }, [dateValue, props.location]);

    const notify1 = () => toast.success("발송 성공!", {
        position : "bottom-right",
        autoClose : 3000,
        hideProgressBar : true,
        theme : "light"
    });

    const notify2 = () => toast.error("발송 실패!", {
        position : "bottom-right",
        autoClose : 3000,
        hideProgressBar : true,
        theme : "dark"
    });

    const handleNameChange = (event: any) => {
        setName(event.target.value);
    }

    const submit = (e: any) => {

        setLoading(true);

        setRows([]);

        if (!dateValue) {
            alert("날짜를 선택해주세요.");
            return;
        }

        fetch("https://peetsunbae.com/fingerprint/purestudytime?dateTime=" + dateValue.getTime() + "&kinds=" + props.location, {
            method: "GET",
            credentials: "include",
        }).then((res) => {
            res.json()
                .then((result: any) => {

                    if (result.message === "success") {

                        const totalAccessInformation = result.data;

                        const userId: number[] = [];
                        const userNameArray: string[] = [];

                        totalAccessInformation.forEach((item: any) => {
                            if (!userId.includes(item.userId) && item.value === "student") {
                                userId.push(item.userId);
                                userNameArray.push(item.name);
                            }
                        })

                        const totalData: { userId: number, usersAccessInformation: [], userName : string }[] = [];

                        userId.forEach((item: any, index : number) => {
                            totalData.push({ userId: item, userName : userNameArray[index], usersAccessInformation: [] });
                        }
                        )

                        totalAccessInformation.forEach((item: any) => {
                            totalData.forEach((item2: any) => {
                                if (item.userId === item2.userId) {
                                    item2.usersAccessInformation.push(item);
                                }
                            })
                        }
                        )

                        console.log(totalData);

                        totalData.forEach((item: any) => {
                            item.pureStudyTime = 0;
                            var previousAccessInformation : any;
                            item.usersAccessInformation.forEach((item2: any) => {
                                if(previousAccessInformation){
                                    if(previousAccessInformation.direction !== item2.direction && previousAccessInformation.direction === "outside"){
                                        item.pureStudyTime += item2.time - previousAccessInformation.time;
                                    }
                                }
                                previousAccessInformation = item2;
                            })
                            
                        })

                        const newRows : any = [];

                        totalData.forEach((item: any) => {
                            

                            const oneRow : any = {};
                            oneRow.id = item.userId;
                            oneRow.name = item.userName;
                            //item.purestudytime은 millisecond단위인데 이를 ~시간 ~분 꼴로 바꿔줘야함
                            const hour = Math.floor(item.pureStudyTime / 3600000);
                            const minute = Math.floor((item.pureStudyTime - hour * 3600000) / 60000);
                            oneRow.purestudytime = hour + "시간 " + minute + "분";

                            var hourBonus = hour;

                            if(minute >= 50){
                                hourBonus++;
                            }

                            if(hourBonus > 10){
                                hourBonus = 10;
                            }

                            oneRow.bonus = -hourBonus;

                            newRows.push(oneRow);
                        })

                        setRows([...newRows]);
                        setLoading(false);

                    }

                })
        })
    }



    const submitBonus = async (e : any) => {

        const location = props.location;

        if(!location){
            alert("지점을 선택해주세요.");
            return;
        }

        if(!isSunday){
            alert("일요일에만 벌점차감이 가능합니다.");
            return;
        }

        if(!rows){
            alert("데이터가 없습니다.");
            return;
        }

        if(!dateValue){
            alert("날짜를 선택해주세요.");
            return;
        }

        if(!window.confirm("모든 학생의 벌점 차감을 진행하시겠습니까?")){
            return;
        }

        var isExistBonuse = false;

        columns.forEach((item : any) => {
            if(item.field === "bonus"){
                isExistBonuse = true;
            }
        });

        if(!isExistBonuse){
            alert("벌점차감 컬럼이 없습니다.");
            return;
        }

        setLoading2(true);

        const data : any = [];

        const year = dateValue.getFullYear();
        const month = dateValue.getMonth() + 1;
        const date = dateValue.getDate();
        const description = `${month}/${date} 일요일 등원 벌점 차감`;

        rows.forEach((item : any) => {

            const oneRow = {
                userId : item.id,
                name : item.name,
                score : item.bonus,
                description : description,
            }

            data.push(oneRow);

        })

        console.log(data);

        const body = {
            data,
            year,
            month,
            date,
            location
        }

        try{

            const result : any = await postFetch("/dashboard/avatar/purestudytimebonus", body);

            console.log(result);

            if(result.message === "success"){
                notify1();
            }else{
                notify2();
            }


            setLoading2(false);

        }catch(e){
            notify2();
            console.log(e);
        }
    }

    return (
        <div>
            <div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "1650px"
                }}>
                    <div style={{display : "flex", alignItems : "center", justifyContent : "space-between"}}>
                        <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disableFuture
                                value={dateValue}
                                onChange={(newValue: any) => {
                                    setDateValue(newValue);
                                }}
                                renderInput={(params: any) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div>
                        <Button onClick={submitBonus} disabled={!rows || loading || !isSunday}>
                            전부 벌점 차감하기
                        </Button>
                    </div>
                </div>
            </div>
            <div className={classes.root} style={{ height: 650, width: '1650px', backgroundColor: "white", marginTop: "12px" }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    density="compact"
                    loading={loading}
                />
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading2}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ToastContainer />
        </div>
    )
}

export default PureStudyTimeCalc;