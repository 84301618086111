import * as React from 'react';
import Box from '@mui/joy/Box';
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import styles from "../../controls/newattendance/styles/attendancestyles.module.css"

export default function PatrolClassSelect(props : any) {
  const [alignment, setAlignment] = React.useState('total');

  return (
    <RadioGroup
      orientation="horizontal"
      aria-label="Alignment"
      name="alignment"
      variant="outlined"
      value={alignment}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        {
            setAlignment(event.target.value)
            props.handleClassChange(event.target.value);
        }
      }
    >
      {["total", "first", "second", "third"].map((item, index) => (
        <Box
          key={item}
          sx={(theme) => ({
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 84,
            height: 58,
            '&:not([data-first-child])': {
              borderLeft: '1px solid',
              borderColor: 'divider',
            },
            [`&[data-first-child] .${radioClasses.action}`]: {
              borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
              borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
            },
            [`&[data-last-child] .${radioClasses.action}`]: {
              borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
              borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
            },
          })}
        >
          <Radio
            value={item}
            disableIcon
            overlay
            label={
              {
                total : <span className={styles.basicFont}>전체</span>,
                first : <span className={styles.basicFont}>0-2교시</span>,
                second : <span className={styles.basicFont}>3-5교시</span>,
                third : <span className={styles.basicFont}>6-8교시</span>,
              }[item]
            }
            variant={alignment === item ? 'solid' : 'plain'}
            slotProps={{
              input: { 'aria-label': item },
              action: {
                sx: { borderRadius: 0, transition: 'none' },
              },
              label: { sx: { lineHeight: 0 } },
            }}
          />
        </Box>
      ))}
    </RadioGroup>
  );
}