import React, { useState, useEffect } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    LicenseInfo,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import { renderCellExpand } from "../infos/suddenData";

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

const UserDemerit: React.FC<any> = (props) => {

    const [rows, setRows] = useState<GridRowsProp[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([
        { field: "demerit", headerName: "벌점", width: 80 },
        { field: "description", headerName: "내용", width: 280, renderCell : renderCellExpand },
        { field: "teacher", headerName: "발송자", width: 120 },
        { field: "createdAt", headerName: "작성일", width: 150, renderCell : renderCellExpand }
    ]);

    useEffect(() => {

        if(!props.demeritData){
            return;
        }

        makeRows();

    }, [props.demeritData]);

    const makeRows = () => {

        const newRows: GridRowsProp[] = [];

        const demeritData = props.demeritData;

        demeritData.forEach((eachDemerit : any) => {

            const oneRow : any = {};

            oneRow.id = eachDemerit.id;
            oneRow.demerit = eachDemerit.score;
            oneRow.description = eachDemerit.description;
            oneRow.teacher = eachDemerit.teacherName;

            if(eachDemerit.createdAt){
                const date = new Date(eachDemerit.createdAt);
                // YYYY-MM-DD HH:MM:SS
                const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours() }:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}`;
                oneRow.createdAt = dateString;
            }

            newRows.push(oneRow);

        })

        setRows([...newRows]);

    };


    return (
        <div>
            <DataGridPremium
                sx={{
                    height : "450px"
                }}
                rows={rows}
                columns={columns}
                hideFooter={true}
                density="compact"
            />
        </div>

    );

}

export default UserDemerit;