import React, { useEffect, useState } from "react";
import { getFetch } from "../../../fetch/fetch";
import styles from "./demeritlist.module.css";
import { GridCellParams, GridRenderCellParams, DataGridPro, GridRowsProp, GridColDef, GridToolbar, LicenseInfo, useGridApiRef, GridFilterModel, DataGridPremium, } from '@mui/x-data-grid-premium';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import TextField from '@mui/material/TextField';


LicenseInfo.setLicenseKey("3124a63cf796a421a7ac55c9bf753845Tz01NjM1MCxFPTE3MDMwMzU5NjMxNzEsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=");

const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .today': {
                    color : "blue",
                },
            },
        };
    },
    { defaultTheme },
);


const columns: GridColDef[] = [
    { field: 'name', headerName: '학생 이름', width: 80, filterable: true, editable: false },
    { field: 'score', headerName: '벌점', width: 60, filterable: true, editable: false },
    { field: 'description', headerName: '사유', width: 250, filterable: true, editable: false },
    { field: 'teacher', headerName: '제출자', width: 60, filterable: true, editable: false },
    { field: 'createdAt', headerName: '벌점 제출 날짜', width: 180, filterable: true, editable: false },
    { field: 'totalScore', headerName: '총점', width: 80, filterable: true, editable: false },
    { field: 'isToday', headerName: '오늘', width: 50, filterable: true, editable: false },
];

const DemeritList: React.FC<any> = (props) => {

    const classes = useStyles2();
    const [rows, setRows] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 2, field: 'name', operator: 'contains', value: "" },
        ],
    });
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [selectedUserName, setSelectedUserName] = useState("");
    const [totalDemerit, setTotalDemerit] = useState<any>();

    const nameChange = (e: any) => {
        setName(e.target.value);
        console.log(filterModel);

        const newFilterModel: any = filterModel;
        newFilterModel.items.forEach((each: any) => {
            if (each.id === 2) {
                each.value = e.target.value;
            }
        })
        setFilterModel({ ...newFilterModel });
    }

    const getDemeritList = async () => {

        try {

            const result: any = await getFetch("/dashboard/avatar/demeritlist");
            console.log(result);

            const data = {
                demeritList: result.demeritList,
                demeritTotal: result.demeritTotal
            }

            return data;

        } catch (e) {
            console.log(e);
        }

    }

    const start = async () => {
        setLoading(true);
        const data = await getDemeritList();
        console.log(data);

        if (!data) {
            return;
        }

        const demeritList = data.demeritList;
        const demeritTotal = data.demeritTotal;



        const newRows: any = [];

        demeritList.forEach((each: any, index: number) => {
            const oneRow: any = {};
            oneRow.id = each.id;
            oneRow.name = each.name;
            oneRow.score = each.score;
            oneRow.description = each.description;
            oneRow.userId = each.userId;

            demeritTotal.forEach((each2 : any, index : number) => {

                if(each2.userId === each.userId){
                    oneRow.totalScore = each2.totalDemerit
                }

            })


            const date = new Date(each.createdAt);
            const today = new Date();

            var isToday = false;

            if(date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate()){
                isToday = true;
            }

            const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일 ${date.getHours() > 11 ? "오후" : "오전"} ${date.getHours() > 12 ? date.getHours() - 12 : date.getHours()}시 ${date.getMinutes()}분`;

            oneRow.createdAt = dateString;
            oneRow.teacher = each.teacherName;
            
            if(isToday){
                oneRow.isToday = "O";
            }

            newRows.push(oneRow);

        })

        setRows([...newRows]);
        setLoading(false);

    }

    useEffect(() => {

        start();

    }, []);

    const handleClick = (e : any) => {
        console.log(e);
        setSelectedUserId(e.row.userId);
        setSelectedUserName(e.row.name);
    }

    return (
        <div>
            <div style={{ marginBottom: "12px", display: "flex", justifyContent: "space-between", width : "1500px" }}>
                <div>
                    
                </div>
                <div>
                    <TextField value={name} onChange={nameChange} id="standard-basic" placeholder="이름을 검색하세요" variant="standard" />
                </div>
            </div>
            <div className={classes.root} style={{ height: 500, width: '1500px', backgroundColor: "white" }}>
                <DataGridPremium
                    loading={loading}
                    onRowClick={handleClick}
                    columns={columns}
                    rows={rows}
                    density="compact"
                    filterModel={filterModel}
                    onFilterModelChange={(model) => setFilterModel(model)}
                    getCellClassName={(param) => {
                        if(param.field === "isToday"){
                            return "today"
                        }else{
                            return ""
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default DemeritList;