import React, { useEffect, useState, useCallback } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    LicenseInfo,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
    gridDetailPanelExpandedRowIdsSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { renderCellExpand } from "./suddenData";
import { IconButton } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .enter': {
                    color : "blue"
                },
                '& .exit': {
                    color : "red"
                },
                '& .phone_memo': {
                    color : "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);

const noData : any = [
    {
        id : 1, description : "데이터가 없습니다"
    }
]

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
    const { id, value: isExpanded } : any = props;
    const apiRef = useGridApiContext();
  
    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowIdsSelector,
    );

    const currentRow = apiRef.current.getRow(id);

    var isSms = false;

    if(currentRow.kind && currentRow.kind.indexOf("문자") !== -1){
        isSms = true;
    }

    var isDurationZero = true;
    //currentRow.description 이 '통화시간 - 0s' 안 포함하고 있으면 isDurationZero = false;
    if(currentRow.description && currentRow.description.indexOf("통화시간 - 0s") === -1){
        isDurationZero = false;
    }
  
    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    if(!isSms && !isDurationZero){

        return (
            <IconButton
              size="small"
              tabIndex={-1}
              disabled={false}
              aria-label={isExpanded ? 'Close' : 'Open'}
            >
              <ExpandMore
                sx={{
                  transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
                fontSize="inherit"
              />
            </IconButton>
          );
    }else{
        return (
            <></>
        )
    }
  

  }
  

const DemeritMemoData : React.FC<any> =(props) => {

    const getDetailPanelContent = useCallback(
        ({row} : any) => 
        {

            return (
                <div>
                    <div
                    style={{
                        paddingTop : "15px",
                        paddingLeft : "15px",
                        paddingRight : "15px",
                        paddingBottom : "15px",
                    }}
                    >
                        {
                            (row && row.contents && row.contents.length > 0) && row.contents.map((eachContent : any, index : number) => {

                                return (
                                    <div key={Math.random()} style={{
                                        marginBottom : "15px",
                                        fontFamily : "Apple_R",
                                        lineHeight : 1.3,
                                        width : "300px"
                                    }}>
                                        {eachContent.msg}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        },
        [],
    );

    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field : "relatedTime", headerName : "관련시간대", width : 120, sortable : false, editable : true },
        { field : "description", headerName : "내용", width : 360, renderCell : renderCellExpand, sortable : false, editable : true },
    ]);



    useEffect(() => {

        start();
 
    }, [props.targetDate, props.classNumber, props.selectedUserId]);

    const start  = async () => {

        const infoResult = await getInfo();
        makeRows(infoResult);

    }


    const getInfo = async () => {

        const targetDate = props.targetDate;
        const selectedUserId = props.selectedUserId;

        if(!targetDate || !selectedUserId){
            console.log("noTargetDate");
            return;
        }

        const targetDateTime = targetDate.getTime();

        const response = await fetch(`https://peetsunbae.com/dashboard/report/attendance/demeritmemo?userId=${selectedUserId}&targetDateTime=${targetDateTime}`, {
            method : "GET",
            credentials : "include"
        })

        const result = await response.json();

        console.log(result);

        if(result.message === "success"){

            return result;

        }else{

            const voidReturnData = {
                relatedTime : new Array(20).fill(undefined),
                description : new Array(20).fill(undefined),
            }

            return voidReturnData;
        }


    }



    const makeRows = (infoResult : any) => {

        if(!infoResult){
            return;
        }

        const relatedTime = infoResult.relatedTime;
        const description = infoResult.description;

        const newRows = [];

        for(var i=0; i<10; i++){

            const oneRow : any = {};

            oneRow.id = i + 1;
            
            newRows.push(oneRow);
        }

        newRows.forEach((eachRow : any, index : number) => {

            eachRow.relatedTime = relatedTime[index + 1] ? relatedTime[index + 1] : "";
            eachRow.description = description[index + 1] ? description[index + 1] : "";

        });

        setRows([...newRows]);

    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";


            console.log("new");

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.id;
            const currentTargetDate = props.targetDate;

            if(!field){
                console.log("noChanged");
                return;
            }


            if(!id || !currentTargetDate){
                console.log("noValue");
                return;
            }

            if(!props.selectedUserId){
                console.log("noUserId");
                return;
            }

            const data = {
                userId : props.selectedUserId,
                field,
                value : value ? value : "",
                id,
                targetDateTime : currentTargetDate.getTime(),
            }

            console.log(data);

            fetch("https://peetsunbae.com/dashboard/report/attendance/demeritmemo", {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(data)
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        console.log(result);
                        if (result.message === "success") {

                        }else{
                            alert("서버에 문제가 있습니다. 다시 시도해주세요.");
                        }
                    })
            })

            return newRow;


        }, [props.targetDate, props.classNumber, props.selectedUserId]
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log("error");
        alert(error.message);
    }, []);


    return (
        <div className={classes.root}
        style={{
            width : "550px",
            height : "172px",
            marginTop : "42px"
        }}
        >
                        <div
            style={{
                display : "flex",
                justifyContent : "center",
                paddingBottom : "10px",
                fontFamily : "Apple_SB",
                paddingTop : "36px",
            }}
            >
                벌점 관련 메모
            </div>
            <DataGridPremium
            sx={{
                backgroundColor : "white"
            }}
            loading={props.loading}
            rows={rows}
            columns={columns}
            density="compact"
            hideFooter
            processRowUpdate={handleEditCommit}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            />
        </div>
    )

}

export default DemeritMemoData;