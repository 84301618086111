import React from "react";

function SongdoFixedSeatSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="1597"
    //   height="1133"
    //   viewBox="0 0 1597 1133"
      width="100%"
      viewBox="230 0 1200 1500"
    >
      <g id="page2" transform="translate(5.5 5.5)">
        <path fill="none" d="M0 0H1587V1123H0z"></path>
        <g id="shape1">
          <path
            fill="#7e7e7e"
            d="M-3.8 0h1109.5l-3.7-3.8-3.8-3.8H3.8L0-3.8-3.8 0z"
            transform="rotate(180 673.097 42.423)"
          ></path>
          <path
            fill="none"
            stroke="#7e7e7e"
            d="M-3.8 0h1109.5m-7.5-7.6H3.8"
            transform="rotate(180 673.097 42.423)"
          ></path>
        </g>
        <g id="290">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 118.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(795.079 118.975)"
          >
            <tspan x="37.5" y="17.5">
              29
            </tspan>
          </text>
        </g>
        <g id="291">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 144.673)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 144.673)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="292">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 170.373)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 170.373)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="220">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.989 118.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(703.989 118.975)"
          >
            <tspan x="37.5" y="17.5">
              22
            </tspan>
          </text>
        </g>
        <g id="221">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.989 144.673)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.989 144.673)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="222">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.989 170.373)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.989 170.373)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="360">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 118.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(977.249 118.975)"
          >
            <tspan x="37.5" y="17.5">
              36
            </tspan>
          </text>
        </g>
        <g id="361">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 144.673)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 144.673)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="362">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 170.373)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 170.373)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="shape11">
          <path
            fill="#fff"
            d="M0 0h52.9v7.6H0V0zm0 0v7.6h-4V0h4zm52.9 0v7.6h4V0h-4zm0 0v-52.9h-4V0h4z"
            transform="matrix(-1 0 0 1 684.998 1043.742)"
          ></path>
          <path
            fill="none"
            stroke="#303030"
            d="M0 0c0-29.2 23.7-52.9 52.9-52.9M0 0v7.6h-4V0h4zm52.9 0v7.6h4V0h-4zm0 0v-52.9h-4V0h4z"
            transform="matrix(-1 0 0 1 684.998 1043.742)"
          ></path>
        </g>
        <g id="shape12">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(613 1051.301)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(613 1051.301)"
          >
            <tspan x="32.5" y="17.5">
              입구
            </tspan>
          </text>
        </g>
        <g id="10">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248 118.59)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(248 118.59)"
          >
            <tspan x="41.5" y="17.5">
              1
            </tspan>
          </text>
        </g>
        <g id="11">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248 144.29)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248 144.29)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="12">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248 169.988)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248 169.988)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="150">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.849 118.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(521.849 118.975)"
          >
            <tspan x="37.5" y="17.5">
              15
            </tspan>
          </text>
        </g>
        <g id="151">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.849 144.673)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.849 144.673)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="152">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.849 170.373)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.849 170.373)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="80">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.769 118.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(430.769 118.975)"
          >
            <tspan x="41.5" y="17.5">
              8
            </tspan>
          </text>
        </g>
        <g id="81">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.769 144.673)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.769 144.673)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="82">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.769 170.373)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.769 170.373)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="shape22">
          <path
            fill="#7e7e7e"
            d="M-3.8 0h966l-3.8-3.8-3.8-3.8H3.8L0-3.8-3.8 0z"
            transform="rotate(-90 1198.487 -151.487)"
          ></path>
          <path
            fill="none"
            stroke="#7e7e7e"
            d="M-3.8 0h966m-7.6-7.6H3.8"
            transform="rotate(-90 1198.487 -151.487)"
          ></path>
        </g>
        <g id="shape23">
          <path
            fill="#7e7e7e"
            d="M-3.8 0h966.2v-3.8h-3.7l-3.8-3.8H3.8L-3.8 0z"
            transform="rotate(90 75.91 164.535)"
          ></path>
          <path
            fill="none"
            stroke="#7e7e7e"
            d="M-3.8 0h966.2m0-7.6H3.8M962.4 0v-7.6"
            transform="rotate(90 75.91 164.535)"
          ></path>
        </g>
        <g id="300">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(794.975 247.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(794.975 247.47)"
          >
            <tspan x="37.5" y="17.5">
              30
            </tspan>
          </text>
        </g>
        <g id="301">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(794.975 273.168)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(794.975 273.168)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="302">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(794.975 298.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(794.975 298.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="230">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.885 247.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(703.885 247.47)"
          >
            <tspan x="37.5" y="17.5">
              23
            </tspan>
          </text>
        </g>
        <g id="231">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.885 273.168)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.885 273.168)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="232">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.885 298.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.885 298.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="370">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.145 247.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(977.145 247.47)"
          >
            <tspan x="37.5" y="17.5">
              37
            </tspan>
          </text>
        </g>
        <g id="371">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.145 273.168)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.145 273.168)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="372">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.145 298.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.145 298.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="20">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248 247.085)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(248 247.085)"
          >
            <tspan x="41.5" y="17.5">
              2
            </tspan>
          </text>
        </g>
        <g id="21">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248 272.784)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248 272.784)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="22">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248 298.483)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248 298.483)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="160">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.745 247.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(521.745 247.47)"
          >
            <tspan x="37.5" y="17.5">
              16
            </tspan>
          </text>
        </g>
        <g id="161">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.745 273.168)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.745 273.168)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="162">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.745 298.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.745 298.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="90">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.665 247.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(430.665 247.47)"
          >
            <tspan x="41.5" y="17.5">
              9
            </tspan>
          </text>
        </g>
        <g id="91">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.665 273.168)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.665 273.168)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="92">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.665 298.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.665 298.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="310">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 375.964)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(795.079 375.964)"
          >
            <tspan x="37.5" y="17.5">
              31
            </tspan>
          </text>
        </g>
        <g id="311">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 401.663)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 401.663)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="312">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 427.363)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 427.363)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="240">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 375.964)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(703.985 375.964)"
          >
            <tspan x="37.5" y="17.5">
              24
            </tspan>
          </text>
        </g>
        <g id="241">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 401.663)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.985 401.663)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="242">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 427.363)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.985 427.363)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="380">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 375.964)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(977.249 375.964)"
          >
            <tspan x="37.5" y="17.5">
              38
            </tspan>
          </text>
        </g>
        <g id="381">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 401.663)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 401.663)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="382">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 427.363)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 427.363)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="30">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 375.58)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(248.684 375.58)"
          >
            <tspan x="41.5" y="17.5">
              3
            </tspan>
          </text>
        </g>
        <g id="31">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 401.28)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248.684 401.28)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="32">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 426.978)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248.684 426.978)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="170">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 375.964)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(521.845 375.964)"
          >
            <tspan x="37.5" y="17.5">
              17
            </tspan>
          </text>
        </g>
        <g id="171">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 401.663)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.845 401.663)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="172">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 427.363)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.845 427.363)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="100">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 375.964)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(430.765 375.964)"
          >
            <tspan x="37.5" y="17.5">
              10
            </tspan>
          </text>
        </g>
        <g id="101">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 401.663)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.765 401.663)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="102">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 427.363)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.765 427.363)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="320">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 504.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(795.079 504.46)"
          >
            <tspan x="37.5" y="17.5">
              32
            </tspan>
          </text>
        </g>
        <g id="321">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 530.159)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 530.159)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="322">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 555.857)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 555.857)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="250">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 504.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(703.985 504.46)"
          >
            <tspan x="37.5" y="17.5">
              25
            </tspan>
          </text>
        </g>
        <g id="251">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 530.159)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.985 530.159)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="252">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 555.857)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.985 555.857)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="390">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 504.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(977.249 504.46)"
          >
            <tspan x="37.5" y="17.5">
              39
            </tspan>
          </text>
        </g>
        <g id="391">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 530.159)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 530.159)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="392">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 555.857)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 555.857)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="40">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 504.075)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(248.684 504.075)"
          >
            <tspan x="41.5" y="17.5">
              4
            </tspan>
          </text>
        </g>
        <g id="41">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 529.774)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248.684 529.774)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="42">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 555.473)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248.684 555.473)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="180">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 504.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(521.845 504.46)"
          >
            <tspan x="37.5" y="17.5">
              18
            </tspan>
          </text>
        </g>
        <g id="181">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 530.159)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.845 530.159)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="182">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 555.857)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.845 555.857)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="110">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 504.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(430.765 504.46)"
          >
            <tspan x="37.5" y="17.5">
              11
            </tspan>
          </text>
        </g>
        <g id="111">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 530.159)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.765 530.159)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="112">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 555.857)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.765 555.857)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="330">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 632.955)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(795.079 632.955)"
          >
            <tspan x="37.5" y="17.5">
              33
            </tspan>
          </text>
        </g>
        <g id="331">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 658.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 658.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="332">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 684.352)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 684.352)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="260">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 632.955)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(703.985 632.955)"
          >
            <tspan x="37.5" y="17.5">
              26
            </tspan>
          </text>
        </g>
        <g id="261">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 658.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.985 658.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="262">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 684.352)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.985 684.352)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="400">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 632.955)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(977.249 632.955)"
          >
            <tspan x="37.5" y="17.5">
              40
            </tspan>
          </text>
        </g>
        <g id="401">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 658.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 658.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="402">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 684.352)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 684.352)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="50">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 632.57)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(248.684 632.57)"
          >
            <tspan x="41.5" y="17.5">
              5
            </tspan>
          </text>
        </g>
        <g id="51">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 658.27)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248.684 658.27)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="52">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 683.968)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248.684 683.968)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="190">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 632.955)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(521.845 632.955)"
          >
            <tspan x="37.5" y="17.5">
              19
            </tspan>
          </text>
        </g>
        <g id="191">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 658.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.845 658.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="192">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 684.352)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.845 684.352)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="120">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 632.955)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(430.765 632.955)"
          >
            <tspan x="37.5" y="17.5">
              12
            </tspan>
          </text>
        </g>
        <g id="121">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 658.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.765 658.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="122">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 684.352)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.765 684.352)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="340">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 761.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(795.079 761.45)"
          >
            <tspan x="37.5" y="17.5">
              34
            </tspan>
          </text>
        </g>
        <g id="341">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 787.149)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 787.149)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="342">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 812.847)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 812.847)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="270">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 761.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(703.985 761.45)"
          >
            <tspan x="37.5" y="17.5">
              27
            </tspan>
          </text>
        </g>
        <g id="271">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 787.149)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.985 787.149)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="272">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 812.847)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.985 812.847)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="410">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 761.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(977.249 761.45)"
          >
            <tspan x="37.5" y="17.5">
              41
            </tspan>
          </text>
        </g>
        <g id="411">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 787.149)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 787.149)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="412">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 812.847)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 812.847)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="60">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 761.066)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(248.684 761.066)"
          >
            <tspan x="41.5" y="17.5">
              6
            </tspan>
          </text>
        </g>
        <g id="61">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 786.764)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248.684 786.764)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="62">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 812.463)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248.684 812.463)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="200">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 761.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(521.845 761.45)"
          >
            <tspan x="37.5" y="17.5">
              20
            </tspan>
          </text>
        </g>
        <g id="201">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 787.149)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.845 787.149)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="202">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 812.847)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.845 812.847)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="130">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 761.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(430.765 761.45)"
          >
            <tspan x="37.5" y="17.5">
              13
            </tspan>
          </text>
        </g>
        <g id="131">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 787.149)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.765 787.149)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="132">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 812.847)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.765 812.847)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="350">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 889.944)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(795.079 889.944)"
          >
            <tspan x="37.5" y="17.5">
              35
            </tspan>
          </text>
        </g>
        <g id="351">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 915.644)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 915.644)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="352">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(795.079 941.342)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(795.079 941.342)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="280">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 889.944)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(703.985 889.944)"
          >
            <tspan x="37.5" y="17.5">
              28
            </tspan>
          </text>
        </g>
        <g id="281">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 915.644)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.985 915.644)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="282">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(703.985 941.342)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(703.985 941.342)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="420">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 889.944)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(977.249 889.944)"
          >
            <tspan x="37.5" y="17.5">
              42
            </tspan>
          </text>
        </g>
        <g id="421">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 915.644)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 915.644)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="422">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(977.249 941.342)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(977.249 941.342)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="70">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 889.56)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(248.684 889.56)"
          >
            <tspan x="41.5" y="17.5">
              7
            </tspan>
          </text>
        </g>
        <g id="71">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 915.26)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248.684 915.26)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="72">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(248.684 940.958)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(248.684 940.958)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="210">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 889.944)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(521.845 889.944)"
          >
            <tspan x="37.5" y="17.5">
              21
            </tspan>
          </text>
        </g>
        <g id="211">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 915.644)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.845 915.644)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="212">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(521.845 941.342)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(521.845 941.342)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="140">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 889.944)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(430.765 889.944)"
          >
            <tspan x="37.5" y="17.5">
              14
            </tspan>
          </text>
        </g>
        <g id="141">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 915.644)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.765 915.644)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="142">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(430.765 941.342)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(430.765 941.342)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="shape132">
          <path
            fill="#7e7e7e"
            d="M3.8 0h1094.4l3.8-3.8v-3.8H-3.8L0-3.8 3.8 0z"
            transform="rotate(180 673.03 521.696)"
          ></path>
          <path
            fill="none"
            stroke="#7e7e7e"
            d="M3.8 0h1094.4m3.8-7.6H-3.8"
            transform="rotate(180 673.03 521.696)"
          ></path>
        </g>
        <g id="430">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 118.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1068.431 118.975)"
          >
            <tspan x="37.5" y="17.5">
              43
            </tspan>
          </text>
        </g>
        <g id="431">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 144.674)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 144.674)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="432">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 170.374)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 170.374)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="500">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 118.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1250.601 118.975)"
          >
            <tspan x="37.5" y="17.5">
              50
            </tspan>
          </text>
        </g>
        <g id="501">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 144.674)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 144.674)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="502">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 170.374)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 170.374)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="440">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.331 247.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1068.331 247.47)"
          >
            <tspan x="37.5" y="17.5">
              44
            </tspan>
          </text>
        </g>
        <g id="441">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.331 273.17)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.331 273.17)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="442">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.331 298.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.331 298.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="510">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.501 247.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1250.501 247.47)"
          >
            <tspan x="37.5" y="17.5">
              51
            </tspan>
          </text>
        </g>
        <g id="511">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.501 273.17)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.501 273.17)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="512">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.501 298.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.501 298.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="450">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 375.966)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1068.431 375.966)"
          >
            <tspan x="37.5" y="17.5">
              45
            </tspan>
          </text>
        </g>
        <g id="451">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 401.664)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 401.664)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="452">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 427.363)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 427.363)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="520">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 375.966)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1250.601 375.966)"
          >
            <tspan x="37.5" y="17.5">
              52
            </tspan>
          </text>
        </g>
        <g id="521">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 401.664)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 401.664)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="522">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 427.363)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 427.363)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="460">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 504.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1068.431 504.46)"
          >
            <tspan x="37.5" y="17.5">
              46
            </tspan>
          </text>
        </g>
        <g id="461">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 530.16)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 530.16)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="462">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 555.859)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 555.859)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="530">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 504.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1250.601 504.46)"
          >
            <tspan x="37.5" y="17.5">
              53
            </tspan>
          </text>
        </g>
        <g id="531">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 530.16)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 530.16)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="532">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 555.859)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 555.859)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="470">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 632.956)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1068.431 632.956)"
          >
            <tspan x="37.5" y="17.5">
              47
            </tspan>
          </text>
        </g>
        <g id="471">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 658.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 658.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="472">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 684.354)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 684.354)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="540">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 632.956)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1250.601 632.956)"
          >
            <tspan x="37.5" y="17.5">
              54
            </tspan>
          </text>
        </g>
        <g id="541">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 658.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 658.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="542">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 684.354)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 684.354)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="480">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 761.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1068.431 761.45)"
          >
            <tspan x="37.5" y="17.5">
              48
            </tspan>
          </text>
        </g>
        <g id="481">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 787.15)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 787.15)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="482">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 812.848)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 812.848)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="550">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 761.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1250.601 761.45)"
          >
            <tspan x="37.5" y="17.5">
              55
            </tspan>
          </text>
        </g>
        <g id="551">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 787.15)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 787.15)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="552">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 812.848)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 812.848)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="490">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 889.942)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1068.431 889.942)"
          >
            <tspan x="37.5" y="17.5">
              49
            </tspan>
          </text>
        </g>
        <g id="491">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 915.641)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 915.641)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="492">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1068.431 941.341)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1068.431 941.341)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="560">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 889.942)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1250.601 889.942)"
          >
            <tspan x="37.5" y="17.5">
              56
            </tspan>
          </text>
        </g>
        <g id="561">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 915.641)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 915.641)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="562">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1250.601 941.341)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1250.601 941.341)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default SongdoFixedSeatSvg;