import React, { useEffect, useState } from "react";
import InnerHeader from "../etc/innerheader";
import styles from "./mobilerestaurantstatus.module.css";
import { useLocation } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import {Clipboard} from "@capacitor/clipboard";

import {ReactComponent as Paste} from "../../../src/svg/paste-light.svg";
import { useIonAlert } from '@ionic/react';
import { returnAccountInfo } from "../../data/locationFuntion";


const MobileRechargetText: React.FC<any> = (props) => {

    const location : any = useLocation();
    const [amount, setAmount] = useState<null | number>();
    const [name, setName] = useState<null | string>();
    const [presentAlert] = useIonAlert();

    useEffect(() => {

        const newAmount = location.state.amount;
        const newName = location.state.name;

        setAmount(newAmount);
        setName(newName);
    }, []);

    const copyClipboard = async () => {

        if(!props.user || !props.user.academy){
            return;
        }

        const academy = props.user.academy;
        const accountInfo = returnAccountInfo(academy);
        

        const message = 
`[송금 계좌 정보]
- ${accountInfo.bank}
- ${accountInfo.account}
- 예금주 : ${accountInfo.name}

[충전 신청 내역]
- 신청금액 : ${amount && amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
- 입금자명 : ${name && name}`


        if(Capacitor.isNativePlatform()){
            console.log(111);
            await Clipboard.write({
                string : message
            });

            console.log("gogoo");
            presentAlert({
                header : "완료",
                subHeader : "송금정보가 저장되었습니다",
                buttons : ["확인"]
            })
        }else{
            await navigator.clipboard.writeText(message);
            presentAlert({
                header : "완료",
                subHeader : "송금정보가 저장되었습니다",
                buttons : ["확인"]
            })
        }
    }

    return (
        <div>
            <InnerHeader title="충전 신청" />
            <div className={styles.chargeBody}>
                <div className={styles.chargeTitle}>
                    충전 신청이 완료되었습니다
                </div>
                <div className={styles.chargeSubTitle}>
                    신청해주신 금액, 입금자명과<br></br>실제 송금한 금액, 입금자명이 일치해야<br></br>충전 내역이 자동으로 반영됩니다. 
                </div>

                <div className={styles.infoBox}>
                    <div className={styles.infoBoxTitle}>
                        <div>
                            충전 신청 안내
                        </div>
                        <div className={styles.pasteDiv} onClick={copyClipboard}>
                            <Paste className={styles.paste} />
                        </div>
                    </div>
                    <div className={styles.sendInfoBox}>
                        <div className={styles.sendInfo}>
                            [송금 계좌 정보]
                        </div>
                        <div className={styles.sendInfo}>
                            - 신한은행
                        </div>
                        <div className={styles.sendInfo}>
                            - {(props.user && props.user.academy) && returnAccountInfo(props.user.academy).account}
                        </div>
                        <div className={styles.sendInfo}>
                            - 예금주 : {(props.user && props.user.academy) && returnAccountInfo(props.user.academy).name}
                        </div>
                    </div>
                    <div className={`${styles.sendInfoBox} ${styles.second}`}>
                        <div className={styles.sendInfo}>
                            [충전 신청 내역]
                        </div>
                        <div className={styles.sendInfo}>
                            - 신청금액 : {amount && amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                        </div>
                        <div className={styles.sendInfo}>
                            - 입금자명 : {name && name}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default MobileRechargetText;