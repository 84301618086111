import { CssVarsProvider } from "@mui/joy/styles";
import React, { useEffect, useState, useRef } from "react";
import styles from "./selectmodal.module.css";
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import { RadioGroup } from "@mui/joy";
import Radio from '@mui/joy/Radio';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';


interface modalProp {
    open: boolean;
    handleClose: () => void;
    selectOptions: { subject: string, value: string }[];
    handleSubjectChange: (value: string) => void;
    subject: string;
}

const SelectModal: React.FC<modalProp> = (props) => {

    const [value, setValue] = useState("femail");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.handleSubjectChange(e.target.value);
    }


    return (
        <CssVarsProvider>
            <Modal
                onClose={() => { props.handleClose() }}
                open={props.open}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        minWidth: 300,
                        borderRadius: 'md',
                        outline: 0
                    }}
                >
                    <div className={styles.main}>
                        <div className={styles.body}>

                        </div>
                    </div>
                </Sheet>
            </Modal>
        </CssVarsProvider>
    );
}

export default SelectModal;