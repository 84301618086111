import * as React from 'react';
import Box from '@mui/joy/Box';
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import styles from "../../controls/newattendance/styles/attendancestyles.module.css"
import totalLocationData from '../../../../data/locationdata';
import { englishLocationToKorean } from '../../../../data/locationFuntion';

export default function LocationSelect(props : any) {
  const [alignment, setAlignment] = React.useState('');
  const [menus, setMenus] = React.useState<any>([]);

  React.useEffect(() => {

    if(!props.user || !props.user.academy){
        return;
    }

    const academy = props.user.academy;

    const filteredLocationData = totalLocationData.filter((locationData) => {

        if(locationData.english === "total"){
            return false;
        }

        return locationData.academy.includes(academy);
    })

    const firstLocationData = filteredLocationData[0];

    setAlignment(firstLocationData.english);

}, [props.user]);

  React.useEffect(() => {

    if(!props.user){
      return;
    }

    const academy = props.user.academy;

    const totalMenus : string[] = [];

    totalLocationData.forEach((location) => {
      if(location.english === "total"){
        return;
      }

      if(!location.academy.includes(academy)){
        return;
      }

      if(location.english === "songdo_free"){
        return;
      }


      totalMenus.push(location.english);
    });

    setMenus([...totalMenus]);

  }, [props.user]);

  return (
    <RadioGroup
      orientation="horizontal"
      aria-label="Alignment"
      name="alignment"
      variant="outlined"
      value={alignment}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        {
            setAlignment(event.target.value)
            props.handleLocationChange(event.target.value);
        }
      }
    >
      {menus.map((item : any, index : number) => (
        <Box
          key={item}
          sx={(theme) => ({
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 85,
            height: 58,
            '&:not([data-first-child])': {
              borderLeft: '1px solid',
              borderColor: 'divider',
            },
            [`&[data-first-child] .${radioClasses.action}`]: {
              borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
              borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
            },
            [`&[data-last-child] .${radioClasses.action}`]: {
              borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
              borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
            },
          })}
        >
          <Radio
            value={item}
            disableIcon
            overlay
            label={
              // {
              //   gangnam: <span className={styles.basicFont}>강남점</span>,
              //   daechi: <span className={styles.basicFont}>대치점</span>,
              //   daechi2 : <span className={styles.basicFont}>대치3층</span>,
              //   daechi3 : <span className={styles.basicFont}>대치6층</span>
              // }[item]
              <span className={styles.basicFont}>{englishLocationToKorean(item)}</span>
            }
            variant={alignment === item ? 'solid' : 'plain'}
            slotProps={{
              input: { 'aria-label': item },
              action: {
                sx: { borderRadius: 0, transition: 'none' },
              },
              label: { sx: { lineHeight: 0 } },
            }}
          />
        </Box>
      ))}
    </RadioGroup>
  );
}