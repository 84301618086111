import React, { useEffect, useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TotalAppliedCharge from "./totalappliedcharge";
import TotalSendedMoney from "./totalsendedmoney";
import LetsRefund from "./letsrefund";

const ControlMoney: React.FC<any> = (props: any) => {
    const [value, setValue] = useState(1);

    const handleChange = (e: any, newValue: number) => {
        setValue(newValue);
    }

    return (
        <div>
            <div style={{ width: "1150px", marginTop: "24px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={<span style={{ fontFamily: "Apple_B" }}>입금 내역</span>} value={1} />
                        <Tab label={<span style={{ fontFamily: "Apple_B" }}>충전 신청 내역</span>} value={2} />
                        <Tab label={<span style={{ fontFamily: "Apple_B" }}>환불하기</span>} value={3} />
                    </Tabs>
                </Box>
            </div>

            {
                value === 1
                &&
                <TotalSendedMoney />
            }
            {
                value === 2
                &&
                <TotalAppliedCharge />
            }
            {
                value === 3
                &&
                <LetsRefund />
            }


        </div>
    );
}

export default ControlMoney;