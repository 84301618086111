import React, { useEffect, useState, useRef } from "react";
import { GridRenderCellParams, DataGridPro, GridRowsProp, GridColDef, GridToolbar, LicenseInfo, useGridApiRef, GridEditRowsModel, GridFilterModel, GridRowParams } from '@mui/x-data-grid-pro';
import { createStyles, makeStyles } from '@mui/styles';
import renderCellExpand from "../../data/rendercellexpand";
import { createTheme, darken, lighten } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SecondOutingTest from "./secondoutingtest";
import { Button, CssVarsProvider, Divider } from "@mui/joy";
import ReactModal from 'react-modal-resizable-draggable';
// import {ReactComponent as XMarkSvg} from "../../../svg/circle-xmark-solid.svg";
import {ReactComponent as XMarkSvg} from "../../../../svg/circle-xmark-solid.svg";
import { returnDayString } from "../../../../mobile/etc/functions";

import { IonButton, useIonToast } from '@ionic/react';
import { ButtonGroup, TextField } from "@mui/material";
import TotalFingerprint2 from "./totalfingerprint2";

LicenseInfo.setLicenseKey("3124a63cf796a421a7ac55c9bf753845Tz01NjM1MCxFPTE3MDMwMzU5NjMxNzEsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=");

const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    color: "red",
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    color: "blue",
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .timeout': {
                    color: theme.palette.error.main,
                },
                '& .good': {
                    color: theme.palette.primary.main,
                },
                '& .absent': {
                    backgroundColor : "#ffe9e8",
                    color : "#a10e25"
                },
                '& .early': {
                    backgroundColor : "#fff8c5",
                    color : "#4d2d00"
                },
            },
        };
    },
    { defaultTheme },
);

const columns0: GridColDef[] = [
    { field: 'seat', headerName: '좌석', width: 50 },
    { field: 'name', headerName: '이름', width: 70 },
    { field: 'status', headerName: '위치', width: 60, filterable: true },
    { field: 'last', headerName: '마지막 기록', width: 200, filterable: false },
    { field: 'regular', headerName: '정기일정', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'regularEtc', headerName: '정기일정 기타', width: 100, filterable: false, renderCell: renderCellExpand },
    { field: 'sudden', headerName: '사유 제출', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'firstReply', headerName: '자리에 없는 이유', width: 400, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: 'secondReply', headerName: '최종 처리', width: 150, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: "zeroReply_1", headerName: "0교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_2", headerName: "0교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_1", headerName: "1교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_2", headerName: "1교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_1", headerName: "2교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_2", headerName: "2교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_1", headerName: "3교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_2", headerName: "3교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_1", headerName: "4교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_2", headerName: "4교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_1", headerName: "5교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_2", headerName: "5교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_1", headerName: "6교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_2", headerName: "6교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_1", headerName: "7교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_2", headerName: "7교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand }
];

const columns1: GridColDef[] = [
    { field: 'seat', headerName: '좌석', width: 50 },

    { field: 'name', headerName: '이름', width: 70 },
    { field: 'status', headerName: '위치', width: 60, filterable: true },
    { field: 'last', headerName: '마지막 기록', width: 200, filterable: false },
    { field: 'regular', headerName: '정기일정', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'regularEtc', headerName: '정기일정 기타', width: 100, filterable: false, renderCell: renderCellExpand },
    { field: 'sudden', headerName: '사유 제출', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'firstReply', headerName: '자리에 없는 이유', width: 400, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: 'secondReply', headerName: '최종 처리', width: 150, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: "oneReply_1", headerName: "1교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_2", headerName: "1교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_1", headerName: "0교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_2", headerName: "0교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_1", headerName: "2교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_2", headerName: "2교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_1", headerName: "3교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_2", headerName: "3교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_1", headerName: "4교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_2", headerName: "4교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_1", headerName: "5교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_2", headerName: "5교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_1", headerName: "6교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_2", headerName: "6교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_1", headerName: "7교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_2", headerName: "7교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand }
];

const columns3: GridColDef[] = [
    { field: 'seat', headerName: '좌석', width: 50 },

    { field: 'name', headerName: '이름', width: 70 },
    { field: 'status', headerName: '위치', width: 60, filterable: true },
    { field: 'last', headerName: '마지막 기록', width: 200, filterable: false },
    { field: 'regular', headerName: '정기일정', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'regularEtc', headerName: '정기일정 기타', width: 100, filterable: false, renderCell: renderCellExpand },
    { field: 'sudden', headerName: '사유 제출', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'firstReply', headerName: '자리에 없는 이유', width: 400, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: 'secondReply', headerName: '최종 처리', width: 150, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: "twoReply_1", headerName: "2교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_2", headerName: "2교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_1", headerName: "1교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_2", headerName: "1교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_1", headerName: "0교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_2", headerName: "0교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_1", headerName: "3교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_2", headerName: "3교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_1", headerName: "4교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_2", headerName: "4교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_1", headerName: "5교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_2", headerName: "5교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_1", headerName: "6교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_2", headerName: "6교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_1", headerName: "7교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_2", headerName: "7교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand }
];

const columns4: GridColDef[] = [
    { field: 'seat', headerName: '좌석', width: 50 },
    { field: 'name', headerName: '이름', width: 70 },

    { field: 'status', headerName: '위치', width: 60, filterable: true },
    { field: 'last', headerName: '마지막 기록', width: 200, filterable: false },
    { field: 'regular', headerName: '정기일정', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'regularEtc', headerName: '정기일정 기타', width: 100, filterable: false, renderCell: renderCellExpand },
    { field: 'sudden', headerName: '사유 제출', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'firstReply', headerName: '자리에 없는 이유', width: 400, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: 'secondReply', headerName: '최종 처리', width: 150, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: "threeReply_1", headerName: "3교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_2", headerName: "3교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_1", headerName: "2교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_2", headerName: "2교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_1", headerName: "1교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_2", headerName: "1교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_1", headerName: "4교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_2", headerName: "4교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_1", headerName: "5교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_2", headerName: "5교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_1", headerName: "6교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_2", headerName: "6교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_1", headerName: "7교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_2", headerName: "7교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand }
];

const columns5: GridColDef[] = [
    { field: 'seat', headerName: '좌석', width: 50 },

    { field: 'name', headerName: '이름', width: 70 },
    { field: 'status', headerName: '위치', width: 60, filterable: true },
    { field: 'last', headerName: '마지막 기록', width: 200, filterable: false },
    { field: 'regular', headerName: '정기일정', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'regularEtc', headerName: '정기일정 기타', width: 100, filterable: false, renderCell: renderCellExpand },
    { field: 'sudden', headerName: '사유 제출', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'firstReply', headerName: '자리에 없는 이유', width: 400, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: 'secondReply', headerName: '최종 처리', width: 150, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: "fourReply_1", headerName: "4교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_2", headerName: "4교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_1", headerName: "3교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_2", headerName: "3교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_1", headerName: "2교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_2", headerName: "2교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_1", headerName: "1교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_2", headerName: "1교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_1", headerName: "0교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_2", headerName: "0교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_1", headerName: "5교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_2", headerName: "5교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_1", headerName: "6교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_2", headerName: "6교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_1", headerName: "7교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_2", headerName: "7교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand }
];

const columns6: GridColDef[] = [
    { field: 'seat', headerName: '좌석', width: 50 },

    { field: 'name', headerName: '이름', width: 70 },
    { field: 'status', headerName: '위치', width: 60, filterable: true },
    { field: 'last', headerName: '마지막 기록', width: 200, filterable: false },
    { field: 'regular', headerName: '정기일정', width: 200, filterable: false },
    { field: 'regularEtc', headerName: '정기일정 기타', width: 100, filterable: false },
    { field: 'sudden', headerName: '사유 제출', width: 200, filterable: false },
    { field: 'firstReply', headerName: '자리에 없는 이유', width: 400, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: 'secondReply', headerName: '최종 처리', width: 150, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: "fiveReply_1", headerName: "5교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_2", headerName: "5교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_1", headerName: "4교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_2", headerName: "4교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_1", headerName: "3교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_2", headerName: "3교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_1", headerName: "2교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_2", headerName: "2교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_1", headerName: "1교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_2", headerName: "1교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_1", headerName: "0교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_2", headerName: "0교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_1", headerName: "6교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_2", headerName: "6교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_1", headerName: "7교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_2", headerName: "7교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand }
];

//6-1교시용
const columns7: GridColDef[] = [
    { field: 'seat', headerName: '좌석', width: 50 },

    { field: 'name', headerName: '이름', width: 70 },
    { field: 'status', headerName: '위치', width: 60, filterable: true },
    { field: 'last', headerName: '마지막 기록', width: 200, filterable: false },
    { field: 'regular', headerName: '정기일정', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'regularEtc', headerName: '정기일정 기타', width: 100, filterable: false, renderCell: renderCellExpand },
    { field: 'sudden', headerName: '사유 제출', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'firstReply', headerName: '자리에 없는 이유', width: 400, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: 'secondReply', headerName: '최종 처리', width: 150, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: "sixReply_1", headerName: "6교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_2", headerName: "6교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_1", headerName: "5교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_2", headerName: "5교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_1", headerName: "4교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_2", headerName: "4교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_1", headerName: "3교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_2", headerName: "3교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_1", headerName: "2교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_2", headerName: "2교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_1", headerName: "1교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_2", headerName: "1교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_1", headerName: "0교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_2", headerName: "0교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixFirstReply_1", headerName: "7교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixFirstReply_2", headerName: "7교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixSecondReply_1", headerName: "8교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixSecondReply_2", headerName: "8교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_1", headerName: "하원 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_2", headerName: "하원 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
];

//6-2교시용
const columns8: GridColDef[] = [
    { field: 'seat', headerName: '좌석', width: 50 },

    { field: 'name', headerName: '이름', width: 70 },
    { field: 'status', headerName: '위치', width: 60, filterable: true },
    { field: 'last', headerName: '마지막 기록', width: 200, filterable: false },
    { field: 'regular', headerName: '정기일정', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'regularEtc', headerName: '정기일정 기타', width: 100, filterable: false, renderCell: renderCellExpand },
    { field: 'sudden', headerName: '사유 제출', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'firstReply', headerName: '자리에 없는 이유', width: 400, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: 'secondReply', headerName: '최종 처리', width: 150, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: "sixFirstReply_1", headerName: "7교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixFirstReply_2", headerName: "7교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_1", headerName: "6교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_2", headerName: "6교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_1", headerName: "5교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_2", headerName: "5교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_1", headerName: "4교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_2", headerName: "4교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_1", headerName: "3교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_2", headerName: "3교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_1", headerName: "2교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_2", headerName: "2교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_1", headerName: "1교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_2", headerName: "1교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_1", headerName: "0교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_2", headerName: "0교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixSecondReply_1", headerName: "8교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixSecondReply_2", headerName: "8교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_1", headerName: "하원 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_2", headerName: "하원 최종처리", width: 100, filterable: false, renderCell: renderCellExpand }
];

//7교시용
const columns9: GridColDef[] = [
    { field: 'seat', headerName: '좌석', width: 50 },

    { field: 'name', headerName: '이름', width: 70 },
    { field: 'status', headerName: '위치', width: 60, filterable: true },
    { field: 'last', headerName: '마지막 기록', width: 200, filterable: false },
    { field: 'regular', headerName: '정기일정', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'regularEtc', headerName: '정기일정 기타', width: 100, filterable: false, renderCell: renderCellExpand },
    { field: 'sudden', headerName: '사유 제출', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'firstReply', headerName: '자리에 없는 이유', width: 400, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: 'secondReply', headerName: '최종 처리', width: 150, filterable: false, renderCell: renderCellExpand, editable: false },
    { field: "sixSecondReply_1", headerName: "8교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixSecondReply_2", headerName: "8교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixFirstReply_1", headerName: "7교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixFirstReply_2", headerName: "7교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_1", headerName: "6교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sixReply_2", headerName: "6교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_1", headerName: "5교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fiveReply_2", headerName: "5교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_1", headerName: "4교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "fourReply_2", headerName: "4교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_1", headerName: "3교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "threeReply_2", headerName: "3교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_1", headerName: "2교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "twoReply_2", headerName: "2교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_1", headerName: "1교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "oneReply_2", headerName: "1교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_1", headerName: "0교시 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "zeroReply_2", headerName: "0교시 최종처리", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_1", headerName: "하원 상황", width: 100, filterable: false, renderCell: renderCellExpand },
    { field: "sevenReply_2", headerName: "하원 최종처리", width: 100, filterable: false, renderCell: renderCellExpand }
];


const SecondTotalAttendanceProcess: React.FC<any> = (props) => {

    const [searchName, setSearchName] = useState("");

    const classes = useStyles2();
    const [rows, setRows] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [alignment, setAlignment] = React.useState('zero');
    const [targetDate, setTargetDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 8));
    const apiRef = useGridApiRef();
    const [name, setName] = useState("");
    const [userData, setUserData] = useState<any>();
    const [bool, setBool] = useState([true, true, true, true, true, true, true, true, true, true, true]);

    const [inNumber, setInNumber] = useState(0);
    const [outNumber, setOutNumber] = useState(0);

    const [users, setUsers] = useState();
    const [data, setData] = useState();

    const [zero, setZero] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 8));
    const [first, setFirst] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 9));
    const [second, setSecond] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 10, 30));
    const [third, setThird] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 13, 20));
    const [fourth, setFourth] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 14, 50));
    const [fifth, setFifth] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 16, 20));
    const [sixth, setSixth] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 18, 40));
    const [seventh, setSeventh] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 21, 30));
    const [sixFirst, setSixFirst] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 19, 50));
    const [sixSecond, setSixSecond] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 21, 0));

    const [selectedUserId, setSelectedUserId] = useState();
    const [selectedFingerprintId, setSelectedFingerprintId] = useState<any>();
    const [selectedUserName, setSelectedUserName] = useState<string>();
    const [isAbsent, setIsAbsent] = useState(false);
    const [isEarly, setIsEarly] = useState(false);
    
    const [open, setOpen] = useState(false);
    const handleClose = () => {setOpen(false);}
    const [open2, setOpen2] = useState(false);
    const handleClose2 = () => {setOpen2(false);}


    const [regularData, setRegularData] = useState<any>();
    const [suddenData, setSuddenData] = useState<any>();

    const [todayDay, setTodayDay] = useState<string>();
    const [update, setUpdate] = useState(0);

    const [present] = useIonToast();

    const [modalDate, setModalDate] = useState(new Date());

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 2, columnField: 'name', operatorValue: 'contains', value: "" }
        ],
    });

    

    useEffect(() => {

        if(open){
            const newDate = new Date(targetDate.getTime());
            setModalDate(newDate);
        }

    }, [open]);

   const returnDayStringEnglish = (dayNumber : number) => {

        var dayString = "";
    
        switch (dayNumber) {
            case 0:
                dayString = "sunday";
                break;
            case 1:
                dayString = "monday";
                break;
            case 2:
                dayString = "tuesday";
                break;
            case 3:
                dayString = "wednesday";
                break;
            case 4:
                dayString = "thursday";
                break;
            case 5:
                dayString = "friday";
                break;
            case 6:
                dayString = "saturday";
                break;
        }
    
        return dayString;
    }

    const reasonToString = (suddenData: any) => {

        var reasonString = "";

        suddenData.forEach((each: any, index: number) => {

            var description;
            switch (each.type) {
                case "long": description = "지각"; break;
                case "early": description = "조기하원"; break;
                case "among": description = "외출"; break;
                case "absent": description = "결석"; break;
            }

            if(index !== 0){
                reasonString += ", ";
            }

            reasonString += `[${description}]`;

            if(each.type === "long"){

                reasonString += `${each.startHours > 11 ? "오후" : "오전"} ${each.startHours > 12 ? each.startHours - 12 : each.startHours}시 ${each.startMinutes.toString().length === 1 ? "0" + each.startMinutes : each.startMinutes}분 도착`;

            }

            if(each.type === "early"){

                reasonString += `${each.endHours > 11 ? "오후" : "오전"} ${each.endHours > 12 ? each.endHours - 12 : each.endHours}시 ${each.endMinutes.toString().length === 1 ? "0" + each.endMinutes : each.endMinutes}분 하원`

            }

            if(each.type === "among"){

                reasonString += `${each.endHours > 11 ? "오후" : "오전"}  ${each.startHours > 12 ? each.startHours - 12 : each.startHours}시 ${each.startMinutes.toString().length === 1 ? "0" + each.startMinutes : each.startMinutes}분 외출 -
                ${each.endHours > 11 ? "오후" : "오전"} ${each.endHours > 12 ? each.endHours - 12 : each.endHours}시 ${each.endMinutes.toString().length === 1 ? "0" + each.endMinutes : each.endMinutes}분 복귀
                `
            }

            reasonString += ` ${each.reason}`;

            if(each.parentpermit === 0){
                reasonString += ` [대기]`
            }

            if(each.parentpermit === 1){
                reasonString += ` [승인]`
            }

            if(each.parentpermit === 2){
                reasonString += ` [거절]`
            }

        })

        return reasonString;

    }


    useEffect(() => {

        console.log(111);

        setLoading(true);
        var date = targetDate.getTime();
        start(date, alignment);
        
    }, [update, targetDate]);

    useEffect(() => {
        var currentDateTime = new Date();
        currentDateTime.setDate(currentDateTime.getDate());
        var index = -1;

        if (currentDateTime.getTime() > first.getTime()) {
            index = 0;
        }
        if (currentDateTime.getTime() > second.getTime()) {
            index = 1;
        }
        if (currentDateTime.getTime() > third.getTime()) {
            index = 2;
        }
        if (currentDateTime.getTime() > fourth.getTime()) {
            index = 3;
        }
        if (currentDateTime.getTime() > fifth.getTime()) {
            index = 4;
        }
        if (currentDateTime.getTime() > sixth.getTime()) {
            index = 5;
        }
        if (currentDateTime.getTime() > sixFirst.getTime()) {
            index = 6;
        }
        if (currentDateTime.getTime() > sixSecond.getTime()) {
            index = 7;
        }
        if (currentDateTime.getTime() > seventh.getTime()) {
            index = 8;
        }

        index = 8;

        console.log('-----');
        console.log(index);

        const newBool = bool;

        for (var i = 0; i <= index; i++) {
            newBool[i] = false;
        }

        //0교시 8시에 열어주는 기능
        if (currentDateTime.getTime() > zero.getTime()) {
            newBool[9] = false;
        }

        // newBool[9] = false;

        setBool([...newBool]);

    }, []);

    const start = async (date: number, currentClass: string) => {

        const newDate = new Date(date);
        const day = newDate.getDay();
        const dayStringEnglish = returnDayStringEnglish(day);


        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/fingerprint/totalStamp?date=" + date + "&location=daechi&locationString=대치점", {
            method: "GET",
            headers: { "Authorization": token },
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    const data = result.data;
                    const reply = result.reply;
                    const users = result.users;

                    console.log(users);

                    setData(data);
                    setUsers(users);

                    users.forEach((eachUser: any) => {
                        eachUser.records = [];
                        eachUser.reply = [];
                    })

                    data.forEach((eachData: any) => {
                        users.forEach((eachUser: any) => {
                            if (+eachData.userId === +eachUser.user_id) {
                                eachUser.records.push(eachData);
                            }
                        })
                    })

                    reply.forEach((eachReply: any) => {
                        users.forEach((eachUser: any) => {
                            if (eachReply.fingerprintUserId === +eachUser.user_id) {
                                eachUser.reply.push(eachReply);
                            }
                        })
                    })



                    users.sort(function (a: any, b: any) {
                        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                    });


                    users.forEach((user: any) => {
                        user.records.forEach((record: any) => {
                            record.realTime = `${new Date(+record.time).getDate()} - ${new Date(+record.time).getHours()} - ${new Date(+record.time).getMinutes()}`;
                            // console.log(record.realTime);
                        })
                    })

                    setUserData(users);
                    filterUsers(users, date, currentClass, dayStringEnglish);
                    setLoading(false);
                })
        })
    }

    const filterUsers = (preParsedUsers: any, targetTime: number, currentClass: string, dayStringEnglish : string) => {
        preParsedUsers.forEach((user: any) => {
            user.records.forEach((record: any) => {
                record.realTime = `${new Date(+record.time).getDate()} - ${new Date(+record.time).getHours()} - ${new Date(+record.time).getMinutes()}`;
            })
        })

        const parsedUsers: any = [];

        preParsedUsers.forEach((each: any) => {
            if (each.access_groups && each.access_groups[0].name === "학생" && each.name !== "Administrator") {
                each.records = each.records.filter((record: any) =>
                    +record.time < targetTime + 60000
                );

                each.records.forEach((record: any) => {
                    const specify = new Date(+record.time);
                    record.realTime = `${specify.getDate()}, ${specify.getHours()}, ${specify.getMinutes()}`
                })

                parsedUsers.push(each);
            }
        })

        const newRows: any = [];


        parsedUsers.forEach((user: any) => {
            const oneRow: any = {}
            oneRow.id = user.user_id;
            oneRow.name = user.name;
            oneRow.userId = user.sqlUserId;
            oneRow.seat = user.studentSeat;

            var alignmentNumber = 0;
            var absentClassNumber = 0;
            var classString = "";

            if(user.absentResult && (user.absentResult.isEarly || user.absentResult.isAbsent)){
                const absentClass = user.absentResult.period;
                console.log("absentClass");
                console.log(absentClass);

                switch (currentClass){
                    case "zero" : 
                        alignmentNumber = 0;
                        break;
                        case "one" : 
                        alignmentNumber = 1;
                        break;
                        case "two" : 
                        alignmentNumber = 2;
                        break;
                        case "three" : 
                        alignmentNumber = 3;
                        break;
                        case "four" : 
                        alignmentNumber = 4;
                        break;
                        case "five" : 
                        alignmentNumber = 5;
                        break;
                        case "six" : 
                        alignmentNumber = 6;
                        break;
                        case "sixFirst" : 
                        alignmentNumber = 7;
                        break;
                        case "sixSecond" : 
                        alignmentNumber = 8;
                        break;
                        case "seven" : 
                        alignmentNumber = 9;
                        break;
                }

                switch (absentClass){
                    case "zero" : 
                        absentClassNumber = 0;
                        break;
                        case "one" : 
                        absentClassNumber = 1;
                        break;
                        case "two" : 
                        absentClassNumber = 2;
                        break;
                        case "three" : 
                        absentClassNumber = 3;
                        break;
                        case "four" : 
                        absentClassNumber = 4;
                        break;
                        case "five" : 
                        absentClassNumber = 5;
                        break;
                        case "six" : 
                        absentClassNumber = 6;
                        break;
                        case "sixFirst" : 
                        absentClassNumber = 7;
                        break;
                        case "sixSecond" : 
                        absentClassNumber = 8;
                        break;
                        case "seven" : 
                        absentClassNumber = 9;
                        break;
                }

                switch (absentClass){
                    case "zero" : 
                        classString = "0교시";
                        break;
                        case "one" : 
                        classString = "1교시";
                        break;
                        case "two" : 
                        classString = "2교시";
                        break;
                        case "three" : 
                        classString = "3교시";
                        break;
                        case "four" : 
                        classString = "4교시";
                        break;
                        case "five" : 
                        classString = "5교시";
                        break;
                        case "six" : 
                        classString = "6교시";
                        break;
                        case "sixFirst" : 
                        classString = "7교시";
                        break;
                        case "sixSecond" : 
                        classString = "8교시";
                        break;
                        case "seven" : 
                        classString = "하원 전 검사 ";
                        break;
                }
            }
            
            if(user.absentResult && user.absentResult.isEarly && (alignmentNumber >= absentClassNumber)){

                console.log("letgo");
                console.log(alignmentNumber);
                console.log(absentClassNumber);

                oneRow.absentStatus = "early";
                oneRow.absentClass = user.absentResult.period;
            }

            if(user.absentResult && user.absentResult.isAbsent && (alignmentNumber >= absentClassNumber)){

                console.log("letgo");
                console.log(alignmentNumber);
                console.log(absentClassNumber);

                oneRow.absentStatus = "absent";
                oneRow.absentClass = user.absentResult.period;
            }

            if(user.regularSchedule){
                oneRow.regular = user.regularSchedule.data[dayStringEnglish];
                if(user.regularSchedule.parentpermit === 1){
                    if(oneRow.regular)
                    oneRow.regular += " [승인]";
                }
                if(user.regularSchedule.parentpermit === 2){
                    if(oneRow.regular)
                    oneRow.regular += " [거절]";
                }
                if(user.regularSchedule.parentpermit === 0){
                    if(oneRow.regular)
                    oneRow.regular += " [대기]";
                }
            }

            if(!oneRow.regular){
                oneRow.regular = "-";
            }

            if(user.regularSchedule){
                oneRow.regularEtc = user.regularSchedule.data["etc"];

                if(user.regularSchedule.parentpermit === 1){
                    if(oneRow.regularEtc)
                    oneRow.regularEtc += " [승인]";
                }
                if(user.regularSchedule.parentpermit === 2){
                    if(oneRow.regularEtc)
                    oneRow.regularEtc += " [거절]";
                }
                if(user.regularSchedule.parentpermit === 0){
                    if(oneRow.regularEtc)
                    oneRow.regularEtc += " [대기]";
                }
            }

            if(!oneRow.regularEtc){
                oneRow.regularEtc = "-";
            }

            if(user.suddenNotice){
                const reason = reasonToString(user.suddenNotice);
                oneRow.sudden = reason;
            }

            if(!oneRow.sudden){
                oneRow.sudden = "-";
            }


            user.reply.forEach((eachReply: any) => {
                switch (eachReply.period) {
                    case "zero":
                        oneRow.zeroReply_1 = eachReply.firstReply;
                        oneRow.zeroReply_2 = eachReply.secondReply;
                        break;
                    case "one":
                        oneRow.oneReply_1 = eachReply.firstReply;
                        oneRow.oneReply_2 = eachReply.secondReply;
                        break;
                    case "two":
                        oneRow.twoReply_1 = eachReply.firstReply;
                        oneRow.twoReply_2 = eachReply.secondReply;
                        break;
                    case "three":
                        oneRow.threeReply_1 = eachReply.firstReply;
                        oneRow.threeReply_2 = eachReply.secondReply;
                        break;
                    case "four":
                        oneRow.fourReply_1 = eachReply.firstReply;
                        oneRow.fourReply_2 = eachReply.secondReply;
                        break;
                    case "five":
                        oneRow.fiveReply_1 = eachReply.firstReply;
                        oneRow.fiveReply_2 = eachReply.secondReply;
                        break;
                    case "six":
                        oneRow.sixReply_1 = eachReply.firstReply;
                        oneRow.sixReply_2 = eachReply.secondReply;
                        break;
                    case "sixFirst":
                        oneRow.sixFirstReply_1 = eachReply.firstReply;
                        oneRow.sixFirstReply_2 = eachReply.secondReply;
                        break;
                    case "sixSecond":
                        oneRow.sixSecondReply_1 = eachReply.firstReply;
                        oneRow.sixSecondReply_2 = eachReply.secondReply;
                        break;
                    case "seven":
                        oneRow.sevenReply_1 = eachReply.firstReply;
                        oneRow.sevenReply_2 = eachReply.secondReply;
                        break;
                }

                if (eachReply.period === currentClass) {
                    oneRow.firstReply = eachReply.firstReply;
                    oneRow.secondReply = eachReply.secondReply;
                }
            });


            if (user.records.length > 0) {
                const lastRecord = user.records[user.records.length - 1];
                const lastTime = new Date(+lastRecord.time);
                oneRow.last = `${lastTime.getHours() < 10 ? "0" + lastTime.getHours() : lastTime.getHours()}:${lastTime.getMinutes() < 10 ? "0" + lastTime.getMinutes() : lastTime.getMinutes()}`;

                const location = lastRecord.direction === "outside" ? "IN" : "OUT";
                oneRow.status = location;
                if (location === "IN") {
                    oneRow.last = oneRow.last + "에 들어옴";
                } else if (location === "OUT") {
                    oneRow.last = oneRow.last + "에 나감";  
                    console.log("before");


                    const delayMinutes = Math.floor((targetTime - lastTime.getTime()) / 60000);
                    console.log(delayMinutes);
                    if(delayMinutes >= 0){
                        const delay = `${Math.floor(delayMinutes / 60)}시간 ${delayMinutes % 60}분 경과`;
                        oneRow.last = oneRow.last + `(${delay})`;

                    }else{
                        const delay = `0분 경과`;
                        oneRow.last = oneRow.last + `(${delay})`;

                    }
                }
            } else {
                oneRow.last = `${new Date(targetTime).getHours() < 10 ? "0" + new Date(targetTime).getHours() : new Date(targetTime).getHours()}:${new Date(targetTime).getMinutes() < 10 ? "0" + new Date(targetTime).getMinutes() : new Date(targetTime).getMinutes()}까지 기록 없음`;
                oneRow.status = "OUT";
            }

            oneRow.last = `${(classString && (alignmentNumber >= absentClassNumber)) ? `${oneRow.last} [${classString}부터 ${oneRow.absentStatus === "absent" ? "결석" : "조퇴"} 처리]` : oneRow.last}`

            // if(oneRow.name === "전혜원" || oneRow.name === "임윤희" || oneRow.name === "조형준"){
            //     console.log("name");
            //     oneRow.status = "IN";
            // }

            newRows.push(oneRow);
        })



        newRows.sort(function (a: any, b: any) {
            if (a.status === "IN" && b.status === "OUT") {
                return 1;
            } else if (a.status === "OUT" && b.status === "IN") {
                return -1;
            } else {
                return 0;
            }
        })

        newRows.sort(function (a: any, b: any) {
            if (a.last.split(" ")[2] === "없음" && b.last.split(" ")[2] !== "없음") {
                return -1;
            } else if (a.last.split(" ")[2] === "없음" && b.last.split(" ")[2] !== "없음") {
                return 1;
            } else {
                return 0;
            }
        })


        newRows.sort(function (a : any, b : any) {

            if(a.status === "IN" || b.status === "IN"){
                return 1;
            }


            if(b.absentStatus && !a.absentStatus){
                return -1;
            }else{
                return 1;
            }

        })

        newRows.sort(function (a : any, b : any) {

            if(a.status === "IN" || b.status === "IN"){
                return 1;
            }

            if(!b.absentStatus || !a.absentStatus){
                return 0;
            }

            if(a.absentStatus === "absent" && b.absentStatus === "early"){
                return 1;
            }else{
                return -1;
            }

        })


        console.log("giogogogi");



        setRows([...newRows]);

    }


    //여기서 newDate 설정할 때 시간 설정 다르게 하면 기준 시각 바뀜
    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {

        if(!newAlignment){
            return;
        }

        setAlignment(newAlignment);

        switch (newAlignment) {
            case "zero":
                setRows([]);
                setLoading(true);
                var newDate = targetDate;
                newDate.setHours(8, 0);
                setTargetDate(newDate);
                start(newDate.getTime(), newAlignment);
                break;
            case "one":
                setRows([]);
                setLoading(true);
                var newDate = targetDate;
                newDate.setHours(9, 0);
                setTargetDate(newDate);
                start(newDate.getTime(), newAlignment);
                break;
            case "two":
                setRows([]);
                setLoading(true);
                var newDate = targetDate;
                newDate.setHours(10, 30);
                setTargetDate(newDate);
                start(newDate.getTime(), newAlignment);
                break;
            case "three":
                setRows([]);
                setLoading(true);
                var newDate = targetDate;
                newDate.setHours(13, 20);
                setTargetDate(newDate);
                start(newDate.getTime(), newAlignment);
                break;
            case "four":
                setRows([]);
                setLoading(true);
                var newDate = targetDate;
                newDate.setHours(14, 50);
                setTargetDate(newDate);
                start(newDate.getTime(), newAlignment);
                break;
            case "five":
                setRows([]);
                setLoading(true);
                var newDate = targetDate;
                newDate.setHours(16, 20);
                setTargetDate(newDate);
                start(newDate.getTime(), newAlignment);
                break;
            case "six":
                setRows([]);
                setLoading(true);
                var newDate = targetDate;
                newDate.setHours(18, 40);
                setTargetDate(newDate);
                start(newDate.getTime(), newAlignment);
                break;
            case "sixFirst":
                setRows([]);
                setLoading(true);
                var newDate = targetDate;
                newDate.setHours(19, 50);
                setTargetDate(newDate);
                start(newDate.getTime(), newAlignment);
                break;
            case "sixSecond":
                setRows([]);
                setLoading(true);
                var newDate = targetDate;
                newDate.setHours(21, 0);
                setTargetDate(newDate);
                start(newDate.getTime(), newAlignment);
                break;
            case "seven":
                setRows([]);
                setLoading(true);
                var newDate = targetDate;
                newDate.setHours(21, 30);
                setTargetDate(newDate);
                start(newDate.getTime(), newAlignment);
                break;
        }
    };

    const handleCommit = async (e: any) => {

        

        // const fingerprintUserId = e.id;
        // const field = e.field;
        // var value = e.value;

        // if (!value) {
        //     value = "";
        // }

        // const data = {
        //     fingerprintUserId,
        //     field,
        //     value,
        //     alignment,
        //     location : "daechi"
        // }

        // var token = "";
        // if (window.electron) {
        //     token = await window.electron.sendMessageApi.getToken();
        // }

        // fetch("https://peetsunbae.com/dashboard/report/attendanceProcess", {
        //     method: "POST",
        //     headers: { "Authorization": token, "Content-Type": "application/json" },
        //     credentials: "include",
        //     body: JSON.stringify(data)
        // }).then((response: any) => {
        //     response.json()
        //         .then((result: any) => {
        //             console.log(result.body);
        //         })
        // })
    }


    const tableChange = () => {
        const params = apiRef.current.getVisibleRowModels();

        var validationCount = 0;
        var inCount = 0;
        var outCount = 0;

        if (params.size) {
            params.forEach((each: any) => {
                if (each) {
                    if (each.status === "OUT") {
                        outCount++;
                    } else if (each.status === "IN") {
                        inCount++;
                    }
                }
            })
        }

        setInNumber(inCount);
        setOutNumber(outCount);
    }


    const handleClick = (e: GridRowParams<any>) => {
        console.log(e.row);
        console.log(e.row.userId);
        setSelectedUserId(e.row.userId);
        if (e.row.userId) {
            setSelectedUserName(e.row.name);
            setSelectedFingerprintId(e.row.id);

            if(e.row.absentStatus === "absent"){
                setIsAbsent(true);
            }else{
                setIsAbsent(false);
            }

            if(e.row.absentStatus === "early"){
                setIsEarly(true);
            }else{
                setIsEarly(false);
            }

        } else {
            setSelectedUserName("학생정보에 지문등록을 진행하신 후에 확인해주세요");
        }
    }


    const handleModalOpen = (e : any) => {

        console.log("gogogo");

        if(!selectedUserId){
            alert("선택한 사용자가 없습니다.");
            return;
        }

        setOpen(true);
    }

    const getMouseCoordinate = (e : React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        const x = e.clientX;
        const y = e.clientY;

        return {x, y};

    }

    useEffect(() => {

        if(open){

            if(!selectedUserId){
                return;
            }

            const year = targetDate.getFullYear();
            const month = targetDate.getMonth() + 1;
            const date = targetDate.getDate();
            const day = targetDate.getDay();
            // const date = 26;
            // const day = 1;

            fetch(`https://peetsunbae.com/dashboard/report/getstudentinfo?studentId=${selectedUserId}&year=${year}&month=${month}&date=${date}`, {
                method : "GET",
                credentials : "include"
            }).then((response : any) => {
                response.json()
                .then((result : any) => {
                    console.log(result);
                    if(result.message === "success"){

                        if(result.regular !== "noData"){
                            setRegularData(result.regular);
                        }else{
                            setRegularData(undefined);
                        }

                        if(result.sudden !== "noData"){
                            setSuddenData(result.sudden);
                        }else{
                            setSuddenData(undefined);
                        }
                    }
                })
            })


        }else{
            setRegularData(undefined);
            setSuddenData(undefined);
        }

    }, [open]);

    const handleAbsent = async (e : any, kind : string) => {

        // if(!selectedFingerprintId){
        //     return;
        // }

        // var classString = "";

        // switch (alignment){
        //     case "zero" : 
        //         classString = "0교시";
        //         break;
        //         case "one" : 
        //         classString = "1교시";
        //         break;
        //         case "two" : 
        //         classString = "2교시";
        //         break;
        //         case "three" : 
        //         classString = "3교시";
        //         break;
        //         case "four" : 
        //         classString = "4교시";
        //         break;
        //         case "five" : 
        //         classString = "5교시";
        //         break;
        //         case "six" : 
        //         classString = "6교시";
        //         break;
        //         case "sixFirst" : 
        //         classString = "7교시";
        //         break;
        //         case "sixSecond" : 
        //         classString = "8교시";
        //         break;
        //         case "seven" : 
        //         classString = "하원 전 검사 ";
        //         break;
        // }

        // if(kind === "absent"){
        //     if(window.confirm(`${selectedUserName} 학생을 ${classString} 부터 결석 처리 할까요?`)){

        //     }else{
        //         return;
        //     }
        // }

        // if(kind === "early"){
        //     if(window.confirm(`${selectedUserName} 학생을 ${classString} 부터 조퇴 처리 할까요?`)){

        //     }else{
        //         return;
        //     }
        // }

        // if(kind === "cancelAbsent"){
        //     if(window.confirm(`${selectedUserName} 학생 결석 처리를 취소 할까요?`)){

        //     }else{
        //         return;
        //     }
        // }

        // if(kind === "cancelEarly"){
        //     if(window.confirm(`${selectedUserName} 학생 조퇴 처리를 취소 할까요?`)){

        //     }else{
        //         return;
        //     }
        // }

        // var token = "";

        // if (window.electron) {
        //     token = await window.electron.sendMessageApi.getToken();
        // }

        // const data = {
        //     selectedFingerprintId,
        //     kind,
        //     alignment,
        //     location : "daechi"
        // }

        // fetch("https://peetsunbae.com/dashboard/report/attendanceProcessAbsent", {
        //     method : "post",
        //     credentials : "include",
        //     headers : {
        //         "Authorization" : token,
        //         "content-type" : "application/json"
        //     },
        //     body : JSON.stringify(data)
        // }).then((response : any) => {
        //     response.json()
        //     .then((result : any) => {
        //         console.log(result);
        //         if(result.message === "success"){
        //             present({
        //                 message: '성공적으로 전송되었습니다!',
        //                 duration: 1500,
        //                 position: "bottom",
        //                 color : "success"
        //               });

        //             setUpdate(Math.random());
        //         }
        //     })
        // })


    }

    const searchNameChange = (e : any) => {
        setSearchName(e.target.value);
        console.log(filterModel);

        const newFilterModel: any = filterModel;
        newFilterModel.items.forEach((each: any) => {
            if (each.id === 2) {
                each.value = e.target.value;
            }
        })
        setFilterModel({ ...newFilterModel });
    }


    const handleModalOpen2 = () => {
        
        setOpen2(true);

    }

    const previousDay = () => {


        const newDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), targetDate.getHours(), targetDate.getMinutes(), targetDate.getSeconds());
        newDate.setDate(newDate.getDate() - 1);
        setTargetDate(newDate);

    }

    const nextDay = () => {

        const newDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), targetDate.getHours(), targetDate.getMinutes(), targetDate.getSeconds());
        newDate.setDate(newDate.getDate() + 1);
        setTargetDate(newDate);

    }
 

    

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "end", marginTop: "12px" }}>
                <div style={{ fontSize: "18px", fontFamily: "Apple_B" }}>
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button sx={{marginRight : "12px"}} onClick={previousDay} variant="soft">◁</Button>
                        <Button onClick={nextDay} variant="soft">▷</Button>
                    </ButtonGroup>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                >
                    <ToggleButton disabled={bool[9]} value="zero"> <span style={{ fontFamily: "Apple_B" }}>0교시</span> </ToggleButton>
                    <ToggleButton disabled={bool[0]} value="one"><span style={{ fontFamily: "Apple_B" }}>1교시</span></ToggleButton>
                    <ToggleButton disabled={bool[1]} value="two"><span style={{ fontFamily: "Apple_B" }}>2교시</span></ToggleButton>
                    <ToggleButton disabled={bool[2]} value="three"><span style={{ fontFamily: "Apple_B" }}>3교시</span></ToggleButton>
                    <ToggleButton disabled={bool[3]} value="four"><span style={{ fontFamily: "Apple_B" }}>4교시</span></ToggleButton>
                    <ToggleButton disabled={bool[4]} value="five"><span style={{ fontFamily: "Apple_B" }}>5교시</span></ToggleButton>
                    <ToggleButton disabled={bool[5]} value="six"><span style={{ fontFamily: "Apple_B" }}>6교시</span></ToggleButton>
                    <ToggleButton disabled={bool[6]} value="sixFirst"><span style={{ fontFamily: "Apple_B" }}>7교시</span></ToggleButton>
                    <ToggleButton disabled={bool[7]} value="sixSecond"><span style={{ fontFamily: "Apple_B" }}>8교시</span></ToggleButton>
                    <ToggleButton disabled={bool[8]} value="seven"><span style={{ fontFamily: "Apple_B" }}>하원</span></ToggleButton>

                </ToggleButtonGroup>

                <div style={{ marginTop: "16px", fontFamily: "Apple_R" }}>
                    기준 시간 : {targetDate.getMonth() + 1}월 {targetDate.getDate()}일 {targetDate.getHours() < 10 ? "0" + targetDate.getHours() : targetDate.getHours()}시 {targetDate.getMinutes() < 10 ? "0" + targetDate.getMinutes() : targetDate.getMinutes()}분
                </div>
            </div>


            <div>
                <div style={{marginTop : "24px", display : "flex", justifyContent : "space-between"}}>
                    <div>
                        지각/결석 검사
                    </div>
                </div>

                <div style={{marginTop : "12px", display : "flex", justifyContent : "space-between"}}>
                    <div>
                        <span style={{ marginRight: "8px", fontFamily: "Apple_R" }}>
                            {selectedUserName}
                        </span>

                        <CssVarsProvider>
                            <Button onClick={handleModalOpen2} variant="soft" color="neutral" disabled={selectedUserId ? false : true}>출입기록 보기</Button>
                            <Button onClick={handleModalOpen} variant="soft" disabled={selectedUserId ? false : true}>최신 사유/정기 보기</Button>
                        </CssVarsProvider>
                    </div>
                    <div>
                        <TextField value={searchName} onChange={searchNameChange} id="standard-basic" placeholder="이름을 검색하세요" variant="standard" />
                    </div>
                </div>

                <div className={classes.root} style={{ height: 600, width: '100%', backgroundColor: "white", marginTop: "8px" }}>

                    <DataGridPro loading={loading} rows={rows} columns={(alignment === "zero" || alignment === "one") ? columns0 : alignment === "two" ? columns1 : alignment === "three" ? columns3 : alignment === "four" ? columns4 : alignment === "five" ? columns5 : alignment === "six" ? columns6 : alignment === "sixFirst" ? columns7 : alignment === "sixSecond" ? columns8 : alignment === "seven" ? columns9 : columns1}
                        density='compact'
                        filterModel={filterModel}
                        apiRef={apiRef}
                        onFilterModelChange={(model) => setFilterModel(model)}
                        onCellEditCommit={handleCommit}
                        onStateChange={tableChange}
                        onRowClick={(e) => { handleClick(e); }}
                        getRowClassName={(params) => {
                            if(params.row.absentStatus === "absent"){
                                return "absent";
                            }else if(params.row.absentStatus === "early"){
                                return "early";
                            }else{
                                return "";
                            }
                        }}

                        getCellClassName={(params) => {
                            if (params.field === "status") {
                                if (params.value === "OUT") {
                                    return "timeout";
                                } else if (params.value === "IN") {
                                    return "good";
                                } else {
                                    return "";
                                }
                            } else if (params.field === "regular"){

                                if(!params.value){
                                    return "";
                                }else if(params.value.includes("[승인]")){
                                    return "good";
                                }else if(params.value.includes["거절"]){
                                    return "timeout";
                                }else{
                                    return "";
                                }
                                
                            } else if (params.field === "regularEtc"){

                                if(!params.value){
                                    return "";
                                }else if(params.value.includes("[승인]")){
                                    return "good";
                                }else if(params.value.includes["거절"]){
                                    return "timeout";
                                }else{
                                    return "";
                                }

                            }else{
                                return "";
                            }
                        }}
                    />
                </div>
            </div>

            <div>
                <div style={{ fontFamily: "Apple_R", fontSize: "14px" }}>
                    OUT : {outNumber}명 / IN : {inNumber}명
                </div>
            </div>

            {
                (users && data && targetDate) &&
                <SecondOutingTest data={data} users={users} targetDate={targetDate} />
            }


            <ReactModal
                initWidth={600}
                initHeight={450}
                onFocus={() => console.log("Modal is clicked")}
                className={"my-modal-custom-class"}
                onRequestClose={() => {}}
                isOpen={open}
                disableResize
            >
                <div style={{height : "80px"}}>

                </div>

                <div style={{paddingLeft : "24px", paddingRight : "24px", paddingBottom : "24px"}}>
                    <div style={{display : "flex", justifyContent : "space-between"}}>
                        <div style={{ fontFamily: "Apple_SB", fontSize: "18px" }}>
                            {selectedUserName} 학생
                        </div>
                        <div>
                            {modalDate.getMonth() + 1}월 {modalDate.getDate()}일 {modalDate.getHours() < 10 ? "0" + modalDate.getHours() : modalDate.getHours()}시 {modalDate.getMinutes() < 10 ? "0" + modalDate.getMinutes() : modalDate.getMinutes()}분 기준
                        </div>
                    </div>

                    <CssVarsProvider>
                        <Divider sx={{marginTop : "8px", marginBottom : "8px"}}>

                        </Divider>
                    </CssVarsProvider>

                    <div>
                        <div style={{fontFamily : "Apple_SB", fontSize : "17px"}}>
                            사유
                        </div>
                        <div style={{ marginTop: "8px", fontFamily: "Apple_R" }}>
                            {
                                suddenData ?
                                    suddenData.map((each : any, index : number) => {

                                        var description;
                                        switch (each.type) {
                                            case "long": description = "지각"; break;
                                            case "early": description = "조기하원"; break;
                                            case "among": description = "외출"; break;
                                            case "absent": description = "결석"; break;
                                        }

                                        return (
                                            <div style={{ fontFamily: "Apple_R" }}>
                                                <div>
                                                    <span>
                                                        [{description}] &nbsp;
                                                    </span>
                                                    {
                                                        each.reason
                                                    }
                                                    <span style={{ color: "#ff9800" }}>
                                                        {each.parentpermit === 0 && " (학부모 승인 대기 중)"}
                                                    </span>
                                                    <span style={{ color: "blue" }}>
                                                        {each.parentpermit === 1 && " (학부모 승인 완료)"}
                                                    </span>
                                                    <span style={{ color: "#ef5350" }}>
                                                        {each.parentpermit === 2 && " (학부모 승인 거절)"}
                                                    </span>
                                                </div>
                                                <div>
                                                    {each.type === "long" && `${each.startHours > 11 ? "오후" : "오전"} ${each.startHours > 12 ? each.startHours - 12 : each.startHours}시 ${each.startMinutes.toString().length === 1 ? "0" + each.startMinutes : each.startMinutes}분 도착`}
                                                    {each.type === "early" && `${each.endHours > 11 ? "오후" : "오전"} ${each.endHours > 12 ? each.endHours - 12 : each.endHours}시 ${each.endMinutes.toString().length === 1 ? "0" + each.endMinutes : each.endMinutes}분 하원`}
                                                    {each.type === "among" && `${each.endHours > 11 ? "오후" : "오전"}  ${each.startHours > 12 ? each.startHours - 12 : each.startHours}시 ${each.startMinutes.toString().length === 1 ? "0" + each.startMinutes : each.startMinutes}분 외출 -
                                                    ${each.endHours > 11 ? "오후" : "오전"} ${each.endHours > 12 ? each.endHours - 12 : each.endHours}시 ${each.endMinutes.toString().length === 1 ? "0" + each.endMinutes : each.endMinutes}분 복귀
                                                    `}
                                                </div>
                                            </div>
                                        );

                                    })
                                    :
                                    "사유 제출 없음"
                            }
                        </div>

                        <CssVarsProvider>
                            <Divider sx={{ marginTop: "8px", marginBottom: "8px" }}>

                            </Divider>
                        </CssVarsProvider>
                        <div style={{fontFamily: "Apple_SB", fontSize: "17px" }}>
                            정기 일정 &nbsp;
                            
                            {
                                (regularData)
                                ?
                                (regularData.staffpermit !== 1)
                                ?
                                <span style={{color : "#ff9800"}}>
                                    (사감 승인 대기 중)
                                </span>
                                :
                                (regularData.staffpermit === 1 && regularData.parentpermit !== 1)
                                ?
                                <span style={{color : "#ff9800"}}>
                                    (학부모 승인 대기 중)
                                </span>
                                :
                                (regularData.parentpermit === 1)
                                ?
                                <span style={{color : "blue"}}>
                                    (학부모 승인 완료)
                                </span>
                                :
                                ""
                                :
                                ""
                            }
                            
                        </div>
                        <div style={{ marginTop: "8px", fontFamily: "Apple_R" }}>
                            <div>
                                [{returnDayString(targetDate.getDay())}요일]
                            </div>
                            {
                                regularData ?
                                    targetDate.getDay() === 1
                                        ?
                                        regularData.data.monday :
                                        targetDate.getDay() === 2
                                            ?
                                            regularData.data.tuesday
                                            :
                                            targetDate.getDay() === 3
                                                ?
                                                regularData.data.wednesday
                                                :
                                                targetDate.getDay() === 4
                                                    ?
                                                    regularData.data.thursday
                                                    :
                                                    targetDate.getDay() === 5
                                                        ?
                                                        regularData.data.friday
                                                        :
                                                        targetDate.getDay() === 6
                                                            ?
                                                            regularData.data.saturday
                                                            :
                                                            targetDate.getDay() === 0
                                                                ?
                                                                regularData.data.sunday
                                                                :
                                                                "정기일정 없음"
                                    :
                                    "정기일정 없음"
                            }

                        </div>
                        <div style={{ marginTop: "8px", fontFamily: "Apple_R" }}>
                            <div>
                                [기타]
                            </div>
                            <div>
                                {
                                    (regularData && regularData.data && regularData.data.etc)
                                    ?
                                    regularData.data.etc
                                    :
                                    "정기일정 없음"
                                }
                            </div>
                        </div>
                    </div>

                </div>

                <div onClick={handleClose} style={{width : "20px", position : "absolute", top : "20px", right : "10px", zIndex : 999, cursor : "pointer"}}>
                    <XMarkSvg fill="#ff5722"   />
                </div>

            </ReactModal>


            <ReactModal
                initWidth={600}
                initHeight={450}
                onFocus={() => console.log("Modal is clicked")}
                className={"my-modal-custom-class"}
                onRequestClose={() => {}}
                isOpen={open2}
                disableResize
            >
                <div style={{height : "80px"}}>

                </div>

                <div>
                    <TotalFingerprint2 open={open2} selectedFingerprintId={selectedFingerprintId} targetDate={targetDate} total={true} />
                </div>


                <div onClick={handleClose2} style={{width : "20px", position : "absolute", top : "20px", right : "10px", zIndex : 999, cursor : "pointer"}}>
                    <XMarkSvg fill="#ff5722"   />
                </div>

            </ReactModal>

           

        </div>
    )
}

export default SecondTotalAttendanceProcess;