import { Button, Modal, ModalDialog } from "@mui/joy";
import React, {useEffect, useState} from "react";
import { Market } from "@ionic-native/market";
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { Capacitor } from "@capacitor/core";
import { Android, Apple, WarningRounded } from "@mui/icons-material";

const UpdateModal : React.FC<any> = (props) => {

    const [platform, setPlatform] = useState<string>("");

    useEffect(() => {

        setPlatform(Capacitor.getPlatform());

    }, []);

    const goNativeStore = () => {

        const url = props.updateUrl;

        if (Capacitor.getPlatform() === "ios") {
            console.log("letsTry");
            InAppBrowser.create(url);
        }

        if (Capacitor.getPlatform() === "android") {
            console.log("goPlayStore");
            // InAppBrowser.create(androidUrl);
            Market.open(url);
        }

    }

    return (
        <Modal open={props.open} onClose={props.onClose}>
            <ModalDialog variant="plain"
            >
                <div>
                    <div
                    style={{
                        fontFamily : "Apple_SB",
                        display : "flex",
                        justifyContent : "center",
                    }}
                    >
                        필수 업데이트 후 사용 가능합니다
                    </div>
                    <div
                    style={{
                        marginTop : "24px",
                        display : "flex",
                        justifyContent : "center"
                    }}
                    >
                        <Button variant="soft" color="neutral" onClick={() => {goNativeStore()}}
                        startDecorator={platform === "android" ? <Android /> : <Apple />}
                        >
                            {platform === "android" ? "Google Play" : "App Store"}
                        </Button>
                    </div>
                </div>
                
            </ModalDialog>
        </Modal>
    );
}

export default UpdateModal;