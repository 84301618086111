import React, { useEffect, useState, useRef } from "react";
import styles from "../dashboard/componentsStyle/makewordtest.module.css";
import Button from '@mui/joy/Button';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import { Chip, CssVarsProvider, Divider } from '@mui/joy';
import { ChevronLeft, ChevronRight, Cloud, KeyboardArrowRight, TurnedIn, WbSunny } from '@mui/icons-material';
import EachdayTestForChart from "../dashboard/components/controls/eachdaytestforchart";

import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';

const GetWordTest: React.FC<any> = (props: any) => {

    const [index, setIndex] = useState(1);
    const [testBook, setTestBook] = useState("");
    const [createdDate, setCreatedDate] = useState("");
    const [testData, setTestData] = useState<any>([]);
    const [update2, setUpdate2] = useState(0);
    const [open2, setOpen2] = useState(false);
    const [dayTestData, setDayTestData] = useState<any>([]);
    const [selectedTitle, setSelectedTitle] = useState("");

    useEffect(() => {

        if (!index) {
            console.log("noIndex");
            return;
        }

        fetch(`https://peetsunbae.com/dashboard/words/getwordtestforstudent?index=${index}`, {
            method: "get",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "success") {

                        const kind = result.data1.kind;

                        switch (kind) {
                            case 1:
                                setTestBook("워드마스터 수능 2000 (2018년 버전)");
                                break;
                            case 2:
                                setTestBook("워드마스터 수능 2000 (2022년 버전)");
                                break;
                            case 3:
                                setTestBook("워드마스터 하이퍼 2000 (2021년 버전)");
                                break;
                            case 5:
                                setTestBook("워드마스터 EBS 파이널 1200(2020년 버전)");
                                break;
                        }

                        const created = new Date(result.data1.createdAt);
                        const createdString = `${created.getMonth() + 1}/${created.getDate()}`;

                        setCreatedDate(createdString);

                        const data1 = result.data1.data;
                        const rows: any = [];

                        const data2 = result.data2;
                        const data2rows: any = [];

                        data2.forEach((each: any) => {
                            each.dayInfo.forEach((eacheach: any) => {
                                const oneRow: any = {};
                                oneRow.day = eacheach.dayNumber;
                                if (eacheach.isTotal) {
                                    oneRow.type = "total"
                                } else {
                                    oneRow.type = "incorrect"
                                }

                                oneRow.words = [];


                                each.report.forEach((eachWord: any) => {

                                    if (eachWord.day === oneRow.day) {
                                        oneRow.words.push(eachWord);
                                    }
                                })

                                data2rows.push(oneRow);
                            })
                        })

                        data2rows.forEach((each: any) => {

                            var totalCount = 0;
                            var count = 0;

                            each.words.forEach((eachWord: any) => {
                                totalCount++;
                                if (eachWord.result === "correct") {
                                    count++;
                                }
                            })

                            each.totalCount = totalCount;
                            each.count = count;

                        })

                        console.log(data2rows);


                        data1.forEach((each: any) => {

                            const newRow: any = {};
                            newRow.month = each.month + 1;
                            newRow.date = each.date;

                            const newDate = new Date(each.year, each.month, each.date);

                            var day = ""

                            switch (newDate.getDay()) {
                                case 0:
                                    day = "일"
                                    break;
                                case 1:
                                    day = "월"
                                    break;
                                case 2:
                                    day = "화"
                                    break;
                                case 3:
                                    day = "수"
                                    break;
                                case 4:
                                    day = "목"
                                    break;
                                case 5:
                                    day = "금"
                                    break;
                                case 6:
                                    day = "토"
                                    break;
                            }

                            newRow.day = day;
                            newRow.data = [];

                            each.data.forEach((eachData: any) => {

                                const partNewRow: any = {};
                                partNewRow.title = `Day${eachData.day}`;
                                if (eachData.type === "incorrect") {
                                    partNewRow.title += " 오답";
                                }

                                partNewRow.completed = false;

                                data2rows.forEach((eachData2: any) => {

                                    if (eachData.day === eachData2.day && eachData.type === eachData2.type) {
                                        partNewRow.completed = true;
                                        partNewRow.wordsData = eachData2;
                                    }

                                })

                                partNewRow.day = eachData.day;
                                partNewRow.type = eachData.type;
                                newRow.data.push(partNewRow);

                            });

                            // const todayDate = new Date();

                            // if(todayDate.getFullYear() === each.year && todayDate.getMonth() === each.month && todayDate.getDate() === each.date){
                            //     console.log("today");
                            //     props.todayWork(newRow.data);
                            // }

                            rows.push(newRow);
                        })

                        console.log(rows);
                        setTestData([...rows]);

                    } else {
                        setTestData([]);
                        setTestBook("");
                        setCreatedDate("");
                    }
                })
        })

    }, [index, update2]);

    const handleChipClick = (data : any) => {

        if(!data){
            return;
        }



        console.log(data);
        setDayTestData([...data.wordsData.words]);
        setSelectedTitle(data.title);

        setOpen2(true);

    }

    const handleIndexChange = (kind : string) => {

        if(kind === "plus"){
            const newIndex = index + 1;
            setIndex(newIndex);
        }

        if(kind === "minus"){
            if(index <= 1){
                return;
            }

            const newIndex = index - 1;
            setIndex(newIndex);
        }

    }


    return (
        <div>
            <div className={styles.subMain}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title5}>
                        최근 출제 내역{createdDate && ` (${createdDate})`}
                    </div>
                    <div>
                        <CssVarsProvider>
                            <Button onClick={(e) => { handleIndexChange("plus") }} size="sm" aria-label="Like" variant="outlined" color="neutral" sx={{ marginRight: "8px", width: "32px" }}>
                                <ChevronLeft />
                            </Button>
                            <Button onClick={(e) => { handleIndexChange("minus") }} size="sm" aria-label="Like" variant="outlined" color="neutral" sx={{ width: "32px" }}>
                                <ChevronRight />
                            </Button>
                        </CssVarsProvider>
                    </div>
                </div>

                {
                    testBook
                    &&
                    <div className={styles.title6}>
                        {testBook}
                    </div>
                }

                <div className={styles.eachBox}>
                    {/* <div className={styles.oneBox}>
                            <div className={styles.questionTitle}>
                                월
                            </div>
                            <div>
                                <CssVarsProvider>
                                    <Chip variant="soft" startDecorator={<WbSunny />} sx={{ marginRight: "8px" }}>
                                        Day1 (30/40)
                                    </Chip>
                                    <Chip variant="soft" startDecorator={<Cloud />} color="danger">
                                        Day 2
                                    </Chip>
                                </CssVarsProvider>
                            </div>
                        </div> */}
                    {
                        testData &&
                        testData.map((each: any, index: number) => {

                            return (
                                <div key={Math.random()} className={styles.oneBox}>
                                    <div className={styles.questionTitle}>
                                        {each.day} <div className={styles.date}>({each.month}/{each.date})</div>
                                    </div>
                                    <div>
                                        <CssVarsProvider>
                                            {
                                                each.data.map((eachData: any, indexIndex: number) => {
                                                    return (
                                                        <div style={{ display: "inline-block", paddingTop: "3px", paddingBottom: "3px" }}>
                                                            <Chip disabled={eachData.completed ? false : true} onClick={(e: any) => { handleChipClick(eachData); }} key={Math.random()} variant="soft" startDecorator={eachData.completed ? <WbSunny /> : <Cloud />} color={eachData.completed ? "primary" : "danger"} sx={{ marginRight: indexIndex === each.data.length - 1 ? "0px" : "8px" }}>
                                                                {eachData.title} {eachData.completed && `(${eachData.wordsData.count}/${eachData.wordsData.totalCount})`}
                                                            </Chip>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </CssVarsProvider>
                                    </div>
                                </div>
                            );


                        })
                    }
                </div>
            </div>


            <Modal
                open={open2}
                onClose={() => { setOpen2(false); }}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 1200,
                        maxHeight: 800,
                        overflowY: "auto",
                        overflowX: "hidden",
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <div>
                        <EachdayTestForChart selectedTitle={selectedTitle} open={open2} data={dayTestData} />
                    </div>
                </Sheet>
            </Modal>



        </div>
    );
}

export default GetWordTest;