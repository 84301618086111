import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import HorizontalLinearStepper from './horizontallinearstepper';
// import { Button } from '@mui/material';
import Button from '@mui/joy/Button';
import { Checkbox, Chip, CssVarsProvider, Modal, Radio, RadioGroup, Sheet } from '@mui/joy';
import CheckIcon from '@mui/icons-material/Check';
import { AddCircle } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ReactModal from 'react-modal-resizable-draggable';
import { ReactComponent as XMarkSvg } from "../../../svg/circle-xmark-solid.svg";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





export default function InsetDividers(props: any) {

    const [id, setId] = React.useState(0);
    const [dayData, setDayData] = React.useState<any>([]);
    const [categorizedData, setCategorizedData] = React.useState<any>([]);
    const [staffpermit, setStaffpermit] = React.useState(0);
    const [parentpermit, setParentpermit] = React.useState(0);
    const [activeStep, setActiveStep] = React.useState(-1);
    const [errorStep, setErrorStep] = React.useState(-1);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [disabled, setDisabled] = React.useState(true);
    const [disabled3, setDisabled3] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [content, setContent] = React.useState("");
    const [hour1, setHour1] = React.useState('15');
    const [minute1, setMinute1] = React.useState('00');
    const [hour2, setHour2] = React.useState('17');
    const [minute2, setMinute2] = React.useState('00');
    const [kind, setKind] = React.useState("지각");
    const [disabled2, setDisabled2] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [staffpermitName, setStaffpermitName] = React.useState("");

    const [selectedRegularScheduleId, setSelectedRegularScheduleId] = React.useState(0);
    const [selectedRegularScheduleDay, setSelectedRegularScheduleDay] = React.useState("");
    const [selectedRegularScheduleFormatIndex, setSelectedRegularScheduleFormatIndex] = React.useState(-1);
    const [selectedRegularScheduleFormat, setSelectedRegularScheduleFormat] = React.useState<any>(null);
    const [selectedRegularScheduleCotent, setSelectedRegularScheduleCotent] = React.useState("");
    const [modalKind, setModalKind] = React.useState("");

    const [restricDateData, setRestrictDateData] = React.useState<number[]>([]);

    const ref = React.useRef(null);

    React.useEffect(() => {

        var count = 0;

        const categorizedData = props.data.categorizedData;


        if (props.data.staffpermit === 1 || props.data.staffpermit === 2) {
            setDisabled(false);
            return;
        }

        if (!categorizedData) {
            setDisabled(false);
            return;
        }

        if (categorizedData.length === 0) {
            setDisabled(false);
            return;
        }

        categorizedData.forEach((each: any) => {

            console.log(each);

            if (Array.isArray(each.data)) {
                each.data.forEach((eachData: any) => {
                    count++;
                })
            }
        })

        console.log("categorizedData : " + categorizedData);
        console.log("selected : " + selected);
        console.log("count : " + count);

        if (selected.length === count) {
            setDisabled(false);
            return;
        } else {
            setDisabled(true);
            return;
        }

    }, [selected]);

    React.useEffect(() => {

        if (props.data) {

            const newDaydata: any = [];

            const dayArray = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "etc"];

            dayArray.forEach((each: string) => {

                const oneDayData: any = {};
                oneDayData.day = each;
                oneDayData.data = props.data.data[each];
                oneDayData.categorizedData = getDayDataFromCategorized(props.data.categorizedData, each);

                newDaydata.push(oneDayData);

            })

            // newDaydata.push(props.data.data.monday);
            // newDaydata.push(props.data.data.tuesday);
            // newDaydata.push(props.data.data.wednesday);
            // newDaydata.push(props.data.data.thursday);
            // newDaydata.push(props.data.data.friday);
            // newDaydata.push(props.data.data.saturday);
            // newDaydata.push(props.data.data.etc);

            // setCategorizedData(props.data.categorizedData);

            setId(props.data.id);

            setDayData([...newDaydata]);

            setStaffpermitName(props.data.staffpermitName);





            if (props.data.staffpermit === 1) {
                setActiveStep(1);
                setErrorStep(-1);
            }
            if (props.data.parentpermit === 1) {
                setActiveStep(2);
                setErrorStep(-1);
            }
            if (props.data.staffpermit === 0) {
                setActiveStep(0);
            }
            if (props.data.staffpermit === 2) {
                setActiveStep(0);
                setErrorStep(1);
            }
            if (props.data.parentpermit === 2) {
                setActiveStep(1);
                setErrorStep(2);
            }

        }

    }, [props.data]);

    const submit = (e: any, id: number, isAccept: boolean) => {

        const body = {
            id, isAccept, data: props.data.data, month: props.month, userId: props.userId
        }

        fetch("https://peetsunbae.com/dashboard/chart/regularSchedule/staffpermit", {
            method: "POST",
            credentials: "include",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body)
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "success") {
                        alert("승인 완료");
                        props.update();
                    }
                })
        })
    }

    const parentSubmit = (e: any, id: number) => {

        if (props.data.staffpermit === 0) {
            alert("사감 승인 후 학부모 승인 처리 가능합니다");
            return;
        }

        if (props.data.staffpermit === 2) {
            alert("사감 승인 후 학부모 승인 처리 가능합니다");
            return;
        }


        const permit = "1";

        fetch(`https://peetsunbae.com/dashboard/chart/regularSchedule/parentpermit`, {
            method: "post",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({
                id, permit
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    alert("수동 학부모 승인 완료");
                    console.log(result);
                })
        })

    }

    const getDayDataFromCategorized = (categorizedData: any, day: string) => {

        if (!Array.isArray(categorizedData)) {
            console.log("noArray");
            return;
        }



        var data: any = [];

        categorizedData.forEach((each: any) => {
            if (each.day === day) {
                if (Array.isArray(each.data)) {
                    data = each.data;
                }
            }
        })

        return data;

    }

    const handleHourChange1 = (e: any) => {
        const val = e.target.value;
        if (val >= 0 && val <= 23) {
            setHour1(val);
        }
    };

    const handleMinuteChange1 = (e: any) => {
        const val = e.target.value;
        if (val >= 0 && val <= 59) {
            setMinute1(val);
        }
    };

    const handleHourChange2 = (e: any) => {
        const val = e.target.value;
        if (val >= 0 && val <= 23) {
            setHour2(val);
        }
    };

    const handleMinuteChange2 = (e: any) => {
        const val = e.target.value;
        if (val >= 0 && val <= 59) {
            setMinute2(val);
        }
    };

    React.useEffect(() => {

        console.log("restricdata change");

        //restricDateData의 값이 ""이 아닌 것들만 골라서 newRestrictDateData에 넣는다
        const newRestrictDateData = restricDateData.filter((each : any) => {
            return each !== "";
        })
        //newRestricDateData의 갯수가 0보다 크면 disabled3를 false로 바꾼다
        if(newRestrictDateData.length > 0){
            setDisabled3(false);
        }else{
            setDisabled3(true);
        }

    }, [restricDateData]);

    const handleRestricDateChange = (e: any, index : number) => {
        
        const newRestrictDateData = [...restricDateData];
        const val = e.target.value;

        console.log(val);
        console.log(val === "");

        //"" 값은 중복이 가능하고 다른 값들은 이미 있는 값이면 return
        if(val !== ""){
            for(var i = 0; i < newRestrictDateData.length; i++){
                if(newRestrictDateData[i] === val){
                    return;
                }
            }
        }

        if((val >= 1 && val <= 31) || val === ""){
            newRestrictDateData[index] = val;
            setRestrictDateData([...newRestrictDateData]);
        }
    
    }

    React.useEffect(() => {

        //kind가 외출일때 hour1, minute1의 시간이 hour2, minute2보다 같거나 늦으면 disabled2를 true로 바꾼다

        if (kind === "외출") {
            if (+hour1 > +hour2) {
                setDisabled2(true);
            } else if (+hour1 === +hour2) {
                if (+minute1 >= +minute2) {
                    setDisabled2(true);
                } else {
                    setDisabled2(false);
                }
            } else {
                setDisabled2(false);
            }
        } else {
            setDisabled2(false);
        }

    }, [kind, hour1, minute1, hour2, minute2, open]);

    const handleRestrictDate = () => {

        setOpen2(false);
        setAnchorEl(null);

        setRestrictDateData([]);

        setContent(selectedRegularScheduleCotent);

        setOpen3(true);

    }


    const handleUpdate = () => {

        setOpen2(false);
        setAnchorEl(null);
        setModalKind("update");

        setContent(selectedRegularScheduleCotent);
        setKind(selectedRegularScheduleFormat["종류"]);

        setOpen(true);

        const exitTime = selectedRegularScheduleFormat["나가는 시간"];
        const enterTime = selectedRegularScheduleFormat["들어오는 시간"];

        //exitTime, enterTime은 "09시 30분" 이나 분이 0일 경우는 "09시" 형태로 나온다. 여기서 시와 분을 hour1, minute1, hour2, minute2에 넣어준다.
        if (exitTime) {
            //분이 0일 경우는 "09시" 형태로 분이 없다.
            if (exitTime.split(" ").length === 1) {
                setHour1(exitTime.split("시")[0]);
                setMinute1("00");
            } else {
                setHour1(exitTime.split("시")[0]);
                setMinute1(exitTime.split(" ")[1].split("분")[0]);
            }
        }

        if (enterTime) {
            //분이 0일 경우는 "09시" 형태로 분이 없다.
            if (enterTime.split(" ").length === 1) {
                setHour2(enterTime.split("시")[0]);
                setMinute2("00");
            } else {
                setHour2(enterTime.split("시")[0]);
                setMinute2(enterTime.split(" ")[1].split("분")[0]);
            }
        }

    }

    const handlePlus = (day: string, content: string) => {

        setKind("지각");
        setHour1("15");
        setMinute1("00");
        setHour2("17");
        setMinute2("00");
        setModalKind("add");

        const regularScheduleId = props.data.id;

        if (!regularScheduleId) {
            alert("정규일정 아이디가 없습니다");
            return;
        }

        if (!day) {
            alert("요일이 없습니다");
            return;
        }

        setSelectedRegularScheduleId(regularScheduleId);
        setSelectedRegularScheduleDay(day);

        console.log(regularScheduleId);
        console.log(day);
        setContent(content);
        setOpen(true);
    }


    const submitDateRestrictUpdate = () => {

        const selectedRegularScheduleId = props.data.id;

        if (!selectedRegularScheduleId) {
            alert("정규일정 아이디가 없습니다");
            return;
        }

        if (!selectedRegularScheduleDay) {
            alert("요일이 없습니다");
            return;
        }

        const newRestrictDateData = [...restricDateData];

        //newRestrictDateData의 값이 ""이 아닌 것들만 골라서 newRestrictDateData에 넣는다
        const realNewRestrictDateData = newRestrictDateData.filter((each : any) => {
            return each;
        })

        console.log(realNewRestrictDateData);

        //realNewRestricDateData의 갯수가 0이면 alert 후 return

        if(realNewRestrictDateData.length === 0){
            alert("제한 날짜를 입력해주세요");
            return;
        }

        if (selectedRegularScheduleFormatIndex < 0) {
            alert("선택된 정규일정 카테고리가 없습니다");
            return;
        }

        const body = {
            regularScheduleId: selectedRegularScheduleId,
            day: selectedRegularScheduleDay,
            formatIndex: selectedRegularScheduleFormatIndex,
            data : realNewRestrictDateData
        }

        console.log(body);

        fetch("https://peetsunbae.com/dashboard/chart/regularSchedule/categorydaterestrictupdate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "success") {
                        notify3();
                        setOpen3(false);
                        props.update();
                    }
                })
        })

    }

    const submitDateRestrictUpdateEveryDay = () => {

        if(!window.confirm("해당 정기일정은 매일 적용합니까?")){
            return;
        }

        const selectedRegularScheduleId = props.data.id;

        if (!selectedRegularScheduleId) {
            alert("정규일정 아이디가 없습니다");
            return;
        }

        if (!selectedRegularScheduleDay) {
            alert("요일이 없습니다");
            return;
        }

        //"1", "2", ..., "31" 까지 배열에 넣는다.
        const newRestrictDateData = new Array(31).fill(0).map((each, index) => {
            return `${index + 1}`;
        });

        //newRestrictDateData의 값이 ""이 아닌 것들만 골라서 newRestrictDateData에 넣는다
        const realNewRestrictDateData = newRestrictDateData.filter((each : any) => {
            return each;
        })

        console.log(realNewRestrictDateData);

        //realNewRestricDateData의 갯수가 0이면 alert 후 return

        if(realNewRestrictDateData.length === 0){
            alert("제한 날짜를 입력해주세요");
            return;
        }

        if (selectedRegularScheduleFormatIndex < 0) {
            alert("선택된 정규일정 카테고리가 없습니다");
            return;
        }

        const body = {
            regularScheduleId: selectedRegularScheduleId,
            day: selectedRegularScheduleDay,
            formatIndex: selectedRegularScheduleFormatIndex,
            data : realNewRestrictDateData
        }

        console.log(body);

        fetch("https://peetsunbae.com/dashboard/chart/regularSchedule/categorydaterestrictupdate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "success") {
                        notify3();
                        setAnchorEl(null);
                        setSelectedRegularScheduleDay("");
                        setSelectedRegularScheduleId(0);
                        setSelectedRegularScheduleFormatIndex(-1);
                        props.update();
                    }
                })
        })

    }


    const submitUpdate = () => {

        const selectedRegularScheduleId = props.data.id;

        if (!selectedRegularScheduleId) {
            alert("정규일정 아이디가 없습니다");
            return;
        }

        if (!selectedRegularScheduleDay) {
            alert("요일이 없습니다");
            return;
        }

        //hour1, minute1, hour2, minute2 null 체크
        if (!hour1 || !minute1 || !hour2 || !minute2) {
            alert("시간을 입력해주세요");
            return;
        }

        if (selectedRegularScheduleFormatIndex < 0) {
            alert("선택된 정규일정 카테고리가 없습니다");
            return;
        }

        //kind null 체크
        if (!kind) {
            alert("종류를 선택해주세요");
            return;
        }

        //hour1, minute1, hour2, minute2를 숫자로 바꾼다
        const hour1Number = +hour1;
        const minute1Number = +minute1;
        const hour2Number = +hour2;
        const minute2Number = +minute2;


        //{종류 : , 들어오는 시간 : ~시 ~분 , 나가는 시간 : ~시 ~분} 꼴을 만들건데 종류에는 kind, 들어오는 시간에는 hour1, minute1, 나가는 시간에는 hour2, minute2를 넣는다. 종류가 지각일 때는 들어오는 시간만 있고 나가는 시간은 없다. 종류가 외출일 때는 들어오는 시간과 나가는 시간이 모두 있다. 종류가 조퇴일 때는 나가는 시간만 있고 들어오는 시간은 없다. 결석일 때는 들어오는 시간도 없고 나가는 시간도 없다. 분이 0이면 ~시로만 표시한다. 분은 적지 않는다.
        var exitTime = `${hour1Number}시`;
        if (minute1Number !== 0) {
            exitTime += ` ${minute1Number}분`;
        }
        var enterTime = `${hour2Number}시`;
        if (minute2Number !== 0) {
            enterTime += ` ${minute2Number}분`;
        }


        var data: any = {}
        data["종류"] = kind;

        if(selectedRegularScheduleFormat["날짜"]){
            data["날짜"] = selectedRegularScheduleFormat["날짜"];
        }

        if (kind === "지각") {
            data["들어오는 시간"] = enterTime;
        } else if (kind === "외출") {
            data["들어오는 시간"] = enterTime;
            data["나가는 시간"] = exitTime;
        } else if (kind === "조퇴") {
            data["나가는 시간"] = exitTime;
        }

        const body = {
            regularScheduleId: selectedRegularScheduleId,
            day: selectedRegularScheduleDay,
            data,
            formatIndex: selectedRegularScheduleFormatIndex
        }

        console.log(body);

        fetch("https://peetsunbae.com/dashboard/chart/regularSchedule/categoryupdate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "success") {
                        notify3();
                        setOpen(false);
                        props.update();
                    }
                })
        })

    }

    const submitAdd = () => {

        if (!selectedRegularScheduleId) {
            alert("정규일정 아이디가 없습니다");
            return;
        }

        if (!selectedRegularScheduleDay) {
            alert("요일이 없습니다");
            return;
        }

        //hour1, minute1, hour2, minute2 null 체크
        if (!hour1 || !minute1 || !hour2 || !minute2) {
            alert("시간을 입력해주세요");
            return;
        }

        //kind null 체크
        if (!kind) {
            alert("종류를 선택해주세요");
            return;
        }

        //hour1, minute1, hour2, minute2를 숫자로 바꾼다
        const hour1Number = +hour1;
        const minute1Number = +minute1;
        const hour2Number = +hour2;
        const minute2Number = +minute2;


        //{종류 : , 들어오는 시간 : ~시 ~분 , 나가는 시간 : ~시 ~분} 꼴을 만들건데 종류에는 kind, 들어오는 시간에는 hour1, minute1, 나가는 시간에는 hour2, minute2를 넣는다. 종류가 지각일 때는 들어오는 시간만 있고 나가는 시간은 없다. 종류가 외출일 때는 들어오는 시간과 나가는 시간이 모두 있다. 종류가 조퇴일 때는 나가는 시간만 있고 들어오는 시간은 없다. 결석일 때는 들어오는 시간도 없고 나가는 시간도 없다. 분이 0이면 ~시로만 표시한다. 분은 적지 않는다.
        var exitTime = `${hour1Number}시`;
        if (minute1Number !== 0) {
            exitTime += ` ${minute1Number}분`;
        }
        var enterTime = `${hour2Number}시`;
        if (minute2Number !== 0) {
            enterTime += ` ${minute2Number}분`;
        }

        var data: any = {}
        data["종류"] = kind;

        if (kind === "지각") {
            data["들어오는 시간"] = enterTime;
        } else if (kind === "외출") {
            data["들어오는 시간"] = enterTime;
            data["나가는 시간"] = exitTime;
        } else if (kind === "조퇴") {
            data["나가는 시간"] = exitTime;
        }

        const body = {
            regularScheduleId: selectedRegularScheduleId,
            day: selectedRegularScheduleDay,
            data
        }

        console.log(body);


        fetch("https://peetsunbae.com/dashboard/chart/regularSchedule/categoryadd", {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "success") {
                        notify1();
                        setOpen(false);
                        props.update();
                    }
                })
        })

    }

    const notify1 = () => toast.success("업로드 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored",
        containerId: props.data.id
    });


    const notify2 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored",
        containerId: props.data.id
    });

    const notify3 = () => toast.success("업데이트 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored",
        containerId: props.data.id
    });

    const notify4 = () => toast.success("승인 완료!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored",
        containerId: props.data.id
    });

    const notify5 = () => toast.success("수동 학부모 승인 완료!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored",
        containerId: props.data.id
    });

    const handleRightClick = (e: any) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
        setOpen2(true);
    }

    const handleClose2 = () => {
        setAnchorEl(null);
        setOpen2(false);

        // setSelectedRegularScheduleFormatIndex(-1);
        // setSelectedRegularScheduleDay("");
        // setSelectedRegularScheduleFormat(null);
        // setSelectedRegularScheduleCotent("");
    }



    const handleDelete = () => {

        setAnchorEl(null);
        setOpen2(false);

        if (!window.confirm("정말 삭제하시겠습니까?")) {
            return;
        }

        if (!props.data.id) {
            alert("정규일정 아이디가 없습니다");
            return;
        }

        if (!selectedRegularScheduleDay || selectedRegularScheduleDay === "") {
            alert("요일이 없습니다");
            return;
        }

        if (selectedRegularScheduleFormatIndex === -1) {
            alert("선택된 포맷이 없습니다");
            return;
        }

        const body = {
            regularScheduleId: props.data.id,
            day: selectedRegularScheduleDay,
            formatIndex: selectedRegularScheduleFormatIndex
        }

        console.log(body);

        fetch("https://peetsunbae.com/dashboard/chart/regularSchedule/categorydelete", {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    if (result.message === "success") {
                        notify2();
                        props.update();
                    }
                })
        })

    }

    const handleClose = () => {

        setOpen(false);
        setOpen2(false);
        setAnchorEl(null);
        setSelectedRegularScheduleDay("");
        setSelectedRegularScheduleId(0);
        setSelectedRegularScheduleFormatIndex(-1);

    }

    const handleClose3 = () => {
        setOpen3(false);
        setOpen2(false);
        setAnchorEl(null);
        setSelectedRegularScheduleDay("");
        setSelectedRegularScheduleId(0);
        setSelectedRegularScheduleFormatIndex(-1);
        setRestrictDateData([]);
    }



    return (
        <div
            onContextMenu={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <List
                sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                }}
            >
                {
                    dayData &&
                    dayData.map((eachDay: any, index: number) => {


                        return (
                            <div key={index}>
                                <ListItem sx={{ height: "96px" }}>
                                    <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                        <div style={{ width: "40px", marginRight: "8px", marginLeft: "12px", }}>
                                            <span style={{ fontFamily: "Apple_B", fontSize: "18px" }}>

                                                {
                                                    index === 0 ? "월" : index === 1 ? "화" : index === 2 ? "수" : index === 3 ? "목" : index === 4 ? "금" : index === 5 ? "토" : index === 6 ? "기타" : ""
                                                }

                                            </span>
                                        </div>
                                        <div style={{
                                            // position: "relative", display: "inline-block" 
                                        }}>
                                            <div style={{ maxWidth: "100%", lineHeight: 1.5, marginRight: "12px", marginBottom: "12px" }}>
                                                {eachDay.data}
                                            </div>
                                            <div style={{
                                                display: "flex", alignItems: "center", marginTop: "12px"
                                                // position: "absolute", left: "calc(100% + 20px)", top: "50%", transform: "translate(0, -50%)" 
                                            }}>
                                                {
                                                    eachDay.categorizedData.map((eachCategorized: any, indexIndex: number) => {
                                                        var checked = selected.includes(`${index}_${indexIndex}`);

                                                        if (activeStep > 0) {
                                                            checked = true;
                                                        }


                                                        //console.log(checked);

                                                        var label = "";

                                                        if (eachCategorized["날짜"] && eachCategorized["날짜"].length !== 31) {
                                                            //eachCategorized["날짜"]는 ["22", "24", "26"] 꼴로서 표현된다. 이를 "22알, 24일, 26일" 꼴로 바꾼다.
                                                            const dateArray = eachCategorized["날짜"];
                                                            var dateString = "";
                                                            for (var i = 0; i < dateArray.length; i++) {
                                                                //dateArray[i]값이 ""이면 continue
                                                                if (!dateArray[i]) {
                                                                    continue;
                                                                }

                                                                dateString += dateArray[i] + "일";
                                                                if (i !== dateArray.length - 1) {
                                                                    dateString += ", ";
                                                                }else{
                                                                    dateString += " ";
                                                                }
                                                            }
                                                            label += dateString;
                                                        }

                                                        if(eachCategorized["날짜"] && eachCategorized["날짜"].length === 31){
                                                            label += "매일 ";
                                                        }


                                                        label += eachCategorized["종류"];
                                                        if (eachCategorized["종류"] !== "결석" && eachCategorized["종류"] !== "분류불가") {
                                                            label += " ";
                                                        }
                                                        if (eachCategorized["나가는 시간"]) {

                                                            if (eachCategorized["종류"] !== "지각") {

                                                                const timeString = eachCategorized["나가는 시간"];
                                                                //'12시 30분' -> '12:30' or '12시' -> '12:00'
                                                                const time = timeString.split(" ")[0].split("시")[0] + ":" + (timeString.split(" ")[1] ? timeString.split(" ")[1].split("분")[0] : "00");
                                                                label += time;

                                                            }

                                                        }
                                                        if (eachCategorized["종류"] === "외출") {
                                                            label += "~";
                                                        }
                                                        if (eachCategorized["들어오는 시간"]) {

                                                            if(eachCategorized["종류"] !== "조퇴"){

                                                                const timeString = eachCategorized["들어오는 시간"];
                                                                //'12시 30분' -> '12:30' or '12시' -> '12:00'
                                                                const time = timeString.split(" ")[0].split("시")[0] + ":" + (timeString.split(" ")[1] ? timeString.split(" ")[1].split("분")[0] : "00");
                                                                label += time;
                                                                
                                                            }
                                                        }

                                                        return (
                                                            <Chip
                                                                onContextMenu={(e) => {
                                                                    handleRightClick(e);
                                                                    setSelectedRegularScheduleDay(eachDay.day);
                                                                    setSelectedRegularScheduleFormatIndex(indexIndex);
                                                                    setSelectedRegularScheduleFormat(eachCategorized);
                                                                    setSelectedRegularScheduleCotent(eachDay.data);
                                                                }}
                                                                key={`${index}_${indexIndex}`}
                                                                variant={checked ? 'soft' : 'soft'}
                                                                color={checked ? 'primary' : 'neutral'}
                                                                startDecorator={
                                                                    checked && <CheckIcon sx={{ zIndex: 1, pointerEvents: 'none' }} />
                                                                }
                                                                sx={{ marginRight: "6px" }}
                                                            >
                                                                <Checkbox
                                                                    variant="outlined"
                                                                    color={checked ? 'primary' : 'neutral'}
                                                                    disableIcon
                                                                    overlay
                                                                    label={label}
                                                                    checked={checked}
                                                                    onChange={(event) => {
                                                                        setSelected((selectedIds: string[]) => {
                                                                            console.log(event.target.checked);
                                                                            if (!event.target.checked) {
                                                                                console.log(2);
                                                                                return selectedIds.filter((id) => id !== `${index}_${indexIndex}`)
                                                                            } else {
                                                                                console.log(1);
                                                                                return [...selectedIds, `${index}_${indexIndex}`]
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </Chip>
                                                        );
                                                    })
                                                }
                                                {
                                                    (eachDay.data)
                                                    &&
                                                    <div style={{ marginLeft: "4px", cursor: "pointer" }}>
                                                        <AddCircle sx={{ color: "#cbcbcf" }} onClick={(e) => {
                                                            handlePlus(eachDay.day, eachDay.data);
                                                        }} />
                                                    </div>
                                                }
                                            </div>


                                        </div>
                                    </div>
                                </ListItem>
                                {
                                    index !== dayData.length - 1
                                    &&
                                    <Divider />
                                }
                            </div>
                        );
                    })
                }
            </List>

            <div style={{ width: "650px", height: "50px", display: "flex", alignItems: "center" }}>
                <HorizontalLinearStepper activeStep={activeStep} errorStep={errorStep} />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "32px" }}>
                <Button onClick={(e: any) => { submit(e, id, false) }} variant="soft" sx={{ width: "100px", marginRight: "12px" }} color="danger">
                    비승인
                </Button>
                <Button disabled={disabled} onClick={(e: any) => { submit(e, id, true) }} variant="soft" sx={{ width: "100px" }}>
                    승인
                </Button>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "24px" }}>
                <Button disabled={disabled} onClick={(e: any) => { parentSubmit(e, id) }} variant="soft" sx={{ width: "100px" }}>
                    학부모 승인
                </Button>
            </div>

            {/* <Modal
                open={open}
                onClose={() => {
                    setOpen(false);
                    setOpen2(false);
                    setAnchorEl(null);
                    setSelectedRegularScheduleDay("");
                    setSelectedRegularScheduleId(0);
                    setSelectedRegularScheduleFormatIndex(-1);
                }}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant='outlined'
                    sx={{
                        maxWidth: 600,
                        borderRadius: 'md',
                        p: 5,
                        boxShadow: 'lg',
                    }}
                >
                    <div style={{ fontFamily: "Apple_B", marginBottom: "8px", fontSize: "18px" }}>
                        정기일정 카테고리화 내역 추가하기
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        - {content}
                    </div>
                    <div style={{width : "260px", margin : "0 auto", marginTop : "24px"}}>
                        <div>
                            <RadioGroup
                                orientation='horizontal'
                                name="justify"
                                value={kind}
                                onChange={(e) => {
                                    console.log(e.target.value);
                                    setKind(e.target.value);
                                }}
                                sx={{
                                    marginTop: "16px",
                                    width: 260,
                                    minHeight: 48,
                                    padding: '4px',
                                    borderRadius: 'md',
                                    bgcolor: 'neutral.softBg',
                                    '--RadioGroup-gap': '4px',
                                    '--Radio-action-radius': '8px',
                                }}
                            >
                                {['지각', '조퇴', '외출', '결석'].map((item) => (
                                    <Radio
                                        key={item}
                                        color="neutral"
                                        value={item}
                                        disableIcon
                                        label={item}
                                        variant="plain"
                                        sx={{
                                            px: 2,
                                            alignItems: 'center',
                                        }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: {
                                                    ...(checked && {
                                                        bgcolor: 'background.surface',
                                                        boxShadow: 'md',
                                                        '&:hover': {
                                                            bgcolor: 'background.surface',
                                                        },
                                                    }),
                                                },
                                            }),
                                        }}
                                    />
                                ))}
                            </RadioGroup>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "10px", paddingRight: "10px", width : "260px"}}>
                            {
                                (kind === "조퇴" || kind === "외출")
                                &&
                                <div>
                                    <div style={{ marginTop: "24px", marginBottom: "12px" }}>
                                        나가는 시간
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <input
                                            className="divider-time-input"
                                            type="number"
                                            value={hour1}
                                            onChange={handleHourChange1}
                                            min="0"
                                            max="23"
                                            style={{ fontSize: "20px", textAlign: "center" }}
                                        />
                                        <span
                                            style={{ fontSize: "18px" }}
                                        >&nbsp;:&nbsp;</span>
                                        <input
                                            className="divider-time-input"
                                            type="number"
                                            value={minute1}
                                            onChange={handleMinuteChange1}
                                            min="0"
                                            max="59"
                                            style={{ fontSize: "20px", textAlign: "center" }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                (kind === "지각" || kind === "외출")
                                &&
                                <div>
                                    <div style={{ marginTop: "24px", marginBottom: "12px" }}>
                                        들어오는 시간
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <input
                                            className="divider-time-input"
                                            type="number"
                                            value={hour2}
                                            onChange={handleHourChange2}
                                            min="0"
                                            max="23"
                                            style={{ fontSize: "20px" }}
                                        />
                                        <span
                                            style={{ fontSize: "18px" }}
                                        >&nbsp;:&nbsp;</span>
                                        <input
                                            className="divider-time-input"
                                            type="number"
                                            value={minute2}
                                            onChange={handleMinuteChange2}
                                            onBlur={(e: any) => {
                                                //minute2의 길이가 1이면 앞에 0을 붙여준다.
                                                if (e.target.value.length === 1) {
                                                    setMinute2("0" + e.target.value);
                                                }
                                            }}
                                            min="0"
                                            max="59"
                                            style={{ fontSize: "20px" }}
                                        />
                                    </div>
                                </div>

                            }
                            {
                                kind === "결석"
                                &&
                                <div style={{ height: "85px" }}>
                                </div>
                            }
                        </div>

                        <Button variant='soft' sx={{ width: '260px', marginTop: "32px" }} disabled={disabled2} onClick={submitAdd}>
                            추가하기
                        </Button>

                    </div>


                </Sheet>
            </Modal> */}

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleUpdate}>수정하기</MenuItem>
                <MenuItem onClick={handleRestrictDate}>날짜 제한시키기</MenuItem>
                <MenuItem onClick={submitDateRestrictUpdateEveryDay}>매일 적용시키기</MenuItem>
                <MenuItem onClick={handleDelete}>삭제하기</MenuItem>
            </Menu>

            <ReactModal
                isOpen={open3}
                onRequestClose={() => {
                    handleClose3();
                }}
                initWidth={600}
                initHeight={400}
                className={"my-modal-custom-class"}
                disableResize
            >
                <div style={{ marginTop: "60px", padding: '30px' }}>
                    <div style={{ fontFamily: "Apple_B", marginBottom: "8px", fontSize: "18px", textAlign: "center" }}>
                        날짜 제한시키기
                    </div>
                    <div style={{ lineHeight: "1.5", textAlign: "center" }}>
                        - {content}
                    </div>

                    <div style={{ margin: "0 auto", width: "260px" }}>

                        <div style={{ width: "100%" }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "24px",
                                width: "100%"
                            }}>
                                {
                                    // 5번 만큼 map을 돌린다
                                    new Array(5).fill(0).map((_, index) => {
                                        return (
                                            <div>
                                                <input
                                                    className="divider-time-input"
                                                    type="number"
                                                    value={restricDateData[index]}
                                                    onChange={(e : any) => {handleRestricDateChange(e, index)}}
                                                    min="0"
                                                    max="31"
                                                    style={{ fontSize: "20px", textAlign: "center" }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div style={{ width: "100%" }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "12px",
                                width: "100%"
                            }}>
                                {
                                    // 5번 만큼 map을 돌린다
                                    new Array(5).fill(0).map((_, index) => {
                                        return (
                                            <div>
                                                <input
                                                    className="divider-time-input"
                                                    type="number"
                                                    value={restricDateData[index + 5]}
                                                    onChange={(e : any) => {handleRestricDateChange(e, index + 5)}}
                                                    min="0"
                                                    max="31"
                                                    style={{ fontSize: "20px", textAlign: "center" }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div style={{ width: "100%", marginTop : "24px", display : "flex", justifyContent : "center", flexWrap : "wrap", minHeight : "20px" }}>
                            {
                                restricDateData.map((eachData, index) => {

                                    if(eachData){

                                        return (
                                            <span>
                                                {eachData}일&nbsp;&nbsp;
                                            </span>
                                        )

                                    }
                                })
                            }
                        </div>

                        <Button variant='soft' sx={{ width: '260px', marginTop: "32px" }} disabled={disabled3} onClick={() => {
                            submitDateRestrictUpdate();
                        }}>
                            날짜 제한시키기
                        </Button>

                    </div>
                </div>

                <div onClick={handleClose3} style={{ width: "20px", position: "absolute", top: "20px", right: "10px", zIndex: 999, cursor: "pointer" }}>
                    <XMarkSvg fill="#ff5722" />
                </div>
            </ReactModal>


            <ReactModal
                isOpen={open}
                onRequestClose={() => {
                    handleClose();
                }}
                initWidth={600}
                initHeight={450}
                className={"my-modal-custom-class"}
                disableResize
            >
                <div style={{ marginTop: "60px", padding: '30px' }}>
                    <div style={{ fontFamily: "Apple_B", marginBottom: "8px", fontSize: "18px", textAlign: "center" }}>
                        {
                            modalKind === "add"
                            &&
                            "추가하기"
                        }
                        {
                            modalKind === "update"
                            &&
                            "수정하기"
                        }
                    </div>
                    <div style={{ lineHeight: "1.5", textAlign: "center" }}>
                        - {content}
                    </div>
                    <div style={{ width: "260px", margin: "0 auto", marginTop: "24px" }}>
                        <div>
                            <RadioGroup
                                orientation='horizontal'
                                name="justify"
                                value={kind}
                                onChange={(e) => {
                                    console.log(e.target.value);
                                    setKind(e.target.value);
                                }}
                                sx={{
                                    marginTop: "16px",
                                    width: 300,
                                    display: 'flex',
                                    justifyContent: "center",
                                    minHeight: 48,
                                    padding: '4px',
                                    borderRadius: 'md',
                                    bgcolor: 'neutral.softBg',
                                    '--RadioGroup-gap': '4px',
                                    '--Radio-action-radius': '8px',
                                }}
                            >
                                {['지각', '조퇴', '외출', '결석'].map((item) => (
                                    <Radio
                                        key={item}
                                        color="neutral"
                                        value={item}
                                        disableIcon
                                        label={item}
                                        variant="plain"
                                        sx={{
                                            px: 2,
                                            alignItems: 'center',
                                            whiteSpace: 'nowrap',
                                        }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: {
                                                    ...(checked && {
                                                        bgcolor: 'background.surface',
                                                        whiteSpace: 'nowrap',
                                                        boxShadow: 'md',
                                                        '&:hover': {
                                                            bgcolor: 'background.surface',
                                                        },
                                                    }),
                                                },
                                            }),
                                        }}
                                    />
                                ))}
                            </RadioGroup>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "10px", paddingRight: "10px", width: "300px" }}>
                            {
                                (kind === "조퇴" || kind === "외출")
                                &&
                                <div>
                                    <div style={{ marginTop: "24px", marginBottom: "12px" }}>
                                        나가는 시간
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <input
                                            className="divider-time-input"
                                            type="number"
                                            value={hour1}
                                            onChange={handleHourChange1}
                                            min="0"
                                            max="23"
                                            style={{ fontSize: "20px", textAlign: "center" }}
                                        />
                                        <span
                                            style={{ fontSize: "18px" }}
                                        >&nbsp;:&nbsp;</span>
                                        <input
                                            className="divider-time-input"
                                            type="number"
                                            value={minute1}
                                            onChange={handleMinuteChange1}
                                            min="0"
                                            max="59"
                                            style={{ fontSize: "20px", textAlign: "center" }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                (kind === "지각" || kind === "외출")
                                &&
                                <div>
                                    <div style={{ marginTop: "24px", marginBottom: "12px" }}>
                                        들어오는 시간
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <input
                                            className="divider-time-input"
                                            type="number"
                                            value={hour2}
                                            onChange={handleHourChange2}
                                            min="0"
                                            max="23"
                                            style={{ fontSize: "20px" }}
                                        />
                                        <span
                                            style={{ fontSize: "18px" }}
                                        >&nbsp;:&nbsp;</span>
                                        <input
                                            className="divider-time-input"
                                            type="number"
                                            value={minute2}
                                            onChange={handleMinuteChange2}
                                            onBlur={(e: any) => {
                                                //minute2의 길이가 1이면 앞에 0을 붙여준다.
                                                if (e.target.value.length === 1) {
                                                    setMinute2("0" + e.target.value);
                                                }
                                            }}
                                            min="0"
                                            max="59"
                                            style={{ fontSize: "20px" }}
                                        />
                                    </div>
                                </div>

                            }
                            {
                                kind === "결석"
                                &&
                                <div style={{ height: "85px" }}>
                                </div>
                            }
                        </div>

                        <Button variant='soft' sx={{ width: '300px', marginTop: "32px" }} disabled={disabled2} onClick={() => {
                            if (modalKind === "add") {
                                submitAdd();
                            } else if (modalKind === "update") {
                                submitUpdate();
                            }
                        }}>
                            {
                                modalKind === "add"
                                &&
                                "추가하기"
                            }
                            {
                                modalKind === "update"
                                &&
                                "수정하기"
                            }
                        </Button>
                    </div>

                </div>

                <div onClick={handleClose} style={{ width: "20px", position: "absolute", top: "20px", right: "10px", zIndex: 999, cursor: "pointer" }}>
                    <XMarkSvg fill="#ff5722" />
                </div>
            </ReactModal>

            <ToastContainer
                enableMultiContainer
                containerId={props.data.id}
            />
        </div>
    );
}


