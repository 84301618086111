import React, { useState, useEffect, useRef } from "react";
import styles from "./footer.module.css";
import { useHistory } from "react-router-dom";

import { ReactComponent as OnHomeSvg } from "../../svg/mobileon/home.svg";
import { ReactComponent as OffHomeSvg } from "../../svg/mobileoff/home.svg";
import { ReactComponent as OnBookSvg } from "../../svg/mobileon/book.svg";
import { ReactComponent as OffBookSvg } from "../../svg/mobileoff/book.svg";
import { ReactComponent as OnMessageSvg } from "../../svg/mobileon/message.svg";
import { ReactComponent as OffMessageSvg } from "../../svg/mobileoff/message.svg";
import { ReactComponent as OnCalendarSvg } from "../../svg/mobileon/calendar.svg";
import { ReactComponent as OffCalendarSvg } from "../../svg/mobileoff/calendar.svg";
import { ReactComponent as OnMenuSvg } from "../../svg/mobileon/menu.svg";
import { ReactComponent as OffMenuSvg } from "../../svg/mobileoff/menu.svg";
import Badge from '@mui/material/Badge';
import { getFetch } from "../../fetch/fetch";

import {App, AppState} from "@capacitor/app";

const Footer: React.FC<any> = (props) => {
    const history = useHistory();
    const [currentLocation, setCurrentLocation] = useState("");
    const [notificationCount, setNotificationCount] = useState(0);

    useEffect(() => {

        if(props.unread){
            console.log("%%%%%%%%%");
            console.log("unread");
            props.unread();
        }else{
            console.log("noUnread");
        }

    }, []);




    const handleStateChange = (state: AppState) => {
        console.log("appStateChange");
        console.log(state.isActive);
        if (state.isActive) {
            getNotificationNumber();
        }
    } 
    

    //스마트폰 백그라운드에서 포어그라운드로 올 때 한번 더 공지사항 새로운거 채팅 새로운거 확인해주기
    useEffect(() => {

        App.addListener("appStateChange", handleStateChange);

        return () => {
            App.removeAllListeners();
        }

    }, []);

    useEffect(() => {
        setCurrentLocation(props.location);
    }, []);

    const getNotificationNumber = async () => {

        // if (props.location === "notification") {
        //     return;
        // }
        try {
            const result : any = await getFetch("/dashboard/notification/getNumber");
            console.log("count");
            console.log(result);
            if(result.message === "success"){
                setNotificationCount(result.count);
            }


        } catch (e) {
            console.log(e);
            return;
        }
    }

    useEffect(() => {

        getNotificationNumber();

    }, [props.notificationUpdate]);

    return (
        <div ref={props.footerRef} className={styles.footer} style={{ paddingBottom: "env(safe-area-inset-bottom)" }}>
            <div className={styles.innerFooter}>
                <div className={styles.footerMenu} onClick={() => { setCurrentLocation("home"); history.push("/mobiledashboard"); }} >
                    <div className={styles.footerIconDiv}>
                        {
                            currentLocation === "home" ?
                                <OnHomeSvg className={`${styles.homeIcon} ${styles.icon}`} />
                                :
                                <OffHomeSvg className={`${styles.homeIcon} ${styles.icon}`} />
                        }
                    </div>
                    <div className={`${styles.footerIconTextDiv} ${currentLocation === "home" ? styles.active : ""}`}>
                        홈
                    </div>
                </div>
                <div className={styles.footerMenu} onClick={() => { setCurrentLocation("edit"); history.push("/mobiledashboard/edit"); }}>
                    <div className={styles.footerIconDiv}>
                        {
                            currentLocation === "edit" ?
                                <OnBookSvg className={`${styles.bookIcon} ${styles.icon}`} />
                                :
                                <OffBookSvg className={`${styles.bookIcon} ${styles.icon}`} />
                        }
                    </div>
                    <div className={`${styles.footerIconTextDiv} ${currentLocation === "edit" ? styles.active : ""}`}>
                        사유 제출
                    </div>
                </div>
                <div className={`${styles.footerMenu} ${styles.message}`} onClick={() => { setCurrentLocation("envelope"); history.push("/mobiledashboard/envelope") }}>
                    <div className={`${styles.footerIconDiv} ${styles.message}`}>
                    <Badge className={`${styles.badge} ${props.unreadMessage ? "" : styles.none}`} badgeContent={props.unreadMessage ? props.unreadMessage : 0} color="warning">

                        {
                            currentLocation === "envelope" ?
                                <OnMessageSvg className={`${styles.messageIcon} ${styles.icon}`} />
                                :
                                <OffMessageSvg className={`${styles.messageIcon} ${styles.icon}`} />
                        }

                        </Badge>
                    </div>
                    <div className={`${styles.footerIconTextDiv} ${currentLocation === "envelope" ? styles.active : ""}`}>
                        메세지
                    </div>

                </div>
                <div className={`${styles.footerMenu}`} onClick={() => { setCurrentLocation("notification"); history.push("/mobiledashboard/notificationlist") }}>
                    <div className={styles.footerIconDiv}>
                        <Badge className={`${styles.badge} ${props.unreadMessage ? "" : styles.none}`} badgeContent={notificationCount ? notificationCount : 0} color="warning">
                            {
                                currentLocation === "notification" ?
                                    <OnCalendarSvg className={`${styles.calendarIcon} ${styles.icon}`} />
                                    :
                                    <OffCalendarSvg className={`${styles.calendarIcon} ${styles.icon}`} />
                            }
                        </Badge>
                    </div>
                    <div className={`${styles.footerIconTextDiv} ${currentLocation === "notification" ? styles.active : ""}`}>
                        공지사항
                    </div>
                </div>
                <div className={styles.footerMenu}  onClick={() => { setCurrentLocation("total"); history.push("/mobiledashboard/totalmenu") }}>
                    <div className={styles.footerIconDiv}>
                        {
                            currentLocation === "total" ?
                            <OnMenuSvg className={`${styles.menuIcon} ${styles.icon}`} />
                            :
                            <OffMenuSvg className={`${styles.menuIcon} ${styles.icon}`} />
                        }
                    </div>
                    <div className={`${styles.footerIconTextDiv} ${currentLocation === "total" ? styles.active : ""}`}>
                        전체
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;