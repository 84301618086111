import React from "react";

function SongdoFreeSeatSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="1597"
    //   height="2255"
    //   viewBox="0 0 1597 2255"
      width="100%"
      viewBox="230 150 1200 1500"
    >
      <g id="page1" transform="translate(5.5 5.5)">
        <path fill="none" d="M0 0H1587V2245H0z"></path>
        <g id="shape1">
          <path
            fill="#7e7e7e"
            d="M-3.8 0h835.4l-3.8-3.8-3.8-3.8H3.8L0-3.8-3.8 0z"
            transform="rotate(180 626.526 102.923)"
          ></path>
          <path
            fill="none"
            stroke="#7e7e7e"
            d="M-3.8 0h835.4M824-7.6H3.8"
            transform="rotate(180 626.526 102.923)"
          ></path>
        </g>
        <g id="850">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 239.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(976.079 239.975)"
          >
            <tspan x="33.5" y="17.5">
              085
            </tspan>
          </text>
        </g>
        <g id="851">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 265.673)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 265.673)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="852">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 291.373)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 291.373)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="780">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.989 239.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(884.989 239.975)"
          >
            <tspan x="33.5" y="17.5">
              078
            </tspan>
          </text>
        </g>
        <g id="781">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.989 265.673)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.989 265.673)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="782">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.989 291.373)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.989 291.373)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="920">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 239.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1158.249 239.975)"
          >
            <tspan x="33.5" y="17.5">
              092
            </tspan>
          </text>
        </g>
        <g id="921">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 265.673)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 265.673)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="922">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 291.373)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 291.373)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="shape11">
          <path
            fill="#fff"
            d="M0 0h52.9v7.6H0V0zm0 0v7.6h-4V0h4zm52.9 0v7.6h4V0h-4zm0 0v-52.9h-4V0h4z"
            transform="matrix(-1 0 0 1 1139.457 1164.742)"
          ></path>
          <path
            fill="none"
            stroke="#303030"
            d="M0 0c0-29.2 23.7-52.9 52.9-52.9M0 0v7.6h-4V0h4zm52.9 0v7.6h4V0h-4zm0 0v-52.9h-4V0h4z"
            transform="matrix(-1 0 0 1 1139.457 1164.742)"
          ></path>
        </g>
        <g id="shape12">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1067.459 1172.301)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1067.459 1172.301)"
          >
            <tspan x="32.5" y="17.5">
              입구
            </tspan>
          </text>
        </g>
        <g id="570">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429 239.59)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(429 239.59)"
          >
            <tspan x="33.5" y="17.5">
              057
            </tspan>
          </text>
        </g>
        <g id="571">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429 265.29)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429 265.29)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="572">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429 290.988)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429 290.988)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="710">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.849 239.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(702.849 239.975)"
          >
            <tspan x="33.5" y="17.5">
              071
            </tspan>
          </text>
        </g>
        <g id="711">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.849 265.673)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.849 265.673)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="712">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.849 291.373)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.849 291.373)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="640">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.769 239.975)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(611.769 239.975)"
          >
            <tspan x="33.5" y="17.5">
              064
            </tspan>
          </text>
        </g>
        <g id="641">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.769 265.673)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.769 265.673)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="642">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.769 291.373)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.769 291.373)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="shape22">
          <path
            fill="#7e7e7e"
            d="M-3.8 0h966.2l-3.7-3.8-3.8-3.8H3.8L0-3.8-3.8 0z"
            transform="rotate(-90 1212.557 -44.274)"
          ></path>
          <path
            fill="none"
            stroke="#7e7e7e"
            d="M-3.8 0h966.2m-7.5-7.6H3.8"
            transform="rotate(-90 1212.557 -44.274)"
          ></path>
        </g>
        <g id="shape23">
          <path
            fill="#7e7e7e"
            d="M-3.8 0h966.2v-3.8h-3.7l-3.8-3.8H3.8L-3.8 0z"
            transform="rotate(90 105.91 315.535)"
          ></path>
          <path
            fill="none"
            stroke="#7e7e7e"
            d="M-3.8 0h966.2m0-7.6H3.8M962.4 0v-7.6"
            transform="rotate(90 105.91 315.535)"
          ></path>
        </g>
        <g id="860">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(975.975 368.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(975.975 368.47)"
          >
            <tspan x="33.5" y="17.5">
              086
            </tspan>
          </text>
        </g>
        <g id="861">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(975.975 394.168)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(975.975 394.168)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="862">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(975.975 419.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(975.975 419.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="790">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.885 368.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(884.885 368.47)"
          >
            <tspan x="33.5" y="17.5">
              079
            </tspan>
          </text>
        </g>
        <g id="791">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.885 394.168)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.885 394.168)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="792">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.885 419.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.885 419.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="930">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.145 368.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1158.145 368.47)"
          >
            <tspan x="33.5" y="17.5">
              093
            </tspan>
          </text>
        </g>
        <g id="931">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.145 394.168)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.145 394.168)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="932">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.145 419.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.145 419.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="580">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429 368.086)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(429 368.086)"
          >
            <tspan x="33.5" y="17.5">
              058
            </tspan>
          </text>
        </g>
        <g id="581">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429 393.784)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429 393.784)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="582">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429 419.483)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429 419.483)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="720">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.745 368.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(702.745 368.47)"
          >
            <tspan x="33.5" y="17.5">
              072
            </tspan>
          </text>
        </g>
        <g id="721">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.745 394.168)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.745 394.168)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="722">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.745 419.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.745 419.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="650">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.665 368.47)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(611.665 368.47)"
          >
            <tspan x="33.5" y="17.5">
              065
            </tspan>
          </text>
        </g>
        <g id="651">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.665 394.168)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.665 394.168)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="652">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.665 419.868)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.665 419.868)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="870">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 496.964)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(976.079 496.964)"
          >
            <tspan x="33.5" y="17.5">
              087
            </tspan>
          </text>
        </g>
        <g id="871">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 522.663)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 522.663)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="872">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 548.362)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 548.362)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="800">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 496.964)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(884.985 496.964)"
          >
            <tspan x="33.5" y="17.5">
              080
            </tspan>
          </text>
        </g>
        <g id="801">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 522.663)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.985 522.663)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="802">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 548.362)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.985 548.362)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="940">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 496.964)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1158.249 496.964)"
          >
            <tspan x="33.5" y="17.5">
              094
            </tspan>
          </text>
        </g>
        <g id="941">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 522.663)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 522.663)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="942">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 548.362)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 548.362)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="590">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 496.58)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(429.684 496.58)"
          >
            <tspan x="33.5" y="17.5">
              059
            </tspan>
          </text>
        </g>
        <g id="591">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 522.28)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429.684 522.28)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="592">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 547.979)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429.684 547.979)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="730">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 496.964)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(702.845 496.964)"
          >
            <tspan x="33.5" y="17.5">
              073
            </tspan>
          </text>
        </g>
        <g id="731">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 522.663)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.845 522.663)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="732">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 548.362)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.845 548.362)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="660">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 496.964)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(611.765 496.964)"
          >
            <tspan x="33.5" y="17.5">
              066
            </tspan>
          </text>
        </g>
        <g id="661">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 522.663)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.765 522.663)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="662">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 548.362)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.765 548.362)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="880">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 625.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(976.079 625.46)"
          >
            <tspan x="33.5" y="17.5">
              088
            </tspan>
          </text>
        </g>
        <g id="881">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 651.159)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 651.159)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="882">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 676.857)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 676.857)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="810">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 625.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(884.985 625.46)"
          >
            <tspan x="33.5" y="17.5">
              081
            </tspan>
          </text>
        </g>
        <g id="811">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 651.159)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.985 651.159)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="812">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 676.857)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.985 676.857)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="950">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 625.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1158.249 625.46)"
          >
            <tspan x="33.5" y="17.5">
              095
            </tspan>
          </text>
        </g>
        <g id="951">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 651.159)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 651.159)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="952">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 676.857)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 676.857)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="600">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 625.076)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(429.684 625.076)"
          >
            <tspan x="33.5" y="17.5">
              060
            </tspan>
          </text>
        </g>
        <g id="601">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 650.774)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429.684 650.774)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="602">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 676.473)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429.684 676.473)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="740">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 625.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(702.845 625.46)"
          >
            <tspan x="33.5" y="17.5">
              074
            </tspan>
          </text>
        </g>
        <g id="741">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 651.159)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.845 651.159)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="742">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 676.857)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.845 676.857)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="670">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 625.46)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(611.765 625.46)"
          >
            <tspan x="33.5" y="17.5">
              067
            </tspan>
          </text>
        </g>
        <g id="671">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 651.159)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.765 651.159)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="672">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 676.857)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.765 676.857)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="890">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 753.955)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(976.079 753.955)"
          >
            <tspan x="33.5" y="17.5">
              089
            </tspan>
          </text>
        </g>
        <g id="891">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 779.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 779.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="892">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 805.352)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 805.352)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="820">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 753.955)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(884.985 753.955)"
          >
            <tspan x="33.5" y="17.5">
              082
            </tspan>
          </text>
        </g>
        <g id="821">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 779.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.985 779.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="822">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 805.352)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.985 805.352)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="960">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 753.955)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1158.249 753.955)"
          >
            <tspan x="33.5" y="17.5">
              096
            </tspan>
          </text>
        </g>
        <g id="961">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 779.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 779.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="962">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 805.352)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 805.352)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="610">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 753.57)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(429.684 753.57)"
          >
            <tspan x="33.5" y="17.5">
              061
            </tspan>
          </text>
        </g>
        <g id="611">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 779.27)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429.684 779.27)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="612">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 804.968)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429.684 804.968)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="750">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 753.955)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(702.845 753.955)"
          >
            <tspan x="33.5" y="17.5">
              075
            </tspan>
          </text>
        </g>
        <g id="751">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 779.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.845 779.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="752">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 805.352)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.845 805.352)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="680">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 753.955)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(611.765 753.955)"
          >
            <tspan x="33.5" y="17.5">
              068
            </tspan>
          </text>
        </g>
        <g id="681">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 779.654)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.765 779.654)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="682">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 805.352)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.765 805.352)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="900">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 882.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(976.079 882.45)"
          >
            <tspan x="33.5" y="17.5">
              090
            </tspan>
          </text>
        </g>
        <g id="901">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 908.149)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 908.149)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="902">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 933.847)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 933.847)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="830">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 882.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(884.985 882.45)"
          >
            <tspan x="33.5" y="17.5">
              083
            </tspan>
          </text>
        </g>
        <g id="831">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 908.149)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.985 908.149)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="832">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 933.847)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.985 933.847)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="970">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 882.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1158.249 882.45)"
          >
            <tspan x="33.5" y="17.5">
              097
            </tspan>
          </text>
        </g>
        <g id="971">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 908.149)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 908.149)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="972">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 933.847)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 933.847)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="620">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 882.066)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(429.684 882.066)"
          >
            <tspan x="33.5" y="17.5">
              062
            </tspan>
          </text>
        </g>
        <g id="621">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 907.764)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429.684 907.764)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="622">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 933.463)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429.684 933.463)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="760">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 882.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(702.845 882.45)"
          >
            <tspan x="33.5" y="17.5">
              076
            </tspan>
          </text>
        </g>
        <g id="761">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 908.149)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.845 908.149)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="762">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 933.847)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.845 933.847)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="690">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 882.45)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(611.765 882.45)"
          >
            <tspan x="33.5" y="17.5">
              069
            </tspan>
          </text>
        </g>
        <g id="691">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 908.149)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.765 908.149)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="692">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 933.847)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.765 933.847)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="910">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 1010.944)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(976.079 1010.944)"
          >
            <tspan x="33.5" y="17.5">
              091
            </tspan>
          </text>
        </g>
        <g id="911">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 1036.643)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 1036.643)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="912">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(976.079 1062.342)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(976.079 1062.342)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="840">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 1010.944)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(884.985 1010.944)"
          >
            <tspan x="33.5" y="17.5">
              084
            </tspan>
          </text>
        </g>
        <g id="841">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 1036.643)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.985 1036.643)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="842">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(884.985 1062.342)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(884.985 1062.342)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="980">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 1010.944)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(1158.249 1010.944)"
          >
            <tspan x="33.5" y="17.5">
              098
            </tspan>
          </text>
        </g>
        <g id="981">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 1036.643)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 1036.643)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="982">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(1158.249 1062.342)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(1158.249 1062.342)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="630">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 1010.56)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(429.684 1010.56)"
          >
            <tspan x="33.5" y="17.5">
              063
            </tspan>
          </text>
        </g>
        <g id="631">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 1036.26)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429.684 1036.26)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="632">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(429.684 1061.958)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(429.684 1061.958)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="770">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 1010.944)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(702.845 1010.944)"
          >
            <tspan x="33.5" y="17.5">
              077
            </tspan>
          </text>
        </g>
        <g id="771">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 1036.643)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.845 1036.643)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="772">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(702.845 1062.342)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(702.845 1062.342)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="700">
          <path
            fill="#d7d7d7"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 1010.944)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            fontWeight="bold"
            transform="translate(611.765 1010.944)"
          >
            <tspan x="33.5" y="17.5">
              070
            </tspan>
          </text>
        </g>
        <g id="701">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 1036.643)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.765 1036.643)"
          >
            <tspan x="32.5" y="17.3">
              이름
            </tspan>
          </text>
        </g>
        <g id="702">
          <path
            fill="#fff"
            stroke="#323232"
            d="M0 0h91.1v25.7H0V0z"
            transform="translate(611.765 1062.342)"
          ></path>
          <text
            fill="#191919"
            fontFamily="맑은 고딕,PingFang SC,Mircrosoft YaHei,Arial"
            fontSize="13.333"
            transform="translate(611.765 1062.342)"
          >
            <tspan x="32.5" y="17.3">
              메모
            </tspan>
          </text>
        </g>
        <g id="shape132">
          <path
            fill="#7e7e7e"
            d="M3.8 0H824l3.8-3.8v-3.8H-3.8L0-3.8 3.8 0z"
            transform="rotate(180 626.526 582.252)"
          ></path>
          <path
            fill="none"
            stroke="#7e7e7e"
            d="M3.8 0H824m3.8-7.6H-3.8"
            transform="rotate(180 626.526 582.252)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SongdoFreeSeatSvg;