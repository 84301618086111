import React, { useEffect, useState } from "react";
import styles from "./wordtest.module.css";
import { ReactComponent as LogoSvg } from '../svg/newlogowhite.svg';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { Link } from 'react-router-dom';

import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Badge, Button, CssVarsProvider } from "@mui/joy";
import { BookmarkBorder } from "@mui/icons-material";

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import GetWordTest from "./getwordtest";



const WordTest: React.FC<any> = (props) => {


    const [check, setCheck] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
    const [userName, setUserName] = useState("");
    const [testRecord, setTestRecord] = useState<any>();
    const [bookKind, setBookKind] = useState("");

    const [open, setOpen] = React.useState<boolean>(false);
    const [open2, setOpen2] = useState(false);

    const [today, setToday] = useState<any>([]);
    const dayList = bookKind != "book5" ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];



    useEffect(() => {

        fetch(`https://peetsunbae.com/dashboard/words/userdefault`, {
            method: "GET",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    console.log(result);

                    if (result.message === "success") {

                        var kind = result.kind;

                        switch (kind) {
                            case 1:
                                setBookKind("book1");
                                break;
                            case 2:
                                setBookKind("book2");
                                break;
                            case 3:
                                setBookKind("book3");
                                break;
                            case 4:
                                setBookKind("book1");
                                break;
                            case 5:
                                setBookKind("book5");
                                break;
                        }
                    }


                })
        })

    }, []);



    useEffect(() => {

        if (!bookKind) {
            return;
        }

        var kind = 1;

        switch (bookKind) {
            case "book1":
                kind = 1;
                break;
            case "book2":
                kind = 2;
                break;
            case "book3":
                kind = 3;
                break;
            case "book5":
                kind = 5;
                break;
        }



        fetch(`https://peetsunbae.com/dashboard/words/userinfo?kind=${kind}`, {
            method: "GET",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    if (result.message === "NoLogin") {
                        props.history.push("/login2");
                    }
                    console.log(result);
                    if (result.message === "success") {
                        setUserName(result.data.name);
                        setTestRecord(result.record);
                    }
                })
        })

    }, [bookKind]);

    //로그아웃 시키는 기능-----------------------------------------------------
    const logOut = async () => {
        //일렉트론 이라면 저장된 토큰 삭제-----------------------------------------------------
        if (window.electron) {
            const result = await window.electron.sendMessageApi.setToken("logOut");
            console.log(result);
        }

        // ipcRenderer.send("signOut");

        //-----------------------------------------------------------------------

        //쿠키 삭제 위해 서버로 fetch 보냄-----------------------------------------
        fetch("https://peetsunbae.com/login/logout", {
            method: "GET",
            credentials: "include"
        }).then((response) => {
            response.json()
                .then((result) => {
                    console.log(result);
                    props.history.push({
                        pathname: "/login2",
                        state: { from: "dashboard" }
                    });
                })
        }).catch((err) => {
            console.log(err);
        })
        //--------------------------------------------------------------------------

        //-------------------------------------------------------------------
    }
    //-----------------------------------------------------------------------


    const handleCheck = (event: any, index: number) => {
        const newCheck = check;

        if (index % 2 === 0) {
            if (event.target.checked === true && newCheck[index + 1] === true) {
                newCheck[index + 1] = false;
            }
        } else {
            if (event.target.checked === true && newCheck[index - 1] === true) {
                newCheck[index - 1] = false;
            }
        }


        newCheck[index] = event.target.checked;
        setCheck([...newCheck]);

    }

    const start = (e: any) => {
        const currentCheck = check;
        var count = 0;

        currentCheck.forEach((each: any) => {
            if (each === true) {
                count++;
            }
        });

        if (count === 0) {
            alert("시험 볼 DAY를 선택하신 후 시작하세요");
            return;
        }

        if (!bookKind) {
            alert("책을 선택하신 후 시작하세요");
            return;
        }

        props.history.push({
            pathname: "/wordtestreal",
            state: { check: check, userName: userName, bookKind: bookKind }
        });

    }

    const handleChange = (
        event: React.SyntheticEvent | null,
        newValue: string | null,
    ) => {

        if (!newValue) {
            return;
        }

        setOpen(true);
        return;
        // setBookKind(newValue);
    };


    const todayWork = (data : any) => {

        console.log(data);

    }

    useEffect(() => {

        if(!bookKind){
            console.log("noBookKind");
            return;
        }

        const index = 1;

        fetch(`https://peetsunbae.com/dashboard/words/getwordtestforstudent?index=${index}`, {
            method: "get",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "success") {

                        const makedKind = result.data1.kind;

                        var kind;

                        switch (bookKind) {
                            case "book1":
                                kind = 1;
                                break;
                            case "book2":
                                kind = 2;
                                break;
                            case "book3":
                                kind = 3;
                                break;
                            case "book5":
                                kind = 5;
                                break;
                        }
                        
                        if(makedKind !== kind){
                            return;
                        }

                        const created = new Date(result.data1.createdAt);
                        const createdString = `${created.getMonth() + 1}/${created.getDate()}`;


                        const data1 = result.data1.data;
                        const rows: any = [];

                        const data2 = result.data2;
                        const data2rows: any = [];

                        data2.forEach((each: any) => {
                            each.dayInfo.forEach((eacheach: any) => {
                                const oneRow: any = {};
                                oneRow.day = eacheach.dayNumber;
                                if (eacheach.isTotal) {
                                    oneRow.type = "total"
                                } else {
                                    oneRow.type = "incorrect"
                                }

                                oneRow.words = [];


                                each.report.forEach((eachWord: any) => {

                                    if (eachWord.day === oneRow.day) {
                                        oneRow.words.push(eachWord);
                                    }
                                })

                                data2rows.push(oneRow);
                            })
                        })

                        data2rows.forEach((each: any) => {

                            var totalCount = 0;
                            var count = 0;

                            each.words.forEach((eachWord: any) => {
                                totalCount++;
                                if (eachWord.result === "correct") {
                                    count++;
                                }
                            })

                            each.totalCount = totalCount;
                            each.count = count;

                        })

                        console.log(data2rows);


                        data1.forEach((each: any) => {

                            const newRow: any = {};
                            newRow.month = each.month + 1;
                            newRow.date = each.date;

                            const newDate = new Date(each.year, each.month, each.date);

                            var day = ""

                            switch (newDate.getDay()) {
                                case 0:
                                    day = "일"
                                    break;
                                case 1:
                                    day = "월"
                                    break;
                                case 2:
                                    day = "화"
                                    break;
                                case 3:
                                    day = "수"
                                    break;
                                case 4:
                                    day = "목"
                                    break;
                                case 5:
                                    day = "금"
                                    break;
                                case 6:
                                    day = "토"
                                    break;
                            }

                            newRow.day = day;
                            newRow.data = [];

                            each.data.forEach((eachData: any) => {

                                const partNewRow: any = {};
                                partNewRow.title = `Day${eachData.day}`;
                                if (eachData.type === "incorrect") {
                                    partNewRow.title += " 오답";
                                }

                                partNewRow.completed = false;

                                data2rows.forEach((eachData2: any) => {

                                    if (eachData.day === eachData2.day && eachData.type === eachData2.type) {
                                        partNewRow.completed = true;
                                        partNewRow.wordsData = eachData2;
                                    }

                                })

                                partNewRow.day = eachData.day;
                                partNewRow.type = eachData.type;
                                newRow.data.push(partNewRow);

                            });

                            const todayDate = new Date();

                            if(todayDate.getFullYear() === each.year && todayDate.getMonth() === each.month && todayDate.getDate() === each.date){
                                console.log("!!!");
                                console.log("today");
                                console.log(newRow.data);
                                setToday([...newRow.data]);
                            }

                            rows.push(newRow);
                        })


                    } 
                })
        })

    }, [bookKind]);



    return (
        <div>

            <div className={`${styles.appBar} ${styles.notablet}`}>
                <Link to="/dashboard/home">
                    <div className={styles.logoDiv} style={{ cursor: "pointer" }}>
                        <LogoSvg className={styles.logoSvg} />
                    </div>
                </Link>
                <div className={styles.profileDiv}>
                    <div className={styles.profileConfig} style={{ cursor: "auto" }}>
                        {userName}
                    </div>
                    <div className={styles.avatarCircle} onClick={logOut}>
                        <img className={styles.avatar} src="img/avatarG.svg" alt="avatar"></img>
                    </div>
                    <div className={styles.logout} onClick={logOut}>
                        로그아웃
                    </div>
                </div>
            </div>

            <div className={styles.main}>
                <div className={styles.title}>
                    {userName}님 환영합니다<br></br>영어 단어 테스트를<br></br>시작할게요.
                </div>
                <div className={styles.subTitle}>
                    아래 표에서 원하는<br></br>시험 범위를 선택해주세요.
                </div>
            </div>

            <div className={styles.selectBookDiv}>
                <div>
                    <CssVarsProvider>
                        <Button onClick={() => {setOpen2(true);}} variant="soft">
                            영단어 숙제 확인
                        </Button>
                    </CssVarsProvider>
                </div>
                <div style={{ width: "420px" }}>
                    <CssVarsProvider>
                        <Select startDecorator={<BookmarkBorder />} value={bookKind} onChange={handleChange}>
                            <Option value={"book1"}>워드마스터 수능 2000 (2018년 버전)</Option>
                            <Option value={"book2"}>워드마스터 수능 2000 (2022년 버전)</Option>
                            <Option value={"book3"}>워드마스터 하이퍼 2000 (2021년 버전)</Option>
                            <Option value={"book5"}>워드마스터 EBS 파이널 1200(2020년 버전)</Option>
                        </Select>
                    </CssVarsProvider>
                </div>
            </div>
            <div className={styles.tableDiv}>
                <div className={styles.tableTop}>
                    <div className={styles.col_1}>
                        범위
                    </div>
                    <div className={styles.col_4}>
                        시험 볼 단어 선택
                    </div>
                    <div className={styles.col_2}>
                        마지막 테스트
                    </div>
                    <div className={styles.col_3}>
                        틀린 단어 갯수
                    </div>

                </div>
                {
                    dayList.map((eachDay: any, index: number) => {

                        const number = index + 1;
                        var count = 0;

                        var dateString: any;
                        var testKind: any;
                        var incorrectWordsCunt: any;


                        if (testRecord) {
                            testRecord.forEach((eachRecord: any, recordIndex: number) => {
                                if (eachRecord.day === eachDay) {
                                    count++;
                                    const date = new Date(eachRecord.createdAt);
                                    dateString = `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
                                    testKind = eachRecord.isTotal ? "전체 단어" : "틀린 단어만";
                                    dateString = dateString + "(" + testKind + ")"
                                    incorrectWordsCunt = eachRecord.count + "개"
                                }
                            })
                        }

                        var assignment = "";

                        console.log(today);
                        console.log("today");

                        today.forEach((eachData : any) => {

                            if(eachData.day === eachDay && !eachData.completed){
                                if(eachData.type === "total"){
                                    assignment += "total";
                                }
                                if(eachData.type === "incorrect"){
                                    assignment += "incorrect";
                                }
                            }

                        })


                        return (
                            <div key={Math.random()} className={styles.rowDiv}>
                                <div className={styles.col_1}>
                                    Day {eachDay}
                                </div>
                                <div className={styles.col_4}>
                                    <FormGroup>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            {
                                                assignment.includes("total")
                                                    ?
                                                    <FormControlLabel control={<Badge color="danger" badgeInset="20%"><Checkbox checked={check[(number * 2) - 2]} onChange={(e: any) => { handleCheck(e, (number * 2) - 2) }} /></Badge>} label={<span style={{ fontFamily: "Apple_R", marginRight: "16px" }}>전체 단어</span>} />
                                                    :
                                                    <FormControlLabel control={<Checkbox checked={check[(number * 2) - 2]} onChange={(e: any) => { handleCheck(e, (number * 2) - 2) }} />} label={<span style={{ fontFamily: "Apple_R", marginRight: "16px" }}>전체 단어</span>} />
                                            }
                                            {
                                                assignment.includes("incorrect")
                                                    ?
                                                    <FormControlLabel disabled={(count === 0 || incorrectWordsCunt === "0개") ? true : false} control={<Badge color="danger" badgeInset="20%"><Checkbox checked={check[(number * 2) - 1]} onChange={(e: any) => { handleCheck(e, (number * 2) - 1) }} /></Badge>} label={<span style={{ fontFamily: "Apple_R" }}>틀린 단어만</span>} />
                                                    :
                                                    <FormControlLabel disabled={(count === 0 || incorrectWordsCunt === "0개") ? true : false} control={<Checkbox checked={check[(number * 2) - 1]} onChange={(e: any) => { handleCheck(e, (number * 2) - 1) }} />} label={<span style={{ fontFamily: "Apple_R" }}>틀린 단어만</span>} />
                                            }
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className={styles.col_2}>
                                    {
                                        count ?
                                            dateString : "-"
                                    }
                                </div>
                                <div className={styles.col_3}>
                                    {
                                        count ?
                                            incorrectWordsCunt : "-"
                                    }
                                </div>

                            </div>
                        );
                    })
                }
            </div>

            <div onClick={start} className={styles.startBtn}>
                테스트 시작
            </div>

            <CssVarsProvider>
                <Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Sheet
                        variant="outlined"
                        sx={{
                            maxWidth: 500,
                            borderRadius: 'md',
                            p: 3,
                            boxShadow: 'lg',
                        }}
                    >
                        <ModalClose
                            variant="outlined"
                            sx={{
                                top: 'calc(-1/4 * var(--IconButton-size))',
                                right: 'calc(-1/4 * var(--IconButton-size))',
                                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                                borderRadius: '50%',
                                bgcolor: 'background.body',
                            }}
                        />
                        <Typography
                            component="h2"
                            id="modal-title"
                            level="h4"
                            textColor="inherit"
                            fontWeight="lg"
                            mb={1}
                        >
                            영단어 책 변경 공지
                        </Typography>
                        <Typography id="modal-desc" textColor="text.tertiary">
                            영단어 책 변경은 담임 멘토에게 문의하여 변경할 수 있습니다.<br></br>
                            변경을 원할 시 담임 멘토에게 변경 요청해주세요.
                        </Typography>
                    </Sheet>
                </Modal>
            </CssVarsProvider>


            <Modal
                open={open2}
                onClose={() => setOpen2(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 800,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <GetWordTest  />
                </Sheet>
            </Modal>

        </div>
    )
}

export default WordTest;