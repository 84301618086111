import { getFetch, postFetch } from "../fetch/fetch";

import { Market } from "@ionic-native/market";
import { InAppBrowser } from '@ionic-native/in-app-browser';
import {Capacitor} from "@capacitor/core";
import {App, AppInfo} from "@capacitor/app";

interface data {
    androidUrl: string;
    iosUrl: string;
    isForcedUpdate : boolean;
}


export const getAvatarSrc = async () => {

    console.log("letsgetavatar");
    
    try{
        const result : any = await getFetch("/dashboard/profile/avatar");
        if(result.message === "success"){
            const index = result.index;
            if(index !== 0){
                return `/img/avatar/Avatar-${index}.png`;
            }else{
                return ;
            }
        }

    }catch(e : any){
        console.log(e);
    }

}



const letsGetServerVersion : (arg0 : AppInfo) => Promise<data> = async (appInfo : AppInfo) => {

    return new Promise(async (resolve, reject) => {
      try {
        const response : any = await postFetch("/realVersion", appInfo);

        console.log(response);

        if(response.message !== "success"){
          console.log("notSuccess");
          return;
        }

        const result = response.data as data;
        
        console.log("serverVersion");

        const data = {
            androidUrl : result.androidUrl,
            iosUrl : result.iosUrl,
            isForcedUpdate : result.isForcedUpdate
        }

        console.log(data);

        resolve(data);

      } catch (e) {
        console.log("error");
        console.log(e);
        reject(e);
      }
    })
  }

  const letsGetInfo : () => Promise<AppInfo> = async () => {

    return new Promise(async (resolve, reject) => {
      try{
        const appInfo = await App.getInfo();
        console.log("appVersion");
        console.log(appInfo);
        console.log(appInfo.build);
        console.log(appInfo.version);
        resolve(appInfo);
      }catch(e){
        console.log(e);
        reject("");
      }
    })
  }

  const goUpdate = (presentAlert : any, androidUrl : string, iosUrl : string) => {
    console.log("goUpdate");
    
    presentAlert({
      header : "필수 업데이트",
      subHeader : "업데이트 후에 앱 사용 가능",
      buttons : [
        {
          text : "업데이트",
          role : "confirm",
          handler : () => {
            goNativeStore(androidUrl, iosUrl);
          }
        }
      ],
      backdropDismiss : false
    })

  }

  const goNativeStore = (androidUrl : string, iosUrl : string) => {

    if(Capacitor.getPlatform() === "ios"){
      console.log("letsTry");
      InAppBrowser.create(iosUrl);
    }

    if(Capacitor.getPlatform() === "android"){
      console.log("goPlayStore");
      // InAppBrowser.create(androidUrl);
      Market.open(androidUrl);
    }

  }

  const compareVersion = async (presentAlert : any, handleOpen : (boolean : boolean) => void, setUrl : (url : string) => void) => {
    try{
      
      const appInfo = await letsGetInfo();
      const data = await letsGetServerVersion(appInfo);


      if(data.isForcedUpdate){
        console.log(1);

        if(Capacitor.getPlatform() === "android"){
          setUrl(data.androidUrl);
        }else if(Capacitor.getPlatform() === "ios"){
          setUrl(data.iosUrl);
        }else{
          setUrl("");
          return;
        }

        handleOpen(true);
        // goUpdate(presentAlert, data.androidUrl, data.iosUrl);
      }


    }catch(e){
      console.log(e);
    }
  }


export const checkMobileMainUpdate = (presentAlert : any, handleOpen : (boolean : boolean) => void, setUrl : (url : string) => void) => {
    compareVersion(presentAlert, handleOpen, setUrl);
}
