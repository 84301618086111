import React, { useEffect, useState, useCallback } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    LicenseInfo,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
    gridDetailPanelExpandedRowIdsSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { renderCellExpand } from "../../infos/suddenData";
import { IconButton } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .enter': {
                    color : "blue"
                },
                '& .exit': {
                    color : "red"
                },
                '& .phone_memo': {
                    color : "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);



const DemeritMemo: React.FC<any> = (props) => {

    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field : "relatedTime", headerName : "관련시간대", width : 120, sortable : false, editable : true },
        { field : "description", headerName : "내용", width : 360, sortable : false, editable : true, renderCell : renderCellExpand },
    ]);



    useEffect(() => {

        start();
 
    }, [props.targetDate, props.classNumber, props.selectedUserId]);

    const start  = async () => {

        const infoResult = await getInfo();
        makeRows(infoResult);

    }


    const getInfo = async () => {

        const targetDate = props.targetDate;
        const selectedUserId = props.selectedUserId;

        if(!targetDate || !selectedUserId){
            console.log("noTargetDate");
            return;
        }

        const targetDateTime = targetDate.getTime();

        const response = await fetch(`https://peetsunbae.com/dashboard/report/attendance/demeritmemo?userId=${selectedUserId}&targetDateTime=${targetDateTime}`, {
            method : "GET",
            credentials : "include"
        })

        const result = await response.json();

        console.log(result);

        if(result.message === "success"){

            return result;

        }else{

            const voidReturnData = {
                relatedTime : new Array(20).fill(undefined),
                description : new Array(20).fill(undefined),
            }

            return voidReturnData;
        }


    }



    const makeRows = (infoResult : any) => {

        if(!infoResult){
            return;
        }

        const relatedTime = infoResult.relatedTime;
        const description = infoResult.description;

        const newRows = [];

        for(var i=0; i<10; i++){

            const oneRow : any = {};

            oneRow.id = i + 1;
            
            newRows.push(oneRow);
        }

        newRows.forEach((eachRow : any, index : number) => {

            eachRow.relatedTime = relatedTime[index + 1] ? relatedTime[index + 1] : "";
            eachRow.description = description[index + 1] ? description[index + 1] : "";

        });

        setRows([...newRows]);

    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";


            console.log("new");

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.id;
            const currentTargetDate = props.targetDate;

            if(!field){
                console.log("noChanged");
                return;
            }


            if(!id || !currentTargetDate){
                console.log("noValue");
                return;
            }

            if(!props.selectedUserId){
                console.log("noUserId");
                return;
            }

            const data = {
                userId : props.selectedUserId,
                field,
                value : value ? value : "",
                id,
                targetDateTime : currentTargetDate.getTime(),
            }

            console.log(data);

            fetch("https://peetsunbae.com/dashboard/report/attendance/demeritmemo", {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(data)
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        console.log(result);
                        if (result.message === "success") {

                        }else{
                            alert("서버에 문제가 있습니다. 다시 시도해주세요.");
                        }
                    })
            })

            return newRow;


        }, [props.targetDate, props.classNumber, props.selectedUserId]
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log("error");
        //alert(error.message);
        return;
    }, []);


    return (
        <div className={classes.root}
        style={{
            width : "1000px",
            height : "172px",
        }}
        >
                        <div
            style={{
                display : "flex",
                justifyContent : "center",
                paddingBottom : "10px",
                fontFamily : "Apple_SB",
                paddingTop : "14px",
            }}
            >
                벌점 관련 메모
            </div>
            <DataGridPremium
            sx={{
                backgroundColor : "white"
            }}
            loading={props.loading}
            rows={rows}
            columns={columns}
            density="compact"
            hideFooter
            processRowUpdate={handleEditCommit}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            />
        </div>
    )
}



export default DemeritMemo;