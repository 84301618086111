import { DataGridPremium, useGridApiRef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import koLocale from 'date-fns/locale/ko'
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import { Button } from '@mui/joy';
import { addZeroIfOne } from '../../etc/etcfunctions';
import { set } from 'lodash';
import { is } from 'date-fns/locale';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

interface GridCellExpandProps {
    value: string;
    width: number;
}


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    }),
);


function isOverflown(element: Element): boolean {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}


const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps,
) {
    const { width, value } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current!);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <div ref={cellValue} className="cellValue">
                {value}
            </div>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );
});

function renderCellExpand(params: GridRenderCellParams<any>) {
    return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
}

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .suspicious_in': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .suspicious': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .never2': {
                    color: "red"
                },
                '& .phone_noproblem': {
                    color: "black"
                },
                '& .phone_memo': {
                    color: "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "blue"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);

const columns1 = [
    {
        field: "isFinish", headerName: "완료 여부", width: 100
    },
    {
        field: "location", headerName: "지점", width: 100
    },
    {
        field: "name", headerName: "이름", width: 150
    },
    {
        field: "date", headerName: "날짜", width: 150
    },
    {
        field: "finishedAt", headerName: "완료 시간", width: 120
    },
    {
        field: "totalNumber", headerName: "총 학생 수", width: 120
    },
    {
        field: "consultNumber", headerName: "상담 학생 수", width: 120
    },
    {
        field: "consultRate", headerName: "상담률", width: 120
    },
    {
        field: "opinion", headerName: "의견", width: 200, renderCell: renderCellExpand
    },
    {
        field: "didOnlineQuestions", headerName: "온라인 질의", width: 120
    },
    {
        field: "whyNotDoneOnlineQuestions", headerName: "온라인 질의 미완료 사유", width: 200, renderCell: renderCellExpand
    },

]

const columns2 = [
    {
        field: "order", headerName: "순서", width: 80
    },
    {
        field: "name", headerName: "이름", width: 100
    },
    {
        field: "consult", headerName: "상담진행", width: 100
    },
    {
        field: "etc", headerName: "특이사항", width: 180, renderCell: renderCellExpand
    },
    {
        field: "report", headerName: "상담일지", width: 150, renderCell: renderCellExpand
    },
    {
        field: "send", headerName: "학부모 전송", width: 150, renderCell: renderCellExpand
    },
    {
        field: "word", headerName: "영단어 출제", width: 150, renderCell: renderCellExpand
    },
    {
        field: "test", headerName: "주간 테스트", width: 150, renderCell: renderCellExpand
    },
    {
        field: "print", headerName: "폴더 프린트 첨부", width: 150
    }
]

const ViewConsultList: React.FC<any> = (props) => {

    const [rows1, setRows1] = useState<any>([]);
    const [rows2, setRows2] = useState<any>([]);
    const classes = useStyles2();
    const [value, setValue] = useState<DateRange<Date>>([new Date(), new Date()]);
    const [disabled, setDisabled] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const apiRef = useGridApiRef();
    const [selectedDataId, setSelectedDataId] = useState(0);
    const [data, setData] = useState([]);
    const [didOnlineQuestions, setDidOnlineQuestions] = useState(false);
    const [whyNotDoneOnlineQuestions, setWhyNotDoneOnlineQuestions] = useState("");
    const [opinion, setOpinion] = useState("");

    useEffect(() => {

        getConsultList();

    }, []);

    useEffect(() => {

        if (!selectedDataId) {
            return;
        }

        specificTeacherConsultData();

    }, [selectedDataId]);

    const specificTeacherConsultData = async () => {

        const consultId = selectedDataId;

        const selectedData: any = data.find((eachData: any) => eachData.id === consultId);

        if (!selectedData) {
            setRows2([]);
            return;
        }

        console.log(selectedData);

        setDidOnlineQuestions(selectedData.didOnlineQuestions);
        setWhyNotDoneOnlineQuestions(selectedData.whyNotDoneOnlineQuestions);
        setOpinion(selectedData.opinion);

        const consultData = selectedData.data;

        const newRows: any = [];

        consultData.forEach((eachData: any, index: number) => {

            const oneRow: any = {};
            oneRow.id = index + 1;
            oneRow.order = index + 1;
            oneRow.name = eachData.name;

            if (eachData.consult) {
                oneRow.consult = "진행";
            } else {
                oneRow.consult = "미진행";
            }



            oneRow.etc = eachData.etc;

            if (!eachData.consult) {
                newRows.push(oneRow);
                return;
            }

            const report = eachData.report;
            const isReportNotDate = isNaN(new Date(report).getTime());
            const isReportDate = !isReportNotDate;

            if (isReportDate) {
                oneRow.report = "완료";
            } else {
                oneRow.report = report;
            }

            //send도 report랑 똑같이 진행
            const send = eachData.send;
            const isSendNotDate = isNaN(new Date(send).getTime());
            const isSendDate = !isSendNotDate;

            if (isSendDate) {
                oneRow.send = "완료";
            } else {
                oneRow.send = send;
            }

            //word도 report랑 똑같이 진행
            const word = eachData.word;
            const isWordNotDate = isNaN(new Date(word).getTime());
            const isWordDate = !isWordNotDate;

            if (isWordDate) {
                oneRow.word = "완료";
            } else {
                oneRow.word = word;
            }

            //test도 report랑 똑같이 진행
            const test = eachData.test;
            const isTestNotDate = isNaN(new Date(test).getTime());
            const isTestDate = !isTestNotDate;

            if (isTestDate) {
                oneRow.test = "완료";
            } else {
                oneRow.test = test;
            }

            if (eachData.print) {
                oneRow.print = "완료";
            } else {
                oneRow.print = "미완료";
            }

            newRows.push(oneRow);

        });

        setRows2([...newRows]);

    }

    const getConsultList = async () => {

        try {

            if (!value[0] || !value[1]) {
                return;
            }

            setLoading1(true);

            const startDate = value[0];
            const endDate = value[1];

            console.log(startDate, endDate);

            const startDateTime = startDate.getTime();
            const endDateTime = endDate.getTime();

            const body = {
                startDateTime,
                endDateTime
            }

            const response = await fetch("https://peetsunbae.com/dashboard/chart/viewconsultlist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(body)
            });

            const result = await response.json();

            console.log(result);

            if (result.message === "success") {
                const data = result.data;
                setData(data);

                const newRows: any = [];

                data.forEach((eachData: any) => {

                    const oneRow: any = {};
                    oneRow.id = eachData.id;

                    oneRow.isFinish = eachData.isFinish ? "완료" : "미완료";

                    if (eachData.location === "대치3층") {
                        eachData.location = "대치점";
                    }

                    oneRow.location = eachData.location;
                    oneRow.name = eachData.name;

                    const date = new Date(eachData.createdAt);
                    const justDate = date.getFullYear() + "년 " + (date.getMonth() + 1) + "월 " + date.getDate() + "일";
                    oneRow.date = justDate;

                    if (eachData.isFinish) {
                        const finishDate = new Date(eachData.finishTime);
                        const finishTimeString = addZeroIfOne(finishDate.getHours()) + ":" + addZeroIfOne(finishDate.getMinutes());
                        oneRow.finishedAt = finishTimeString;
                    }

                    oneRow.totalNumber = eachData.data.length;

                    var consultNumber = 0;

                    eachData.data.forEach((eachData: any) => {
                        if (eachData.consult) {
                            consultNumber++;
                        }
                    });

                    oneRow.consultNumber = consultNumber;
                    oneRow.consultRate = (consultNumber / oneRow.totalNumber * 100).toFixed(2) + "%";

                    oneRow.opinion = eachData.opinion;

                    if (eachData.didOnlineQuestions) {
                        oneRow.didOnlineQuestions = "완료";
                    } else {
                        oneRow.didOnlineQuestions = "미완료";
                    }

                    oneRow.whyNotDoneOnlineQuestions = eachData.whyNotDoneOnlineQuestions;

                    newRows.push(oneRow);

                })

                setRows1([...newRows]);
            }

            setLoading1(false);

        } catch (e) {
            console.log(e);
        }
    }

    const goLink = (link: string) => {
        window.open(link, "_blank");
    }

    return (
        <div>
            <div style={{
                marginTop: "24px",
                marginBottom: "12px",
                display: "flex",
                justifyContent: "space-between",
                width: "1500px"
            }}>
                <div>
                    <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            startText="시작일"
                            endText="마지막일"
                            value={value}
                            onChange={(newValue: any) => {
                                setValue(newValue);
                                if (newValue[0] && newValue[1]) {
                                    setDisabled(false);
                                } else {
                                    setDisabled(true);
                                }
                            }}
                            renderInput={(startProps: any, endProps: any) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}

                        />
                    </LocalizationProvider>
                </div>
                <div>
                    {
                        (props.user && props.user.academy === "sunbae")
                        &&
                        <>
                         <Button variant="outlined" color="neutral" sx={{marginRight : "18px"}}
                    onClick={() => {
                        goLink("https://peetsunbae-my.sharepoint.com/:x:/g/personal/peetsunbae_peetsunbae_onmicrosoft_com/EcXgzISrs1pEtx9CcaI4QlABMT_snh1BfsAuHApDaXAk3w?e=5bVEXb");
                    }}
                    >
                        강남점 상담스케쥴
                    </Button>
                    <Button variant="outlined" color="neutral"
                    onClick={() => {
                        goLink("https://peetsunbae-my.sharepoint.com/:x:/g/personal/peetsunbae_peetsunbae_onmicrosoft_com/EaTZjfQnpFFEgDSitlZfkVcBrnAVgI9y8VuA5T8H2IlWww?e=Ccp2TZ");
                    }}
                    >
                        대치점 상담스케쥴
                    </Button>
                        </>
                    }
                   
                </div>
            </div>
            <div style={{
                marginBottom: "24px"
            }}>
                <Button disabled={disabled} variant='solid' color="primary"
                    onClick={() => {
                        getConsultList();
                    }}
                >조회하기</Button>
            </div>

            <div style={{
                width: "1500px",
                height: "400px",
                marginBottom: "20px"
            }} className={classes.root}>
                <DataGridPremium
                    rows={rows1}
                    columns={columns1}
                    density='compact'
                    loading={loading1}
                    getCellClassName={(params) => {

                        if (params.field === "isFinish") {
                            if (params.value === "완료") {
                                return "once";
                            } else {
                                return "never";
                            }
                        }

                        if (params.field === "didOnlineQuestions") {
                            if (params.value === "완료") {
                                return "";
                            } else {
                                return "never2";
                            }
                        }

                        return ""
                    }}
                    apiRef={apiRef}
                    onRowSelectionModelChange={(params) => {
                        console.log(params);
                        if (!params || params.length === 0) {
                            return;
                        }
                        setSelectedDataId(+params[0]);
                    }}
                />
            </div>
            <div
                style={{
                    width: "1500px",
                    height: "350px",
                    marginBottom: "20px"
                }}
                className={classes.root}
            >
                <DataGridPremium
                    rows={rows2}
                    columns={columns2}
                    density='compact'
                    getCellClassName={(params) => {
                        if (params.field === "consult") {
                            if (params.value === "진행") {
                                return "once";
                            } else {
                                return "never";
                            }
                        }

                        if (params.field === "report" || params.field === "send" || params.field === "word" || params.field === "test" || params.field === "print") {
                            if (params.value === "완료") {
                                return "";
                            } else {
                                return "never2";
                            }
                        }

                        return ""
                    }}
                />
            </div>
        </div>
    )

}

export default ViewConsultList;
