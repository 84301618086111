import { IonLoading, IonToast, useIonActionSheet } from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import { getFetch, postFetch } from "../../fetch/fetch";
import styles from "./mobilecheckstatus.module.css";

import { ReactComponent as Check } from "../../../src/svg/check-light.svg";
import { ReactComponent as Xmark } from "../../../src/svg/xmark-light.svg";
import { ReactComponent as ArrowUp } from "../../../src/svg/arrow-up-light.svg";
import { ReactComponent as Mail } from "../../../src/svg/mobiletotal/paper-plane-light.svg";
import { returnDayString } from "../etc/functions";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { ReactComponent as Trash } from "../../../src/svg/mobiletotal/trash-can-xmark-light.svg";

const MobileCheckStatusStudent: React.FC<any> = (props) => {

    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(false);
    const statusTopRef = useRef<HTMLDivElement>(null);
    const [bodyHeight, setBodyHeight] = useState(0);
    const history = useHistory();
    const [today, setToday] = useState(new Date());
    const [showToast1, setShowToast1] = useState(false);
    const [present] = useIonActionSheet();

    const confirmDelete = (enrolledId : number, targetDateTime : Date) => {
        present({
            header: "예약 취소 안내",
            subHeader: "예약내역을 삭제할까요?",
            buttons: [{
                text: "삭제",
                role: "destructive"
            },
            {
                role: "cancel",
                text: "아니오",
            }
            ],
            onDidDismiss: ({ detail }) => {
                console.log(detail.role);
                switch (detail.role) {
                    case "destructive":
                        deleteEnrolledOfflineQuestion(enrolledId, targetDateTime);
                        break;
                    case "cancel":
                        break;
                }
            },
        })
    }

    const deleteEnrolledOfflineQuestion = async (enrolledId: number, targetDateTime: Date) => {

        try {

            const targetDate = new Date(targetDateTime);

            //오늘이 아니면 삭제 불가능
            if (today.getFullYear() !== targetDate.getFullYear() || today.getMonth() !== targetDate.getMonth() || today.getDate() !== targetDate.getDate()) {
                console.log("notToday");
                return;
            }

            setLoading(true);

            const result : any = await postFetch("/dashboard/checklist/deleteenrollchecklist", { enrolledId });

            setLoading(false);

            if(result.message === "success"){

                setShowToast1(true);
                getEnrolledOfflineQuestion();

            }


        } catch (e) {
            console.log(e);
        }


    }

    const calcBodyHeight = () => {
        if (!statusTopRef || !statusTopRef.current) {
            return;
        }

        if (!props.statusDivHeight) {
            return;
        }

        const totalHeight = props.statusDivHeight;
        const bodyHeight = totalHeight - statusTopRef.current.offsetHeight;

        console.log(totalHeight);
        console.log(statusTopRef.current.offsetHeight);
        console.log(bodyHeight);

        setBodyHeight(bodyHeight);
    }

    useEffect(() => {

        setTimeout(() => {
            calcBodyHeight();
        }, 30);


    }, [props.statusDivHeight]);

    const getEnrolledOfflineQuestion = async () => {

        setLoading(true);


        try {
            const result: any = await getFetch("/dashboard/checklist/mytotalenrollchecklist");

            console.log(result.data);

            setData(result.data);

        } catch (e) {
            console.log(e);
        }

        setTimeout(() => {
            setLoading(false);
        }, 50);
    }

    useEffect(() => {
        getEnrolledOfflineQuestion();
    }, []);

    const sendMessage = (enrolledId : string, targetDateTime : Date, teacherName : string) => {

        if(!enrolledId || !targetDateTime || !teacherName){
            console.log("error");
            return;
        }

        //오늘이 아니면 return
        const targetDate = new Date(targetDateTime);

        if (today.getFullYear() !== targetDate.getFullYear() || today.getMonth() !== targetDate.getMonth() || today.getDate() !== targetDate.getDate()) {
            console.log("notToday");
            return;
        }


        console.log(enrolledId, targetDate, teacherName);

        history.push({
            pathname: "/mobiledashboard/checkwrite",
            state: {
                enrolledId,
                teacherName
            }
        })

    }

    return (
        <div className={styles.statusBody} style={{ height: `${props.statusDivHeight}px`, overflow: "scroll" }}>

            <div className={styles.statusTop} ref={statusTopRef}>
                <div className={styles.statusTitle}>
                    계획표 검사 신청 현황
                </div>
                <div className={styles.statusNumber}>
                    {/* # 신청 횟수 - {data && data.length}회 */}
                </div>
            </div>

            {
                loading
                &&
                <div style={{ width: "90.6%", margin: "0 auto" }}>
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                </div>
            }

            {
                !loading
                &&
                <div className={styles.description}>
                    <div className={styles.descriptionBody} style={{ minHeight: `${bodyHeight}px` }}>

                        {
                            data &&
                            data.map((eachCheckResult: any, index: number) => {

                                console.log(eachCheckResult);

                                eachCheckResult.teacherName = " 검사조교";
                                eachCheckResult.uploadTeacherDescription = "(시간별 최대 신청자수 제한)";
                                eachCheckResult.subject = "하원 전 검사 필수";

                                const targetDate = new Date(eachCheckResult.targetDate);

                                const month = targetDate.getMonth() + 1;
                                const date = targetDate.getDate();
                                var targetDateDay = returnDayString(targetDate.getDay());

                                const targetDateString = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일 ${targetDateDay}요일`;

                                const newDate = new Date(+eachCheckResult.enrolledId);
                                const startHours = newDate.getHours();
                                
                                const intervalTime = eachCheckResult.intervalTime;

                                newDate.setMinutes(newDate.getMinutes() + intervalTime);

                                const endHours = newDate.getHours();

                                const targetHoursString = `${startHours}시-${endHours}시`;


                                var isTargetDateToday = false;

                                if (today.getFullYear() === targetDate.getFullYear() && today.getMonth() === targetDate.getMonth() && today.getDate() === targetDate.getDate()) {
                                    isTargetDateToday = true;
                                }



                                return (
                                    <div key={eachCheckResult.id}>
                                        <div className={`${styles.eachDescription} ${data.length - 1 === index ? styles.last : ""}`}>
                                            <div className={styles.eachDescriptionDate}>
                                                계획표 검사 신청
                                            </div>
                                            <div className={styles.eachDescriptionBox}>
                                                <div className={styles.eachDescriptionText}>
                                                    {eachCheckResult.teacherName} 
                                                </div>
                                                <div className={styles.eachDescriptionText}>
                                                    [{eachCheckResult.uploadTeacherDescription}]
                                                </div>
                                                <div className={styles.eachDescriptionTargetDate}>
                                                    {eachCheckResult.subject}
                                                </div>
                                                <div className={styles.eachDescriptionKindDiv}>
                                                    <div className={styles.eachDescriptionKindText}>
                                                        [{targetDateString}]
                                                        &nbsp;{targetHoursString}
                                                    </div>
                                                    <div className={`${styles.eachDescriptionKindSuccess} 
                                                    ${eachCheckResult.status === "notCompleted" ? styles.decline : eachCheckResult.status === "completed" ? styles.success : ""}
                                                    `}>
                                                        {
                                                            (eachCheckResult.status === "completed") &&
                                                            <Check fill="#fff" className={styles.check}></Check>
                                                        }
                                                        {
                                                            (eachCheckResult.status === "notCompleted") &&
                                                            <Xmark fill="#fff" className={styles.xmark}></Xmark>
                                                        }

                                                        &nbsp;
                                                        <span>
                                                            {
                                                                (eachCheckResult.status === "completed") &&
                                                                <div>
                                                                    검사 완료
                                                                </div>
                                                            }
                                                            {
                                                            (eachCheckResult.status === "notCompleted") &&
                                                                "미완료"
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                {
                                                    (isTargetDateToday && eachCheckResult.status === "notCompleted") &&
                                                    <div className={styles.trashCanDiv2}
                                                    onClick={(e : any) => {
                                                        confirmDelete(eachCheckResult.enrolledId, eachCheckResult.targetDate);
                                                    }}
                                                    >
                                                        <Trash className={styles.trash2} />
                                                    </div>
                                                }
                                                {
                                                    isTargetDateToday &&
                                                    <div className={styles.trashCanDiv} onClick={(e: any) => { sendMessage(
                                                        eachCheckResult.enrolledId, eachCheckResult.targetDate, eachCheckResult.teacherName
                                                    ) }}>
                                                        <Mail className={styles.trash} />
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                );

                            })
                        }




                    </div>
                </div>
            }

            <IonToast
                isOpen={showToast1}
                onDidDismiss={() => { setShowToast1(false); }}
                message="😃  예약 취소 성공 !"
                duration={1500}
                position="top"
                color="primary"
            />



            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}
        </div>
    );
}

export default MobileCheckStatusStudent;