import React, { useEffect, useState, useRef } from "react";
import styles from "./mobilerestaurant.module.css";
import RadioGroup from '@mui/joy/RadioGroup';
import Radio, { radioClasses } from '@mui/joy/Radio';

import { returnDayString } from "../etc/functions";

import Sheet from '@mui/joy/Sheet';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { CssVarsProvider } from '@mui/joy/styles';

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { CircularProgress } from "@mui/material";

import { IonToast } from '@ionic/react';




const MobileRestaurantSubmit: React.FC<any> = () => {

    const [firstWeekInfo, setFirstWeekInfo] = useState<any>();
    const [secondWeekInfo, setSecondWeekInfo] = useState<any>();
    const [today, setToday] = useState(new Date());

    const [targetDateIndex, setTargetDateIndex] = useState<any>(0);
    const [whenType, setWhenType] = useState<any>("lunch");

    const whiteRef = useRef<any>(null);
    const wrapperRef = useRef<any>(null);
    const [translateValue, setTranslateValue] = useState("translate(3%, -109%)");

    const imageBoxRef = useRef<any>(null);
    const [height, setHeight] = useState(120);
    const [isSafeArea, setIsSafeArea] = useState(false);


    const [loading, setLoading] = useState(true);
    const [mealLoading, setMealLoading] = useState(false);

    const [chargedAmount, setChargedAmount] = useState(0);

    const [deadline, setDeadline] = useState<any>();
    const [selectedMealId, setSelectedMealId] = useState(0);
    const [selectedMealName, setSelectedMealName] = useState("");
    const [selectedMealPrice, setSelectedMealPrice] = useState(0);
    const [selectedRestaurantName, setSelectedRestaurantName] = useState("");

    const [mealMenu, setMealMenu] = useState<any>([]);

    const [lackOfBanlance, setLackOfBalance] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [timeOut, setTimeOut] = useState(false);
    const [success, setSuccess] = useState(false);
    const [nonSelect, setNonSelect] = useState(false);
    const [nonSelectMeal, setNonSelectMeal] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [showToast2, setShowToast2] = useState(false);


    useEffect(() => {

        const valueBottom = +getComputedStyle(document.documentElement).getPropertyValue("--sab").split("p")[0];

        if (valueBottom > 0) {
            setIsSafeArea(true);
        }

        console.log(window.innerWidth);

        const height = Math.floor((window.innerWidth * 120) / 375);

        console.log(height);

        setHeight(height);

    }, []);

    useEffect(() => {
        const newDate = new Date();
        const newFirstWeekInfo = [];

        for (var i = 0; i < 7; i++) {
            const oneRow: any = {};

            oneRow.date = newDate.getDate();
            oneRow.dayString = returnDayString(newDate.getDay());
            oneRow.today = false;
            if (i === 0) {
                oneRow.today = true;
            }
            newFirstWeekInfo.push(oneRow);
            newDate.setDate(newDate.getDate() + 1);
        }

        console.log(newFirstWeekInfo);

        setFirstWeekInfo([...newFirstWeekInfo]);

        const newSecondWeekInfo = [];

        for (var i = 0; i < 7; i++) {
            const oneRow: any = {};
            oneRow.date = newDate.getDate();
            oneRow.dayString = returnDayString(newDate.getDay());
            oneRow.today = false;
            newSecondWeekInfo.push(oneRow);
            newDate.setDate(newDate.getDate() + 1);
        }

        console.log(newSecondWeekInfo);

        setSecondWeekInfo([...newSecondWeekInfo]);

    }, []);

    useEffect(() => {
        const newDate = new Date();
        const newFirstWeekInfo = [];

        for (var i = 0; i < 7; i++) {
            const oneRow: any = {};

            oneRow.date = newDate.getDate();
            oneRow.dayString = returnDayString(newDate.getDay());
            oneRow.today = false;
            if (i === 0) {
                oneRow.today = true;
            }
            newFirstWeekInfo.push(oneRow);
            newDate.setDate(newDate.getDate() + 1);
        }

        console.log(newFirstWeekInfo);

        setFirstWeekInfo([...newFirstWeekInfo]);

        const newSecondWeekInfo = [];

        for (var i = 0; i < 7; i++) {
            const oneRow: any = {};
            oneRow.date = newDate.getDate();
            oneRow.dayString = returnDayString(newDate.getDay());
            oneRow.today = false;
            newSecondWeekInfo.push(oneRow);
            newDate.setDate(newDate.getDate() + 1);
        }

        console.log(newSecondWeekInfo);

        setSecondWeekInfo([...newSecondWeekInfo]);

    }, []);


    useEffect(() => {

        setSelectedMealName("");
        setSelectedRestaurantName("");
        setSelectedMealPrice(0);

        getInfo();

    }, [targetDateIndex, whenType]);


    const getInfo = async () => {

        setMealLoading(true);

        const dateValue = new Date(today.getTime());
        dateValue.setDate(dateValue.getDate() + targetDateIndex);

        if(!dateValue || !whenType){
            return;
        }

        var token : any = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }


        fetch(`https://peetsunbae.com/dashboard/restaurant/start?dateValue=${new Date(dateValue).getTime()}&whenType=${whenType}`, {
            method: "GET",
            headers: { "Authorization": token },
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    setChargedAmount(result.amount);
                    setMealMenu(result.meal);
                    setDeadline(result.deadline);
                    const array: any = [];
                    if (result.meal.length > 0) {
                        result.meal.forEach((each: any) => {
                            array.push(each.restaurantName);
                        });
                        const set: any = new Set(array);
                    }
                    setMealLoading(false);
                })
        })
    }

    const handleClickDate = (index: number) => {
        setTargetDateIndex(index);
    }

    const menuClickHandle = (index: number) => {

        console.log(22);

        if (!wrapperRef.current || !whiteRef.current) {
            console.log("noRef");
            return;
        }

        const wrapperWidth = wrapperRef.current.clientWidth;
        const whiteWidth = whiteRef.current.clientWidth;

        const value0 = whiteWidth * 0.03;
        const value2 = wrapperWidth - whiteWidth * 1.03;

        var newTranslateValue: any;
        switch (index) {
            case 0:
                newTranslateValue = value0;
                setWhenType("lunch");
                break;
            case 1:
                newTranslateValue = value2;
                setWhenType("dinner");
                break;
        }

        if (!newTranslateValue) {
            return;
        }

        const newTranslateValueText = `translate(${newTranslateValue}px, -109%)`;

        setTranslateValue(newTranslateValueText);
    }


    const handleMealClick = (e : any) => {
        const selectedId = e.target.value;
        console.log(e.target.value);

        var newRestaurantName = "";
        var newMealName = "";
        var newPrice = 0;

        if(!mealMenu){
            console.log("noMealMenu");
            return;
        }

        mealMenu.forEach((eachMeal : any) => {
            if(+eachMeal.id === +selectedId){
                newRestaurantName = eachMeal.restaurantName;
                newMealName = eachMeal.mealName;
                newPrice = eachMeal.mealPrice;
            }
        })

        setSelectedRestaurantName(newRestaurantName);
        setSelectedMealName(newMealName);
        setSelectedMealPrice(newPrice);
        setSelectedMealId(+selectedId);
    }


    const submit = async () => {

        console.log(1);
        console.log(whenType);
        console.log(selectedRestaurantName);
        console.log(selectedMealName);
        console.log(+chargedAmount < +selectedMealPrice);

        const dateValue = new Date(today.getTime());
        dateValue.setDate(dateValue.getDate() + targetDateIndex);

        if (+chargedAmount < +selectedMealPrice) {

            setToastMessage("😔 잔액이 부족합니다");
            setShowToast(true);

        } else if (!whenType) {

            setToastMessage("😔 점심/저녁을 골라주세요");
            setShowToast(true);

        } else if (!selectedMealName || !selectedMealId) {
            setToastMessage("😔 메뉴를 선택해주세요");
            setShowToast(true);
        } else if(!dateValue){
            setToastMessage("😔 날짜를 선택해주세요");
            setShowToast(true);
        } 
        else if (dateValue) {
            setLoading(true);
            const date = new Date(dateValue);
            console.log(date.getHours());
            console.log("====");
            console.log(date.getDate());
  

            var token : any = "";

            if (window.electron) {
                token = window.electron.sendMessageApi.getToken();
            }

            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                token = value;
            }


            fetch("https://peetsunbae.com/dashboard/restaurant/order", {
                method: "POST",
                headers: { "Authorization": token, "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({
                    targetYear: date.getFullYear(),
                    targetMonth: date.getMonth() + 1,
                    targetDate: date.getDate(),
                    whenType: whenType,
                    restaurantName: selectedRestaurantName,
                    mealName: selectedMealName,
                    price: selectedMealPrice,
                    mealId : selectedMealId
                })
            }).then((response) => {
                response.json()
                    .then((response) => {
                        setLoading(false);
                        console.log(response);
                        if (response.message === "TIME_OUT") {
                            setToastMessage("😔 주문 시간이 지났습니다. 점심 9:30, 저녁 14:30 까지");
                            setShowToast(true);
                        } else if (response.message === "LACK_BALANCE") {
                            setToastMessage("😔 잔액이 부족합니다");
                            setShowToast(true);
                        } else if (response.message === "success") {
                            setSuccess(true);
                            setShowToast2(true);
                        } else if (response.message === "DUPLICATE") {
                            setToastMessage("😔 해당 날짜에 이미 주문했습니다");
                            setShowToast(true);
                        }
                    })
            }).catch((error) => {
                console.log(error);
            })


        }
    }




    return (
        <div>
            <div className={styles.dateSelectBarDiv}>
                <div className={styles.dateSelectBar}>
                    <div className={styles.weekDiv}>
                        {
                            firstWeekInfo && firstWeekInfo.map((eachDate: any, index: number) => {
                                return (
                                    <div key={index} className={`${styles.eachDayDiv} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""} ${(index === targetDateIndex) ? styles.active : ""}`} onClick={(e: any) => { handleClickDate(index); }}>
                                        <div className={styles.eachDayDivWrapper}>
                                            <div className={`${styles.dateNumber} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""}`}>
                                                {eachDate.date}
                                            </div>
                                            <div className={`${styles.dayString} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""}`}>
                                                {eachDate.today ? "오늘" : eachDate.dayString}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className={styles.weekDiv}>
                        {
                            secondWeekInfo && secondWeekInfo.map((eachDate: any, index: number) => {
                                return (
                                    <div key={index} className={`${styles.eachDayDiv} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""} ${(index + 7 === targetDateIndex) ? styles.active : ""}`} onClick={(e: any) => { handleClickDate(index + 7); }}>
                                        <div className={styles.eachDayDivWrapper}>
                                            <div className={`${styles.dateNumber} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""}`}>
                                                {eachDate.date}
                                            </div>
                                            <div className={`${styles.dayString} ${(eachDate.dayString === "토" || eachDate.dayString === "일") ? styles.weekend : ""}`}>
                                                {eachDate.today ? "오늘" : eachDate.dayString}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>

            <div className={styles.justBorder}>

            </div>

            <div className={styles.body}>
                <div className={styles.kindSelectDiv} ref={wrapperRef}>
                    <div className={styles.studyTimeSelectMenuWrapper}>
                        <div className={`${styles.studyTimeSelectMenuText} ${whenType === "lunch" ? styles.active : ""}`} onClick={() => { menuClickHandle(0) }}>
                            점심
                        </div>
                        <div className={`${styles.studyTimeSelectMenuText} ${whenType === "dinner" ? styles.active : ""}`} onClick={() => { menuClickHandle(1) }}>
                            저녁
                        </div>
                    </div>
                    <div style={{ position: "relative" }}>
                        <div ref={whiteRef} className={styles.whiteWrapper} style={{ transform: translateValue }}>
                        </div>
                    </div>
                </div>

                <div className={styles.menuWrapper}>
                    {
                        !mealLoading &&
                        <CssVarsProvider>
                        <RadioGroup
                            onChange={(e : any) => {handleMealClick(e);}}
                            aria-label="platform"
                            defaultValue="Website"
                            overlay
                            name="platform"
                            sx={{
                                flexDirection: 'row',
                                gap: 2,
                                [`& .${radioClasses.checked}`]: {
                                    [`& .${radioClasses.action}`]: {
                                        inset: -1,
                                        border: '3px solid',
                                        borderColor: 'primary.500',
                                    },
                                },
                                [`& .${radioClasses.radio}`]: {
                                    display: 'contents',
                                    '& > svg': {
                                        zIndex: 2,
                                        position: 'absolute',
                                        top: '-8px',
                                        right: '-8px',
                                        bgcolor: 'background.body',
                                        borderRadius: '50%',
                                        // fill : "#263238"
                                    },
                                },
                            }}
                        >
                            {mealMenu && mealMenu.map((eachMenu : any) => (
                                <div key={eachMenu.id}>
                                    <Sheet
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 'md',
                                            bgcolor: 'background.level1',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1.5,
                                            width: height,
                                            height: height,
                                            position: "relative"
                                        }}
                                    >
                                        <Radio id={`${eachMenu.id}`} value={eachMenu.id} checkedIcon={<CheckCircleRoundedIcon />} />
                                        {/* <Avatar variant="soft" size="sm" />
                                    <FormLabel htmlFor={value}>{value}</FormLabel> */}
                                        <div className={styles.mealImageWrapper} ref={imageBoxRef}>
                                            <img className={styles.mealImage} src={`https://peetsunbae.com/${eachMenu.src.split("/public/")[1]}`} />
                                        </div>
                                    </Sheet>
                                    <div className={styles.mealDescription}>
                                        <div className={styles.restaurantName}>
                                            {eachMenu.restaurantName}
                                        </div>
                                        <div className={styles.mealName} style={{maxWidth : `${height}px`}}>
                                            {eachMenu.mealName}
                                        </div>
                                        <div className={styles.mealName}>
                                        {eachMenu.mealPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </RadioGroup>
                    </CssVarsProvider>
                    }
                    {
                        mealLoading &&
                        <div className={styles.loadingDiv} style={{height : height}}>
                            <CircularProgress />
                        </div>
                    }

                </div>
            </div>

            <div onClick={submit} className={`${styles.submitButton} ${isSafeArea ? styles.safeArea : ""}`}>
                도시락 주문하기
            </div>

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => {setShowToast(false); setToastMessage("");}}
                message = {toastMessage}
                duration={1500}
                position="top"
                color="danger"
            />

            <IonToast
                isOpen={showToast2}
                onDidDismiss={() => { setShowToast2(false); }}
                message= "😃 주문에 성공했습니다 !"
                duration={1500}
                position="top"
                color="primary"
            />

        </div>
    );
}

export default MobileRestaurantSubmit;