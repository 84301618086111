import React, { useEffect, useState } from 'react';
import { Route, HashRouter as Router, Link } from "react-router-dom"
import SignUp from './signup/signup';
import Login from './login/login';
import Dashboard from './dashboard/dashboard';
import { Socket } from "socket.io-client";
import Complete from './signup/complete';
import ChartForParent from './dashboard/components/chartforparent';
import QuestionExample from './etc/questionexample';
import Login2 from "./login/login2";
import Wordtest from "./etc/wordtest";
import Wordtestreal from "./etc/wordtestreal";
import RegularParentpermit from './etc/regularparentpermit';
import SuddenNoticepermit from './etc/suddennoticepermit';
import SendVoiceText from './etc/sendvoicetext';
import Blank from './dashboard/components/blank';

import "@ionic/react/css/core.css";

// import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";-

import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

import "../src/ionictheme/variable.css";

import { useMediaQuery } from "react-responsive";
import MobileLogin from './mobile/login/mobilelogin';

import { setupIonicReact } from "@ionic/react";
import MobileSignUp from './mobile/signup/mobilesignup';

import MobileCenter from './mobile/center/mobilecenter';
import { Capacitor } from '@capacitor/core';
import DiagnosisForParent from './dashboard/components/diagnosisforparent';

import { LicenseInfo } from '@mui/x-license-pro';

import KioskMain from './kiosk/kioskmain';
import WifiPermitForParent from './etc/wifipermit';

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");


setupIonicReact();

interface props {
  socket: Socket;
}

const App: React.FC<props> = (appProps) => {

  const [currentScreen, setCurrentScreen] = useState("");
  const isMobile = useMediaQuery({
    query: `(max-width : 1024px)`
  })
  const isNotPc = useMediaQuery({
    query : `(max-width : 1025px)`
  })



  useEffect(() => {
    if (isMobile || Capacitor.isNativePlatform()) {
      setCurrentScreen("mobile");
    } else {
      setCurrentScreen("pc");
    }

  }, [isMobile]);

  useEffect(() => {



  }, []);



  return (
    <Router>
      <div id="routing-container">
        <Route exact path="/chartforparent" component={ChartForParent}></Route>
        <Route exact path="/diagnosis" component={DiagnosisForParent}></Route>

        <Route exact path="/" component={currentScreen === "mobile" ? MobileLogin : currentScreen === "pc" ? Login : Blank}></Route>
        <Route path="/signup" component={currentScreen === "mobile" ? MobileSignUp : currentScreen === "pc" ? SignUp : Blank}></Route>
        <Route path="/complete" component={Complete}></Route>
        <Route path="/dashboard" render={(props) =><Dashboard socket={appProps.socket} {...props} />}/>
        <Route path="/view/questionexample" component={QuestionExample}></Route>
        <Route path="/login2" component={Login2}></Route>
        <Route path="/wordtest" component={Wordtest}></Route>
        <Route path="/wordtestreal" component={Wordtestreal}></Route>
        <Route path="/regularschedulepermit" component={RegularParentpermit}></Route>
        <Route path="/suddennoticepermit" component={SuddenNoticepermit}></Route>
        <Route path="/wifipermit" component={WifiPermitForParent}></Route>

        <Route path="/sendvoicetext" component={SendVoiceText}></Route>

        {/* mobile route */}
        <Route path="/mobiledashboard" render={(props) => <MobileCenter currentScreen={currentScreen} socket={appProps.socket} {...props} />}></Route>

        {/* kiosk route */}
        <Route path="/kiosk" render={(props) => <KioskMain socket={appProps.socket} {...props} />}></Route>
      </div>
    </Router>

  )
}

export default App;
