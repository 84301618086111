import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as CircleCheck } from "../../../src/svg/circle-check-solid.svg";
import InnerHeader from "../etc/innerheader";
import styles from "./mobilesuccess.module.css"

const MobileSuccess: React.FC<any> = (props) => {

    const [title, setTitle] = useState<number | null>();
    const [description, setDescription] = useState<string | null>();
    const location: any = useLocation();
    const headerRef = useRef<any>();
    const [height, setHeight] = useState(0);

    useEffect(() => {

        const newTitle = location.state.title;
        const newDescription = location.state.description;

        setTitle(newTitle);
        setDescription(newDescription);

    }, []);

    useEffect(() => {

        console.log(1);
        console.log(2);
        console.log(headerRef.current);

        if (!headerRef || !headerRef.current) {
            console.log("noRef");
            return;
        }

        const calcBodyHeight = () => {

            if (!headerRef || !headerRef.current) {
                console.log("noRef");
                return;
            }

            console.log("gogogo");

            const totalHeight = window.innerHeight;
            const safeAreaTop = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const headerHeight = headerRef.current.offsetHeight;
            const bodyHeight = totalHeight - headerHeight - safeAreaTop;

            console.log(bodyHeight);

            setHeight(bodyHeight);
        }

        calcBodyHeight();

        window.addEventListener("resize", () => calcBodyHeight);

        return () => window.removeEventListener("resize", () => calcBodyHeight);

    }, [headerRef]);

    return (
        <div>
            <InnerHeader headerRef={headerRef} title={title ? title : ""} home={location.state.home} />
            <div className={styles.body} style={{ height: `${height}px`}}>
                <div className={styles.checkDiv}>
                    <CircleCheck fill="#3d50b0" className={styles.circleCheck} />
                </div>
                <div className={styles.textDiv}>
                    <div className={styles.title}>
                        {
                            description && description
                        }
                    </div>
                </div>
                <div style={{height : "5rem"}}>

                </div>
            </div>
        </div>
    );
}

export default MobileSuccess;