import React, {useEffect, useState} from "react";

const KioskMain = (props : any) => {


    return (
        <div>
            <h1>Kiosk Main</h1>
        </div>
    )

}

export default KioskMain;