import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../etc/footer";
import styles from "./mobilechat.module.css";
import { IonLoading } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { IonApp } from "@ionic/react";
import { IonAvatar } from "@ionic/react";
import { Alert, Autocomplete, Avatar, Box, Button, LinearProgress, Modal, Stack, TextField } from "@mui/material";
import { ReactComponent as ShapeLogoSvg } from '../../../src/svg/onlyshape.svg';
import { englishAcademyNameToKorean, renderLogoSvg } from "../../data/locationFuntion";

interface EachData {
    id: number;
    count: number;
    name: string;
    unreadNumber: number;
    lastMessage: any;
    status: undefined | "noMessage";
    avatar: number;
}

interface data extends Array<EachData> { };

interface user {
    name: string;
    value: "student" | "teacher" | "parent" | "staff";
    id: number;
}



const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    borderRadius: "12px",
    boxShadow: 12,
    p: 1,
    paddingLeft: 2,
    paddingRight: 2
};


const MobileChat: React.FC<any> = (props) => {

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const menuHeaderRef: any = useRef(null);
    const footerRef: any = useRef(null);
    const [bodyHeight, setBodyHeight] = useState<any>(0);
    const [footerHeight, setFooterHeight] = useState<number>(0);
    const [platform, setPlatform] = useState("");
    const [users, setUsers] = useState<any>(null);
    const [data, setData] = useState<data | null>(null);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [selectedStudentName, setSelectedStudentName] = useState("");
    const [makeChatRoomActice, setMakeChatRoomActive] = useState(false);

    const [familyLastMessage, setFamilyLastMessage] = useState<any>(null);
    const [familyUnreadNumber, setFamilyUnreadNumber] = useState<number>(0);
    const [familyRoomTotalUsersCount, setFamilyRoomTotalUsersCount] = useState<number>(0);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setMakeChatRoomActive(false); setOpen(false); }


    useEffect(() => {
        console.log("gogogo")
        if (!menuHeaderRef || !footerRef || !menuHeaderRef.current || !footerRef.current) {
            console.log("not Go");
            return;
        }


        const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];

        const caculateBodyHeight = () => {

            if (!menuHeaderRef || !footerRef || !menuHeaderRef.current || !footerRef.current) {
                console.log("not Go");
                return;
            }

            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = menuHeaderRef.current.offsetHeight;
            const footerHeight = footerRef.current.offsetHeight;

            console.log(totalHeight);
            console.log(safeareaTop);
            console.log(headerHeight);
            console.log(footerHeight);
            setFooterHeight(footerHeight);

            const lastHeight = totalHeight - safeareaTop - headerHeight - footerHeight;
            console.log(lastHeight);
            console.log("lastHeight");
            setBodyHeight(lastHeight);
        }

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [menuHeaderRef, footerRef, platform]);



    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

        console.log(platform);
        console.log(platform === "ios");

    }, []);

    useEffect(() => {
        if (!props.user) {
            return;
        }

        startFunction();
    }, [props.user]);

    useEffect(() => {
        if(!props.user){
            return;
        }

        startFunction(true);
        
    }, [props.messageUpdate, props.familyMessageUpdate]);


    const startFunction = async (retry = false) => {

        if(!retry){
        setLoading(true);
        }

        if (props.user.value === "student" || props.user.value === "parent") {
            getChatRoomsForStudent();
        }
        if (props.user.value === "teacher" || props.user.value === "staff") {
            await getChatRoomForTeacher();
            await getTotalStudents();
        }
        setLoading(false);
    }

    const getChatRoomsForStudent = async () => {

        var token: any = "";

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            console.log(value);
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/envelope/getChatRoomsForStudent", {
            method: "GET",
            headers: {
                "Authorization": token
            },
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result.message);
                    const newData: any = [];

                    if (result.message === "NO_CHATROOM") {
                        const oneData: any = {};
                        oneData.status = "noMessage";
                        oneData.name = "수능선배 관리자";
                        oneData.unreadNumber = 0
                        newData.push(oneData);
                    }
                    if (result.message === "NO_MESSAGE") {
                        const oneData: any = {};
                        oneData.status = "noMessage";
                        oneData.name = "수능선배 관리자";
                        oneData.unreadNumber = 0
                        newData.push(oneData);
                    }
                    if (result.message === "FULL_INFO") {
                        const oneData: any = {};
                        oneData.lastMessage = result.data.lastMessage;
                        oneData.name = "수능선배 관리자";
                        oneData.unreadNumber = result.data.unreadNumber;
                        newData.push(oneData);
                    }

                    setData([...newData]);
                    setFamilyLastMessage(result.data.familyLastMessage);
                    setFamilyUnreadNumber(result.data.familyUnreadNumber);
                    setFamilyRoomTotalUsersCount(result.data.familyRoomTotalUsersCount);

                })
        })

    }


    const getChatRoomForTeacher = async () => {

        return new Promise(async (resolve, reject) => {


            var token: any = "";

            //네이티브 라면 토큰 가져오는 기능
            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                console.log(value);
                token = value;
            }

            fetch("https://peetsunbae.com/dashboard/envelope/getChatRooms", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include"
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        if (result.message === "success") {
                            console.log(result);
                            result.data.sort(function (a: any, b: any) {
                                if (new Date(a.lastMessage.createdAt).getTime() > new Date(b.lastMessage.createdAt).getTime()) {
                                    return -1;
                                } else {
                                    return 1;
                                }
                            })
                            setData(result.data);
                        }
                        resolve("success");
                    })
            }).catch((e) => {
                console.log(e);
                reject(e);
            })

        })

    }




    const getTotalStudents = async () => {

        return new Promise(async (resolve, reject) => {
            var token: any = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            //네이티브 라면 토큰 가져오는 기능
            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                console.log(value);
                token = value;
            }

            fetch("https://peetsunbae.com/dashboard/chart/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        console.log(result);
                        const rows: any = [];
                        if (result.data) {
                            result.data.forEach((each: any, index: number) => {
                                var data: any = {};
                                data.id = each.id;
                                data.label = each.name;
                                data.phoneNumber = each.phoneNumber;
                                data.value = each.value;
                                data.key = index;
                                if (data.value === "student") {
                                    rows.push(data);
                                }
                            })
                            setUsers([...rows]);
                        }
                        resolve("success");
                    })
            }).catch((e) => {
                console.log(e);
                reject(e);
            })

        })

    }

    //채팅방 클릭했을 때 처리
    const handleClickChatRoom = (chatRoomId: number, studentName: string) => {


        history.push({
            pathname: "/mobiledashboard/envelope/room",
            state: {
                chatRoomId,
                studentName,
            }
        })

        console.log("gogogo");

    }

    //가족방 클릭했을 때 처리
    const handleClickFamilyChatRoom = (chatRoomId: number, studentName: string, familyRoomTotalUsersCount : number) => {

        history.push({
            pathname: "/mobiledashboard/envelope/familyroom",
            state: {
                chatRoomId,
                studentName,
                familyRoomTotalUsersCount
            }
        })

        console.log("gogogo!");

    }



    const openChatRoom = async (e: any) => {

        if (!selectedUser) {
            alert("학생을 먼저 선택해주세요.");
            return;
        }

        var token : any = "";

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            console.log(value);
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/envelope/createroom", {
            method: "post",
            credentials: "include",
            headers: {
                "content-type": "application/json",
                "Authorization" : token
            },
            body: JSON.stringify({
                studentId: selectedUser.id
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);
                    if (result.message === "created") {
                        handleClickChatRoom(result.roomNumber, selectedUser.label);
                    }
                    if (result.message === "already") {
                        handleClickChatRoom(result.roomNumber, selectedUser.label);
                    }
                })
        })

    }

    const onchange = (e: any, value: any) => {
        console.log(value);
        if (value) {
            setSelectedStudentName(value.label);
        } else {
            setSelectedStudentName("");
        }
        setSelectedUser(value);
        if (value) {
            setMakeChatRoomActive(true);
        } else {
            setMakeChatRoomActive(false);
        }
    }


    return (

        <div>
            <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
                <div ref={menuHeaderRef} className={styles.headerWrapper}>
                    <div className={styles.headerDiv}>
                        <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`}>
                            <div className={styles.title}>
                                채팅
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loading
                &&
                <LinearProgress />
            }

            {
                !loading
                &&
                <div className={styles.body} style={{ height: `${bodyHeight}px`, overflow: "scroll" }}>
                    {
                        data && data.map((eachChatRoom: EachData, index: number) => {

                            var lastMessageMonth = 0;
                            var lastMessageDate = 0;

                            if (eachChatRoom.status !== "noMessage") {
                                const lastMessageCreatedAt = eachChatRoom.lastMessage.createdAt;
                                const date = new Date(lastMessageCreatedAt);
                                lastMessageMonth = date.getMonth() + 1;
                                lastMessageDate = date.getDate();
                            }


                            return (
                                <div key={eachChatRoom.id} className={styles.eachChatRoomDiv} onClick={(e: any) => { handleClickChatRoom(eachChatRoom.id, eachChatRoom.name) }}>
                                    <div className={styles.eachChatRoom}>
                                        <div className={styles.avatarDiv}>
                                            {
                                                eachChatRoom.name === "수능선배 관리자" &&
                                                <Avatar sx={{ bgcolor: "#fff", border: "#bdbdbd 1px solid" }}>
                                                    {/* <ShapeLogoSvg className={styles.shapeLogo} /> */}
                                                    {
                                                        (props.user && props.user.academy) &&
                                                        renderLogoSvg(props.user.academy, "shape", styles.shapeLogo)
                                                    }
                                                </Avatar>
                                            }
                                            {
                                                (eachChatRoom.name !== "수능선배 관리자" && !eachChatRoom.avatar) &&
                                                <Avatar sx={{ bgcolor: "#b0dbf1" }}>
                                                    <img src="img/user-solid.svg" alt="user" className="avatarImg" />
                                                </Avatar>
                                            }
                                            {
                                                (eachChatRoom.name !== "수능선배 관리자" && eachChatRoom.avatar) ?
                                                    <IonAvatar className={styles.avatar}>
                                                        <img alt="Silhouette of a person's head" src={`/img/avatar/Avatar-${eachChatRoom.avatar}.png`} className={styles.ionAvatarImg} />
                                                    </IonAvatar>
                                                    :
                                                    ""
                                            }
                                        </div>
                                        <div className={styles.descriptionDiv}>
                                            <div className={styles.nameDiv}>
                                                {(props.user && props.user.academy) && 
                                                eachChatRoom.name === "수능선배 관리자" ? englishAcademyNameToKorean(props.user.academy) + " 관리자" : eachChatRoom.name}
                                            </div>
                                            <div className={styles.messageDiv}>
                                                {
                                                    eachChatRoom.name !== "수능선배 관리자" &&
                                                    (eachChatRoom.lastMessage.message.includes("/home/centos/peetsunbae/dist") ? "사진" : eachChatRoom.lastMessage.message)
                                                }
                                                {
                                                    (eachChatRoom.name === "수능선배 관리자" && eachChatRoom.status !== "noMessage") &&
                                                    "미리보기를 제공하지 않습니다"
                                                }
                                                {
                                                    (eachChatRoom.status === "noMessage") &&
                                                    "클릭해서 채팅을 시작하세요"
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.dateDiv}>
                                            <div className={styles.dateText}>
                                                {
                                                    eachChatRoom.status !== "noMessage" &&
                                                    `${lastMessageMonth}월 ${lastMessageDate}일`
                                                }
                                                {
                                                    eachChatRoom.status === "noMessage" &&
                                                    "-"
                                                }
                                            </div>
                                            <div className={styles.unreadNumberDiv}>
                                                <div className={`${styles.unreadNumber} ${eachChatRoom.unreadNumber === 0 ? styles.numTransparent : ""}`}>
                                                    {eachChatRoom.unreadNumber}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    {
                        (props.user && (props.user.value === "student" || props.user.value === "parent") && data) &&
                        <div className={styles.eachChatRoomDiv} onClick={(e: any) => { handleClickFamilyChatRoom(props.user.id, props.user.name, familyRoomTotalUsersCount) }}>
                            <div className={styles.eachChatRoom}>
                                <div className={styles.avatarDiv}>
                                    <Avatar sx={{ bgcolor: "#fff", border: "#bdbdbd 1px solid" }}>
                                        <img src="img/family.svg" alt="user" className="avatarImg" />
                                    </Avatar>
                                </div>
                                <div className={styles.descriptionDiv}>
                                    <div className={styles.nameDiv}>
                                        가족 채팅방 💕 <span className={styles.familyRoomCount} style={{marginLeft : "4px", color : "#c0c0c0"}}>{familyRoomTotalUsersCount}</span>
                                    </div>
                                    <div className={styles.messageDiv}>
                                        {
                                            familyLastMessage ?
                                                (familyLastMessage.message.includes("/home/centos/peetsunbae/dist") ? "사진" : familyLastMessage.message)
                                                :
                                                "클릭해서 채팅을 시작하세요"
                                        }
                                    </div>
                                </div>
                                <div className={styles.dateDiv}>
                                    <div className={styles.dateText}>
                                        {
                                            familyLastMessage ?
                                                `${new Date(familyLastMessage.createdAt).getMonth() + 1}월 ${new Date(familyLastMessage.createdAt).getDate()}일`
                                                :
                                                "-"
                                        }
                                    </div>
                                    <div className={styles.unreadNumberDiv}>
                                        <div className={`${styles.unreadNumber} ${familyUnreadNumber === 0 ? styles.numTransparent : ""}`}>
                                            {
                                                familyUnreadNumber
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }



            <Footer footerRef={footerRef} location={"envelope"} unreadMessage={props.unreadMessage} unread={props.unread} />

            {
                (props.user && (props.user.value === "teacher" || props.user.value === "staff") &&
                    <Fab onClick={handleOpen} color="primary" aria-label="add" size="medium" sx={{ bottom: `${footerHeight + 30}px`, right: "1rem", position: "fixed" }}>
                        <AddIcon />
                    </Fab>

                )
            }

            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.messageTitle}>
                        채팅방 열기
                    </div>
                    <div className={styles.autocompleteDiv}>
                        <Autocomplete
                            onChange={onchange}
                            disablePortal
                            id="combo-box-demo"
                            options={users}
                            sx={{ width: "100%", borderRadius: "40px !important" }}
                            renderInput={(params) => <TextField {...params} sx={{ borderRadius: "24px" }} label={<span className={styles.nameText}>이름</span>} />}
                        />
                    </div>
                    {/* <div className={styles.textfieldDiv}>
                        <TextField value={message} onChange={(e) => { changeMessage(e) }} fullWidth id="outlined-basic" label={<span className={styles.nameText}>메세지</span>} variant="outlined" />
                    </div> */}


                    {loading &&
                        <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
                            <LinearProgress />
                        </Box>
                    }



                    <div className={styles.buttonDiv}>
                        <Button onClick={openChatRoom} disabled={!makeChatRoomActice} variant="contained"><span className={styles.buttonText}>시작하기</span></Button>
                    </div>
                </Box>
            </Modal>

        </div>
    );
}

export default MobileChat;