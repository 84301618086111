import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import koLocale from 'date-fns/locale/ko'
import styles from "../../../componentsStyle/totaledit.module.css"
import { GridRenderCellParams, DataGridPro, GridRowsProp, GridColDef, GridToolbar, LicenseInfo, useGridApiRef, GridFilterModel, DataGridPremium } from '@mui/x-data-grid-premium';
import { eachDayOfInterval } from 'date-fns';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { Autocomplete, Backdrop, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { IonItemSliding } from '@ionic/react';
import { Button, Radio, RadioGroup } from "@mui/joy";
import { Autorenew, HowToReg } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import totalLocationData from "../../../../data/locationdata";

LicenseInfo.setLicenseKey("7e2ba431ba4eff510b2133d8ca5534afTz02NTM0MixFPTE3MTQyMDk3Njk2NzgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

interface GridCellExpandProps {
    value: string;
    width: number;
}


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    }),
);


const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .pending': {
                    color: theme.palette.warning.main,
                },
                '& .permit': {
                    color: theme.palette.primary.main,
                },
                '& .decline': {
                    color: theme.palette.error.main,
                },
                '& .notPermit': {
                    color: "rgb(205, 205, 198)",
                },
            },
        };
    },
    { defaultTheme },
);


function isOverflown(element: Element): boolean {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}


const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps,
) {
    const { width, value } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);


    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current!);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <div ref={cellValue} className="cellValue">
                {value}
            </div>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );
});

function renderCellExpand(params: GridRenderCellParams<any>) {
    return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
}









const WifiPermit: React.FC<any> = (props) => {


    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [users, setUsers] = useState<any>([]);
    const columns: GridColDef[] = [
        { field: 'name', headerName: '이름', width: 100 },
        { field: 'location', headerName: '위치', width: 100 },
        { field: 'date', headerName: '해제날짜', width: 150, filterable: false },
        { field: 'agree', headerName: "학부모 승인", width: 150 },
        { field: 'startTime', headerName: "시작 시간", width: 150 },
        { field: 'endTime', headerName: "종료 시간", width: 150 },
        { field: 'reason', headerName: "사유", width: 150, filterable: false, renderCell: renderCellExpand },
        { field: 'time', headerName: "제출시각", width: 150, filterable: false },
        {
            field: 'forcepermit', headerName: "승인시키기", width: 150, filterable: false, renderCell: (params: any) => {

                console.log(params);
                const agree = params.row.agree;
                const id = params.row.id;

                if (params.row.agree === "대기중") {
                    return (
                        <Button
                            size="sm"
                            onClick={() => {
                                forcepermit(id);
                            }}
                        >
                            승인
                        </Button>
                    )

                } else {
                    return (
                        <div>
                            -
                        </div>
                    )
                }


            }
        },
        {
            field: 'forcecancel', headerName: "취소시키기", width: 150, filterable: false, renderCell: (params: any) => {

                console.log(params);
                const agree = params.row.agree;
                const id = params.row.id;
                const endDate = params.row.endDate;
                const endDateTime = new Date(endDate).getTime();

                const currentDate = new Date();
                const currentDateTime = currentDate.getTime();

                var isEnd = false;

                if (currentDateTime > endDateTime) {
                    isEnd = true;
                }

                if (!isEnd && params.row.agree !== "직원거절" && params.row.agree !== "승인거절") {
                    return (
                        <Button
                            size="sm"
                            color="danger"
                            onClick={() => {
                                forcecancel(id);
                            }}
                        >
                            취소
                        </Button>
                    )

                } else {
                    return (
                        <div>
                            -
                        </div>
                    )
                }

            }
        },
    ];

    const [value, setValue] = React.useState<DateRange<Date>>([new Date(), new Date()]);
    const [submitBool, setSubmitBool] = useState(false);
    const [rows, setRows] = useState<any>();
    const classes = useStyles2();
    const [loading, setLoading] = useState(false);
    const [alignment, setAlignment] = useState("total");
    const [update, setUpdate] = useState(0);
    const [update2, setUpdate2] = useState(0);
    const [update3, setUpdate3] = useState(0);

    const [textfieldValue, setTextfieldValue] = useState("");
    const [searchMenu, setSearchMenu] = useState("list");
    const [location, setLocation] = useState("gangnam");
    const [selectedLocation, setSelectedLocation] = useState("");
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [deviceList, setDeviceList] = useState<any>([]);
    const [searchText, setSearchText] = useState("");
    const [selectedMacAddr, setSelectedMacAddr] = useState("");
    const [totalAddressList, setTotalAddressList] = useState<any>([]);

    const [rows2, setRows2] = useState<GridRowsProp>([]);
    const columns2: GridColDef[] = [
        { field: 'ip', headerName: 'IP', width: 150 },
        { field: 'macaddr', headerName: '맥주소', width: 200 },
        { field: "deviceName", headerName: "기기이름", width: 200 },
        { field: "hostname", headerName: "등록된 이름", width: 200 }
    ];

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            {
                id: 2, field: "location", operator: "contains", value: "",
            },
            {
                id: 3, field: "name", operator: "contains", value: ""
            }
        ],
    });

    const [filterModel2, setFilterModel2] = React.useState<GridFilterModel>({
        items: [
            {
                id: 1, field: "macaddr", operator: "contains", value: "",
            }
        ],
    });
    const [isRandomizedMacAddress, setIsRandomizedMacAddress] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const notify1 = () => toast.success("주소 등록 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });


    useEffect(() => {
        props.activateMenuList("wifipermit");
    }, []);

    useEffect(() => {

        submit();

    }, [update]);

    const forcecancel = async (id: number) => {

        try {

            setLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/wifipermit/forcecancel", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: id
                })
            });

            const result = await response.json();

            console.log(result);

            if (result.message !== "success") {
                new Error("승인 실패");
            }

            if (result.message === "success") {
                setUpdate(Math.random());
                setLoading(false);
            }

        } catch (e) {
            console.log(e);
        }

    }

    const forcepermit = async (id: number) => {

        try {

            setLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/wifipermit/forcepermit", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: id
                })
            });

            const result = await response.json();

            console.log(result);

            if (result.message !== "success") {
                new Error("승인 실패");
            }

            if (result.message === "success") {
                setUpdate(Math.random());
                setLoading(false);
            }

            if (result.message === "ALREADY_DECIDED") {
                alert("이미 처리된 내역입니다");
                setLoading(false);
            }

        } catch (e) {
            console.log(e);
        }
    }

    const submit = async () => {

        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/wifipermit/total", {
            method: "POST",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                message: value
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);

                    if (result.message !== "success") {
                        return;
                    }

                    var data: any = [];
                    result.data.forEach((each: any, index: number) => {

                        const startDate = new Date(each.startDate);
                        const endDate = new Date(each.endDate);

                        const startHours = startDate.getHours() < 10 ? "0" + startDate.getHours() : startDate.getHours();
                        const startMinutes = startDate.getMinutes() < 10 ? "0" + startDate.getMinutes() : startDate.getMinutes();
                        const endHours = endDate.getHours() < 10 ? "0" + endDate.getHours() : endDate.getHours();
                        const endMinutes = endDate.getMinutes() < 10 ? "0" + endDate.getMinutes() : endDate.getMinutes();

                        const startTimeString = `${startHours} : ${startMinutes}`;
                        const endTimeString = `${endHours} : ${endMinutes}`;

                        var oneRow: any = {};
                        oneRow.id = each.id;
                        oneRow.name = each.name;
                        oneRow.location = each.location;
                        oneRow.date = `${new Date(each.startDate).getMonth() + 1}월 ${new Date(each.startDate).getDate()}일`;
                        oneRow.createdAt = each.createdAt
                        oneRow.startTime = startTimeString;
                        oneRow.endTime = endTimeString;
                        oneRow.reason = each.description;
                        each.time = new Date(each.createdAt).getTime();
                        oneRow.time = `${(new Date(+each.time).getMonth() + 1) < 10 ? "0" + (new Date(+each.time).getMonth() + 1) : (new Date(+each.time).getMonth() + 1)}/${new Date(+each.time).getDate() < 10 ? "0" + new Date(+each.time).getDate() : new Date(+each.time).getDate()} ${new Date(+each.time).getHours() < 10 ? "0" + new Date(+each.time).getHours() : new Date(+each.time).getHours()}:${new Date(+each.time).getMinutes() < 10 ? "0" + new Date(+each.time).getMinutes() : new Date(+each.time).getMinutes()}:${new Date(+each.time).getSeconds() < 10 ? "0" + new Date(+each.time).getSeconds() : new Date(+each.time).getSeconds()}`;
                        oneRow.endDate = each.endDate;

                        if (each.permitstatus === 1) {
                            oneRow.agree = "승인완료";
                        }
                        if (each.permitstatus === 2) {
                            oneRow.agree = "승인거절";
                        }
                        if (each.permitstatus === 0) {
                            oneRow.agree = "대기중";
                        }
                        if (each.permitstatus === 3) {
                            oneRow.agree = "직원승인";
                        }
                        if (each.permitstatus === 4) {
                            oneRow.agree = "직원거절";
                        }

                        data.push(oneRow);
                    })
                    console.log(data);


                    setRows([...data]);
                })
        })

    }

    const handleChange = (event: React.MouseEvent, newAlignment: string) => {

        console.log(newAlignment);

        setAlignment(newAlignment);

        const newFilterModel = filterModel;

        // if (newAlignment === "total") {
        //     newFilterModel.items.forEach((each: any) => {
        //         if (each.id === 2) {
        //             each.value = ""
        //         }
        //     })
        // }
        // if (newAlignment === "gangnam") {
        //     newFilterModel.items.forEach((each: any) => {
        //         if (each.id === 2) {
        //             each.value = "강남점"
        //         }
        //     })
        // }
        // if (newAlignment === "daechi") {
        //     newFilterModel.items.forEach((each: any) => {
        //         if (each.id === 2) {
        //             each.value = "대치점"
        //         }
        //     })
        // }

        // if (newAlignment === "daechi2") {
        //     newFilterModel.items.forEach((each: any) => {
        //         if (each.id === 2) {
        //             each.value = "대치3층"
        //         }
        //     })
        // }

        // if (newAlignment === "daechi3") {
        //     newFilterModel.items.forEach((each: any) => {
        //         if (each.id === 2) {
        //             each.value = "대치6층"
        //         }
        //     })
        // }

        totalLocationData.forEach((each) => {

            if (each.english === newAlignment) {
                newFilterModel.items.forEach((each2: any) => {
                    if (each2.id === 2) {
                        each2.value = each.filterValue_contains;
                    }
                })
            }

        });

        setFilterModel({ ...newFilterModel });

    }

    const textFieldChange = (e: any) => {

        setTextfieldValue(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 3) {
                each.value = e.target.value;
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    useEffect(() => {

        console.log("---------");
        async function start() {

            var token = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/avatar/change/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        console.log(result);
                        const rows: any = [];
                        result.data.forEach((each: any, index: number) => {
                            var data: any = {};
                            data.id = each.id;
                            data.label = `${each.name} <${each.location}>`;
                            rows.push(data);
                        })
                        setUsers([...rows]);

                    })
            })
        }

        start();

    }, []);

    const onchange = async (e: any, value: any) => {
        if (!users) {
            return;
        }

        if (!value) {
            setSelectedUser(null);
            setSelectedLocation("");
            return;
        }

        console.log(value);

        console.log("selectedChange");
        setSelectedUser(value);


        //value.labe의 <> 속에 있는 값을 가져온다.
        const location = value.label.split("<")[value.label.split("<").length - 1].split(">")[0];
        console.log(location);
        setSelectedLocation(location);

    }

    useEffect(() => {

        if (!selectedUser) {
            setSelectedMacAddr("");
            setSelectedLocation("");
            setDeviceList([]);
            return;
        }

        const userId = selectedUser.id;

        if (!userId) {
            return;
        }

        setSelectedMacAddr("");
        setDeviceList([]);
        getUserDeviceList(userId);

    }, [selectedUser, update3]);

    useEffect(() => {

        if (!selectedLocation) {
            return;
        }

        setSelectedMacAddr("");

        getTotalDhcpList(selectedLocation);

    }, [selectedLocation, update2]);

    const getUserDeviceList = async (userId: number) => {

        setLoading2(true);
        var token: any = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }


        fetch("https://peetsunbae.com/dashboard/wifipermit/statusForTeacher?userId=" + userId, {
            method: "GET",
            headers: { "Authorization": token },
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);

                    if (result.message !== "success") {
                        return;
                    }

                    const deviceList = result.deviceList;
                    setDeviceList([...deviceList]);


                    setLoading2(false);
                })
        })
    }

    const getTotalDhcpList = async (location: string) => {

        if (!location) {
            return;
        }

        setLoading3(true);

        fetch("https://peetsunbae.com/dashboard/wifipermit/getTotalDhcpList?location=" + selectedLocation, {
            method: "GET",
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    console.log(result);

                    if (result.message !== "success") {
                        return;
                    }

                    const totalDhcpList = result.totalDhcpList;
                    const totalAddressList = result.totalAddressList;

                    console.log(totalDhcpList);
                    console.log(totalAddressList);

                    setTotalAddressList([...totalAddressList]);

                    totalDhcpList.forEach((each: any, index: number) => {
                        totalAddressList.forEach((each2: any, index2: number) => {

                            if (!each2.macaddr) {
                                return;
                            }

                            if (each2.macaddr.length === 0) {
                                return;
                            }

                            if (!each2.macaddr[0].macaddr) {
                                return;
                            }

                            const eachMacaddr = each2.macaddr[0].macaddr;

                            if (each.mac === eachMacaddr) {
                                each.name = each2.name;
                            }
                        })
                    });

                    const newRows: any = [];

                    totalDhcpList.forEach((each: any, index: number) => {

                        const oneRow: any = {};

                        oneRow.id = index;
                        oneRow.ip = each.ip;
                        oneRow.macaddr = each.mac;
                        oneRow.deviceName = each.hostname;
                        oneRow.hostname = each.name;

                        newRows.push(oneRow);

                    });

                    setRows2([...newRows]);
                    setLoading3(false);
                })
        })

    }

    const letsUpdate2 = () => {
        const randomNum = Math.random();
        setUpdate2(randomNum);
    }

    const handleSearchTextChange = (e: any) => {

        console.log(e.target.value);

        setSearchText(e.target.value);

        const newFilterModel = filterModel2;

        console.log(newFilterModel)

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 1) {
                each.value = e.target.value;
            }
        })

        console.log(newFilterModel)

        setFilterModel2({ ...newFilterModel });

    }

    useEffect(() => {

        if (!selectedMacAddr) {
            setIsRandomizedMacAddress(false);
        }

        //macaddr의 두번째 글자가 2, 6, A, E 일 경우 랜덤화된 맥주소라고 판단

        if (selectedMacAddr) {
            if (selectedMacAddr[1] === "2" || selectedMacAddr[1] === "6" || selectedMacAddr[1] === "a" || selectedMacAddr[1] === "e") {
                setIsRandomizedMacAddress(true);
            } else {
                setIsRandomizedMacAddress(false);
            }
        }

    }, [selectedMacAddr]);

    useEffect(() => {

        if(!selectedMacAddr) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

    }, [selectedMacAddr]);


    const submitAddress = async () => {

        if(!window.confirm("주소를 등록하시겠습니까?")) {
            return;
        }

        const macaddr = selectedMacAddr;

        if(!macaddr) {
            return;
        }

        const user = selectedUser;

        if(!user) {
            return;
        }

        var userName = user.label.split("<")[0].trim();
        const userId = user.id;

        if(!userName) {
            return;
        }

        if(!userId) {
            return;
        }

        console.log(userName);

        //Invalid characters: < > ( ) # ' ".

        userName = userName.replace("<", "");
        userName = userName.replace(">", "");
        userName = userName.replace("(", "");
        userName = userName.replace(")", "");
        userName = userName.replace("#", "");
        userName = userName.replace("'", "");
        userName = userName.replace('"', "");
        userName = userName.replace(".", "");
        userName = userName.replace(" ", "");
        userName = userName.replace("_", "");

        var nameNumberSelectFinish = false;

        var i = 1;

        console.log(totalAddressList);

        while (!nameNumberSelectFinish) {

            var isExist = false;

            totalAddressList.forEach((each: any, index: number) => {
                console.log(each.name, `${userName}${i}`);
                if (each.name === `${userName}${i}_${userId}`) {
                    console.log("exist");
                    isExist = true;
                }
            })

            if (!isExist) {
                nameNumberSelectFinish = true;
            } else {
                i++;
            }

        }

        const finalName = `${userName}${i}_${userId}`;

        console.log(macaddr, finalName, userId);

        fetch("https://peetsunbae.com/dashboard/wifipermit/postUserAddress", {
            method : "post",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                macaddr, name : finalName, userId
            })
        }).then((response : any) => {
            response.json().then((result : any) => {

                if(result.message !== "success") {
                    alert("주소 등록 실패");
                    return;
                }

                notify1();
                setUpdate3(Math.random());
                setUpdate2(Math.random());

            })
        })
        
    }




    return (
        <div className="main">
            <div className="title" style={{
                display: "flex", alignItems: "center",
                marginBottom: "50px"
            }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "6px",
                        marginRight: "10px"
                    }}
                >
                    <img src="img/off/wifipermit.svg" alt="playlist" style={{ width: "18px" }} />
                </div>
                <div>방화벽 해제</div>
            </div>

            <div className={styles.searchMenu}>
                <div onClick={(e) => { setSearchMenu("list") }} className={`${styles.searchMenuDiv} ${searchMenu === "list" ? styles.active : ""}`}>
                    해제리스트
                </div>
                <div onClick={(e) => { setSearchMenu("submit") }} className={`${styles.searchMenuDiv} ${searchMenu === "submit" ? styles.active : ""}`}>
                    주소등록하기
                </div>
            </div>

            {
                searchMenu === "list" &&
                <>
                    <div className={styles.datePicker}>
                        <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                            <DateRangePicker
                                startText="시작일"
                                endText="마지막일"
                                value={value}
                                onChange={(newValue: any) => {
                                    setValue(newValue);
                                    if (newValue[0] && newValue[1]) {
                                        setSubmitBool(true);
                                    }
                                }}
                                renderInput={(startProps: any, endProps: any) => (
                                    <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <TextField {...endProps} />
                                    </React.Fragment>
                                )}

                            />
                        </LocalizationProvider>
                    </div>

                    {submitBool ?
                        <div onClick={submit} className={styles.totalCheckBtn}>
                            조회하기
                        </div>
                        :
                        <div className={styles.disableTotalCheckBtn}>
                            조회하기
                        </div>
                    }

                    <div style={{ marginTop: "24px", display: "flex", justifyContent: "space-between", width: "1500px" }}>
                        <div>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                            >
                                {/* <ToggleButton value="total">ALL</ToggleButton>
                                <ToggleButton value="gangnam">강남점</ToggleButton>
                                <ToggleButton value="daechi">대치점</ToggleButton>
                                <ToggleButton value="daechi2">대치3층</ToggleButton>
                                <ToggleButton value="daechi3">대치6층</ToggleButton> */}
                                {
                                    props.user && totalLocationData.filter((each) => each.academy.includes(props.user.academy)).map((each, index) => {
                                        return (
                                            <ToggleButton key={index} value={each.english}>{each.korean}</ToggleButton>
                                        );
                                    })
                                }
                            </ToggleButtonGroup>
                        </div>
                        <div>
                            <TextField value={textfieldValue} variant="standard" placeholder='이름을 검색하세요' onChange={textFieldChange} />
                        </div>
                    </div>

                    <div className={styles.dataGrid}>
                        {rows &&
                            <div style={{ height: 400, width: '1500px' }}>
                                <div style={{ display: "flex", height: "100%" }}>
                                    <div style={{ flexGrow: 1 }} className={classes.root}>
                                        <DataGridPremium
                                            filterModel={filterModel}
                                            onFilterModelChange={(newModel) => { setFilterModel(newModel); }}
                                            rowHeight={40}
                                            rows={rows}
                                            columns={columns}
                                            components={{ Toolbar: GridToolbar }}
                                            getCellClassName={(params: any) => {
                                                if (params.field != "agree") {
                                                    return '';
                                                } else if (!params.value) {
                                                    return '';
                                                } else {
                                                    console.log(params.value);
                                                    if (params.value === "승인완료") {
                                                        console.log("call");
                                                        return "permit"
                                                    } else if (params.value === "승인거절") {
                                                        return "decline"
                                                    } else if (params.value === "직원승인") {
                                                        return "permit"
                                                    } else if (params.value === "직원거절") {
                                                        return "decline"
                                                    } else {
                                                        return "pending"
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </>
            }
            {
                searchMenu === "submit" &&
                <div>
                    <div style={{
                        fontSize: "14px",
                        marginBottom: "12px",
                        color: "gray"
                    }}>
                        ** 학생 기기에서 랜덤 맥주소를 끄고 기기 맥주소를 사용하도록 설정해주세요.
                    </div>
                    <div style={{
                        marginBottom: "32px"
                    }}>
                        <div style={{
                            fontSize: "20px",
                            fontFamily: "Apple_SB",
                            marginBottom: "18px"
                        }}>
                            - 학생 선택
                        </div>
                        <div style={{
                            display: "flex",
                        }}>
                            <Autocomplete
                                onChange={onchange}
                                value={selectedUser}
                                disablePortal
                                id="combo-box-demo"
                                options={users}
                                sx={{ width: "250px", marginRight: "12px" }}
                                renderInput={(params) => <TextField {...params} placeholder="학생을 선택하세요" />}
                            />
                        </div>
                    </div>
                    <div style={{
                        marginBottom: "32px"
                    }}>
                        <div style={{
                            fontSize: "20px",
                            fontFamily: "Apple_SB",
                            marginBottom: "18px"
                        }}>
                            - 선택된 학생의 현재 등록된 기기 리스트
                        </div>
                        <div style={{
                            display: "flex",
                            minHeight: "30px",
                        }}>
                            {
                                deviceList.map((each: any, index: number) => {
                                    return (
                                        <div key={index} style={{
                                            marginRight: "12px"
                                        }}>
                                            ({index + 1}) {each.name} ({each.macaddr})
                                        </div>
                                    )
                                })
                            }
                            {
                                deviceList.length === 0 &&
                                <div>
                                    -
                                </div>
                            }
                        </div>
                    </div>
                    {
                        selectedLocation &&
                        <div style={{
                            marginBottom: "32px",
                            width: "1200px"
                        }}>
                            <div style={{
                                fontSize: "20px",
                                fontFamily: "Apple_SB",
                                marginBottom: "18px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <span>
                                    - {selectedLocation}에 접속된 기기 리스트
                                    <span style={{
                                        marginLeft: "12px",
                                        paddingTop: "4px",
                                        cursor: "pointer"
                                    }}
                                        onClick={letsUpdate2}
                                    >
                                        <Autorenew fontSize="small" />
                                    </span>
                                </span>
                                <div>
                                    <TextField sx={{ fontSize: "24px", marginLeft: "12px" }} label="맥주소 검색" variant="standard" value={searchText} onChange={handleSearchTextChange} />
                                </div>
                            </div>
                            <div style={{ flexGrow: 1, height: 500, backgroundColor : "white" }} className={classes.root}>
                                <DataGridPremium
                                    rowHeight={40}
                                    loading={loading3}
                                    rows={rows2}
                                    columns={columns2}
                                    isRowSelectable={(params) => {
                                        if (params.row.hostname) {
                                            return false;
                                        }
                                        return true;
                                    }}
                                    filterModel={filterModel2}
                                    onFilterModelChange={(newModel) => { setFilterModel2(newModel); }}
                                    getCellClassName={(params) => {
                                        if (params.row.hostname) {
                                            return "notPermit"
                                        }
                                        return ""
                                    }}
                                    onRowClick={(params) => {
                                        console.log(params);

                                        if (params.row.hostname) {
                                            return;
                                        }

                                        setSelectedMacAddr(params.row.macaddr);
                                    }}
                                />
                            </div>
                            <div style={{
                                marginTop: "24px",
                                display: "flex",
                                justifyContent: "flex-end",
                                minHeight: "50px"
                            }}>
                                <div>
                                    <span style={{
                                        color: isRandomizedMacAddress ? "red" : "black"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}>선택된 맥 주소 : {selectedMacAddr}</div>
                                        <div>{isRandomizedMacAddress ? "(랜덤화된 맥주소일 확률이 높습니다. 학생의 기기를 검사해주세요)" : ""}</div>
                                    </span>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}>
                                <Button startDecorator={<HowToReg />} disabled={disabled}
                                onClick={submitAddress}
                                >
                                    주소 등록하기
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            }




            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ToastContainer />


        </div>
    )

}

export default WifiPermit;